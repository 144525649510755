import MainContentContainer from '@Components/MainContentContainer';
import Navbar from '@Components/Navbar';
import Step from './Step';
import useHook from './hook';
import Result from './Result';
import Weather from './Weather';
import Manpower from './Manpower';
import Activities from './Activities';
import Machinery from './Machinery';
import Materials from './Materials';
import DelayActivities from './DelayActivities';
import Attachment from './Attachment';
import Summary from './Summary';

export default ({ user }) => {
  const h = useHook({ user });
  return (
    <MainContentContainer>
      <Navbar
        link={`/project/report?id=${h.logDetail?.AssetId}`}
        text="Log Detail"
        subtext={h.logDetail?.name}
      />
      <Step {...h} />
      {{
        0: <Weather {...h} />,
        1: <Activities {...h} />,
        2: <Manpower {...h} />,
        3: <Materials {...h} />,
        4: <Machinery {...h} />,
        5: <DelayActivities {...h} />,
        6: <Attachment {...h} />,
        7: <Summary {...h} />,
        8: <Result {...h} />,
      }[h.activeStep]}
    </MainContentContainer>
  );
};
