/* eslint-disable max-lines */
/* eslint-disable linebreak-style */
import Button from '@Components/Button';
import { timeNow } from '@Helpers';
import {
  Grid, IconButton, InputLabel, MenuItem, TextField,
} from '@material-ui/core';
import { AddOutlined, Delete } from '@material-ui/icons';
import moment from 'moment';
import { useState } from 'react';

export default (h) => {
  const [onHover, setOnHover] = useState(false);
  const handleDelayActivities = (setData, value, idx, attribute) => {
    setData(prev => prev.map(
      (data, idx2) => (idx === idx2 ? ({ ...data, [attribute]: value }) : data),
    ));
  };
  const addNewRow = (data, setData) => {
    setData(prev => [...prev, {
      id: data.length + 1, code: '', name: '', location: '', start_time: timeNow, end_time: timeNow, reason_delay: '',
    }]);
  };
  const checkDelayActivity = h.delayActivitiesDataList.filter(e => e.name).length;

  return (
    <div className="w-75 mt-5 mx-auto" style={{ minHeight: '60vh' }}>
      <DelayActivitySection
        data={h.delayActivitiesDataList}
        setData={h.setDelayActivitiesDataList}
        hover={onHover}
        setHover={setOnHover}
        handleDelayActivities={handleDelayActivities}
        addNewRow={addNewRow}
      />
      <div className="d-flex justify-content-end mt-4">
        <Button variant="text" onClick={h.handleBackStep}>
          PREVIOUS
        </Button>
        <Button onClick={h.handleNextStep} disabled={!checkDelayActivity}>
          NEXT
        </Button>
      </div>
    </div>
  );
};

const activityDummyList = [
  'SURVEY WORK',
  'CONSTRUCTION',
  'EXCAVATION WORKS',
  'REMOVE AND REPLACE MATERIAL',
];

const DelayActivitySection = ({
  className = '', data, setData, hover, setHover, handleDelayActivities, addNewRow,
}) => (
  <Grid container xs={12} className={className}>
    {!data.length ? (
      <Grid item xs={12}>
        No Data
      </Grid>
    ) : (
      <Grid
        container
        item
        xs={12}
        className="p-4 mb-4"
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          border: '1px solid black',
          borderRadius: 10,
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          transform: hover ? 'scale(1.02)' : 'scale(1)',
          transition: 'all .5s',
        }}
      >
        {data.map((f, idx) => {
          return <DelayActivitiesRow f={f} idx={idx} handleDelayActivities={handleDelayActivities} data={data} setData={setData} />;
        })}
      </Grid>
    )}
    <Button
      className="mt-4 float-right w-100"
      style={{ backgroundColor: '#F7F7F7' }}
      onClick={() => addNewRow(data, setData)}
      disabled={data.length === activityDummyList.length}
    >
      <AddOutlined style={{ color: data.length === activityDummyList.length ? '#e9d8d8a1' : '#058DFA' }} />
      <p style={{ color: data.length === activityDummyList.length ? '#e9d8d8a1' : '#058DFA' }}>Add Delay Activities</p>
    </Button>
  </Grid>
);

const DelayActivitiesRow = ({
  f, idx, handleDelayActivities, data, setData,
}) => {
  return (
    <Grid container item xs={12} className="pt-3">
      <Header />
      <Grid item xs={1}>
        <TextField
          value={f.code}
          onChange={e => handleDelayActivities(setData, e.target.value, idx, 'code')}
          className="mb-0"
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
          disabled={!f.name}
        />
      </Grid>
      <Grid item xs={4} className="pl-2">
        <TextField
          select
          value={f.name}
          onChange={e => handleDelayActivities(setData, e.target.value, idx, 'name')}
          className="w-100 justify-content-center pt-1"
          variant="standard"
          InputLabelProps={{ shrink: true }}
        >
          {activityDummyList.map(z => (
            <MenuItem value={z} disabled={data.map(e => e.activity).includes(z)}>{z}</MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2} className="pl-2">
        <TextField
          value={f.location}
          onChange={e => handleDelayActivities(setData, e.target.value, idx, 'location')}
          className="mb-0"
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
          disabled={!f.name}
        />
      </Grid>
      <Grid item xs={2} className="pl-2">
        <TextField
          value={f.start_time}
          onChange={e => handleDelayActivities(setData, e.target.value, idx, 'start_time')}
          className="w-100 mb-0"
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
          type="time"
          disabled={!f.name}
        />
      </Grid>
      <Grid item xs={2} className="pl-2">
        <TextField
          value={f.end_time}
          onChange={e => handleDelayActivities(setData, e.target.value, idx, 'end_time')}
          className="w-100 mb-0"
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
          type="time"
          disabled={!f.name}
        />
      </Grid>
      {data.length > 1 && (
        <Grid item xs={1} className="d-flex align-items-center justify-content-center pt-2">
          <IconButton className="p-0" onClick={() => setData(prev => prev.filter((e, prev_idx) => idx !== prev_idx))}>
            <Delete style={{ color: 'red' }} />
          </IconButton>
        </Grid>
      )}
      <Grid item xs={12} className="mt-2">
        <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)' }}>Reason for the Delay</InputLabel>
      </Grid>
      <Grid item xs={12} className="">
        <TextField
          value={f.reason_delay}
          onChange={e => handleDelayActivities(setData, e.target.value, idx, 'reason_delay')}
          className="w-100 mb-0"
          variant="outlined"
          size="small"
          style={{ backgroundColor: '#EFEFEF' }}
          rows={4}
          InputLabelProps={{ shrink: true }}
          multiline
          disabled={!f.name}
        />
      </Grid>
    </Grid>
  );
};

const Header = () => (
  <Grid container item xs={12}>
    <Grid item xs={1}>
      <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)' }}>WBS Code</InputLabel>
    </Grid>
    <Grid item xs={4} className="pl-2">
      <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)' }}>Activity</InputLabel>
    </Grid>
    <Grid item xs={2} className="pl-2">
      <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)' }}>Location</InputLabel>
    </Grid>
    <Grid item xs={2} className="pl-2">
      <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)' }}>Start Time</InputLabel>
    </Grid>
    <Grid item xs={2} className="pl-2">
      <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)' }}>End Time</InputLabel>
    </Grid>
  </Grid>
);
