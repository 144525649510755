import { makeStyles } from '@material-ui/core';
import { useRef } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';

const useStyles = makeStyles({
  full_width: {
    width: '100%',
    height: '100%',
  },
});

export default ({ setSignature }) => {
  const classes = useStyles();
  const sigCanvas = useRef();
  return (
    <>
      <div style={{ border: '1px solid black' }} className="w-100">
        <ReactSignatureCanvas
          canvasProps={{ className: classes.full_width }}
          ref={sigCanvas}
          onEnd={() => {
            const URL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
            setSignature(URL);
          }}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p>Draw your signature into the box above</p>
        <button
          onClick={() => {
            sigCanvas.current.clear();
            setSignature(null);
          }}
        >
          Clear
        </button>
      </div>
    </>
  );
}
