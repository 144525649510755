import { ArrowRight } from '@material-ui/icons';
import React from 'react';
import Table from '@Components/MaterialTable/v8';
import { Link } from 'react-router-dom';

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
};

const cardCss = {
  backgroundColor: 'white',
  borderRadius: 10,
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  padding: 13,
  position: 'relative',
  height: '93%',
};

const assetProgressListContainer = {
  maxHeight: '80%',
  overflowY: 'auto',
};

const CardTitle = ({ title }) => (<p style={{ color: '#605f65', fontSize: 12 }}>{title}</p>);

export default function ProjectProgress(props) {
  return (
    <div style={{ ...cardCss }}>
      <div style={{ ...flexCenter, justifyContent: 'space-between' }}>
        <CardTitle title={props.title} />
      </div>
      <div className="hide-scroll" style={{ ...assetProgressListContainer }}>
        <Table tableData={props.assets} />
      </div>
      <Link to="/dashboard/project-progress" target="_top">
        <div style={{
          ...flexCenter, justifyContent: 'flex-end', position: 'absolute', right: 10, bottom: 10,
        }}
        >
          <p style={{ color: '#605f65', fontSize: 10, cursor: 'pointer' }}>See Details</p>
          <ArrowRight style={{ color: '#605f65', fontSize: 18 }} />
        </div>
      </Link>
    </div>
  );
}
