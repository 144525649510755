/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const WideView = (props) => {
  const transformComponentRef = useRef(null);

  const Control = ({ zoomIn, zoomOut, resetTransform }) => (
    <>
      <button onClick={() => zoomIn()}>+</button>
      <button onClick={() => zoomOut()}>-</button>
      <button onClick={() => resetTransform()}>x</button>
    </>
  );

  return (
    <TransformWrapper
      initialScale={1}
      initialPositionX={0}
      initialPositionY={0}
      ref={transformComponentRef}
      wheel={{ disabled: false, step: 0.1 }}
      panning={{ velocityDisabled: true }}
      doubleClick={{ disabled: true }}
      centerOnInit
    >
      {(utils) => (
        <>
          {props.hasControl && (<Control {...utils} />)}
          <TransformComponent
            wrapperStyle={{ width: '60vw', cursor: 'move' }}
          >
            <img src={props.imageUrl} alt={`${props.imageUrl}`} style={{ height: '75vh' }} />
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  );
};

export default WideView;
