/* eslint-disable complexity */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';

export default function Discipline({ style, data, ...props }) {
  return (
    <Box
      item
      xs={6}
      md={4}
      className="marker-card"
      style={style}
    >
      <img
        src={`${process.env.REACT_APP_S3}/${data.thumbnail}`}
        alt="Thumbnail"
        style={{
          width: '250px',
          height: '200px',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      />
      <div style={{ padding: '10px' }}>
        <h6 style={{ margin: '0', fontSize: '12px' }}>Discipline: {data.DisciplineId ? data['Discipline.name'] : 'N/A'}</h6>
        <h6 style={{ margin: '0', fontSize: '12px' }}>Activity: {data.activity ?? 'N/A'}</h6>
        <h6 style={{ margin: '0', fontSize: '12px' }}>Date: {data.createdAt ? moment(data.createdAt).format('DD/MM/YYYY') : 'N/A'}</h6>
        <h6 style={{ margin: '0', fontSize: '12px' }}>Location: {data.address ?? 'N/A'}</h6>
      </div>
    </Box>
  );
}
