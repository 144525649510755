import { useEffect, useState } from 'react';
import moment from 'moment';
import Api, { endpoints } from '@Helpers/api';
import queryString from 'query-string';
import { useParams, useHistory } from 'react-router-dom';
import { today } from '@Helpers';

export default () => {
  const history = useHistory();
  const { AssetId } = useParams();
  const [asset, setAsset] = useState();
  const [phases, setPhases] = useState([]);
  const [filteredPhases, setFilteredPhases] = useState([]);
  const [category, setCategory] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [files, setFiles] = useState([]);
  const [uploadPercentages, setUploadPercentages] = useState([0]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [masterlistName, setMasterlistName] = useState('');
  const [masterlistDate, setMasterlistDate] = useState(today);
  const [masterlistType, setMasterlistType] = useState(0);
  const [masterlistOptions, setMasterlistOptions] = useState([]);

  const handleNextStep = () => { setActiveStep((prev) => prev + 1); };
  const handleBackStep = () => { setActiveStep((prev) => prev - 1); };

  const paymentCallback = (paymentModel) => {
    UploadFiles(AssetId);
  };
  const UploadFiles = (cpid) => {
    if (!files.length) return;
    setUploadPercentages([...Array(files.length)].map(() => 0));
    files.forEach((file, idx) => {
      const xhr = Api({
        endpoint: endpoints.newAssetFile(),
        data: {
          label: masterlistName,
          name: masterlistName,
          AssetId,
          type: 'masterlist',
          media_type: 'masterlist',
        },
        files: [file],
        uploadPercent: (p) => updatePercentage(idx, p),
        onSuccess: () => {
          toast('success', `${file.name} uploaded`);
          updatePercentage(idx, 'done');
          // redirect immediately when no payment happend
          history.push(`/asset/${AssetId}`);
          // window.location.reload(true);
        },
        onFail: () => {
          toast('error', 'Something went wrong, please try again.');
        },
      });
      setUploadFiles(prev => [...prev, xhr]);
    });
  };

  const updatePercentage = (i, p) => {
    if (p === 100) p = 99;
    if (p === 'done') p = 100;
    setUploadPercentages(arr => { arr[i] = p; return [...arr]; });
  };

  useEffect(() => {
    setUploadPercentages([...Array(files.length)].map(() => 0));
  }, [files]);
  const getAssetPhase = (id) => {
    Api({
      endpoint: endpoints.showAssets(id),
      onSuccess: ({ data }) => setAsset(data),
      onFail: () => toast('error', 'Error getting assets data. Please try again later.'),
    });
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => {
        setPhases(data.projectphase);
      },
      onFail: (err) => toast('error', err),
    });
  };

  const getMasterlistTypes = () => {
    Api({
      endpoint: endpoints.getMasterlistOptions(),
      onSuccess: ({ data }) => {
        setMasterlistOptions(data);
      },
      onFail: () => toast('error', 'Error getting masterlist options'),
    })
  }

  useEffect(() => {
    getAssetPhase(AssetId);
    getMasterlistTypes();
  }, [AssetId]);
  useEffect(() => {
    if (asset && phases) {
      setFilteredPhases(phases.filter(p => asset.selectedPhase.split(',').map(x => Number(x)).includes(p.id)));
    }
  }, [asset, phases]);

  return {
    AssetId,
    filteredPhases,
    category,
    setCategory,
    activeStep,
    setActiveStep,
    handleNextStep,
    asset,
    handleBackStep,
    files,
    setFiles,
    uploadPercentages,
    paymentCallback,
    uploadFiles,
    masterlistDate,
    masterlistName,
    masterlistType,
    setMasterlistDate,
    setMasterlistName,
    setMasterlistType,
    masterlistOptions,
    setMasterlistOptions,
  };
};
