/* eslint-disable complexity */
import { Grid, Tabs, Tab, Divider } from '@material-ui/core';
import { useState } from 'react';
import { WeatherIcons } from '@Assets/Icons/WeatherIcons';
import IotIcon from '@Assets/Icons/iot_icon.svg';
import CurrentWeatherCard from '@Components/DashboardCard/CurrentWeatherCard';
import IotReading from '@Components/DashboardCard/IotReading';

const cardCss = {
  backgroundColor: 'white',
  borderRadius: 10,
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  padding: 13,
  position: 'relative',
  height: '100%',
};

const WeatherIconCss = {
  transform: 'translate(0, 0%)', flex: 1,
};

const CardTitle = ({ title }) => (<p style={{ color: '#605f65', fontSize: 12 }}>{title ?? 'Status'}</p>);

const subtractDays = (date, day) => {
  date.setDate(date.getDate() - day);
  return date;
};
const generateDaysReverse = () => [...Array(7)].map((v, didx) => {
  const newDate = subtractDays(new Date(), didx);
  return `${newDate.getFullYear()}/${(newDate.getMonth() + 1)}/${newDate.getDate()}`;
});

const Days = {
  0: 'Sunday', 1: 'Monday', 2: 'Tuesday', 3: 'Wednesday', 4: 'Thursday', 5: 'Friday', 6: 'Saturday',
};

export default function index(props) {
  const [tab_value, set_tab_value] = useState(0);
  const handleChange = (event, newValue) => { set_tab_value(newValue); };
  const days = generateDaysReverse();
  const currentDateWeather = props.data[days[0]];
  const IotReadingTitle = ['Temperature', 'Wind', 'Precipitation', 'Humidity', 'Chance of Rain', 'UV Index'];
  const IotReadingValue = ['18', '5km/h', '56%', '90%', '87%', '4'];
  return (
    <div style={{ ...cardCss }}>
      <div className="d-flex align-items-center">
        <CardTitle title="Weather" />
        <img src={IotIcon} style={{ marginLeft: 10 }} />
      </div>
      <br />
      <Grid container xs={12} md={12}>
        <Grid item xs={12} md={12}>
          <Tabs
            value={tab_value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {[{ id: 'current' }, { id: 'past' }].map(e => <Tab label={e.id} style={{ minWidth: '50%', fontSize: 12 }} />)}
          </Tabs>
        </Grid>
        {
          !tab_value ? (
            <>
              <Grid item xs={12} md={12} className="my-3">
                <CurrentWeatherCard {...currentDateWeather.average} />
              </Grid>
              {currentDateWeather.byTime[0].others.map((readings, idx) => <IotReading {...readings} title={IotReadingTitle[idx]} value={IotReadingValue[idx]} />)}
            </>
          ) : (
            <>
              <Grid item xs={12} md={12} className="mt-3">
                <div style={{
                  display: 'flex', flexDirection: 'row',
                }}
                >
                  {currentDateWeather.byTime.map((readings) => (
                    <div style={{
                      display: 'flex', flexDirection: 'column', flex: 1, fontSize: '0.8rem', paddingLeft: 10,
                    }}
                    >
                      <p className="flex-standard" style={{ color: '#c8c8ca', fontSize: 15 }}>{readings.time}</p>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <img src={WeatherIcons[readings.w]} style={{ flex: 1, width: '40%' }} />
                        <p style={{ flex: 3 }}>&nbsp;&nbsp;{readings.t}°C</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider className="my-3" style={{ height: 3, backgroundColor: '#5bb2cf' }} />
                <div style={{
                  display: 'flex', flexDirection: 'column',
                }}
                >
                  {days.map(d => (
                    <div style={{ display: 'flex', flexDirection: 'row', height: '30px', marginBottom: 5, fontSize: '0.8rem' }}>
                      <p className="d-flex align-items-center" style={{ flex: 2 }}>&nbsp;&nbsp;{Days[(new Date(d).getDay())]}</p>
                      <img src={WeatherIcons[1]} style={{ ...WeatherIconCss }} />
                      <p className="flex-standard" style={{ flex: 1 }}>&nbsp;&nbsp;24°C</p>
                      <p className="flex-standard" style={{ flex: 1 }}>&nbsp;&nbsp;24°C</p>
                    </div>
                  ))}
                </div>
              </Grid>
            </>
          )
        }
      </Grid>
    </div>
  );
}
