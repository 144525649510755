import React from 'react';
import useProgressiveImg from './useProgressiveImg';

export default ({ path, style, ...imgbody }) => {
  const [src, { blur }] = useProgressiveImg(`${process.env.REACT_APP_S3}/static/media/dummyblur.webp`, path);
  return (
    <img
      src={src}
      style={{
        filter: blur ? 'blur(20px)' : 'none',
        transition: blur ? 'none' : 'filter 0.3s ease-out',
        ...style,
      }}
      {...imgbody}
    />
  );
};
