import React, { useEffect, useState } from 'react';
import {
  makeStyles, Tooltip, withStyles,
} from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';

const MonthLineCalender = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const sortData = arr => arr.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
  const CheckDateInMonth = (date) => {
    return moment(date)
      .isBetween(
        moment(props.startDate).startOf('month').format('YYYY-MM-DD'),
        moment(props.startDate).startOf('month').add(1, 'months').format('YYYY-MM-DD'),
      );
  };

  const monthName = item => moment(item.createdAt, 'YYYY-MM-DD').format('YYYY-MM-DD');

  useEffect(() => {
    const emptyArray = [];
    const initialList = [...sortData(props.data)];
    const filterList = initialList.filter(e => CheckDateInMonth(e.createdAt));
    const groupBy = _.groupBy(filterList, monthName);
    for (let mIdx = 1; mIdx <= moment(props.startDate).daysInMonth(); mIdx++) {
      const date = moment(`${moment(props.startDate).format('YYYY-MM')}-${mIdx}`, 'YYYY-MM-D').format('YYYY-MM-DD');
      const checkDateInFuture = moment(date).isBetween(moment(), moment(props.startDate).startOf('month').add(1, 'months').format('YYYY-MM-DD'));
      if (groupBy[date]) {
        const arr = groupBy[date];
        emptyArray.push(arr[arr.length - 1]);
      } else if (checkDateInFuture) emptyArray.push({ id: date, createdAt: date, status: 'not_available' });
      else if (!emptyArray.find(e => e.inspectionAt === date)) emptyArray.push({ id: date, createdAt: date, status: null });
    }
    setData(emptyArray.map(e => ({ ...e, createdAt: monthName(e) })));
  }, [props.data, props.startDate]);

  const MonthLineCalenderLegend = () => {
    return (
      <div className={classes.legendContainer}>
        {legendData.map((legendValue) => (
          <div className={classes.legendItem}>
            <span
              className={classes.legendSquare}
              style={{ backgroundColor: legendValue.color }}
            />
            <span className={classes.legendValue}>{legendValue.value}</span>
          </div>
        ))}
      </div>
    );
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#ffffff',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  return (
    <div>
      <div className="flex-standard mb-1">
        <p style={{ fontSize: 14 }}>{moment(props.startDate).format('MMMM YYYY')}</p>
      </div>
      <div className="d-flex mb-3">
        {data.map(e => (
          <div className={classes.legendItem}>
            <HtmlTooltip
              placement="top"
              title={(
                <>
                  <div className="p-1 flex-standard">
                    <div style={{ width: 12, height: 12, backgroundColor: legendData.find(f => f.status === e.status)?.color }} className="mr-2" />
                    <p className="mr-2">{moment(e.createdAt).format('DD-MM-YYYY')}</p>
                    <p>{legendData.find(f => f.status === e.status)?.value}</p>
                  </div>
                </>
              )}
            >
              <span
                className={classes.calenderSquare}
                style={{ backgroundColor: legendData.find(f => f.status === e.status)?.color }}
              />
            </HtmlTooltip>
          </div>
        ))}
      </div>
      <MonthLineCalenderLegend />
    </div>
  );
};

export default MonthLineCalender;

const useStyles = makeStyles(() => ({
  legendContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  legendItem: {
    display: 'flex',
    paddingLeft: '8px',
    paddingRight: '8px',
    alignItems: 'center',
  },
  legendValue: {
    marginTop: 'auto',
    marginRight: 'auto',
    fontSize: '13px',
    fontFamily: 'CeraProRegular',
  },
  calenderSquare: {
    height: '15px',
    width: '15px',
    marginRight: '2px',
  },
  legendSquare: {
    height: '10px',
    width: '10px',
    marginRight: '4px',
  },
}));

const legendData = [
  {
    value: 'Missing Report',
    status: null,
    color: 'red',
  },
  {
    value: 'Merged',
    status: 'Merged',
    color: 'orange',
  },
  {
    value: 'Submitted',
    status: 'Submited',
    color: 'purple',
  },
  {
    value: 'Rejected',
    status: 'Rejected',
    color: 'yellow',
  },
  {
    value: 'Approved',
    status: 'Approved',
    color: '#2196F3',
  },
  {
    value: 'Completed',
    status: 'Completed',
    color: '#39c890',
  },
  {
    value: 'Not Available',
    status: 'not_available',
    color: '#bebcbc',
  },
];
