/* eslint-disable max-lines */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid, Button, Divider, Tooltip, TextField, MenuItem, Chip,
} from '@material-ui/core';
import { ChevronLeft, DateRange, DescriptionOutlined, InfoOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import total_issue from '@Assets/Icons/total_issue.svg';
import closed_issue from '@Assets/Icons/closed_issue.svg';
import open_issue from '@Assets/Icons/open_issue.svg';
import MultilineChart from '@Components/MultilineChart/v4';
import _ from 'lodash';

const useStyles = makeStyles({
  container: {
    height: '-webkit-fill-available',
    padding: 10,
  },
  card: {
    marginTop: 20,
    padding: 10,
    minHeight: 'max-content',
    // height: 180,
  },
});
export default function view(props) {
  const classes = useStyles();
  const [filter, setFilter] = useState(4);
  const [dateRange, setDateRange] = useState('monthly');
  const [tableData, setTableData] = useState([]);
  const [chartData, setChartData] = useState([]);

  const CalculateOpenCase = (arr) => {
    const result = [];
    for (let i = ['weekly'].includes(dateRange) ? 6 : 11; i >= 0; i--) {
      const currentDate = moment().subtract(i, ['weekly'].includes(dateRange) ? 'days' : 'months');
      const formattedDate = currentDate.format(['weekly'].includes(dateRange) ? 'ddd' : 'MMM');
      const count = arr.filter(item => item.is_open && moment(item.createdAt).isSame(currentDate, ['weekly'].includes(dateRange) ? 'day' : 'month')).length;
      result.push({ x: formattedDate, y: count });
    }
    return result;
  }

  useEffect(() => {
    if (!props.ncrReport.length) return;
    const checkNcr = Object.values(_.groupBy(props.ncrReport, 'AssetId')).map(report => ({
      title: report[0]?.Asset.name,
      reports: report,
      data: CalculateOpenCase(report),
    })).sort((a, b) => b.open_ncr - a.open_ncr).slice(0, 5);
    setChartData(checkNcr);
  }, [props.ncrReport, dateRange]);

  useEffect(() => {
    if (!props.ncrReport.length) return;
    const sortingArray = {
      1: props.ncrReport.sort((a, b) => a.Asset?.name.localeCompare(b.Asset?.name)).slice(0, 3),
      2: props.ncrReport.sort((b, a) => a.Asset?.name.localeCompare(b.Asset?.name)).slice(0, 3),
      3: props.ncrReport.sort((a, b) => a.createdAt.localeCompare(b.createdAt)).slice(0, 3),
      4: props.ncrReport.sort((b, a) => a.createdAt.localeCompare(b.createdAt)).slice(0, 3),
    }[filter];
    setTableData(sortingArray);
  }, [filter, props.ncrReport]);

  const checkStage = (h) => {
    if (!h.NonConformityReportFiles?.length) return 'Part A';
    const sortArr = h.NonConformityReportFiles.sort((a, b) => b.stage - a.stage);
    const stage = {
      1: 'Part A', 2: 'Part B1', 3: 'Part B', 4: 'Part C', 5: 'Part D', 6: 'Part E',
    }[sortArr[0].stage];
    return stage;
  }
  return (
    <Grid container className={classes.container}>
      <Grid container item xs={12}>
        <Grid item xs={10} md={10}>
          <p className="d-flex align-items-center">
            <Link to="/dashboard" style={{ marginBlock: 20, color: 'grey' }} className="d-flex align-items-center">
              <ChevronLeft />Overview/
            </Link>
            <span style={{ color: 'blue' }}>Non Conformity Report</span>
          </p>
          <h1 className="pl-3" style={{ fontWeight: 600, marginTop: 20, fontSize: 20 }}>Non Conformity Report</h1>
          <p className="pl-3" style={{ marginTop: 10, marginBottom: 20, color: 'grey', fontSize: 14 }}>Manage and track your site non-conformity issue.</p>
        </Grid>
        {/* <Grid item xs={2} md={2} style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            style={{
              backgroundColor: '#2960f6', color: 'white', textTransform: 'none',
            }}
          >
            <DescriptionOutlined style={{ width: 20, marginRight: 5 }} />
            Generate Report
          </Button>
        </Grid> */}
      </Grid>
      <Grid item xs={12}>
        <Divider className="mt-2" style={{ height: 2, backgroundColor: '#dbdbdb' }} />
      </Grid>
      <Grid item xs={12} className={classes.card} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <div className="py-2 px-1" style={{ transform: 'scale(0.8)' }}>
          <DateRange />
          <TextField
            select
            variant="standard"
            value={dateRange}
            onChange={e => setDateRange(e.target.value)}
            InputProps={{ disableUnderline: true, style: { fontFamily: 'CeraProRegular' } }}
            style={{ marginLeft: 15 }}
          >
            <MenuItem value="weekly" style={{ fontFamily: 'CeraProRegular', fontSize: 12 }}>Weekly</MenuItem>
            <MenuItem value="monthly" style={{ fontFamily: 'CeraProRegular', fontSize: 12 }}>Monthly</MenuItem>
          </TextField>
        </div>
      </Grid>
      <div className="d-flex w-100" style={{ padding: 10, marginTop: 20 }}>
        {[
          { title: 'Total Issue', value: props.ncrReport.length, icon: total_issue },
          { title: 'Open Issue', value: props.ncrReport.filter(f => !!f.is_open).length, icon: closed_issue },
          { title: 'Closed Issue', value: props.ncrReport.filter(f => !f.is_open).length, icon: open_issue },
        ].map((e, idx) => (
          <div
            className="d-flex mx-2 p-3"
            style={{
              flex: 1, backgroundColor: (idx % 2) ? '#BDD6EB' : '#F8FBF9', borderRadius: 7,
            }}
          >
            <div style={{ padding: 15, backgroundColor: (idx % 2) ? '#F8FBF9' : '#BDD6EB', borderRadius: 7 }}>
              <img src={e.icon} />
            </div>
            <div className="d-flex flex-column ml-2 font-weight-bolder text-uppercase">
              <p style={{ fontSize: 12 }}>{e.title}</p>
              <p style={{ fontSize: 22 }}>{e.value}</p>
            </div>
          </div>
        ))}
      </div>
      <Grid item xs={12} className={classes.card}>
        <Chart title="Highest Open Issue" data={chartData} />
      </Grid>
      <Grid container item xs={12} style={{ padding: 10, marginTop: 30 }}>
        <Grid item xs={12} className="d-flex justify-content-between align-items-center">
          <p className="font-weight-bolder text-title text-uppercase" style={{ color: '#a4a4a4' }}>Recent Issue Raised</p>
          <div className="d-flex align-items-center" style={{ transform: 'scale(0.8)' }}>
            <p style={{ color: '#a4a4a4' }}>Sort By: </p>
            <TextField
              select
              variant="standard"
              value={filter}
              onChange={e => setFilter(e.target.value)}
              InputProps={{ disableUnderline: true, style: { fontFamily: 'CeraProRegular' } }}
              style={{ marginLeft: 15 }}
            >
              <MenuItem value={1} style={{ fontFamily: 'CeraProRegular', fontSize: 12 }}>A --&#8594; Z</MenuItem>
              <MenuItem value={2} style={{ fontFamily: 'CeraProRegular', fontSize: 12 }}>Z --&#8594; A</MenuItem>
              <MenuItem value={3} style={{ fontFamily: 'CeraProRegular', fontSize: 12 }}>Oldest --&#8594; Latest</MenuItem>
              <MenuItem value={4} style={{ fontFamily: 'CeraProRegular', fontSize: 12 }}>Latest --&#8594; Oldest</MenuItem>
            </TextField>
          </div>
        </Grid>
        <Grid item xs={12} className="mt-3" style={{ border: 'solid #F2F2F2' }}>
          <Grid item xs={12} className="d-flex" style={{ backgroundColor: '#f1f1f1', color: '#a4a4a4', fontSize: 14 }}>
            <div style={{ flex: 1 }} className="text-title py-3 font-weight-bolder d-flex justify-content-center">No</div>
            <div style={{ flex: 2 }} className="text-title py-3 font-weight-bolder">Date Issued</div>
            <div style={{ flex: 2 }} className="text-title py-3 font-weight-bolder">Issue Description</div>
            <div style={{ flex: 2 }} className="text-title py-3 font-weight-bolder d-flex justify-content-center">Site</div>
            <div style={{ flex: 1 }} className="text-title py-3 font-weight-bolder d-flex justify-content-center">Issued By</div>
            <div style={{ flex: 1 }} className="text-title py-3 font-weight-bolder d-flex justify-content-center">Stage</div>
            <div style={{ flex: 2 }} className="text-title py-3 font-weight-bolder d-flex justify-content-center">Status</div>
          </Grid>
          {tableData.map(e => (
            <Grid item xs={12} className="d-flex" style={{ fontSize: 13, color: 'black', borderTop: 'solid #F2F2F2' }}>
              <p style={{ flex: 1 }} className="py-3 d-flex justify-content-center">{e.id}</p>
              <p style={{ flex: 2 }} className="py-3 ">{moment(e.createdAt).format('DD/MM/YYYY hh:mm A')}</p>
              <p style={{ flex: 2 }} className="py-3 ">{e.NonConformityReportFiles.find(f => [1].includes(f.stage))?.details.description}</p>
              <p style={{ flex: 2 }} className="py-3 d-flex justify-content-center">{e.Asset?.name}</p>
              <p style={{ flex: 1 }} className="py-3 d-flex justify-content-center">{e.created_by}</p>
              <p style={{ flex: 1 }} className="py-3 d-flex justify-content-center">{checkStage(e)}</p>
              <div style={{ flex: 2 }} className="py-3 d-flex justify-content-center">
                <Chip
                  label={e.is_open ? 'Open' : 'Closed'}
                  className="text-standard"
                  style={{
                    // width: '30%',
                    height: '1.5rem',
                    backgroundColor: e.is_open ? '#ffc542' : '#3dd598',
                    color: 'black',
                  }}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

const Chart = (props) => {
  return (
    <div style={{ ...cardCss }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 className="text-title-lg">{props.title}</h3>
          <Tooltip title="Title text goes here">
            <InfoOutlined style={{ width: 10, color: '#605f65', marginLeft: 5 }} />
          </Tooltip>
        </div>
      </div>
      <div style={{ ...flexCenter, justifyContent: 'space-between', height: '100%' }}>
        <MultilineChart chartData={props.data} />
      </div>
    </div>
  );
};

const cardCss = {
  backgroundColor: 'white',
  borderRadius: 10,
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  padding: 13,
  position: 'relative',
  height: '100%',
};

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
};

const dataDay = [
  {
    title: 'Hospital Lawas',
    data: [
      { x: moment().subtract(6, 'days').format('ddd'), y: 7 },
      { x: moment().subtract(5, 'days').format('ddd'), y: 10 },
      { x: moment().subtract(4, 'days').format('ddd'), y: 2 },
      { x: moment().subtract(3, 'days').format('ddd'), y: 4 },
      { x: moment().subtract(2, 'days').format('ddd'), y: 3 },
      { x: moment().subtract(1, 'days').format('ddd'), y: 7 },
      { x: moment().format('ddd'), y: 2 },
    ],
  },
  {
    title: 'Hospital Petrajaya',
    data: [
      { x: moment().subtract(6, 'days').format('ddd'), y: 7 },
      { x: moment().subtract(5, 'days').format('ddd'), y: 4 },
      { x: moment().subtract(4, 'days').format('ddd'), y: 10 },
      { x: moment().subtract(3, 'days').format('ddd'), y: 10 },
      { x: moment().subtract(2, 'days').format('ddd'), y: 9 },
      { x: moment().subtract(1, 'days').format('ddd'), y: 10 },
      { x: moment().format('ddd'), y: 2 },
    ],
  },
  {
    title: 'NCDC',
    data: [
      { x: moment().subtract(6, 'days').format('ddd'), y: 9 },
      { x: moment().subtract(5, 'days').format('ddd'), y: 3 },
      { x: moment().subtract(4, 'days').format('ddd'), y: 7 },
      { x: moment().subtract(3, 'days').format('ddd'), y: 10 },
      { x: moment().subtract(2, 'days').format('ddd'), y: 3 },
      { x: moment().subtract(1, 'days').format('ddd'), y: 6 },
      { x: moment().format('ddd'), y: 0 },
    ],
  },
  {
    title: 'Residensi Brickfields',
    data: [
      { x: moment().subtract(6, 'days').format('ddd'), y: 1 },
      { x: moment().subtract(5, 'days').format('ddd'), y: 2 },
      { x: moment().subtract(4, 'days').format('ddd'), y: 7 },
      { x: moment().subtract(3, 'days').format('ddd'), y: 3 },
      { x: moment().subtract(2, 'days').format('ddd'), y: 8 },
      { x: moment().subtract(1, 'days').format('ddd'), y: 4 },
      { x: moment().format('ddd'), y: 5 },
    ],
  },
  {
    title: 'Station 9 Glenmarie 2 LRT3',
    data: [
      { x: moment().subtract(6, 'days').format('ddd'), y: 9 },
      { x: moment().subtract(5, 'days').format('ddd'), y: 7 },
      { x: moment().subtract(4, 'days').format('ddd'), y: 2 },
      { x: moment().subtract(3, 'days').format('ddd'), y: 3 },
      { x: moment().subtract(2, 'days').format('ddd'), y: 1 },
      { x: moment().subtract(1, 'days').format('ddd'), y: 4 },
      { x: moment().format('ddd'), y: 6 },
    ],
  },
];

const dataMonth = [
  {
    title: 'Hospital Lawas',
    data: [
      { x: moment().subtract(11, 'months').format('MMM'), y: 5 },
      { x: moment().subtract(10, 'months').format('MMM'), y: 10 },
      { x: moment().subtract(9, 'months').format('MMM'), y: 1 },
      { x: moment().subtract(8, 'months').format('MMM'), y: 10 },
      { x: moment().subtract(7, 'months').format('MMM'), y: 4 },
      { x: moment().subtract(6, 'months').format('MMM'), y: 5 },
      { x: moment().subtract(5, 'months').format('MMM'), y: 6 },
      { x: moment().subtract(4, 'months').format('MMM'), y: 9 },
      { x: moment().subtract(3, 'months').format('MMM'), y: 10 },
      { x: moment().subtract(2, 'months').format('MMM'), y: 8 },
      { x: moment().subtract(1, 'months').format('MMM'), y: 9 },
      { x: moment().format('MMM'), y: 5 },
    ],
  },
  {
    title: 'Hospital Petrajaya',
    data: [
      { x: moment().subtract(11, 'months').format('MMM'), y: 1 },
      { x: moment().subtract(10, 'months').format('MMM'), y: 7 },
      { x: moment().subtract(9, 'months').format('MMM'), y: 6 },
      { x: moment().subtract(8, 'months').format('MMM'), y: 1 },
      { x: moment().subtract(7, 'months').format('MMM'), y: 8 },
      { x: moment().subtract(6, 'months').format('MMM'), y: 7 },
      { x: moment().subtract(5, 'months').format('MMM'), y: 4 },
      { x: moment().subtract(4, 'months').format('MMM'), y: 10 },
      { x: moment().subtract(3, 'months').format('MMM'), y: 10 },
      { x: moment().subtract(2, 'months').format('MMM'), y: 5 },
      { x: moment().subtract(1, 'months').format('MMM'), y: 2 },
      { x: moment().format('MMM'), y: 8 },
    ],
  },
  {
    title: 'NCDC',
    data: [
      { x: moment().subtract(11, 'months').format('MMM'), y: 1 },
      { x: moment().subtract(10, 'months').format('MMM'), y: 8 },
      { x: moment().subtract(9, 'months').format('MMM'), y: 9 },
      { x: moment().subtract(8, 'months').format('MMM'), y: 4 },
      { x: moment().subtract(7, 'months').format('MMM'), y: 5 },
      { x: moment().subtract(6, 'months').format('MMM'), y: 3 },
      { x: moment().subtract(5, 'months').format('MMM'), y: 8 },
      { x: moment().subtract(4, 'months').format('MMM'), y: 6 },
      { x: moment().subtract(3, 'months').format('MMM'), y: 4 },
      { x: moment().subtract(2, 'months').format('MMM'), y: 6 },
      { x: moment().subtract(1, 'months').format('MMM'), y: 1 },
      { x: moment().format('MMM'), y: 4 },
    ],
  },
  {
    title: 'Residensi Brickfields',
    data: [
      { x: moment().subtract(11, 'months').format('MMM'), y: 3 },
      { x: moment().subtract(10, 'months').format('MMM'), y: 7 },
      { x: moment().subtract(9, 'months').format('MMM'), y: 6 },
      { x: moment().subtract(8, 'months').format('MMM'), y: 10 },
      { x: moment().subtract(7, 'months').format('MMM'), y: 1 },
      { x: moment().subtract(6, 'months').format('MMM'), y: 9 },
      { x: moment().subtract(5, 'months').format('MMM'), y: 6 },
      { x: moment().subtract(4, 'months').format('MMM'), y: 2 },
      { x: moment().subtract(3, 'months').format('MMM'), y: 7 },
      { x: moment().subtract(2, 'months').format('MMM'), y: 10 },
      { x: moment().subtract(1, 'months').format('MMM'), y: 7 },
      { x: moment().format('MMM'), y: 2 },
    ],
  },
  {
    title: 'Station 9 Glenmarie 2 LRT3',
    data: [
      { x: moment().subtract(11, 'months').format('MMM'), y: 5 },
      { x: moment().subtract(10, 'months').format('MMM'), y: 7 },
      { x: moment().subtract(9, 'months').format('MMM'), y: 10 },
      { x: moment().subtract(8, 'months').format('MMM'), y: 2 },
      { x: moment().subtract(7, 'months').format('MMM'), y: 10 },
      { x: moment().subtract(6, 'months').format('MMM'), y: 4 },
      { x: moment().subtract(5, 'months').format('MMM'), y: 4 },
      { x: moment().subtract(4, 'months').format('MMM'), y: 3 },
      { x: moment().subtract(3, 'months').format('MMM'), y: 10 },
      { x: moment().subtract(2, 'months').format('MMM'), y: 9 },
      { x: moment().subtract(1, 'months').format('MMM'), y: 10 },
      { x: moment().format('MMM'), y: 5 },
    ],
  },
];
