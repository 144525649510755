/* eslint-disable max-lines */
import { useState, useEffect } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { Link } from 'react-router-dom';
import {
  Grid, Button, Divider, Tooltip, TextField, MenuItem, Chip,
} from '@material-ui/core';
import { ChevronLeft, DescriptionOutlined, InfoOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import PieChart from '@Components/PieChart/v3';
import moment from 'moment';
import TimeRangeChart from '@Components/TimeRangeChart';
import _ from 'lodash';

const useStyles = makeStyles({
  container: {
    height: '-webkit-fill-available',
    padding: 10,
  },
  card: {
    marginTop: 20,
    padding: 10,
    minHeight: 'max-content',
    // height: 180,
  },
});
export default function view(props) {
  const classes = useStyles();
  const [calenderFromDate, setCalenderFromDate] = useState(moment().subtract(6, 'months').format('YYYY-MM-DD'));
  const [calenderToDate, setCalenderToDate] = useState(moment().format('YYYY-MM-DD'));
  const [calenderChartData, setCalenderChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState(1);

  useEffect(() => {
    if (!tableData.length) return;
    const sortingArray = {
      1: tableData.slice().sort((a, b) => a['Cctv.Asset.name'].localeCompare(b['Cctv.Asset.name'])),
      2: tableData.slice().sort((b, a) => a['Cctv.Asset.name'].localeCompare(b['Cctv.Asset.name'])),
      3: tableData.slice().sort((a, b) => a.Date.localeCompare(b.Date)),
      4: tableData.slice().sort((b, a) => a.Date.localeCompare(b.Date)),
    }[filter];
    setTableData(sortingArray);
  }, [filter]);
  useEffect(() => {
    Api({
      endpoint: endpoints.getCCTVStatus(),
      // data: { CctvId: 2 },
      onSuccess: ({ data }) => {
        const groupedData = _.chain(data)
          .groupBy(obj => obj.captureAt.substr(0, 10))
          .map((group, day) => ({ value: _.sumBy(group, 'isOnline'), day }))
          .value();
        const currentDate = moment(calenderFromDate);
        while (currentDate.isSameOrBefore(calenderToDate)) {
          groupedData.push({
            value: 0,
            day: currentDate.format('YYYY-MM-DD'),
          });
          currentDate.add(1, 'day');
        }
        setCalenderChartData(groupedData);
      },
      onFail: () => { toast('error', 'Failed get static data'); },
    });
    Api({
      endpoint: endpoints.getCCTVRecentBreakdown(),
      // data: { CctvId: 2 },
      onSuccess: ({ data }) => setTableData(data),
      onFail: () => { toast('error', 'Failed get static data'); },
    });
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid container item xs={12}>
        <Grid item xs={10} md={10}>
          <p className="d-flex align-items-center">
            <Link to="/dashboard" style={{ marginBlock: 20, color: 'grey' }} className="d-flex align-items-center">
              <ChevronLeft />Overview/
            </Link>
            <span style={{ color: 'blue' }}>CCTV Breakdown</span>
          </p>
          <h1 className="pl-3" style={{ fontWeight: 600, marginTop: 20, fontSize: 20 }}>CCTV Breakdown</h1>
          <p
            className="pl-3"
            style={{
              marginTop: 10, marginBottom: 20, color: 'grey', fontSize: 14,
            }}
          >Manage and track your CCTV status.
          </p>
        </Grid>
        {/* <Grid item xs={2} md={2} style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            style={{
              backgroundColor: '#2960f6', color: 'white', textTransform: 'none',
            }}
          >
            <DescriptionOutlined style={{ width: 20, marginRight: 5 }} />
            Generate Report
          </Button>
        </Grid> */}
      </Grid>
      <Grid item xs={12}>
        <Divider className="mt-2" style={{ height: 2, backgroundColor: '#dbdbdb' }} />
      </Grid>
      {/* temp disabled since no api yet */}
      {/* <Grid item xs={12} md={4} className={classes.card}>
        <Chart
          title="Total CCTV"
          withChart
        />
      </Grid> */}
      <Grid item xs={12} md={12} className={classes.card}>
        <Chart title="Highest CCTV Breakdown" withCalendarChart calendarData={calenderChartData} />
      </Grid>
      <Grid container item xs={12} style={{ padding: 10, marginTop: 30 }}>
        <Grid item xs={12} className="d-flex justify-content-between align-items-center">
          <p className="font-weight-bolder text-uppercase text-title" style={{ color: '#a4a4a4' }}>Recent Breakdown</p>
          <div className="d-flex align-items-center" style={{ transform: 'scale(0.8)' }}>
            <p style={{ color: '#a4a4a4' }}>Sort By: </p>
            <TextField
              select
              variant="standard"
              value={filter}
              onChange={e => setFilter(e.target.value)}
              InputProps={{ disableUnderline: true, style: { fontFamily: 'CeraProRegular' } }}
              style={{ marginLeft: 10 }}
            >
              <MenuItem value={1} style={{ fontFamily: 'CeraProRegular', fontSize: 12 }}>A --&#8594; Z</MenuItem>
              <MenuItem value={2} style={{ fontFamily: 'CeraProRegular', fontSize: 12 }}>Z --&#8594; A</MenuItem>
              <MenuItem value={3} style={{ fontFamily: 'CeraProRegular', fontSize: 12 }}>Oldest --&#8594; Latest</MenuItem>
              <MenuItem value={4} style={{ fontFamily: 'CeraProRegular', fontSize: 12 }}>Latest --&#8594; Oldest</MenuItem>
            </TextField>
          </div>
        </Grid>
        <Grid item xs={12} className="mt-3" style={{ border: 'solid #F2F2F2' }}>
          <Grid item xs={12} className="d-flex" style={{ backgroundColor: '#f1f1f1', color: '#a4a4a4', fontSize: 14 }}>
            <div style={{ flex: 1 }} className="text-title py-3 font-weight-bolder d-flex justify-content-center">No</div>
            <div style={{ flex: 2 }} className="text-title py-3 font-weight-bolder">Breakdown At</div>
            <div style={{ flex: 2 }} className="text-title py-3 font-weight-bolder">Site Name</div>
            <div style={{ flex: 1 }} className="text-title py-3 font-weight-bolder d-flex justify-content-center">CCTV ID</div>
            {/* <div style={{ flex: 2 }} className="text-title py-3 font-weight-bolder d-flex justify-content-center">Online Time</div> */}
            <div style={{ flex: 2 }} className="text-title py-3 font-weight-bolder d-flex justify-content-center">Status</div>
          </Grid>
          {tableData.map((e, eidx) => (
            <Grid item xs={12} className="d-flex text-standard" style={{ color: '#a4a4a4', fontSize: 13, borderTop: 'solid #F2F2F2' }}>
              <div style={{ flex: 1 }} className="py-3 d-flex justify-content-center">{eidx + 1}</div>
              <div style={{ flex: 2 }} className="py-3 ">{moment(e.Date).format('DD/MM/YYYY HH:mm A')}</div>
              <div style={{ flex: 2 }} className="py-3 ">{e['Cctv.Asset.name']}</div>
              <div style={{ flex: 1 }} className="py-3 d-flex justify-content-center">{e.CctvId}</div>
              {/* <div style={{ flex: 2 }} className="py-3 d-flex justify-content-center">{moment(e.last_online_date).format('DD/MM/YYYY HH:mm A')}</div> */}
              <div style={{ flex: 2 }} className="py-3 d-flex justify-content-center">
                <Chip
                  // label={e.online_status ? 'Online' : 'Error'}
                  label="Online"
                  style={{
                    width: '30%',
                    height: '1.5rem',
                    // backgroundColor: e.online_status ? '#3dd598' : '#f74513',
                    backgroundColor: '#3dd598',
                    color: 'white',
                  }}
                />{e.online_status}
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

const Chart = (props) => (
  <div style={{ ...cardCss }}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3 className="text-title-lg">{props.title}</h3>
        <Tooltip title="Title text goes here">
          <InfoOutlined style={{ width: 10, color: '#605f65', marginLeft: 5 }} />
        </Tooltip>
      </div>
    </div>
    <CardContent {...props} />
  </div>
);

const CardContent = ({
  withChart, chartData,
  withCalendarChart, calendarData,
}) => {
  let Content = () => (<></>);
  if (withChart) {
    if (!chartData) console.warn('Dashboard Card Err: withChart attribute require chartData');
    Content = () => <PieChart data={chartData} />;
  }
  if (withCalendarChart) {
    if (!calendarData) console.warn('Dashboard Card Err: withFraction attribute require fractionData');
    Content = () => (
      <TimeRangeChart
        data={calendarData}
        from={moment().subtract(6, 'months').format('YYYY-MM-DD')}
        to={moment().format('YYYY-MM-DD')}
      />
    );
  }
  return (
    <div
      className={`d-flex h-100 justify-content-between ${withCalendarChart && 'pt-3'}`}
      style={{ alignItems: withCalendarChart ? 'start' : 'center' }}
    >
      <Content />
    </div>
  );
};

const cardCss = {
  backgroundColor: 'white',
  borderRadius: 10,
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  padding: 13,
  position: 'relative',
  height: '100%',
};
