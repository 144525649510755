import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import moment from 'moment';

export default function Hook({ division, project, user }) {
  const [reports, setReports] = useState([]);
  const [storedReports, setStoredReports] = useState([]);
  const [siteReports, setSiteReports] = useState([]);
  const [justUpdated, setJustUpdated] = useState(false);
  const [date, setDate] = useState(null);
  const [monthYear, setMonthYear] = useState(moment());
  const [statusType, setStatusType] = useState('all');
  const [siteDiaryList, setSiteDiaryList] = useState([]);
  const [siteDiaryFilteredList, setSiteDiaryFilteredList] = useState([]);
  const [ncrReports, setNcrReports] = useState([]);
  const [tab, setTab] = useState('siteReports');
  const [isLoading, setIsLoading] = useState(false);
  const [constructionLogSD, setConstructionLogSD] = useState([]);
  const [isConstructionLog, setIsConstructionLog] = useState(false);
  const [constructionLogFiltered, setConstructionLogFiltered] = useState([]);
  const [selectedConstructionLog, setSelectedConstructionLog] = useState([]);
  const [openMergeLogDialog, setOpenMergeLogDialog] = useState(false);
  const [signature, setSignature] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setSelectedConstructionLog([]);
    const data = {};
    if (project?.uuid) {
      data.project_uuid = project.uuid;
    } else if (project?.name === 'View All Projects') {
      data.DivisionId = division.id;
    } else {
      data.filterOnly = 'osh';
    }
    Api({
      endpoint: endpoints.getReports(),
      data,
      onSuccess: (response) => setReports(response.data),
      onFail: setReports([]),
    });
    Api({
      endpoint: endpoints.getStoredReport(),
      data,
      onSuccess: (response) => setStoredReports(response.data),
      onFail: setStoredReports([]),
    });
    Api({
      endpoint: endpoints.getSiteReport(project.id, 'site-reports'),
      onSuccess: (response) => setSiteReports(response.data),
      onFail: setSiteReports([]),
    });
    Api({
      endpoint: endpoints.getSiteDiary(),
      siteBuddy: true,
      data: { AssetId: project.id, disablePagination: 1 },
      onSuccess: (response) => setSiteDiaryList(response.data),
      onFail: setSiteDiaryList([]),
    });
    Api({
      endpoint: endpoints.getSiteDiaryLog(),
      data: { AssetId: project.id, disablePagination: 1 },
      onSuccess: (response) => setConstructionLogSD(response.data),
      onFail: setConstructionLogSD([]),
    });
    Api({
      endpoint: endpoints.getNcrReport(),
      data: { AssetId: project.id, disablePagination: 1 },
      onSuccess: (response) => {
        setIsLoading(false);
        setNcrReports(response.data);
      },
      onFail: setNcrReports([]),
    });
    setJustUpdated(false);
  }, [project, justUpdated, tab, isConstructionLog]);
  const onSave = (data) => {
    Api({
      endpoint: endpoints.createReport(),
      data,
      onSuccess: (response) => {
        toast('success', 'Successfully Create ');
        setJustUpdated(true);
      },
      onFail: console.log('lol'),
    });
  };
  const onDelete = (id) => {
    Api({
      endpoint: endpoints.deleteReport(id),
      onSuccess: (response) => {
        toast('success', 'Successfully Delete ');
        setJustUpdated(true);
      },
      onFail: console.log('lol'),
    });
  };

  const deleteSiteReport = (id) => {
    Api({
      endpoint: endpoints.deleteAssetFile(id),
      onSuccess: () => {
        toast('success', 'Successfully Delete ');
        setJustUpdated(true);
      },
      onFail: console.log('lol'),
    });
  };

  const deleteNCRReport = (id) => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.deleteNcrReport(id),
      onSuccess: () => {
        toast('success', 'Successfully Delete ');
        setJustUpdated(true);
        setIsLoading(false);
      },
      onFail: console.log('lol'),
    });
  };

  const deleteConstructionLog = (id) => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.deleteSiteDiaryLog(id),
      onSuccess: () => {
        toast('success', 'Successfully Delete ');
        setJustUpdated(true);
        setIsLoading(false);
      },
      onFail: () => {
        setJustUpdated(true);
        setIsLoading(false);
      },
    });
  };

  const mergeConstructionLog = () => {
    setIsLoading(true);
    const data = { ConstructionLogIds: selectedConstructionLog.join(','), AssetId: project.id, signature };
    Api({
      endpoint: endpoints.mergeSiteDiaryLog(),
      data,
      onSuccess: () => {
        toast('success', 'Successfully Merge ');
        setIsConstructionLog(false);
        setJustUpdated(true);
        setIsLoading(false);
        setSelectedConstructionLog([]);
      },
      onFail: () => {
        setIsConstructionLog(false);
        setJustUpdated(true);
        setIsLoading(false);
        setSelectedConstructionLog([]);
      },
    });
  };

  useEffect(() => {
    if (!siteDiaryList.length) return;
    const filterDate = siteDiaryList.filter(e => moment(e.createdAt).isBetween(moment(monthYear).startOf('month'), moment(monthYear).endOf('month')));
    let filterType = filterDate;
    if (['Merged', 'Submited', 'Approved', 'Rejected', 'Completed'].includes(statusType)) filterType = filterDate.filter(a => a.status === statusType);
    setSiteDiaryFilteredList(filterType.sort((b, a) => a.createdAt.localeCompare(b.createdAt)));
  }, [siteDiaryList, statusType, monthYear]);

  useEffect(() => {
    if (!constructionLogSD.length) return;
    const filterDate = constructionLogSD.filter(e => moment(e.createdAt).isBetween(moment(monthYear).startOf('month'), moment(monthYear).endOf('month')));
    setConstructionLogFiltered(filterDate.sort((b, a) => a.createdAt.localeCompare(b.createdAt)));
  }, [constructionLogSD, monthYear]);

  return {
    reports,
    onDelete,
    onSave,
    storedReports,
    siteReports,
    deleteSiteReport,
    date,
    setDate,
    statusType,
    setStatusType,
    siteDiaryList,
    siteDiaryFilteredList,
    monthList,
    statusList,
    monthYear,
    setMonthYear,
    ncrReports,
    user,
    deleteNCRReport,
    tab,
    setTab,
    isLoading,
    isConstructionLog,
    setIsConstructionLog,
    constructionLogSD: constructionLogFiltered,
    deleteConstructionLog,
    selectedConstructionLog,
    setSelectedConstructionLog,
    mergeConstructionLog,
    openMergeLogDialog,
    setOpenMergeLogDialog,
    signature,
    setSignature,
  };
}

const monthList = [
  { value: 1, name: 'Jan' },
  { value: 2, name: 'Feb' },
  { value: 3, name: 'Mar' },
  { value: 4, name: 'Apr' },
  { value: 5, name: 'May' },
  { value: 6, name: 'Jun' },
  { value: 7, name: 'Jul' },
  { value: 8, name: 'Aug' },
  { value: 9, name: 'Sept' },
  { value: 10, name: 'Oct' },
  { value: 11, name: 'Nov' },
  { value: 12, name: 'Dec' },
];

const statusList = [
  { value: 'all', name: 'All' },
  { value: 'Merged', name: 'Merged' },
  { value: 'Submited', name: 'Submitted' },
  { value: 'Approved', name: 'Approved' },
  { value: 'Rejected', name: 'Rejected' },
  { value: 'Completed', name: 'Completed' },
];
