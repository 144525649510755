/* eslint-disable linebreak-style */
import { useEffect, useState } from 'react';
import moment from 'moment';
import Api, { endpoints } from '@Helpers/api';
// import queryString from 'query-string';
import { useHistory, useParams } from 'react-router-dom';

export default ({ user }) => {
  const history = useHistory();
  const { ReportUuid } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [signature, setSignature] = useState(null);
  const [resultSuccess, setResultSuccess] = useState(false);

  const handleNextStep = () => { setActiveStep((prev) => prev + 1); };
  const handleBackStep = () => { setActiveStep((prev) => prev - 1); };

  useEffect(() => {
    if (!ReportUuid) return;
    setIsLoading(true);
    Api({
      endpoint: endpoints.showSiteDiary(ReportUuid),
      onSuccess: ({ data }) => {
        setIsLoading(false);
        if (!!data.filter(e => ['Submited', 'Approved', 'Rejected', 'Completed'].includes(e.status)).length) {
          toast('error', 'Access Denied');
          return history.push('/project');
        }
      },
      onFail: () => {
        setIsLoading(false);
        toast('error', 'Error getting assets data. Please try again later.');
      },
    });
  }, [ReportUuid]);

  const submitReport = () => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.updateSiteDiary(ReportUuid),
      data: { signature, status: 'Submited' },
      onSuccess: () => {
        toast('success', 'Site Diary Submitted');
        setIsLoading(false);
        setResultSuccess(true);
        handleNextStep();
      },
      onFail: () => {
        toast('error', 'Opss, something went wrong, please try again.');
        setIsLoading(false);
        setResultSuccess(false);
        handleNextStep();
      },
    });
  };

  return {
    activeStep,
    setActiveStep,
    handleNextStep,
    signature,
    setSignature,
    resultSuccess,
    isLoading,
    submitReport,
    ReportUuid,
  };
};
