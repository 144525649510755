import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid, Button, Tabs, Tab, TextField, MenuItem, Avatar,
} from '@material-ui/core';
import { DescriptionOutlined, ChevronLeft, Sort } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import SearchBox from '@Components/SearchBox';
import moment from 'moment';

const useStyles = makeStyles({
  container: {
    height: '-webkit-fill-available',
    padding: 10,
  },
  card: {
    marginTop: 30,
    padding: 10,
    minHeight: 'max-content',
    // height: 180,
  },
  cardWeather: {
    marginTop: 30,
    padding: 10,
    minHeight: 'max-content',
    height: 400,
  },
  dropdown: {
    '.MuiInputBase-root': {
      fontSize: 12,
    },
  },
});

export default function view(props) {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [filter, setFilter] = useState(0);
  const handleChange = (e, v) => setTabValue(v);
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={12}>
        <Grid item xs={10} md={10}>
          <p className="d-flex align-items-center">
            <Link to="/dashboard" style={{ marginBlock: 20, color: 'grey' }} className="d-flex align-items-center">
              <ChevronLeft />Overview/
            </Link>
            <span style={{ color: 'blue' }}>Active Site</span>
          </p>
          <h1 className="pl-3" style={{ fontWeight: 600, marginTop: 20, fontSize: 20 }}>Active Site</h1>
          <p
            className="pl-3"
            style={{
              marginBlock: 5, marginBottom: 20, color: 'grey', fontSize: 14,
            }}
          >Manage and track your active site over the year.
          </p>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Tabs value={tabValue} onChange={handleChange} size="small" aria-label="basic tabs example">
          <Tab label="All" />
          <Tab label="Full" />
          <Tab label="Basic" />
          <Tab label="Lite" />
        </Tabs>
        <hr style={{ margin: 0 }} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={12}
        style={{
          outline: '1px #DCDCDC solid', borderRadius: 10, padding: 10, marginTop: 20,
        }}
      >
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 20,
        }}
        >
          <SearchBox onChange={(e) => props?.handleSearchActiveSite(e)} />
          <TextField
            select
            variant="standard"
            value={filter}
            onChange={e => setFilter(e.target.value)}
            InputProps={{ disableUnderline: true }}
            style={{ transform: 'scale(0.8)' }}
          >
            <MenuItem value={0} style={{ fontSize: 12 }}><Sort style={{ width: 20, fontSize: 14 }} /> Today</MenuItem>
            <MenuItem value={0} style={{ fontSize: 12 }}><Sort style={{ width: 20, fontSize: 14 }} /> Last Week</MenuItem>
            <MenuItem value={0} style={{ fontSize: 12 }}><Sort style={{ width: 20, fontSize: 14 }} /> Last Month</MenuItem>
          </TextField>
        </div>
        <Grid item xs={12} className="mt-2">
          <Grid item xs={12} className="d-flex" style={{ fontSize: 12, fontFamily: 'CeraProRegular' }}>
            <div style={{ flex: 1 }} className="py-2 font-weight-bolder d-flex">Site Name</div>
            <div style={{ flex: 1 }} className="py-2 font-weight-bolder">Location</div>
            <div style={{ flex: 1 }} className="py-2 font-weight-bolder">Mode</div>
            <div style={{ flex: 1 }} className="py-2 font-weight-bolder d-flex">Site Type</div>
            <div style={{ flex: 1 }} className="py-2 font-weight-bolder d-flex">Start Date</div>
            <div style={{ flex: 1 }} className="py-2 font-weight-bolder d-flex">End Date</div>
            <div style={{ flex: 2 }} className="py-2 font-weight-bolder d-flex justify-content-center">Site Manager</div>
            <div style={{ flex: 1 }} className="py-2 font-weight-bolder d-flex justify-content-center">Contact No</div>
          </Grid>
          {props.assets?.map(e => (
            <Grid item xs={12} className="d-flex" style={{ fontSize: 12 }}>
              <div style={{ flex: 1 }} className="py-2 d-flex"><p style={{ fontSize: 12 }}>{e.name}</p></div>
              <div style={{ flex: 1 }} className="py-2"><p style={{ fontSize: 12 }}>{`${e?.location}, ${e?.state}`}</p></div>
              <div style={{ flex: 1 }} className="py-2"><p style={{ fontSize: 12 }}>{props.phases.find(d => d.id == e.currentPhase)?.name}</p></div>
              <div style={{ flex: 1 }} className="py-2 d-flex"><p style={{ fontSize: 12 }}>{props.assetTypeList.find(d => d.id === e.AssetTypeId)?.name}</p></div>
              <div style={{ flex: 1 }} className="py-2 d-flex"><p style={{ fontSize: 12 }}>{moment(e?.createdAt).format('DD/MM/YYYY')}</p></div>
              <div style={{ flex: 1 }} className="py-2 d-flex"><p style={{ fontSize: 12 }}>{moment(e?.expecteddecomissionAt).format('DD/MM/YYYY')}</p></div>
              <div style={{ flex: 2 }} className="py-2 d-flex align-items-center"><Avatar
                style={{ transform: 'scale(0.8)' }}
                alt={e?.project_manager}
                src={`${process.env.REACT_APP_S3}/${e?.image}`}
              /><p style={{ fontSize: 12 }}>{e.project_manager}</p>
              </div>
              <div style={{ flex: 1 }} className="py-3 d-flex justify-content-center"><p style={{ fontSize: 12 }}>+60123456789</p></div>

            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
