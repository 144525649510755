import { makeStyles } from '@material-ui/core';
import { ResponsiveTimeRange } from '@nivo/calendar';
import moment from 'moment';

export default function TimeRangeChart({ from, to, data }) {
  const classes = useStyles();

  const TimeRangeLegend = () => {
    return (
      <div className={classes.legendContainer}>
        {legendData.map((legendValue) => (
          <div className={classes.legendItem}>
            <span
              className={classes.legendSquare}
              style={{ backgroundColor: legendValue.color }}
            />
            <span className={classes.legendValue}>{legendValue.value}</span>
          </div>
        ))}
      </div>
    );
  };

  const DayTooltip = ({ event }) => {
    return (
      <div className={classes.dayTooltip}>
        <span
          className={classes.tooltipColor}
          style={{ backgroundColor: event.color }}
        />
        <span className={classes.tooltipElement}>{moment(event.day).format('DD-MM-YYYY')}</span>
        {Number(event.value) >= 0 && (
          <span className={classes.tooltipElement}>
            <strong>{`${!!Number(event.value) ? 'Online' : 'Error'}`}</strong>
          </span>
        )}
      </div>
    );
  };

  // setup color scaling
  const colors = legendData.map(ld => ld.color);
  const valueScale = [0, 1]; // change value scale here
  const colorScaleFn = (value) => {
    if (!value) return colors[1];
    for (let i = 2; i < valueScale.length; i++) {
      if (value < valueScale[i]) {
        return colors[i];
      }
    }
    return colors[colors.length - 1];
  };
  colorScaleFn.ticks = () => valueScale;
  // END: setup color scaling
  return (
    <div className="w-100 d-flex flex-column" style={{ height: '36vh' }}>
      <ResponsiveTimeRange
        data={data?.length ? data : dummydata}
        from={from}
        to={to}
        emptyColor="#eeeeee"
        colorScale={colorScaleFn}
        margin={{
          top: 20, right: 100, bottom: 0, left: 100,
        }}
        weekdayTicks={[0, 1, 2, 3, 4, 5, 6]}
        dayBorderWidth={2}
        weekdayLegendOffset={100}
        dayBorderColor="#ffffff"
        theme={{
          fontSize: 13,
          fontFamily: 'CeraProRegular',
        }}
        tooltip={(event) => <DayTooltip event={event} />}
      />
      <TimeRangeLegend />
    </div>
  );
}

const useStyles = makeStyles(() => ({
  legendContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  legendItem: {
    display: 'flex',
    paddingLeft: '3px',
    paddingRight: '3px',
    alignItems: 'center',
  },
  legendValue: {
    marginTop: 'auto',
    marginRight: 'auto',
    fontSize: '13px',
    fontFamily: 'CeraProRegular',
  },
  legendSquare: {
    height: '10px',
    width: '10px',
    marginRight: '4px',
  },
  dayTooltip: {
    display: 'flex',
    backgroundColor: '#ffffff',
    border: '1px solid #c4c4c4',
    paddingBottom: '5px',
    paddingTop: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  tooltipColor: {
    height: '12px',
    width: '12px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  tooltipElement: {
    marginLeft: '8px',
  },
}));

const legendData = [
  {
    value: 'Not Available',
    color: '#eeeeee',
  },
  {
    value: 'Error',
    color: '#fd5a5b',
  },
  {
    value: 'Online',
    color: '#39c890',
  },
];

const dummydata = [
  {
    value: 100,
    day: '2023-02-01',
  },
  {
    value: 100,
    day: '2023-02-02',
  },
  {
    value: 10,
    day: '2023-02-06',
  },
  {
    value: 0,
    day: '2023-02-07',
  },
];
