import Button from '@Components/Button';
import { InputLabel } from '@material-ui/core';

export default (h) => {
  return (
    <div className="mt-5 mx-auto" style={{ minHeight: '60vh', width: '30%' }}>
      <div className="mb-3">
        <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Category</InputLabel>
        <p style={{ color: '#cda317' }}>{h.reportDetail.category}</p>
      </div>
      <div className="my-3">
        <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Corrective Action to be Taken</InputLabel>
        <p>{h.reportPartB1.details.correctiveAction}</p>
      </div>
      {!!h.reportPartB1.details.specifyReason && (
        <div className="my-3">
          <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Other Purpose</InputLabel>
          <p>{h.reportPartB1.details.specifyReason}</p>
        </div>
      )}
      <div className="d-flex justify-content-end mt-4">
        <Button variant="text" onClick={h.handleBackStep}>
          PREVIOUS
        </Button>
        <Button onClick={h.handleNextStep}>
          NEXT
        </Button>
      </div>
    </div>
  );
};
