/* eslint-disable complexity */
import { Grid } from '@material-ui/core';
import React from 'react';

const cardCss = {
  backgroundColor: 'white',
  borderRadius: 10,
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  padding: 13,
  position: 'relative',
  height: '100%',
};

const labelBox = {
  marginTop: 5,
};

const labelText = {
  fontSize: 14,
  color: 'black',
  width: '100%',
  padding: 5,
  borderRadius: '5px',
  outline: 'grey 1px solid',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
};

const CardTitle = ({ title }) => (<p style={{ color: '#605f65', fontSize: 12 }}>{title ?? 'Status'}</p>);
const InfoLabel = ({ title, value }) => (
  <div style={{ ...labelText }}>
    <span className="text-standard" style={{ padding: 5, float: 'left' }}>{title ?? 'Status'}</span>
    <span style={{
      color: '#605f65', fontSize: 10, padding: 5, float: 'right',
    }}
    >{value}
    </span>
  </div>
);

export default function index(props) {
  return (
    <div style={{ ...cardCss }}>
      <CardTitle title="Orthophoto Update" />
      <div style={{ marginTop: 5 }} />
      <Grid container xs={12} md={12} style={{ ...labelBox }}>
        <InfoLabel title="Uploaded By Adam Harith" value="9/1/2023" />
      </Grid>
      <Grid container xs={12} md={12} style={{ ...labelBox }}>
        <InfoLabel title="Uploaded By Adam Harith" value="28/12/2023" />
      </Grid>
      <Grid container xs={12} md={12} style={{ ...labelBox }}>
        <InfoLabel title="Uploaded By Adam Harith" value="27/12/2023" />
      </Grid>
      <Grid container xs={12} md={12} style={{ ...labelBox }}>
        <InfoLabel title="Uploaded By Adam Harith" value="26/12/2023" />
      </Grid>
    </div>
  );
}
