import Button from '@Components/Button';
import { useHistory } from 'react-router-dom';
import ReportUpdatedImg from '@Assets/Images/reportUpdated.svg';
import ReportRejectedImg from '@Assets/Images/reportRejected.svg';
import { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

export default (h) => {
  const history = useHistory();
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('@Assets/Icons/failed-lottie.json'),
    });
  }, [h.isLoading]);

  const ResultImage = () => (
    h.isRejected ? (
      <img src={ReportRejectedImg} style={{ height: 400, objectFit: 'none' }} />
    ) : (
      <img src={ReportUpdatedImg} style={{ height: 400, width: 600, objectFit: 'cover' }} />
    )
  );

  return (
    <div className="w-50 mt-5 mx-auto d-flex flex-column align-items-center" style={{ minHeight: '60vh' }}>
      {h.resultSuccess ? (
        <ResultImage />
      ) : (
        <div className="text-center mt-0">
          <h1 className="text-dark">Update Failed !</h1>
          <div ref={container} style={{ height: 450, marginTop: -50, marginBottom: -50 }} />
          <h2 className="text-dark">Failed to update Site Diary, please retry in a moment !!!</h2>
        </div>
      )}
      <div className="d-flex justify-content-between" style={{ marginTop: !h.resultSuccess && 20 }}>
        <Button style={{ border: '2px solid black', backgroundColor: 'white', color: 'black' }} onClick={() => history.goBack()}>
          Return To Report Page
        </Button>
      </div>
    </div>
  );
};
