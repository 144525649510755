import useHook from './hook';
import StorageDetail from './StorageDetail';
import StorageUsage from './StorageUsage';

const titleStyle = {
  fontWeight: 600,
  fontSize: 22,
  color: 'var(--primary-color)',
};

export default (props) => {
  const h = useHook(props);
  return (
    <div className="p2" style={{ marginInline: '7.5rem' }}>
      <h1
        className="my-auto pl-4 pb-2"
        style={titleStyle}
      >
        Storage Analysis
      </h1>
      <StorageDetail {...h} parentUser={props.user} />
      <StorageUsage {...h} />
    </div>
  );
};
