import Button from '@Components/Button';
import { TextField, InputLabel } from '@material-ui/core';
import moment from 'moment';

export default (h) => {
  return (
    <div className="mt-5 mx-auto" style={{ minHeight: '60vh', width: '30%' }}>
      <div className="w-100" style={{ border: '0.5px solid grey', borderRadius: 10 }}>
        {[
          { title: 'Reference Number', value: h.refferenceNumber ?? '-' },
          { title: 'House / Unit', value: !!h.unit ? h.unit.title : '-' },
          { title: 'Level', value: !!h.level ? h.level.title : '-' },
          { title: 'Block', value: !!h.block ? h.block.title : '-' },
        ].map((e, idx) => (
          <div className="d-flex justify-content-between px-4 py-2" style={{ borderTop: !!idx && '0.5px solid grey' }}>
            <p style={{ color: 'grey' }}>{e.title}</p>
            <p className="font-weight-bolder">{e.value}</p>
          </div>
        ))}
      </div>
      <div className="my-3">
        <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Date</InputLabel>
        <p>{moment(h.reportDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
      </div>
      <div className="my-3">
        <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Description</InputLabel>
        <TextField
          className="w-100 mt-1"
          multiline
          minRows={6}
          maxRows={6}
          value={h.description}
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
      <div className="d-flex justify-content-end mt-4">
        <Button variant="text" onClick={h.handleBackStep}>
          PREVIOUS
        </Button>
        <Button onClick={h.handleNextStep}>
          NEXT
        </Button>
      </div>
    </div>
  );
};
