import React from 'react';
import { Table, Button } from 'reactstrap';
import { Tooltip, makeStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Visibility } from '@material-ui/icons';

const styles = {
  button: {
    backgroundColor: '#36404A',
    border: 'thin solid grey',
    borderRadius: '10px',
  },
  headerContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  headerButton: {
    backgroundColor: '#161944',
    borderColor: '#00A8CC',
    borderRadius: '30px',
    marginLeft: '10px',
    // fontSize: "12px"
  },
  tableHeader: {
    color: '#7B80AA',
    fontSize: '1em',
    backgroundColor: '#1D2151',
  },
  iconInTable: { margin: '0 7px 0 7px', fontSize: '1.5em', cursor: 'pointer' },
};
const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: '#000',
      borderColor: '#fff',
    },
    '& .Mui-selected': {
      color: '#fff !important',
      backgroundColor: 'var(--primary-color) !important',
    },
  },
}));
export default ({
  tableList, collist, pagination, isFiltered, onClickClear = () => {},
}) => {
  const classes = useStyles();
  let tableListPerpage = tableList ? [...tableList.sort((a, b) => b.captureAt.localeCompare(a.captureAt))] : [];
  tableListPerpage = tableListPerpage?.splice((pagination.page - 1) * pagination.perpage, pagination.perpage);
  return (
    <>
      <div className="d-flex justify-content-end">
        {!!isFiltered && (
          <div className="flex-item">
            <Button variant="outlined" style={styles.button} className="float-right my-3" onClick={onClickClear}>Reset Selection</Button>
          </div>
        )}
        <div className="flex-item">
          <Pagination
            count={Math.ceil(tableList?.length / pagination.perpage)}
            variant="outlined"
            classes={{ ul: classes.ul }}
            page={parseInt(pagination.page)}
            onChange={(_, v) => pagination.setPage(v)}
            className="float-right my-3"
          />
        </div>
      </div>
      <Table className="text-center">
        <thead>
          <tr style={{ color: 'var(--text-primary-color)' }}>
            <th>Image</th>
            {collist.map(c => <th>{c.label}</th>)}
            <th>Date</th>
            <th>Time</th>
            <th>CCTV Name</th>
          </tr>
        </thead>
        <tbody>
          {!!tableListPerpage.length ? tableListPerpage.map(footage => (
            <tr className="pointer color-text-primary">
              <td>
                <Link to={{ pathname: `${process.env.REACT_APP_S3}/${footage.path}` }} target="_blank">
                  <Tooltip title="View">
                    <Visibility style={{ color: 'black' }} />
                  </Tooltip>
                </Link>
              </td>
              {collist.map(c => <td>{footage[c.key] ?? 0}</td>)}
              <td>{moment(footage.captureAt).utc().format('Do MMMM YYYY')}</td>
              <td>{moment(footage.captureAt).utc().format('h:mm A')}</td>
              <td>{footage.Cctv?.name}</td>
            </tr>
          )) : <tr className="text-center"><td colSpan="9">No Data</td></tr>}
        </tbody>
      </Table>
    </>
  );
};
