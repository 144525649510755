/* eslint-disable max-lines */
import Button from '@Components/Button';
import { CircularProgress, Divider, Grid } from '@material-ui/core';
import moment from 'moment';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ConfirmSubmission from './ConfirmSubmission';

export default (h) => {
  const [open, setOpen] = useState(false);
  const TitleSummary = ({ title }) => <p className="font-weight-bold" style={{ color: 'black', fontSize: 19 }}>{title}</p>;
  return (
    <>
      <div className="w-50 mt-5 mx-auto py-4 pl-3" style={{ minHeight: '60vh', backgroundColor: h.isLoading ? 'white' : '#F5F5F5' }}>
        {h.isLoading ? (
          <CircularProgress
            size={75}
            style={{
              color: 'var(--primary-color)', position: 'absolute', top: '50%', left: '50%', marginTop: -30, marginLeft: -35,
            }}
          />
        ) : (
          <>
            <div className="overflow-auto px-3 pr-4" style={{ maxHeight: '55vh' }}>
              <div className="mb-3">
                <TitleSummary title="Review Log Summary" />
              </div>
              <Divider className="mb-3" style={{ backgroundColor: 'D9D9D9' }} />
              <div className="mb-3">
                <TitleSummary title="Activities" />
                <p className="font-weight-bold mt-2 mb-1" style={{ color: 'black' }}>Date</p>
                <p className="text-muted">{moment(h.reportDate).format('DD MMM YYYY')}</p>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="font-weight-bold mt-2 mb-1" style={{ color: 'black' }}>Start Time</p>
                    <p className="text-muted">{moment(h.start_time, 'HH:mm').format('hh:mm A')}</p>
                  </div>
                  <div>
                    <p className="font-weight-bold mt-2 mb-1" style={{ color: 'black' }}>End Time</p>
                    <p className="text-muted text-right">{moment(h.end_time, 'HH:mm').format('hh:mm A')}</p>
                  </div>
                </div>
                <p className="font-weight-bold mt-2 mb-1" style={{ color: 'black' }}>Location</p>
                <p className="text-muted">{h.location}</p>
                <p className="font-weight-bold mt-2 mb-1" style={{ color: 'black' }}>Trade</p>
                <p className="text-muted">{h.trade}</p>
                <p className="font-weight-bold  mt-2 mb-1" style={{ color: 'black' }}>Category</p>
                <p className="text-muted">{h.category}</p>
                <p className="font-weight-bold  mt-2 mb-1" style={{ color: 'black' }}>Sub-Category</p>
                <p className="text-muted">{h.sub_category}</p>
                <p className="font-weight-bold  mt-2 mb-1" style={{ color: 'black' }}>Activity</p>
                <p className="text-muted">{h.wbsCodeActivity} {h.activity}</p>
                <p className="font-weight-bold  mt-2 mb-1" style={{ color: 'black' }}>Status</p>
                <p className="text-muted">{h.status}</p>
                <p className="font-weight-bold  mt-2 mb-1" style={{ color: 'black' }}>Remarks</p>
                <p className="text-muted">{h.remark}</p>
              </div>
              <Divider className="mb-3" style={{ backgroundColor: 'D9D9D9' }} />
              <div className="mb-3">
                <TitleSummary title="Direct Manpower" />
                <div className="d-flex align-items-center mt-2 mb-1">
                  <p className="font-weight-bold" style={{ color: 'black', flex: 3 }}>Name (Contractor)</p>
                  <p className="font-weight-bold" style={{ color: 'black', flex: 1 }}>Bumi</p>
                  <p className="font-weight-bold text-center" style={{ color: 'black', flex: 1 }}>Non-Bumi</p>
                  <p className="font-weight-bold text-right" style={{ color: 'black', flex: 1 }}>Others</p>
                </div>
                {h.manpowerDataList.filter(f => !f.isSub && ((f.value ?? 0) + (f.non_bumi ?? 0) + (f.others ?? 0))).map(e => (
                  <div className="d-flex align-items-center">
                    <p className="text-muted" style={{ flex: 3 }}>&#x2022; {e.name}</p>
                    <p className="text-muted" style={{ flex: 1 }}>{e.value ?? 0}</p>
                    <p className="text-muted text-center" style={{ flex: 1 }}>{e.non_bumi ?? 0}</p>
                    <p className="text-muted text-right" style={{ flex: 1 }}>{e.others ?? 0}</p>
                  </div>
                ))}
                {!!h.manpowerDataList.filter(f => !!f.isSub && ((f.value ?? 0) + (f.non_bumi ?? 0) + (f.others ?? 0))).length && (
                  <>
                    <div className="d-flex align-items-center mt-2 mb-1">
                      <p className="font-weight-bold" style={{ color: 'black', flex: 3 }}>Name (Sub-Contractor)</p>
                      <p className="font-weight-bold" style={{ color: 'black', flex: 1 }}>Bumi</p>
                      <p className="font-weight-bold text-center" style={{ color: 'black', flex: 1 }}>Non-Bumi</p>
                      <p className="font-weight-bold text-right" style={{ color: 'black', flex: 1 }}>Others</p>
                    </div>
                    {h.manpowerDataList.filter(f => !!f.isSub && ((f.value ?? 0) + (f.non_bumi ?? 0) + (f.others ?? 0))).map(e => (
                      <div className="d-flex align-items-center">
                        <p className="text-muted" style={{ flex: 3 }}>&#x2022; {e.name}</p>
                        <p className="text-muted" style={{ flex: 1 }}>{e.value ?? 0}</p>
                        <p className="text-muted text-center" style={{ flex: 1 }}>{e.non_bumi ?? 0}</p>
                        <p className="text-muted text-right" style={{ flex: 1 }}>{e.others ?? 0}</p>
                      </div>
                    ))}
                  </>
                )}
              </div>
              <Divider className="mb-3" style={{ backgroundColor: 'D9D9D9' }} />
              <div className="mb-3">
                <TitleSummary title="Materials" />
                <div className="d-flex align-items-center mt-2 mb-1">
                  <p className="font-weight-bold" style={{ color: 'black', flex: 1 }}>Item (Contractor)</p>
                </div>
                {h.materialDataList.filter(f => !f.isSub && !!f.quantity).map(e => {
                  return (
                    <div className="mb-1">
                      <div className="d-flex align-items-center">
                        <p className="text-muted" style={{ flex: 1 }}>&#x2022; {e.code} {e.name}</p>
                        <p className="text-muted text-right" style={{ flex: 1 }}>Quantity: {e.quantity}</p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="text-muted mb-1">Unit: {!!e.unit ? e.unit : '-'}</p>
                        <p className="text-muted mb-1">{[undefined, 'false'].includes(e.approval) ? 'Not Approved' : 'Approved'}</p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="text-muted mb-1">Receipt No: {!!e.receipt_no ? e.receipt_no : '-'}</p>
                      </div>
                    </div>
                  );
                })}
                {!!h.materialDataList.filter(f => !!f.isSub && !!f.quantity).length && (
                  <div className="mb-1">
                    <div className="d-flex align-items-center mt-2 mb-1">
                      <p className="font-weight-bold" style={{ color: 'black', flex: 1 }}>Item (Sub-Contractor)</p>
                    </div>
                    {h.materialDataList.filter(f => !!f.isSub && !!f.quantity).map(e => (
                      <div className="mb-1">
                        <div className="d-flex align-items-center">
                          <p className="text-muted" style={{ flex: 1 }}>&#x2022; {e.code} {e.name}</p>
                          <p className="text-muted text-right" style={{ flex: 1 }}>Quantity: {e.quantity}</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="text-muted mb-1">Unit: {!!e.unit ? e.unit : '-'}</p>
                          <p className="text-muted mb-1">{[undefined, 'false'].includes(e.approval) ? 'Not Approved' : 'Approved'}</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="text-muted mb-1">Receipt No: {!!e.receipt_no ? e.receipt_no : '-'}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <Divider className="mb-3" style={{ backgroundColor: 'D9D9D9' }} />
              <div className="mb-3">
                <TitleSummary title="Machinery" />
                <div className="d-flex justify-content-between align-items-center mt-2 mb-1">
                  <p className="font-weight-bold" style={{ color: 'black' }}>Item (Contractor)</p>
                  <p className="font-weight-bold" style={{ color: 'black' }}>Total</p>
                </div>
                {h.machineryDataList.filter(f => !f.isSub && !!f.quantity).map(e => (
                  <div className="mb-1">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="text-muted">&#x2022; {e.code} {e.name}</p>
                      <p className="text-muted">{e.quantity}</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="text-muted" style={{ fontSize: 14 }}><span className="font-weight-bold">Working</span> ( {e.working} )</p>
                      <p className="text-muted" style={{ fontSize: 14 }}><span className="font-weight-bold">Idle</span> ( {e.idle} )</p>
                      <p className="text-muted" style={{ fontSize: 14 }}><span className="font-weight-bold">Down</span> ( {e.down} )</p>
                    </div>
                  </div>
                ))}
                {!!h.machineryDataList.filter(f => !!f.isSub && !!f.quantity).length && (
                  <div className="mb-1">
                    <div className="d-flex align-items-center mt-2 mb-1">
                      <p className="font-weight-bold" style={{ color: 'black', flex: 1 }}>Item (Sub-Contractor)</p>
                    </div>
                    {h.machineryDataList.filter(f => !!f.isSub && !!f.quantity).map(e => (
                      <div className="mb-1">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="text-muted">&#x2022; {e.code} {e.name}</p>
                          <p className="text-muted">{e.quantity}</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="text-muted" style={{ fontSize: 14 }}><span className="font-weight-bold">Working</span> ( {e.working} )</p>
                          <p className="text-muted" style={{ fontSize: 14 }}><span className="font-weight-bold">Idle</span> ( {e.idle} )</p>
                          <p className="text-muted" style={{ fontSize: 14 }}><span className="font-weight-bold">Down</span> ( {e.down} )</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <Divider className="mb-3" style={{ backgroundColor: 'D9D9D9' }} />
              <div className="mb-3">
                <TitleSummary title="Delay Activity" />
                {h.delayActivitiesDataList.filter(f => !!f.name).map(e => (
                  <div className="mb-1">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="text-muted">&#x2022; {e.code} {e.name}</p>
                      <p className="text-muted" style={{ fontSize: 14 }}><span className="font-weight-bold">Location:</span> {e.location}</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="text-muted" style={{ fontSize: 14 }}>
                        <span className="font-weight-bold">Start Time:</span> {moment(e.start_time, 'HH:mm').format('hh:mm A')}
                      </p>
                      <p className="text-muted" style={{ fontSize: 14 }}>
                        <span className="font-weight-bold">End Time:</span> {moment(e.end_time, 'HH:mm').format('hh:mm A')}
                      </p>
                    </div>
                    <p className="text-muted" style={{ fontSize: 14 }}><span className="font-weight-bold">Reason:</span> {e.reason_delay}</p>
                  </div>
                ))}
              </div>
              <Divider className="mb-3" style={{ backgroundColor: 'D9D9D9' }} />
              <div className="mb-3">
                <TitleSummary title="Images" />
                <p className="text-muted mt-1 mb-1">Currently stored {h.savedImage.length - h.filesDeleted.length} images</p>
                <Grid container spacing={2}>
                  {h.savedImage.filter(f => !h.filesDeleted.includes(f.id)).map(e => (
                    <Grid item xs={3}>
                      <Link to={{ pathname: `${process.env.REACT_APP_S3}/${e.path}` }} target="_blank">
                        <img
                          src={`${process.env.REACT_APP_S3}/${e.path}`}
                          style={{ width: '100%', height: '100px', objectFit: 'cover' }}
                        />
                      </Link>
                    </Grid>
                  ))}
                </Grid>
                <p className="text-muted mt-2 mb-1">{h.filesDeleted.length} images will be deleted</p>
                <Grid container spacing={2}>
                  {h.savedImage.filter(f => !!h.filesDeleted.includes(f.id)).map(e => (
                    <Grid item xs={3}>
                      <Link to={{ pathname: `${process.env.REACT_APP_S3}/${e.path}` }} target="_blank">
                        <img
                          src={`${process.env.REACT_APP_S3}/${e.path}`}
                          style={{ width: '100%', height: '100px', objectFit: 'cover' }}
                        />
                      </Link>
                    </Grid>
                  ))}
                </Grid>
                <p className="text-muted mt-2 mb-1">{h.files.length} images uploaded</p>
                <Grid container spacing={2}>
                  {h.files.map(e => (
                    <Grid item xs={3}>
                      <Link to={{ pathname: e.preview }} target="_blank">
                        <img
                          src={e.preview}
                          style={{ width: '100%', height: '100px', objectFit: 'cover' }}
                        />
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-4 pr-4">
              <Button variant="text" onClick={h.handleBackStep}>
                PREVIOUS
              </Button>
              <Button onClick={() => setOpen(true)}>
                NEXT
              </Button>
            </div>
          </>
        )
        }
      </div >
      <ConfirmSubmission open={open} setOpen={setOpen} confirmFunction={h.EditSiteDiary} />
    </>
  );
};
