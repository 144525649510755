import React from 'react';
import {
  VictoryLine,
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryAxis,
  VictoryLegend,
  VictoryScatter,
  VictoryTooltip,
} from 'victory';

export default function MultilineChart({ chartData }) {
  const colorStack = ['#0062FF', '#00B86E', '#F8CF3E', '#414141', '#B54539'];
  return (
    <>
      <VictoryChart
        height={400}
        width={1700}
        padding={{
          left: 70, right: 400, top: 10, bottom: 50,
        }}
        containerComponent={<VictoryVoronoiContainer />}
      >
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          style={{
            tickLabels: { fontSize: 14 },
          }}
          tickValues={7}
          tickCount={7}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={(t) => t.toFixed(0)}
          style={{
            tickLabels: { fontSize: 14 },
            ticks: { size: 10 },
          }}
        />
        <VictoryLegend
          x={1400}
          y={10}
          orientation="vertical"
          gutter={1}
          style={{ labels: { fontFamily: 'CeraProRegular', fontSize: 14, width: '10px'  } }}
          colorScale={colorStack}
          data={chartData?.map((line, idx) => ({
            name: line.title?.replace(' ', '\n'),
            symbol: { fill: colorStack[idx], type: 'square' },
            labels: { fill: 'black' },
          }))}
        />
        {chartData.map((e, idx) => (
          <VictoryLine
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            style={{
              data: { stroke: colorStack[idx] },
            }}
            data={e.data}
          />
        ))}
        {chartData.map((e, idx) => (
          <VictoryScatter
            data={e.data}
            size={5}
            style={{ data: { fill: colorStack[idx] } }}
            labels={({ datum }) => `${datum.x}\nTotal: ${datum.y}`}
            labelComponent={<VictoryTooltip pointerWidth={20} />}
          />
        ))}
      </VictoryChart>
    </>
  );
}
