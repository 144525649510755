import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { useHistory, useParams } from 'react-router-dom';
import { today } from '@Helpers';

export default ({ user }) => {
  const history = useHistory();
  const { ReportId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [reportDetail, setReportDetail] = useState(null);
  const [signature, setSignature] = useState(null);
  const [resultSuccess, setResultSuccess] = useState(true);
  const [reportDate, setReportDate] = useState(today);

  const handleNextStep = () => { setActiveStep((prev) => prev + 1); };
  const handleBackStep = () => { setActiveStep((prev) => prev - 1); };

  useEffect(() => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.showNcrReport(ReportId),
      onSuccess: ({ data }) => {
        setReportDetail(data);
        setIsLoading(false);
        if (!data.NonConformityReportFiles.find(e => e.stage === 4)) {
          toast('error', 'Access Denied - Part C Not Found');
          history.push(`/project/report?id=${data.AssetId}`);
        } else if (!!data.NonConformityReportFiles.find(e => e.stage === 5)) {
          toast('error', 'Access Denied - Part D Completed');
          history.push(`/project/report?id=${data.AssetId}`);
        }
      },
      onFail: () => {
        setIsLoading(false);
        toast('error', 'Error');
        history.push('/denied');
      },
    });
  }, [ReportId]);

  const UpdateReport = () => {
    const data = {
      stage: 5,
      details: {
        reportDate,
      },
      signature,
    };
    setIsLoading(true);
    Api({
      endpoint: endpoints.updateNcrReport(ReportId),
      data,
      files: [],
      filesImage: [],
      filesDrawing: [],
      onSuccess: () => {
        toast('success', 'NCR Report updated');
        setIsLoading(false);
        setResultSuccess(true);
        handleNextStep();
      },
      onFail: () => {
        toast('error', 'Opss, something went wrong, please try again.');
        setIsLoading(false);
        setResultSuccess(false);
        handleNextStep();
      },
    });
  };

  return {
    activeStep,
    setActiveStep,
    handleNextStep,
    handleBackStep,
    signature,
    setSignature,
    UpdateReport,
    ReportId,
    resultSuccess,
    isLoading,
    reportDate,
    setReportDate,
    reportDetail,
  };
};
