import React from 'react';
import MainContentContainer from '@Components/MainContentContainer';
import Navbar from '@Components/Navbar';
import { MuiThemeProvider, createTheme, InputAdornment } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CalendarToday } from '@material-ui/icons';
import TableFinancial from '@Components/TableFinancial';
import NoData from '@Assets/Images/DataNotFound.svg';
import MomentUtils from '@date-io/moment';
import ToggleButton from './ToggleButton';
import Chart from './Chart';
import useHook from './hook';

const Collist = [
  { label: 'Month/Year', key: 'monthYear' },
  { label: 'Financial Schedule Curve (RM)', key: 'scheduleCurve' },
  { label: 'Financial Actual Curve (RM)', key: 'actualCurve' },
  { label: 'Financial Variance (RM)', key: 'financialVariance' },
];

export default function index(props) {
  const h = useHook();
  return (
    <MainContentContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Navbar
          to="/project/"
          text="S-Curve"
          subtext={props.filtered_projects[props.selected_project]?.name}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ToggleButton {...h} />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <MuiThemeProvider theme={getMuiTheme()}>
              <DatePicker
                size="small"
                views={['year']}
                label="Custom Year"
                value={h.year}
                onChange={(date) => h.setYear(date)}
                input
                startAdornment={(
                  <InputAdornment position="start">
                    <CalendarToday />
                  </InputAdornment>
                )}
                variant="inline"
                inputVariant="outlined"
                style={{ marginLeft: 15 }}
              />
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </div>
      </div>
      {!!h.chartData
        ? <Chart {...h} />
        : <div className="d-flex justify-content-center"> <img src={NoData} style={{ height: '55vh' }} /> </div>}
      {!!h.tableData
        ? (
          <TableFinancial
            {...h}
            // pagination={h.pagination}
            tableList={h.tableData}
            collist={Collist}
            isFiltered={false}
          />
        )
        : (
          <div className="d-flex justify-content-center"> <img src={NoData} style={{ height: '55vh' }} /> </div>
        )}
    </MainContentContainer>
  );
}

const getMuiTheme = () => createTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
});
