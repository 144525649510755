import { useState, useEffect } from 'react';
import {
  Grid, Button, TextField, MenuItem, Divider
} from '@material-ui/core';
import {
  CalendarToday,
  ChevronLeft, DescriptionOutlined, InfoOutlined,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Table from '@Components/MaterialTable/v11';
import FilterIcon from '@Assets/Icons/filter-solid.svg';
import BarChart from '@Components/BarChart/v2';
import { abSort } from '@Helpers';
import moment from 'moment';
import _ from 'lodash';

const useStyles = makeStyles({
  paper: {
    width: '100%',
    height: '-webkit-fill-available',
    padding: 20,
  },
  container: {
    height: '-webkit-fill-available',
    padding: 10,
  },
  sideNavButton: {
    width: '100%',
    justifyContent: 'normal',
  },
});
const monthName = item => moment(item.updatedAt, 'YYYY-MM-DD').format('MMM');

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export default function ProjectProgressDetails(props) {
  const classes = useStyles();
  const [filter, setFilter] = useState(0);
  const [filterDate, setFilterDate] = useState(0);
  const missedUpdateData = props.assets?.map((d, idx) => {
    let asset_ortho = d.asset_files
      .filter(af => !!['orthophotos'].includes(af.media_type));
    let total_month = 12;
    if (filter === 0) {
      total_month = 6;
      asset_ortho = asset_ortho.filter(af => moment(af.updatedAt) > moment().subtract(6, 'months'));
    }
    if (filter === 1) {
      asset_ortho = asset_ortho.filter(af => moment(af.updatedAt) > moment().subtract(12, 'months'));
    }
    if (filter === 2) {
      asset_ortho = asset_ortho.filter(af => !!(moment(af.updatedAt) > moment().startOf('year').subtract(12, 'months')
        && moment(af.updatedAt) < moment().startOf('year')));
    }
    const countByMonth = total_month - Object.keys(_.groupBy(asset_ortho, monthName)).length;
    return { x: d.name, y: countByMonth, y2: countByMonth / total_month * 10 };
  }).sort(abSort);
  const missedUpdateDataPercentage = missedUpdateData.map(d => ({ ...d, y: d.y2 }));
  const updatedData = props.assets?.map((d, idx) => {
    let started;
    let ended;
    let asset_ortho = d.asset_files
      .filter(af => !!['orthophotos'].includes(af.media_type));
    if (filterDate === 0) {
      asset_ortho = asset_ortho.filter(af => !!(moment(af.updatedAt) > moment().startOf('year').subtract(12, 'months')
        && moment(af.updatedAt) < moment().startOf('year')));
      started = moment(d.createdAt) < moment().startOf('year');
      ended = moment(d.expecteddecommissionAt) < moment().startOf('year');
    }
    if (filterDate === 1) {
      asset_ortho = asset_ortho.filter(af => !!(moment(af.updatedAt) > moment().startOf('year').subtract(24, 'months')
        && moment(af.updatedAt) < moment().startOf('year').subtract(12, 'months')));
      started = moment(d.createdAt) < moment().startOf('year').subtract(12, 'months');
      ended = moment(d.expecteddecommissionAt) < moment().startOf('year').subtract(12, 'months');
    }
    if (filterDate === 2) {
      asset_ortho = asset_ortho.filter(af => !!(moment(af.updatedAt) > moment().startOf('year').subtract(36, 'months')
        && moment(af.updatedAt) < moment().startOf('year').subtract(24, 'months')));
      started = moment(d.createdAt) < moment().startOf('year').subtract(24, 'months');
      ended = moment(d.expecteddecommissionAt) < moment().startOf('year').subtract(24, 'months');
    }
    const eachMonthOrtho = _.groupBy(asset_ortho, monthName);
    const abc = months.map((mo, id) => ({ name: mo, no: id + 1 }));
    const eachMonthOrthop = _.groupBy(abc, 'name');
    for (let mIdx = 0; mIdx < Object.keys(eachMonthOrthop).length; mIdx++) {
      const m = Object.keys(eachMonthOrthop)[mIdx];
      if (!started || !!ended) {
        eachMonthOrtho[m] = undefined;
        break;
      };
      eachMonthOrtho[m] = !!eachMonthOrtho[m] ? eachMonthOrtho[m].length : 0;
    }
    return { siteName: d.name, ...eachMonthOrtho };
  });
  return (
    <Grid container spacing={4} className={classes.container}>
      <Grid container item xs={12}>
        <Grid item xs={10} md={10}>
          <p className="d-flex align-items-center">
            <Link to="/dashboard" style={{ marginBlock: 20, color: 'grey' }} className="d-flex align-items-center">
              <ChevronLeft />Overview/
            </Link>
            <span style={{ color: 'blue' }}>Orthophoto Update</span>
          </p>
          <h1 className="pl-3" style={{ fontWeight: 600, marginTop: 20, fontSize: 20 }}>Orthophoto Update</h1>
          <p
            className="pl-3"
            style={{
              marginBlock: 5, marginBottom: 20, color: 'grey', fontSize: 14,
            }}
          >Manage and track your orthophoto update over the year.
          </p>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider className="mt-2" style={{ height: 2, backgroundColor: '#dbdbdb' }} />
      </Grid>
      <Grid item xs={6} md={6}>
        <div style={{ outline: '1px #DCDCDC solid', borderRadius: 5, padding: 20 }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ fontSize: 12, fontWeight: 600 }}>Total Missed Update &nbsp;&nbsp;</p>
              <InfoOutlined style={{ color: 'grey', width: 15 }} />
            </div>
            <div style={{ transform: 'scale(0.8)' }}>
              <TextField
                select
                variant="standard"
                value={filter}
                onChange={e => setFilter(e.target.value)}
                InputProps={{ disableUnderline: true }}
              >
                <MenuItem value={0} style={{ fontSize: 12 }}><img src={FilterIcon} style={{ padding: '0px 5px 3px 5px', cursor: 'pointer', width: '15px' }} /> Last 6 Months</MenuItem>
                <MenuItem value={1} style={{ fontSize: 12 }}><img src={FilterIcon} style={{ padding: '5px 5px 5px 5px', cursor: 'pointer', width: '15px' }} /> Last 12 Months</MenuItem>
                <MenuItem value={2} style={{ fontSize: 12 }}><img src={FilterIcon} style={{ padding: '5px 5px 5px 5px', cursor: 'pointer', width: '15px' }} /> Previous Year</MenuItem>
              </TextField>
            </div>
          </div>
          <BarChart data={missedUpdateData} />
        </div>
      </Grid>
      <Grid item xs={6} md={6}>
        <div style={{ outline: '1px #DCDCDC solid', borderRadius: 5, padding: 20 }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ fontSize: 12, fontWeight: 600 }}>Total Missed Update Percentage&nbsp;&nbsp;</p>
              <InfoOutlined style={{ color: 'grey', width: 15 }} />
            </div>
            <div style={{ transform: 'scale(0.8)' }}>
              <TextField
                select
                variant="standard"
                value={filter}
                onChange={e => setFilter(e.target.value)}
                InputProps={{ disableUnderline: true }}
              >
                <MenuItem value={0} style={{ fontSize: 12 }}><img src={FilterIcon} style={{ padding: '0px 5px 3px 5px', cursor: 'pointer', width: '15px' }} /> Last 6 Months</MenuItem>
                <MenuItem value={1} style={{ fontSize: 12 }}><img src={FilterIcon} style={{ padding: '5px 5px 5px 5px', cursor: 'pointer', width: '15px' }} /> Last 12 Months</MenuItem>
                <MenuItem value={2} style={{ fontSize: 12 }}><img src={FilterIcon} style={{ padding: '5px 5px 5px 5px', cursor: 'pointer', width: '15px' }} /> Previous Year</MenuItem>
              </TextField>
            </div>
          </div>
          <BarChart data={missedUpdateDataPercentage} yAxis="percentage" />
        </div>
      </Grid>
      <p style={{
        marginLeft: 20, color: 'grey', fontWeight: 600, fontSize: 12,
      }}
      >MONTHLY ORTHOPHOTO UPDATE
      </p>
      <Grid item xs={12} md={12}>
        <div style={{ outline: '1px #DCDCDC solid', borderRadius: 10, padding: 10 }}>
          <div style={{
            display: 'flex', alignItems: 'center', width: '100%', marginBottom: 20, justifyContent: 'end',
          }}
          >
            <div style={{ display: 'flex', alignItems: 'center', transform: 'scale(0.8)' }}>
              <TextField
                select
                variant="outlined"
                value={filterDate}
                onChange={e => setFilterDate(e.target.value)}
                size="small"
                fullWidth
              >
                <MenuItem value={0} style={{ fontSize: 14 }}><div style={{ display: 'flex', alignItems: 'center' }}><CalendarToday style={{ width: 20 }} />&nbsp;&nbsp;2022</div></MenuItem>
                <MenuItem value={1} style={{ fontSize: 14 }}><div style={{ display: 'flex', alignItems: 'center' }}><CalendarToday style={{ width: 20 }} />&nbsp;&nbsp;2021</div></MenuItem>
                <MenuItem value={2} style={{ fontSize: 14 }}><div style={{ display: 'flex', alignItems: 'center' }}><CalendarToday style={{ width: 20 }} />&nbsp;&nbsp;2020</div></MenuItem>
              </TextField>
            </div>
          </div>
          <Table
            tableHead
            tableData={updatedData}
            tableMinWidth={900}
            tableMaxHeight={900}
            {...props}
          />
        </div>
      </Grid>
    </Grid>
  );
}
