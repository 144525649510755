import Button from '@Components/Button';
import { Grid, InputLabel } from '@material-ui/core';
import moment from 'moment';
import { Link } from 'react-router-dom';

export default (h) => {
  const replaceText = text => text.replace('ncr/', '');
  return (
    <div className="mt-3 mx-auto" style={{ minHeight: '60vh', width: '40%' }}>
      <div className="mb-3">
        <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Category</InputLabel>
        <p style={{ color: '#cda317' }}>{h.reportDetail?.category}</p>
      </div>
      <div className="my-3">
        <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Corrective Action to be Taken</InputLabel>
        <p>{h.reportPartB1.details?.correctiveAction}</p>
      </div>
      {!!h.reportPartB1.details?.specifyReason && (
        <div className="my-3">
          <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Other Purpose</InputLabel>
          <p>{h.reportPartB1.details?.specifyReason}</p>
        </div>
      )}
      <div className="my-3">
        <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Image</InputLabel>
        <p>{h.reportPartB1?.details.filesImage.length} images uploaded</p>
        <Grid container spacing={2} className="my-1">
          {!!h.reportPartB1?.details.filesImage.length && (
            h.reportPartB1?.details.filesImage.map(image => (
              <Grid xs={2} item>
                <Link to={{ pathname: `${process.env.REACT_APP_S3}/${image}` }} target="_blank">
                  <img src={`${process.env.REACT_APP_S3}/${image}`} style={{ width: '100%', height: '100%' }} />
                </Link>
              </Grid>
            ))
          )}
        </Grid>
      </div>
      <div className="my-3">
        <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Drawing</InputLabel>
        <p>{h.reportPartB1.details.filesDrawing.length} items uploaded</p>
        <Grid container spacing={2} className="my-1">
          {!!h.reportPartB1.details.filesDrawing.length && (
            h.reportPartB1.details.filesDrawing.map(image => (
              <>
                <Grid xs={4} item>
                  <Link to={{ pathname: `${process.env.REACT_APP_S3}/${image}` }} target="_blank">
                    <img src={`${process.env.REACT_APP_S3}/static/icons/file.png`} style={{ width: 100, height: 100 }} />
                  </Link>
                </Grid>
                <Grid xs={8} item className="d-flex" alignItems="center">
                  {replaceText(image)}
                </Grid>
              </>
            ))
          )}
        </Grid>
      </div>
      <div className="my-3">
        <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>File</InputLabel>
        <p>{h.reportPartB1.details.files.length} items uploaded</p>
        <Grid container spacing={2} className="my-1">
          {!!h.reportPartB1.details.files.length && (
            h.reportPartB1.details.files.map(image => (
              <>
                <Grid xs={4} item>
                  <Link to={{ pathname: `${process.env.REACT_APP_S3}/${image}` }} target="_blank">
                    <img src={`${process.env.REACT_APP_S3}/static/icons/file.png`} style={{ width: 100, height: 100 }} />
                  </Link>
                </Grid>
                <Grid xs={8} item className="d-flex" alignItems="center">
                  {replaceText(image)}
                </Grid>
              </>
            ))
          )}
        </Grid>
      </div>
      <Grid container className="my-3">
        <Grid container item xs={12} className="d-flex justify-content-between">
          <Grid item xs={4}>
            <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Issued By (E.R Representative)</InputLabel>
          </Grid>
          <Grid item xs={4}>
            <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Issued By (E.R Representative)</InputLabel>
          </Grid>
        </Grid>
        <Grid container item xs={12} className="d-flex justify-content-between">
          {[{ image: h.reportPartB1?.signature }, { image: h.reportPartB2?.signature }].map(e => (
            <Grid item xs={4} className="d-flex align-items-center">
              <img src={e.image} className="my-2 w-100" />
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={12} className="d-flex justify-content-between">
          {[
            { verified_by: h.reportPartB1?.details?.verified_by, date: h.reportPartB1?.createdAt },
            { verified_by: h.reportPartB2?.details?.verified_by, date: h.reportPartB2?.createdAt },
          ].map(e => (
            <Grid item xs={4}>
              <p>{e.verified_by}</p>
              <p>{moment(e.date).format('hh:mm A DD MMMM YYYY')}</p>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <div className="d-flex justify-content-end mt-4">
        <Button variant="text" onClick={h.handleBackStep}>
          PREVIOUS
        </Button>
        <Button onClick={h.handleNextStep}>
          NEXT
        </Button>
      </div>
    </div>
  );
};
