import { numberWithCommas } from '@Helpers';
import React from 'react';
import {
  VictoryChart, VictoryLine, VictoryScatter, VictoryLegend, VictoryAxis, VictoryLabel, VictoryVoronoiContainer, VictoryTooltip,
} from 'victory';

export default function Chart({ chartData }) {
  return (
    <div>
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="25%" stopColor="#008ffb" />
            <stop offset="100%" stopColor="#3b71e8" />
          </linearGradient>
        </defs>
      </svg>
      <VictoryChart
        domainPadding={17}
        width={800}
        height={250}
        padding={{
          right: 70, bottom: 30, left: 70, top: 10,
        }}
        containerComponent={(
          <VictoryVoronoiContainer
            voronoiDimension="x"
            labels={({ datum }) => ['chart-line-3', 'chart-line-5'].includes(datum.childName) ? `${chartData.unit && chartData.unit == 'RM' ? `RM ${numberWithCommas(datum.y)}` : `${datum.y}%`}` : null}
          // labelComponent={(
          //   <VictoryTooltip
          //     cornerRadius={0}
          //     flyoutStyle={{
          //       fill: 'white',
          //       stroke: 'blue',
          //     }}
          //   />
          // )}
          />
        )}
      >
        <VictoryLegend
          x={680}
          y={90}
          orientation="vertical"
          style={{ border: { stroke: 'transparent' }, labels: { fontSize: 8 } }}
          gutter={20}
          colorScale={['#F7C412', '#058DFA']}
          data={[
            { name: 'Scheduled' }, { name: 'Actual' },
          ]}
        />
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          style={{
            tickLabels: { fontSize: 6, opacity: 0.65 },
            ticks: { size: 0 },
            axis: { stroke: '#F4F5F7', strokeWidth: 0.9 },
            grid: { stroke: 'transparent' },
          }}
          tickLabelComponent={<VictoryLabel angle={-45} />}
          //   tickCount={12}
          axisLabelComponent={(
            <VictoryLabel
              angle={0}
              verticalAnchor="start"
              dy={60}
              dx={0}
              style={{ fontSize: 32 }}
            />
          )}
        />
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          dependentAxis
          style={{
            tickLabels: { fontSize: 8, opacity: 0.65 },
            ticks: { size: 0 },
            axis: { stroke: '#F4F5F7', strokeWidth: 0.9 },
            grid: { stroke: '#F4F5F7', strokeWidth: 0.9 },
          }}
          tickLabelComponent={<VictoryLabel />}
          tickFormat={t => (t < 0.1 ? '' : numberWithCommas(t))}
          tickCount={5}
          axisLabelComponent={(
            <VictoryLabel
              angle={0}
              verticalAnchor="start"
              dy={60}
              dx={60}
              style={{ fontSize: 32 }}
            />
          )}
        />
        <VictoryLine
          interpolation="catmullRom"
          data={chartData.actualData}
          style={{ data: { stroke: '#F7C412' } }}
        //   labels={({ datum }) => datum.y} // to appear on each point
        />
        <VictoryScatter
          data={chartData.actualData}
          size={3}
          style={{ data: { fill: '#F7C412' } }}
        />
        <VictoryLine
          interpolation="catmullRom"
          data={chartData.scheduleData}
          style={{ data: { stroke: '#058DFA' } }}
        />
        <VictoryScatter
          data={chartData.scheduleData}
          size={3}
          style={{ data: { fill: '#058DFA' } }}
        />
      </VictoryChart>
    </div>
  );
}
