import React from 'react';
import {
  Select, FormControl, MenuItem, InputLabel, Box,
} from '@material-ui/core';

export default function index(props) {
  return (
    <Box style={{ minWidth: 300 }}>
      <FormControl fullWidth>
        <InputLabel id="select-label">&nbsp;&nbsp;&nbsp;Detection Level</InputLabel>
        <Select
          style={{ maxHeight: 50 }}
          labelId="select-label"
          id="select"
          value={props.detectionLevel}
          onChange={(e) => props.setDetectionLevel(e.target.value)}
          variant="outlined"
        >
          <MenuItem disabled>Select Detection Level</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>
      </FormControl>
    </Box>

  );
}
