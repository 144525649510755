/* eslint-disable max-lines */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid, Divider, Chip,
} from '@material-ui/core';
import cctv_dashboard from '@Assets/Icons/cctv_dashboard.svg';
import contractor from '@Assets/Icons/contractor.svg';
import image from '@Assets/Icons/image.svg';
import location from '@Assets/Icons/location.svg';
import map from '@Assets/Icons/map.svg';
import ncr from '@Assets/Icons/ncr.svg';
import phone_number from '@Assets/Icons/phone_number.svg';
import site_manager from '@Assets/Icons/site_manager.svg';
import site_type from '@Assets/Icons/site_type.svg';
import video from '@Assets/Icons/video.svg';
import { CheckCircle, ChevronLeft, Sort } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import SearchBox from '@Components/SearchBox/v2';
import NoData from '@Assets/Images/Data-not-found5.svg';
import moment from 'moment';

const useStyles = makeStyles({
  container: {
    height: '-webkit-fill-available',
    padding: 10,
  },
  card: {
    marginTop: 20,
    padding: 10,
    height: 'max-content',
    // height: 180,
  },
});
export default function view(props) {
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState('');
  const [selectedAsset, setSelectedAsset] = useState(0);
  const [assetDetail, setAssetDetail] = useState({});
  const [contractorName, setContractorName] = useState('');
  const [searchedAsset, setSearchedAsset] = useState([]);
  const [siteOverviewDetail, setSiteOverviewDetail] = useState({
    cctv: 0, media: 0, orthophoto: 0, ncr: 0,
  });

  useEffect(() => {
    if (!props.assets.length) return;
    setSelectedAsset(0);
    setSearchedAsset(props.searchKeyword(searchKey, props.assets.sort((a, b) => b.expecteddecommissionAt.localeCompare(a.expecteddecommissionAt))));
  }, [props.assets, searchKey]);

  useEffect(() => {
    if (!props.assets.length) return;
    if (!searchedAsset.length) return;
    setAssetDetail(searchedAsset[selectedAsset]);
    setContractorName(props.contractorList.find(e => e.id === searchedAsset[selectedAsset].ContractorId)?.name);
    setSiteOverviewDetail({
      cctv: searchedAsset[selectedAsset].cctvs.length,
      media: CalculateMedia(searchedAsset[selectedAsset]),
      orthophoto: CalculateOrthophoto(searchedAsset[selectedAsset]),
      ncr: 0,
    });
  }, [props.assets, selectedAsset, searchedAsset]);

  const CalculateMedia = (asset) => {
    let total = 0;
    if (!asset.aerial_inspections_details.length) return total;
    asset.aerial_inspections_details.forEach(element => {
      if (!element.InspectionFiles.length) total += 0;
      total += element.InspectionFiles.length;
    });
    return total;
  };

  const CalculateOrthophoto = (asset) => {
    if (!asset.asset_files.length) return 0;
    return asset.asset_files.filter(e => ['orthophotos'].includes(e.media_type)).length;
  };

  return (
    <Grid container className={classes.container}>
      <Grid container item xs={12}>
        <Grid item xs={10} md={10}>
          <p className="d-flex align-items-center">
            <Link to="/dashboard" style={{ marginBlock: 20, color: 'grey' }} className="d-flex align-items-center">
              <ChevronLeft />Overview/
            </Link>
            <span style={{ color: 'blue' }}>Completed Site</span>
          </p>
          <h1 className="pl-3" style={{ fontWeight: 600, marginTop: 20, fontSize: 20 }}>Completed Site</h1>
          <p
            className="pl-3"
            style={{
              marginBlock: 5, marginBottom: 20, color: 'grey', fontSize: 14,
            }}
          >Manage and track your completed site over the year.
          </p>
        </Grid>
        {/* <Grid item xs={2} md={2} style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            style={{
              backgroundColor: '#2960f6', color: 'white', textTransform: 'none',
            }}
          >
            <DescriptionOutlined style={{ width: 20, marginRight: 5 }} />
            Generate Report
          </Button>
        </Grid> */}
      </Grid>
      <Grid item xs={12}>
        <Divider className="mt-2" style={{ height: 2, backgroundColor: '#dbdbdb' }} />
      </Grid>
      <div className="d-flex w-100">
        <div className={classes.card} style={{ flex: 4, paddingRight: '1rem' }}>
          <div className="d-flex flex-row w-100" style={{ height: 'max-content' }}>
            <div style={{ flex: 12, display: 'flex', alignItems: 'center' }}>
              <SearchBox
                onChange={(e) => setSearchKey(e.target.value)}
                style={{
                  '& fieldset': {
                    height: '35px !important',
                    borderRadius: '30px',
                    marginTop: 3,
                  },
                  '& .MuiFormLabel-root': {
                    fontSize: 14,
                  },
                  '& .MuiIconButton-root': {
                    transform: 'scale(0.8)',
                  },
                }}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 10 }}>
              <Sort className="pointer" style={{ fontSize: 16 }} />
            </div>
          </div>
          {!searchedAsset.length ? (
            <div className="flex-standard mt-3 flex-column">
              <p className="font-italic font-weight-bold" style={{ fontSize: 20, color: '#8a9db6' }}>No Results Found</p>
              <p>Try using different keywords</p>
            </div>
          ) : (
            <Grid container item xs={12} className="mt-3" style={{ overflow: 'auto', maxHeight: '35rem' }}>
              {searchedAsset.map((e, idx) => {
                return (
                  <Grid
                    item
                    xs={12}
                    className="py-1 px-2 pointer"
                    style={{ backgroundColor: selectedAsset === idx ? '#D9D9D9' : 'white' }}
                    onClick={() => setSelectedAsset(idx)}
                  >
                    <div className="font-weight-bolder d-flex align-items-center justify-content-between">
                      <div className="text-title">{e.name}</div>
                      <div className="text-title">{moment(e.expecteddecommissionAt).diff(moment(e.commissionAt), 'weeks')}&nbsp;weeks</div>
                    </div>
                    <div className="font-italic text-standard font-weight-bolder d-flex align-items-center pt-1 mb-4" style={{ fontSize: 12, color: '#b7b7b9' }}>
                      <CheckCircle style={{ color: '#4eca71', fontSize: 16 }} />
                      &nbsp;completed on {moment(e.expecteddecommissionAt).format('DD MMMM YYYY')}
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </div>
        <div className="flex-standard px-2 py-1" style={{ flex: 0.1 }}>
          <Divider orientation="vertical" style={{ width: 2, backgroundColor: '#80808045' }} />
        </div>
        <div className={classes.card} style={{ flex: 8, paddingLeft: 20, paddingTop: 15 }}>
          {!searchedAsset.length ? <div className="flex-standard mt-3"><img src={NoData} style={{ height: '55vh' }} /></div> : (
            <div className="d-flex flex-column w-100">
              <div className="d-flex flex-row w-100 align-items-center">
                <h3 className="mr-2 text-title-lg" style={{ fontSize: 25 }}>{assetDetail?.name}</h3>
                <Chip
                  size="small"
                  label="Completed"
                  className="font-weight-bolder"
                  style={{
                    borderRadius: 3, backgroundColor: '#def2e4', color: '#73cb8f', transform: 'scale(0.8)',
                  }}
                />
              </div>
              <div className="d-flex flex-row w-100">
                <p
                  className="text-standard"
                  style={{ color: '#b7b7b9', fontSize: 14, marginTop: 5 }}
                >{assetDetail?.location}, {assetDetail?.state}, {assetDetail?.country}
                </p>
              </div>
              <div className="w-100 mt-4">
                <p className="font-weight-bolder pb-3 text-title">Site Details</p>
                <div className="p-2" style={{ ...cardCss }}>
                  <Grid container>
                    {[
                      { title: 'Location', icon: location, value: assetDetail?.location ?? '-' },
                      { title: 'Province', icon: map, value: assetDetail?.state ?? '-' },
                      { title: 'Site Type', icon: site_type, value: props.assetTypeList.find(e => e.id === assetDetail?.AssetTypeId)?.name ?? '-' },
                      { title: 'Site Contractor', icon: contractor, value: contractorName ?? '-' },
                      { title: 'Site Manager', icon: site_manager, value: assetDetail?.project_manager ?? '-' },
                      { title: 'Contact Number', icon: phone_number, value: '0123456789' },
                    ].map(detail => (
                      <Grid item xs={12} md={6} className="d-flex flex-row py-2 px-3">
                        <div className="flex-standard" style={{ flex: 1 }}>
                          <img src={detail.icon} style={{ cursor: 'pointer', width: '15px' }} />
                        </div>
                        <div className="pl-2 d-flex justify-content-center flex-column" style={{ flex: 4 }}>
                          <p className="text-standard" style={{ color: '#b7b7b9' }}>{detail.title}</p>
                          <p className="font-weight-bolder text-title">{detail.value}</p>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </div>
              <div className="w-100 mt-4">
                <p className="font-weight-bolder pb-3 text-title">Site Overview</p>
                <div className="p-2" style={{ ...cardCss }}>
                  <Grid container>
                    {[
                      { title: 'Total CCTV', icon: cctv_dashboard, value: siteOverviewDetail.cctv },
                      { title: 'Total Media', icon: video, value: siteOverviewDetail.media },
                      { title: 'Total Orthophoto', icon: image, value: siteOverviewDetail.orthophoto },
                      { title: 'Non-Conformity Report', icon: ncr, value: siteOverviewDetail.ncr },
                    ].map(detail => (
                      <Grid item xs={12} md={6} className="d-flex flex-row py-2 px-3">
                        <div className="flex-standard" style={{ flex: 1 }}>
                          <img src={detail.icon} style={{ cursor: 'pointer', width: '14px' }} />
                        </div>
                        <div className="pl-2 d-flex justify-content-center flex-column" style={{ flex: 4 }}>
                          <p className="text-standard" style={{ color: '#b7b7b9' }}>{detail.title}</p>
                          <p className="font-weight-bolder text-title">{detail.value}</p>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Grid>
  );
}

const cardCss = {
  backgroundColor: 'white',
  borderRadius: 10,
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  padding: 13,
  position: 'relative',
};
