import React from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles({
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#c3c3c5',
    },
  },
  leftHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      color: '#c3c3c5',
    },
  },
});

export default ({
  tableData,
}) => {
  const classes = useStyles();

  const columns = [
    {
      name: 'Issue',
      selector: 'name',
      options: {
        setCellHeaderProps: () => ({ className: classes.leftHeader }),
        setCellProps: () => ({ className: classes.leftHeader }),
      },
    },
    {
      name: 'Status',
      selector: 'status',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
        sortCompare: (order) => ({ data: data1 }, { data: data2 }) => {
          if (order === 'desc') return data2.props.label.localeCompare(data1.props.label);
          return (data1.props.label.localeCompare(data2.props.label));
        },
      },
    },
    {
      name: 'Issued By',
      selector: 'user',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
    {
      name: 'Date',
      selector: 'date',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
  ];

  const options = {
    rowsPerPage: 5,
    rowsPerPageOptions: [5],
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        options={options}
        columns={columns.map((col) => ({ ...col, label: col.name, name: col.selector }))}
        data={tableData.map((x) => {
          return ({
            ...x,
            name: x.name,
            status: <Chip label={x.status} style={{ ...chipCSS, backgroundColor: x.status === 'Closed' ? '#3dd598' : '#ffc542', width: '50%' }} />,
            user: x.user,
            date: moment(x.date).format('DD/MM/YYYY'),
          });
        })}
      />
    </MuiThemeProvider>
  );
};

const chipCSS = { color: 'black', width: '80%', height: '1.5rem', fontSize: 12 };

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
  overrides: {
    MUIDataTableToolbar: {
      root: {
        display: 'none',
      },
    },
    MUIDataTableSelectCell: {
      root: {
        display: 'none',
      },
    },
    MUIDataTableBodyCell: {
      root: {
        paddingLeft: 10,
        fontSize: 12,
      },
    },
    MUIDataTableBodyRow: {
      root: {
        borderBottom: '1px solid #e5e5e5',
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: '0px 0px 0px 0px',
      },
    },
    MuiTableHead: {
      root: {
        '& .MuiTableHead-root': {
          color: '#000000DE',
          fontSize: 12,
          fontWeight: 500,
          verticalAlign: 'middle',
        },
        '& .MuiTableCell-root': {
          color: '#c3c3c5',
          fontSize: 12,
          fontWeight: 500,
          verticalAlign: 'middle',
          paddingLeft: '10px !important',
          backgroundColor: '#fafafb',
        },
      },
    },
    MUIDataTableHeadCell: {
      toolButton: {
        margin: '0px !important',
      },
      data: {
        fontSize: 12,
      },
    },
  },
});
