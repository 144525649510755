import Button from '@Components/Button';
import {
  Grid, InputLabel, MenuItem, TextField, Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import DataNotFoundImg from '@Assets/Images/Data-not-found3.svg';
import { Info } from '@material-ui/icons';
import { today } from '@Helpers';
import { Link } from 'react-router-dom';

export default (h) => {
  return (
    <div className="mt-5 mx-auto" style={{ minHeight: '60vh', width: '80%' }}>
      <Grid container xs={12}>
        <Grid container item xs={12} className="d-flex justify-content-between align-items-center">
          <Grid item className="d-flex align-items-center">
            <InputLabel className="mr-2 font-weight-bolder" style={{ fontSize: 16, margin: 0, color: 'black' }}>Date:&nbsp;</InputLabel>
            <TextField
              value={h.reportDate}
              onChange={e => h.setReportDate(e.target.value)}
              className="justify-content-center"
              variant="standard"
              type="date"
              InputProps={{ inputProps: { max: today } }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs="auto" className="d-flex align-items-center">
            <InputLabel className="d-flex align-items-center mr-2 font-weight-bolder" style={{ fontSize: 16, margin: 0, color: 'black' }}>
              IOT Weather Availability
              <Tooltip title="Yes: Has Device, No: Data Taken from OWM">
                <Info className="px-1" style={{ fontSize: 18 }} />
              </Tooltip>
              :&nbsp;
            </InputLabel>
            <TextField
              select
              value={h.hasIotWeather}
              onChange={e => h.setHasIotWeather(e.target.value)}
              className="justify-content-center"
              variant="standard"
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt-3">
          {h.hasIotWeather === null ? (
            <div className="d-flex justify-content-center">
              <img src={DataNotFoundImg} style={{ width: '25vw' }} />
            </div>
          ) : (
            <div>
              <div className="d-flex" style={{ backgroundColor: '#D8E5F4' }}>
                <TableCell value="Hour" style={{ flex: 2, fontWeight: 'bolder' }} isHeader />
                <TableCell value="Weather" style={{ flex: 3, fontWeight: 'bolder' }} isHeader />
                <TableCell value="Intensity" style={{ flex: 2, fontWeight: 'bolder' }} isHeader />
                <TableCell value="Temperature" style={{ flex: 2, fontWeight: 'bolder' }} isHeader />
                <TableCell value="Wind" style={{ flex: 2, fontWeight: 'bolder' }} isHeader />
                <TableCell value="Humidity" style={{ flex: 2, fontWeight: 'bolder' }} isHeader />
                <TableCell value="UV" style={{ flex: 2, fontWeight: 'bolder' }} isHeader />
                <TableCell value="Dew Point" style={{ flex: 2, fontWeight: 'bolder' }} isHeader />
              </div>
              {dummyData.map((data, idx) => {
                return (
                  <div className="d-flex" style={{ backgroundColor: !!(idx % 2) ? '#F8FBFF' : 'white' }}>
                    <TableCell value={moment(data.hour).format('hh:mm A')} style={{ flex: 2 }} />
                    <TableCell value={data.weather} style={{ flex: 3 }} />
                    <TableCell value={`${data.intensity}%`} style={{ flex: 2 }} />
                    <TableCell value={`${data.temperature}°C`} style={{ flex: 2 }} />
                    <TableCell value={`${data.wind} m/s S`} style={{ flex: 2 }} />
                    <TableCell value={`${data.humidity}%`} style={{ flex: 2 }} />
                    <TableCell value={data.uv} style={{ flex: 2 }} />
                    <TableCell value={data.dew_point} style={{ flex: 2 }} />
                  </div>
                );
              })}
            </div>
          )}
        </Grid>
      </Grid>
      <div className="d-flex justify-content-end mt-4">
        <Link to={`/project/report?id=${h.logDetail?.AssetId}`}>
          <Button variant="text" onClick={h.handleBackStep}>
            CANCEL
          </Button>
        </Link>
        <Button onClick={h.handleNextStep} disabled={!h.hasIotWeather}>
          NEXT
        </Button>
      </div>
    </div>
  );
};

const TableCell = ({ value, style, isHeader = false }) => (
  <div className="d-flex justify-content-center py-2" style={{ borderLeft: `1px solid ${isHeader ? 'white' : '#F6FAFF'}`, ...style }}>{value}</div>
);

const dummyData = [
  {
    id: 1, hour: '2023-07-24T21:00:00.000Z', weather: 'Light Rain', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 2, hour: '2023-07-24T22:00:00.000Z', weather: 'Clear Sky', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 3, hour: '2023-07-24T23:00:00.000Z', weather: 'Light Rain', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 4, hour: '2023-07-24T00:00:00.000Z', weather: 'Clear Sky', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 5, hour: '2023-07-24T01:00:00.000Z', weather: 'Light Rain', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 6, hour: '2023-07-24T02:00:00.000Z', weather: 'Clear Sky', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 7, hour: '2023-07-24T03:00:00.000Z', weather: 'Overcast Clouds', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 8, hour: '2023-07-24T04:00:00.000Z', weather: 'Light Rain', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 9, hour: '2023-07-24T05:00:00.000Z', weather: 'Clear Sky', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 10, hour: '2023-07-24T06:00:00.000Z', weather: 'Light Rain', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 11, hour: '2023-07-24T07:00:00.000Z', weather: 'Clear Sky', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 12, hour: '2023-07-24T08:00:00.000Z', weather: 'Overcast Clouds', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 13, hour: '2023-07-24T09:00:00.000Z', weather: 'Light Rain', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 14, hour: '2023-07-24T10:00:00.000Z', weather: 'Clear Sky', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 15, hour: '2023-07-24T11:00:00.000Z', weather: 'Light Rain', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 16, hour: '2023-07-24T12:00:00.000Z', weather: 'Clear Sky', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 17, hour: '2023-07-24T13:00:00.000Z', weather: 'Light Rain', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 18, hour: '2023-07-24T14:00:00.000Z', weather: 'Clear Sky', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 19, hour: '2023-07-24T15:00:00.000Z', weather: 'Overcast Clouds', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 20, hour: '2023-07-24T16:00:00.000Z', weather: 'Light Rain', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 21, hour: '2023-07-24T17:00:00.000Z', weather: 'Clear Sky', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 22, hour: '2023-07-24T18:00:00.000Z', weather: 'Light Rain', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 23, hour: '2023-07-24T19:00:00.000Z', weather: 'Clear Sky', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
  {
    id: 24, hour: '2023-07-24T20:00:00.000Z', weather: 'Overcast Clouds', intensity: 10, temperature: 31, wind: 4.2, humidity: 32, uv: 7, dew_point: 9,
  },
];
