import Button from '@Components/Button';
import {
  TextField, InputLabel, Select, MenuItem, FormControl,
} from '@material-ui/core';

export default (h) => {
  const isFieldEmpty = !(!!h.masterlistType && !!h.masterlistDate && !!h.masterlistName);
  return (
    <div className="w-25 mt-5 mx-auto" style={{ minHeight: '60vh' }}>
      <TextField
        label="Masterlist Name"
        value={h.masterlistName}
        onChange={e => h.setMasterlistName(e.target.value)}
        className="w-100 my-2"
        variant="standard"
      />
      <TextField
        label="Masterlist Date"
        value={h.masterlistDate}
        onChange={e => h.setMasterlistDate(e.target.value)}
        className="w-100 my-3"
        variant="standard"
        type="date"
        InputLabelProps={{ shrink: true }}
      />
      <FormControl className="w-100">
        <InputLabel>Masterlist Type</InputLabel>
        <Select
          value={h.masterlistType}
          onChange={(e) => h.setMasterlistType(Number(e.target.value))}
        >
          {h.masterlistOptions.map(p => (
            <MenuItem value={p.id}>{p.item}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button className="mt-4 float-right" onClick={h.handleNextStep} disabled={isFieldEmpty}>
        NEXT
      </Button>
    </div>
  );
};
