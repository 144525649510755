import Button from '@Components/Button';
import { TextField } from '@material-ui/core';

export default (h) => {
  const isFieldEmpty = !(!!h.drawingTitle && !!h.drawingDate && !!h.drawingVersion && !!h.drawingRemarks);
  return (
    <div className="w-25 mt-5 mx-auto" style={{ minHeight: '60vh' }}>
      <TextField
        label="Drawing Title"
        value={h.drawingTitle}
        onChange={e => h.setDrawingTitle(e.target.value)}
        className="w-100 my-2"
        variant="standard"
      />
      <TextField
        label="Drawing Date"
        value={h.drawingDate}
        onChange={e => h.setDrawingDate(e.target.value)}
        className="w-100 mt-3"
        variant="standard"
        type="date"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Version"
        value={h.drawingVersion}
        onChange={e => h.setDrawingVersion(e.target.value)}
        className="w-100 my-2"
        variant="standard"
      />
      <TextField
        label="Remarks"
        value={h.drawingRemarks}
        onChange={e => h.setDrawingRemarks(e.target.value)}
        className="w-100 my-4"
        variant="outlined"
        rows={4}
        multiline
      />
      <Button className="mt-4 float-right" onClick={h.handleNextStep} disabled={isFieldEmpty}>
        NEXT
      </Button>
    </div>
  );
};
