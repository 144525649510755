import { Grid, Button } from '@material-ui/core';
import { DescriptionOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import AssetInfoCard from '@Components/DashboardCard/AssetInfo';
import AssetAiStatusCard from '@Components/DashboardCard/AssetAiStatus';
import AssetTimelineCard from '@Components/DashboardCard/AssetTimeline';
import AssetLogUpdatesCard from '@Components/DashboardCard/AssetLogUpdates';
import AssetWeatherIotCard from '@Components/DashboardCard/AssetWeatherIot';
import ReportTableCard from '@Components/DashboardCard/ReportTable';
import moment from 'moment';

import DeniedPage from '@Pages/DeniedPage';
import AssetCompliance from '@Components/DashboardGraph/AssetCompliance';
import CCTV from '@Components/DashboardCard/CCTV';
import { dateRange } from '@Helpers';

const useStyles = makeStyles({
  container: {
    height: '-webkit-fill-available',
    padding: 10,
  },
  cardHead: {
    padding: 30,
    // height: 180,
  },
  cardHeadRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 20,
    marginTop: 100,
    // height: 180,
  },
  card: {
    // marginTop: 10,
    padding: 10,
    height: 'max-content',
    // height: 180,
  },
});
const DummyIot = {};
const currentDate = moment().format('YYYY/MM/DD');
dateRange('2023/01/01', currentDate).forEach(d => {
  DummyIot[d] = {
    average: {
      w: 1,
      t: 34,
    },
    byTime: [
      {
        time: '19:43',
        w: 1,
        t: 34,
        others: [
          { w: 31, v: 34 },
          { w: 36, v: 34 },
          { w: 34, v: 34 },
          { w: 35, v: 34 },
          { w: 32, v: 34 },
          { w: 33, v: 34 },
        ],
      },
      { time: '22:00', w: 1, t: 29 },
      { time: '01:00', w: 1, t: 26 },
      { time: '04:00', w: 1, t: 22 },
    ],
  };
});
export default function view(props) {
  const classes = useStyles();
  const selectedAsset = props.assets.find(asset => asset.id === Number(props.AssetId));
  if (!selectedAsset) return (<DeniedPage />);
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={8} className={classes.cardHead}>
        <h1 className="text-title-xl mb-1" style={{ fontWeight: 600, marginTop: 10 }}>{selectedAsset.name} </h1>
        <p className="text-standard-lg" style={{ marginBottom: 20, color: 'grey' }}>Manage and track your site overview and report website traffic.</p>
      </Grid>
      <Grid item xs={12} md={4} className={classes.cardHeadRight}>
        <Button
          style={{
            backgroundColor: '#2960f6',
            color: 'white',
            textTransform: 'none',
            fontSize: 12,
            marginTop: 4,
            paddingInline: 20
          }}
        >
          <DescriptionOutlined style={{ fill: 'white', width: 20, marginRight: 5, fontSize: 14 }} />
          GENERATE REPORT
        </Button>
      </Grid>
      <Grid container item xs={12} md={12} style={{ height: 'max-content' }}>
        <Grid container item xs={12} md={3} style={{ height: 'max-content' }}>
          <Grid item xs={12} md={12} className={classes.card}>
            <AssetInfoCard
              asset={selectedAsset}
              {...props}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{
              marginTop: 10, paddingRight: 10, paddingLeft: 10, height: 'max-content',
            }}
          >
            <AssetAiStatusCard
              assetId={selectedAsset.id}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.card} style={{ marginTop: 5 }}>
            <AssetTimelineCard startDate={new Date(selectedAsset.commissionAt)} endDate={new Date(selectedAsset.expecteddecommissionAt)} />
          </Grid>
          <Grid item xs={12} md={12} className={classes.card}>
            <AssetLogUpdatesCard
              title="Active Site"
              withChart
              withStatistics
              statisticsData={{
                rate: false,
                percent: 75,
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.card}>
            <AssetWeatherIotCard
              title="Weather"
              data={DummyIot}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={9}>
          <Grid item xs={12} md={12} className={classes.card}>
            {selectedAsset && <AssetCompliance data={props.complianceData} />}
          </Grid>
          {selectedAsset.has_cctv && (
            <Grid item xs={12} md={12} className={classes.card}>
              <CCTV
                cctvs={selectedAsset.cctvs}
                open={props.openCCTVModal}
                setOpen={props.setOpenCCTVModal}
                selected={props.selectedCCTV}
                setSelected={props.setSelectedCCTV}
              />
            </Grid>
          )}
          <Grid item xs={12} md={12} className={classes.card}>
            <ReportTableCard title="Non Conformity Report" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
