import Button from '@Components/Button';
import { InputLabel, TextField } from '@material-ui/core';

export default (h) => {
  const isFieldEmpty = !(!!h.reportDate && !!h.causeNonConformance && !!h.correctiveAction);
  return (
    <div className="mt-5 mx-auto" style={{ minHeight: '60vh', width: '30%' }}>
      <div className="mb-3">
        <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Select Date</InputLabel>
        <TextField
          value={h.reportDate}
          onChange={e => h.setReportDate(e.target.value)}
          className="w-100 mb-3"
          variant="standard"
          type="date"
          InputLabelProps={{ shrink: true }}
        />
      </div>
      <div className="my-3">
        <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Cause of Non-Conformance</InputLabel>
        <TextField
          value={h.causeNonConformance}
          onChange={e => h.setCauseNonConformance(e.target.value)}
          className="w-100 mb-3 mt-1"
          variant="outlined"
          multiline
          minRows={4}
          maxRows={4}
        />
      </div>
      <div className="my-3">
        <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Corrective Action</InputLabel>
        <TextField
          value={h.correctiveAction}
          onChange={e => h.setCorrectiveAction(e.target.value)}
          className="w-100 mb-3 mt-1"
          variant="outlined"
          multiline
          minRows={6}
          maxRows={6}
        />
      </div>
      <div className="d-flex justify-content-end mt-4">
        <Button variant="text" onClick={h.handleBackStep}>
          PREVIOUS
        </Button>
        <Button onClick={h.handleNextStep} disabled={isFieldEmpty}>
          NEXT
        </Button>
      </div>
    </div>
  );
};
