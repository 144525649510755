/* eslint-disable max-len */
/* eslint-disable max-lines */
import { useState, useEffect } from 'react';
import {
  Grid, ButtonGroup, Button, TextField, MenuItem,
} from '@material-ui/core';
import { DescriptionOutlined } from '@material-ui/icons';
import FilterIcon from '@Assets/Icons/filter-solid.svg';
import { makeStyles } from '@material-ui/core/styles';
import StatusCard from '@Components/DashboardCard/Status';
import Breakdown from '@Components/DashboardCard/Breakdown';
import WeatherMap from '@Components/DashboardCard/WeatherMap';
import AssetCompliance from '@Components/DashboardGraph/AssetCompliance';
import ProjectProgress from '@Components/DashboardCard/ProjectProgress';
import SearchBox from '@Components/SearchBox/v2';
import _ from 'lodash';

const useStyles = makeStyles({
  container: {
    height: '-webkit-fill-available',
    padding: 10,
  },
  card: {
    padding: 10,
    minHeight: 'max-content',
    minWidth: '20%',
  },
  cardWeather: {
    marginTop: 25,
    padding: 10,
    minHeight: 'max-content',
    height: 400,
  },
});
export default function view(props) {
  const classes = useStyles();
  const [overviewTab, setOverviewTab] = useState('all');
  const [filter, setFilter] = useState(0);
  const [searchKey, setSearchKey] = useState('');
  const [date, setDate] = useState();

  useEffect(() => {
    const keys = ['name'];
    const values = [searchKey];
    // const result = searchItems(allAsset, keys, values);
    // // console.log('mmm search', result, keys, values);
    // setAsset(result);
  }, [searchKey]);
  const handleClickTab = (tab) => {
    setOverviewTab(tab);
  };
  const calculatedMissingAssetFiles = props.assets.map(({ asset_files, name }) => {
    const currentYearAssetFile = asset_files.filter(item => (new Date(item.createdAt).getFullYear()) === 2023);
    const uniqueMonths = currentYearAssetFile.reduce((months, item) => months.add(new Date(item.createdAt).getMonth()), new Set());
    const currentMonth = new Date().getMonth() + 1; // Add 1 because getMonth() is zero-based
    const missingMonthsAssetFile = currentMonth - uniqueMonths.size;
    return {
      name,
      missingMonthsAssetFile,
      missingMonthsAssetFilePercent: missingMonthsAssetFile / currentMonth * 100,
    };
  });
  calculatedMissingAssetFiles.sort((a, b) => b.missingMonthsAssetFile - a.missingMonthsAssetFile);

  const decommissionedCount = props.assets.reduce((count, item) => {
    const decommissionDate = new Date(item.expecteddecommissionAt);
    return count + (decommissionDate < new Date());
  }, 0);
  const activeSiteCount = props.assets.length - decommissionedCount;

  const checkNcr = Object.values(_.groupBy(props.ncrReport, 'AssetId')).map(report => ({
    asset_name: report[0]?.Asset.name,
    reports: report,
    open_ncr: report.filter(f => !!f.is_open).length,
    closed_ncr: report.filter(f => !f.is_open).length,
  })).sort((a, b) => b.open_ncr - a.open_ncr);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} md={6}>
        <h2 style={{
          fontWeight: 600, marginTop: 20, paddingLeft: 10, fontSize: 22,
        }}
        >
          Welcome, {props.user?.name}
        </h2>
        <p
          style={{
            marginBlock: 10, marginBottom: 20, color: 'grey', paddingLeft: 10, fontSize: 14,
          }}
        >
          Manage and track your site overview and report website traffic.
        </p>
        <ButtonGroup
          variant="outlined"
          aria-label="outlined primary button group"
          style={{ marginBottom: 10, paddingLeft: 10 }}
        >
          <Button
            onClick={() => handleClickTab('all')}
            variant={overviewTab === 'all' ? 'contained' : 'outlined'}
            style={{ fontSize: 12 }}
          >All
          </Button>
          <Button
            onClick={() => handleClickTab('full')}
            variant={overviewTab === 'full' ? 'contained' : 'outlined'}
            style={{ fontSize: 12 }}
          >Full
          </Button>
          <Button
            onClick={() => handleClickTab('basic')}
            variant={overviewTab === 'basic' ? 'contained' : 'outlined'}
            style={{ fontSize: 12 }}
          >Basic
          </Button>
          <Button
            onClick={() => handleClickTab('lite')}
            variant={overviewTab === 'lite' ? 'contained' : 'outlined'}
            style={{ fontSize: 12 }}
          >Lite
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid container item xs={6} md={6}>
        <Grid item xs={12} md={12} justifyContent="flex-end" style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: -20 }}>
          <div className="d-flex" style={{ transform: 'scale(0.8)', marginTop: 30, marginRight: -10 }}>
            <SearchBox onChange={(e) => setSearchKey(e.target.value)} />
          </div>
        </Grid>
        <Grid container item xs={12} md={12} spacing={2}>
          <Grid item xs={4} md={4}>
            <div style={{
              display: 'flex', alignItems: 'center', transform: 'scale(0.8)', marginRight: '-3vw',
            }}
            >
              <TextField
                select
                variant="outlined"
                value={filter}
                onChange={e => setFilter(e.target.value)}
                size="small"
                fullWidth
              >
                <MenuItem disabled value={0} style={{ fontSize: 12 }}><img src={FilterIcon} style={{ padding: '0px 5px 3px 0px', cursor: 'pointer', width: 12 }} /> Filter</MenuItem>
                <MenuItem value={1} style={{ fontSize: 12 }}><img src={FilterIcon} style={{ padding: '0px 5px 3px 0px', cursor: 'pointer', width: 12 }} /> Monthly</MenuItem>
                <MenuItem value={2} style={{ fontSize: 12 }}><img src={FilterIcon} style={{ padding: '0px 5px 3px 0px', cursor: 'pointer', width: 12 }} /> Weekly</MenuItem>
                <MenuItem value={3} style={{ fontSize: 12 }}><img src={FilterIcon} style={{ padding: '0px 5px 3px 0px', cursor: 'pointer', width: 12 }} /> Daily</MenuItem>
              </TextField>
            </div>
          </Grid>
          <Grid item xs={4} md={4}>
            <div style={{
              display: 'flex', alignItems: 'center', transform: 'scale(0.82)', marginRight: '-1.5vw',
            }}
            >
              <TextField
                value={date}
                onChange={(e) => setDate(e.target.value)}
                type="date"
                size="small"
                variant="outlined"
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={4} md={4} alignItems="center">
            <Button
              style={{
                backgroundColor: '#2960f6',
                color: 'white',
                textTransform: 'none',
                height: '49%',
                fontSize: 12,
                marginTop: 4,
              }}
              fullWidth
            >
              <DescriptionOutlined style={{
                fill: 'white', width: 20, marginRight: 5, fontSize: 14,
              }}
              />
              GENERATE REPORT
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={12}>
        <Grid item xs={6} md={2} className={classes.card}>
          <StatusCard
            title="Active Site"
            withChart
            withStatistics
            statisticsData={{
              rate: false,
              percent: Number((activeSiteCount / props.assets.length * 100).toFixed(2)),
            }}
            chartData={[{ x: 1, y: activeSiteCount }, { x: 2, y: 0 }, { x: 3, y: 0 }]} // need to split by group: Full Basic Lite later
            legendList={['Full', 'Basic', 'Lite']}
            seeDetails="/dashboard/activesite"
            isReload
          />
        </Grid>
        <Grid item xs={6} md={2} className={classes.card}>
          <StatusCard
            title="Completed Site"
            withFraction
            fractionData={{
              x: decommissionedCount,
              y: props.assets.length,
              unit: 'site',
            }}
            withStatistics
            statisticsData={{
              rate: false,
              percent: Number((decommissionedCount / props.assets.length * 100).toFixed(2)),
            }}
            seeDetails="/dashboard/completedsite"
            isReload
          />
        </Grid>
        <Grid item xs={6} md={2} className={classes.card}>
          <StatusCard
            title="Lowest Orthophoto"
            withStatistics
            statisticsData={{
              rate: true,
              percent: calculatedMissingAssetFiles[0]?.missingMonthsAssetFile > 0 ? -calculatedMissingAssetFiles[0]?.missingMonthsAssetFilePercent : 0,
            }}
            withErrorLabel
            errorLabel={{
              value: calculatedMissingAssetFiles[0]?.missingMonthsAssetFile,
              description: 'missed update',
            }}
            withProjectName
            projectName={`${calculatedMissingAssetFiles[0]?.name}`}
            seeDetails="/dashboard/orthophoto-update"
            isReload
          />
        </Grid>
        <Grid item xs={6} md={2} className={classes.card}>
          <StatusCard
            title="Highest Open NCR"
            withSegments
            projectName={checkNcr[0]?.asset_name}
            segmentsData={[{ x: 1, y: checkNcr[0]?.closed_ncr }, { x: 2, y: checkNcr[0]?.open_ncr }, { x: 3, y: 0 }]}
            seeDetails="/dashboard/nonconfirmityreport"
            isReload
          />
        </Grid>
        <Grid item xs={6} md={2} className={classes.card}>
          <StatusCard
            title="Highest Open Site Memo"
            withSegments
            projectName="Hospital Petrajaya, Sarawak"
            segmentsData={[{ x: 1, y: 0 }, { x: 2, y: 0 }, { x: 3, y: 0 }]}
            legendList={['Open', 'Closed', 'Pending']}
            seeDetails="/dashboard/nonconfirmityreport"
            isReload
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} md={12}>
        <Grid item xs={12} md={7} className={classes.card} style={{ height: 320 }}>
          <AssetCompliance data={props.complianceData} />
        </Grid>
        <Grid item xs={12} md={5} className={classes.card} style={{ height: 320 }}>
          <Breakdown assets={props.assets} title="CCTV Breakdown" seeDetails="/dashboard/cctvbreakdown" />
        </Grid>
      </Grid>
      <Grid container item xs={12} md={12}>
        <Grid item xs={12} md={4} className={classes.cardWeather}>
          <ProjectProgress title="Project Deadline" assets={props.assets} />
        </Grid>
        <Grid item xs={12} md={8} className={classes.cardWeather}>
          <WeatherMap title="Weather Today" assets={props.assets} assets_weather={props.assets_weather} />
        </Grid>
      </Grid>
    </Grid>
  );
}
