import React, { useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText,
} from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import DataNotFoundImg from '@Assets/Images/Data-not-found3.svg';
import Radio from '@Components/Radio';

export default function DeleteDialog({
  open, setOpen, addInspectionId, inspectionSessions,
}) {
  const [selectedInspection, setSelectedInspection] = useState();

  const handleClose = () => { setSelectedInspection(); setOpen(); };
  const handleAddInspection = () => {
    handleClose();
    addInspectionId(selectedInspection);
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <Dialog open={Number.isInteger(Number(open))} onClose={handleClose}>
        <DialogContent className="mx-3 mt-4">
          <h2 className="text-center mb-2" style={{ fontSize: 20 }}>Select inspection to add to this task?</h2>
          <DialogContentText>
            {!!inspectionSessions.length
              ? (
                <Radio
                  items={inspectionSessions.map(d => ({ value: d.id, label: <p>{d.id} {d.name}</p> }))}
                  value={selectedInspection}
                  onChange={(e) => setSelectedInspection(Number(e.target.value))}
                />
              )
              : (
                <div className="d-flex justify-content-center">
                  <img src={DataNotFoundImg} style={{ width: '30vw' }} />
                </div>
              )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleAddInspection} color="primary" autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
}

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
});
