/* eslint-disable complexity */
import {
  Switch, Route, useLocation, Redirect,
} from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Map from '@Components/MapV2';
import _ from 'lodash';

import InspectionSession from '@Pages/InspectionSession';
import Mapping from '@Pages/Mapping';
import Report from '@Pages/Report';
import Document from '@Pages/Document'
import CCTV from '@Pages/CCTV';
import CctvAnalytics from '@Pages/CctvAnalytics';
import IFrame from '@Pages/IFrame';
import IFrameList from '@Pages/IFrameList';
import ManpowerAnalytic from '@Pages/ManpowerAnalytic';
import SCurve from '@Pages/SCurve';
import InfoDialog from './components/InfoDialog';

import Sidebar from './Sidebar';
import Hook from './hook';
import WelcomePage from './components/WelcomePage';

export default function Property(props) {
  const location = useLocation();
  if (!!props.user && ['name', 'email'].map(attr => !!props.user[attr]).includes(false)) {
    if (props.user.RoleId > 1 && !!props.user.OrganizationId) return <Redirect to="/pending-information" />;
  }
  const prefixLocation = location.pathname.split('/');
  const isAssetView = prefixLocation.length > 4 && prefixLocation[2] === 'asset';
  const h = Hook(props);
  const sidebarCondition = (!!!isAssetView && !!h.projects.length && !_.isEmpty(h.project));
  return (
    <Grid container>
      {!!h.openInfoDialog && <InfoDialog {...h} />}
      {!!props.user?.is_show_intro && process.env.REACT_APP_BRANCH === 'galaxy' && <WelcomePage {...h} />}
      {sidebarCondition && <Sidebar {...h} parentProps={props} sidebarCondition={sidebarCondition} prefixLocation={prefixLocation} />}
      <Grid item xs={12} md={9} className="main d-flex">
        <Switch>
          {[
            {
              path: '/project',
              exact: true,
              render: () => { props.setIsMap(true); return (<Map {...h} />); },
            },
            {
              path: '/project/inspection',
              children: <InspectionSession {...h} user={props.user} assetTypeList={h.assetTypeList} />,
            },
            {
              path: '/project/report',
              children: <Report {...h} user={props.user} />,
            },
            {
              path: '/project/document',
              children: <Document {...h} user={props.user} />,
            },
            {
              path: '/project/mapping',
              children: <Mapping {...h} />,
            },
            {
              path: '/project/cctv',
              children: <CCTV {...h} />,
            },
            {
              path: '/project/osh',
              children: <CctvAnalytics {...h} analytic_type="osh" />,
            },
            {
              path: '/project/project-progress',
              children: <CctvAnalytics {...h} analytic_type="project-progress" />,
            },
            {
              path: '/project/asset/3d/:id',
              children: <IFrame type="3d" {...h} />,
            },
            {
              path: '/project/asset/360/:id',
              children: <IFrame type="threeSixty" {...h} />,
            },
            {
              path: '/project/asset/3d',
              children: <IFrameList type="3d" {...h} user={props.user} />,
            },
            {
              path: '/project/asset/360',
              children: <IFrameList type="threeSixty" {...h} user={props.user} />,
            },
            {
              path: '/project/manpower-analytic',
              children: <ManpowerAnalytic {...h} />,
            },
            {
              path: '/project/s-curve',
              children: <SCurve {...h} />,
            },
          ].map(e => (
            <Route {...e} />
          ))}
        </Switch>
      </Grid>
    </Grid>
  );
}
