import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';

export default function Hook({ project }) {
  const [loadingTable, setLoadingTable] = useState(false);
  const [footage_detection, set_footage_detection] = useState([]);
  const [footage_detection_filtered, set_footage_detection_filtered] = useState(null);
  const [page, setPage] = useState(1);
  const [duration, setDuration] = useState(30);
  const [detectionLevel, setDetectionLevel] = useState(1);
  const perpage = 10;
  const getCCTVFootage = (CctvIdList) => {
    Api({
      endpoint: endpoints.getCCTVFootage(),
      data: { detection: detectionLevel, disablePagination: 1, sortby: 'id,DESC', CctvId: CctvIdList.join(',') },
      onSuccess: (res) => {
        set_footage_detection(res.data);
        setLoadingTable(false);
      },
      onFail: (err) => { toast('error', err); setLoadingTable(false); },
    });
  };
  const onClickPlot = (e) => set_footage_detection_filtered([e.data]);
  const onClickClear = () => set_footage_detection_filtered(null);
  useEffect(() => {
    if (!project?.id) return;
    setLoadingTable(true);
    Api({
      endpoint: endpoints.getCCTV(),
      data: { AssetId: project.id },
      onSuccess: ({ data }) => {
        const result = data.map(({ id }) => String(id));
        getCCTVFootage(result, project.id);
      },
      onFail: (err) => { toast('error', err); setLoadingTable(false); },
    });
  }, [project, detectionLevel]);
  return {
    footage_detection,
    footage_detection_filtered,
    pagination: {
      perpage,
      page,
      setPage,
    },
    loadingTable,
    duration,
    setDuration,
    onClickPlot,
    onClickClear,
    detectionLevel,
    setDetectionLevel,
  };
}
