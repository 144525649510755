/* eslint-disable max-len */
/* eslint-disable linebreak-style */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import { useEffect, useState } from 'react';
import {
  Grid, IconButton, Button, TextField, MenuItem, createTheme, MuiThemeProvider, Chip, CircularProgress, withStyles, Checkbox,
} from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import MaterialTable from '@Components/MaterialTable';
import HighlightTabs from '@Components/HighlightTabs';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Navbar from '@Components/Navbar';
import NoDataImg from '@Assets/Images/Data-not-found3.svg';
import DeleteDialog from '@Components/DeleteDialog';
import MainContentContainer from '@Components/MainContentContainer';
import { RaiseIconSelection } from '@Assets/Icons/RaiseIconSelection';
import UserIcon from '@Components/UserIcon';
import { EditIcon, ViewIcon } from '@Assets/Icons';
import { capitalize } from '@Helpers';
import moment from 'moment';
import {
  AccountCircle, AddOutlined, Assignment, Create, DateRange, GetApp, History,
} from '@material-ui/icons';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MonthLineCalender from '@Components/MonthLineCalender';
import Api, { endpoints } from '@Helpers/api';
import Hook from './hook';
import GenerateReport from './GenerateReport';
import {
  ActionSectionNCR, CategoryNCR, CheckStatus, StageNCR, VerificationNCR,
} from './Component';
import ReportStatusHistory from './Component/ReportStatusHistory';
import MergeLogConfirmation from './Component/MergeLogConfirmation';

const columns = [
  {
    name: 'REPORT ID',
    selector: 'id',
    align: 'left',
    minWidth: 70,
  },
  {
    name: 'REPORT GENERATED',
    selector: 'createdAt',
    align: 'center',
    minWidth: 220,
  },
  {
    name: 'DOWNLOAD',
    selector: 'download',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'DELETE',
    selector: 'delete',
    align: 'center',
    minWidth: 100,
  },
];

const columnsSR = [
  {
    name: 'ID',
    selector: 'id',
    align: 'left',
    minWidth: 60,
  },
  {
    name: 'FILE',
    selector: 'file_path',
    align: 'left',
    minWidth: 470,
  },
  {
    name: 'ACTIONS',
    selector: 'action',
    align: 'center',
    minWidth: 100,
  },
];

const columnsDS = [
  {
    name: 'ID',
    selector: 'id',
    align: 'center',
    minWidth: 10,
  },
  {
    name: 'NAME',
    selector: 'name',
    align: 'center',
    minWidth: 240,
  },
  {
    name: 'DESCRIPTION',
    selector: 'description',
    align: 'center',
    minWidth: 150,
  },
  {
    name: 'UPLOADER',
    selector: 'userimage',
    align: 'center',
  },
  {
    name: 'DATE',
    selector: 'inspectionAt',
    align: 'center',
  },
  {
    name: 'STATUS',
    selector: 'status',
    align: 'center',
  },
  {
    name: 'ACTION',
    selector: 'actions',
    align: 'center',
  },
];

const columnsDSLog = [
  {
    name: '#',
    selector: 'selector',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'ID',
    selector: 'id',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'NAME',
    selector: 'name',
    align: 'center',
    minWidth: 240,
  },
  {
    name: 'REPORTED AT',
    selector: 'reportedAt',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'CREATED AT',
    selector: 'createdAt',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'CREATED BY',
    selector: 'createdBy',
    align: 'center',
  },
  {
    name: 'ACTION',
    selector: 'actions',
    align: 'center',
  },
];

const columnsNCR = [
  {
    name: 'ID',
    selector: 'id',
    align: 'center',
    minWidth: 10,
  },
  {
    name: 'REFERENCE NUMBER',
    selector: 'refNum',
    align: 'center',
  },
  {
    name: 'STATUS',
    selector: 'status',
    align: 'center',
  },
  {
    name: 'DATE ISSUED',
    selector: 'dateIssued',
    align: 'center',
  },
  {
    name: 'CATEGORY',
    selector: 'category',
    align: 'center',
  },
  {
    name: 'CREATED BY',
    selector: 'user',
    align: 'center',
  },
  {
    name: 'STAGE',
    selector: 'stage',
    align: 'center',
  },
  {
    name: 'VERIFICATION',
    selector: 'verifiedBy',
    align: 'center',
  },
  {
    name: 'ACTION',
    selector: 'actions',
    align: 'center',
  },
];

const ViewBtn = (props) => (
  <Link to={{ pathname: `${process.env.REACT_APP_ENDPOINT_URL}${props.path}` }} target="_blank">
    <IconButton style={{ width: 24, height: 24 }}>
      <ViewIcon height="18px" width="18px" color="var(--primary-color)" />
    </IconButton>
  </Link>
);

const DeleteBtn = () => (
  <IconButton style={{ width: 24, height: 24 }}>
    <DeleteIcon height="15px" width="15px" style={{ color: 'red' }} />
  </IconButton>
);
const EditBtn = () => (
  <IconButton
    variant="contained"
    style={{ width: 24, height: 24 }}
  >
    <EditIcon width="15px" height="15px" color="var(--primary-color)" />
  </IconButton>
);

const ActionSection = (props, { isConstructionLog, setSelectedReport, setOpenDialog, setOpenSiteDiaryHistory }) => {
  return (
    isConstructionLog ? (
      <div className="d-flex justify-content-around align-items-center">
        <Link to={`/edit-log-detail/${props?.id}`}>
          <Create className="pointer" style={{ color: 'black' }} />
        </Link>
        <Link to={{ pathname: `${process.env.REACT_APP_ENDPOINT_URL}downloadReportContstructionLogSiteDiary?ConstructionLogIds=${props.id}` }} target="_blank">
          <GetApp className="pointer" style={{ color: 'black' }} />
        </Link>
        <DeleteIcon className="pointer" style={{ color: 'red' }} onClick={() => { setSelectedReport(props); setOpenDialog(true); }} />
      </div>
    ) : (
      <div className="flex-standard" style={{ gap: 2 }}>
        <ViewBtn {...props} />
        <IconButton onClick={() => { setSelectedReport(props); setOpenSiteDiaryHistory(true); }}>
          <History />
        </IconButton>
        {/* <DeleteBtn /> */}
      </div>
    )
  );
};

export default function Report(props) {
  const h = Hook(props);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedReport, setSelectedReport] = useState();
  const [openSiteDiaryHistory, setOpenSiteDiaryHistory] = useState(false);
  const [siteDiaryHistoryDetail, setSiteDiaryHistoryDetail] = useState([]);
  const [isLoadingSiteDiaryHistory, setIsLoadingSiteDiaryHistory] = useState(false);
  const showDelete = props.selectedPhaseList[props.category]?.removeReportAccess;

  useEffect(() => {
    if (!selectedReport?.uuid) return;
    if (!openSiteDiaryHistory) return;
    setIsLoadingSiteDiaryHistory(true);
    Api({
      endpoint: endpoints.showSiteDiary(selectedReport.uuid),
      onSuccess: ({ data }) => {
        setSiteDiaryHistoryDetail(data);
        setIsLoadingSiteDiaryHistory(false)
      },
      onFail: () => {
        setSiteDiaryHistoryDetail([]);
        setIsLoadingSiteDiaryHistory(false);
      },
    });
  }, [selectedReport, openSiteDiaryHistory]);
  // const tabslist = [
  //   { label: 'GENERATED REPORT', value: 'generated' },
  //   { label: 'NCR', value: 'ncrReports' },
  //   { label: 'SITE DIARY', value: 'siteDiary', isHide: [313].includes(props.user?.id) },
  //   { label: 'STORED REPORT', value: 'storedReports' },
  //   { label: 'SITE REPORT', value: 'siteReports' },
  // ];
  const tabslist = [
    { label: 'SITE REPORT', value: 'siteReports' },
    { label: 'SITE DIARY', value: 'siteDiary', isHide: [313].includes(props.user?.id) },
    { label: 'NCR', value: 'ncrReports' },
    { label: 'STORED REPORT', value: 'storedReports' },
    { label: 'GENERATED REPORT', value: 'generated' },
  ];

  useEffect(() => {
    h.setMonthYear(moment());
    h.setStatusType('all');
  }, [h.tab]);

  const deleteButton = (report) => (
    showDelete && (
      <IconButton style={{ width: 12, height: 12 }} onClick={() => { setSelectedReport(report); setOpenDialog(true); }}>
        <DeleteIcon style={{ color: 'red' }} />
      </IconButton>
    )
  );
  const tabRender = {
    selfgenerate: (
      <div className="d-flex justify-content-between flex-column">
        <p>Please select the date for the report to be generated</p>
        <TextField
          label="Date"
          value={h.date}
          onChange={e => h.setDate(e.target.value)}
          className="w-50 mb-3 mt-2"
          variant="standard"
          type="date"
          InputLabelProps={{ shrink: true }}
        />
        {!!h.date && (
          <Link to={{ pathname: `${process.env.REACT_APP_ENDPOINT_URL}generatepdf?AssetId=${props.project.id}&date=${h.date}` }} target="_blank">
            <Button style={{ backgroundColor: 'var(--primary-color)', width: '50%', color: 'var(--light-color)' }}>
              Generate Report
            </Button>
          </Link>
        )}
      </div>
    ),
    generated: reportGenerated(h.reports, { tab: h.tab, deleteButton }, { columns }),
    storedReports: reportGenerated(h.reports, { tab: h.tab, deleteButton }, { columns: columnsSR }),
    siteReports: reportGenerated(h.siteReports, { tab: h.tab, deleteButton }, { columns: columnsSR }),
    siteDiary: reportGenerated(
      !h.isConstructionLog ? h.siteDiaryFilteredList : h.constructionLogSD,
      {
        tab: h.tab, deleteButton, isConstructionLog: !!h.isConstructionLog, setSelectedReport, setOpenDialog, setOpenSiteDiaryHistory,
      },
      { columns: !h.isConstructionLog ? columnsDS : columnsDSLog },
      h,
    ),
    ncrReports: reportGenerated(h.ncrReports, { tab: h.tab, deleteButton, setSelectedReport, setOpenDialog }, { columns: columnsNCR }, h),
  };
  useEffect(() => {
    if (!props.queryParam.id) return;
    if (!props.user?.can_view_report) return <Redirect to="/project" />;
  }, [props.queryParam.id]);
  return (
    <MainContentContainer>
      <div className="d-flex justify-content-between" style={{ paddingBlock: 7 }}>
        <Navbar
          to="/project/"
          text="Report"
          subtext={props.filtered_projects[props.selected_project]?.name}
        />
        {props.project && props.project.name !== 'View All Projects' && h.tab === 'generated'
          ? <GenerateReport project={props.project} callback={(data) => h.onSave(data)} /> : null}
      </div>
      {h.tab !== 'selfgenerate' && (
        <div className="d-flex justify-content-between align-items-center">
          <HighlightTabs items={tabslist.filter(e => !e.isHide)} tab={h.tab} setTab={h.setTab} />
          {h.tab === 'siteDiary' && (
            <div className="d-flex" style={{ transform: 'scale(0.8)' }}>
              <div className="py-2 px-1 d-flex align-items-center">
                <DateRange />
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Grid container justifyContent="space-around">
                    <MuiThemeProvider theme={getMuiTheme()}>
                      <DatePicker
                        variant="inline"
                        openTo="month"
                        views={['year', 'month']}
                        value={h.monthYear}
                        onChange={(date) => h.setMonthYear(date)}
                        style={{ marginLeft: 15 }}
                      />
                    </MuiThemeProvider>
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
              <div className="py-2 px-1 ml-2 d-flex align-items-center">
                {props.user?.can_submit_cl ? (
                  <Button
                    style={{ color: h.isConstructionLog ? 'blue' : 'black', fontWeight: 'bolder', fontFamily: 'CeraProRegular' }}
                    startIcon={<Assignment style={{ color: 'black' }} />}
                    onClick={() => h.setIsConstructionLog(prev => !prev)}
                  >
                    Logs
                  </Button>
                ) : (
                  <>
                    <Assignment />
                    <TextField
                      select
                      variant="standard"
                      value={h.statusType}
                      onChange={e => h.setStatusType(e.target.value)}
                      InputProps={{ disableUnderline: true, style: { fontFamily: 'CeraProRegular' } }}
                      style={{ marginLeft: 15 }}
                    >
                      {h.statusList.map(e => (
                        <MenuItem value={e.value} style={{ fontFamily: 'CeraProRegular', fontSize: 14, transform: 'scale(0.9)' }}>{e.name}</MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
              </div>
            </div>
          )}
          {h.tab === 'ncrReports' && ['consultant'].includes(h.user.raise_role) && (
            <Link to={`/ncr-form/part-A/${props.queryParam.id}`}>
              <Button
                variant="contained"
                style={{
                  color: '#FFFFFF', backgroundColor: 'var(--primary-color)', fontFamily: 'CeraProRegular', height: 30,
                }}
              >
                <AddOutlined style={{ fontSize: 14 }} />
                <p className="text-white" style={{ fontSize: 12 }}>Add Report</p>
              </Button>
            </Link>
          )}
        </div>
      )}
      {h.tab === 'siteDiary' && !!h.isConstructionLog && !!h.selectedConstructionLog.length && (
        <MergeLogConfirmation open={h.openMergeLogDialog} setOpen={h.setOpenMergeLogDialog} isLoading={h.isLoading} mergeFunction={h.mergeConstructionLog} signature={h.signature} setSignature={h.setSignature} />
      )}
      {h.isLoading ? (
        <CircularProgress
          size={75}
          className="position-absolute"
          style={{
            top: '40%', left: '60%', marginTop: -30, marginLeft: 0, color: 'var(--primary-color)',
          }}
        />
      ) : (
        <>
          {h.tab === 'siteDiary' && !h.isConstructionLog && (
            <Grid item xs={12} style={{ padding: 10 }} className="flex-standard mt-2 mb-1">
              <MonthLineCalender
                data={h.siteDiaryList}
                startDate={moment(h.monthYear).startOf('month')}
                endDate={moment(h.monthYear).endOf('month')}
              />
            </Grid>
          )}
          {tabRender[h.tab]}
        </>
      )}
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        selected={selectedReport}
        setSelected={setSelectedReport}
        deleteFunction={() => {
          if (h.tab === 'ncrReports') return h.deleteNCRReport(selectedReport.id);
          if (h.tab === 'siteDiary') return h.deleteConstructionLog(selectedReport.id);
          return h.tab === 'generated' ? h.onDelete(selectedReport.id) : h.deleteSiteReport(selectedReport.id);
        }}
      />
      <ReportStatusHistory
        open={openSiteDiaryHistory}
        setOpen={setOpenSiteDiaryHistory}
        selected={selectedReport}
        setSelected={setSelectedReport}
        siteDiaryHistoryDetail={siteDiaryHistoryDetail}
        isLoading={isLoadingSiteDiaryHistory}
      />
    </MainContentContainer>
  );
}

const reportGenerated = (reports, extHook, tableprops, h = null) => {
  const hasReport = !!reports.length;
  const isGenerated = extHook.tab === 'generated';
  const isDiarySite = extHook.tab === 'siteDiary';
  const isNcr = extHook.tab === 'ncrReports';
  if (!hasReport && !isGenerated) {
    return (<div className="d-flex justify-content-center"><img src={NoDataImg} style={{ width: '30vw' }} /> </div>);
  }
  return (
    <div style={{ padding: 10 }}>
      <MaterialTable
        tableHead
        tableMinWidth={300}
        tableMaxHeight={900}
        tableData={reports.sort((a, b) => b.createdAt.localeCompare(a.createdAt)).map(x => {
          const tData = { ...x };
          if (isDiarySite && !extHook.isConstructionLog) {
            tData.name = x.name ?? '-';
            tData.description = x.description ?? '-';
            tData.actions = ActionSection(x, extHook);
            tData.userimage = <AccountCircle />;
            tData.status = CheckStatus(x, extHook, h);
            tData.inspectionAt = moment(x.createdAt).format('DD/MM/YYYY');
          }
          if (isDiarySite && !!extHook.isConstructionLog) {
            tData.selector = (
              <CustomCheckbox
                checked={h.selectedConstructionLog?.find(e => e === x.id)}
                value={x.id}
                onChange={e => {
                  if (!!h.selectedConstructionLog.includes(e.target.value)) return h.setSelectedConstructionLog(prev => prev.filter(f => f !== e.target.value));
                  h.setSelectedConstructionLog(prev => ([...prev, e.target.value]));
                }}
              />
            );
            tData.name = x.name ?? '-';
            tData.createdBy = x.User?.name ?? '-';
            tData.actions = ActionSection(x, extHook);
            tData.createdAt = moment(x.createdAt).format('DD/MM/YYYY');
            tData.reportedAt = moment(x.reportedAt).format('DD/MM/YYYY');
          }
          if (isNcr) {
            tData.refNum = x.refferenceNumber ?? '-';
            tData.status = (
              <Chip
                label={!x.verified_by ? 'Open' : 'Closed'}
                className="text-standard"
                style={{
                  height: '1.5rem',
                  backgroundColor: !x.verified_by ? '#ffc542' : '#3dd598',
                  color: 'black',
                }}
              />
            );
            tData.dateIssued = moment(x.createdAt).format('DD/MM/YYYY');
            tData.category = CategoryNCR(x);
            tData.user = x.created_by ?? '-';
            tData.stage = StageNCR(x);
            tData.verifiedBy = VerificationNCR(x, h);
            tData.actions = ActionSectionNCR(x, extHook);
          }
          if (isGenerated) {
            tData.download = (
              <Link to={{ pathname: `${process.env.REACT_APP_ENDPOINT_URL}report/${x.id}` }} target="_blank">
                <IconButton style={{
                  color: 'white', background: 'var(--primary-color)', width: 12, height: 12,
                }}
                ><GetAppIcon />
                </IconButton>
              </Link>
            );
            tData.delete = extHook.deleteButton(x);
            tData.createdAt = moment(x.createdAt).format('DD/MM/YYYY');
          } else {
            const eachfileName = x.name ? x.name?.split(',') : '';
            const eachfile = x.path ? x.path?.split(',') : x.raw_path?.split(',');
            tData.file_path = x.path === '' ? 'N/A' : eachfile?.map((file, idx) => (
              <>
                <Link to={{ pathname: `${file}` }} target="_blank">
                  <IconButton style={{
                    color: 'white', background: 'var(--primary-color)', width: 12, height: 12,
                  }}
                  ><InsertDriveFileIcon />
                  </IconButton> &nbsp; {!!x.name ? eachfileName[idx] : 'Unnamed Files'}
                </Link>
                <br />
              </>
            ));
            tData.action = extHook.deleteButton(x);
          }
          return tData;
        })}
        {...tableprops}
      />
    </div>
  );
};

const getMuiTheme = () => createTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
});

const CustomCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);