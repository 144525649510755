import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { MonthsList } from '@Helpers';
import moment from 'moment';

export default function Hook({ division, project, user }) {
  const [documents, setDocuments] = useState([]);
  const [justUpdated, setJustUpdated] = useState(false);
  const [date, setDate] = useState(null);
  const [monthYear, setMonthYear] = useState(moment());
  const [statusType, setStatusType] = useState('all');
  const [tab, setTab] = useState('drawing');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getDrawings();
    setJustUpdated(false);
  }, [project, justUpdated, tab]);

  const getDrawings = () => {
    if (!project.id) return;
    // const data = {};
    // data.AssetId = project.id;
    // data.type = 'site_drawing';
    Api({
      endpoint: endpoints.getSiteDrawings(project.id, 'site_drawing'),
      onSuccess: (response) => {
        setDocuments(response.data);
        setIsLoading(false);
      },
      onFail: setDocuments([]),
    });
  };

  const onDelete = (id) => {
    Api({
      endpoint: endpoints.deleteReport(id),
      onSuccess: (response) => {
        toast('success', 'Successfully Delete ');
        setJustUpdated(true);
      },
      onFail: console.log('lol'),
    });
  };

  return {
    documents,
    onDelete,
    date,
    setDate,
    statusType,
    setStatusType,
    MonthsList,
    monthYear,
    setMonthYear,
    user,
    tab,
    setTab,
    isLoading,
  };
}