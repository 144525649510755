import MainContentContainer from '@Components/MainContentContainer';
import Navbar from '@Components/Navbar';
import Step from './Step';
import useHook from './hook';
import SiteInfo from './SiteInfo';
import ReportInfo from './ReportInfo';
import ReportSummary from './ReportSummary';
import ReportVerify from './ReportVerify';
import Result from './Result';

export default ({ user }) => {
  const h = useHook({ user });
  return (
    <MainContentContainer>
      <Navbar
        to="/project/"
        text="Non-Conformance Report"
        subtext="Create New Report"
      />
      <Step {...h} />
      {{
        0: <SiteInfo {...h} />,
        1: <ReportInfo {...h} />,
        2: <ReportSummary {...h} />,
        3: <ReportVerify {...h} />,
        4: <Result {...h} />,
      }[h.activeStep]}
    </MainContentContainer>
  );
};
