import { TextField, MenuItem } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import Table from '@Components/MaterialTable/v7';
import CCTV from '@Assets/Icons/cctv.svg';
import { Link } from 'react-router-dom';
import _ from 'lodash';

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
};

const cardCss = {
  backgroundColor: 'white',
  borderRadius: 10,
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  padding: 13,
  position: 'relative',
  height: '100%',
};

const CardTitle = ({ title }) => (<p style={{ color: '#605f65', fontSize: 12 }}>{title ?? 'Status'}</p>);
const CardContent = ({ tableData, tableType }) => {
  return (
    <div style={{ ...flexCenter, justifyContent: 'space-between' }}>
      <Table tableData={tableData} tableType={tableType} />
    </div>
  );
};
export default function index(props) {
  const [tableType, setTableType] = useState('cctv');
  const [cctvBreakdowns, setCctvBreakdowns] = useState([]);
  useEffect(() => {
    if (!props?.assets.length) return;
    const asset_cctvs = props?.assets.map(({ name, cctvs }) => cctvs.map(cctv => ({
      ...cctv, AssetName: name, status: 0, label: cctv.name.replace('CCTV ', ''),
    }))).flat();
    // TODO: get firebase data
    const groupedByAsset = Object.values(_.groupBy(asset_cctvs, 'AssetId')).filter(x => !!x.length).map(x => ({
      AssetName: x[0].AssetName, status: x[0].status, total_cctv: x.length, createdAt: x[0].createdAt
    }));
    if (tableType === 'cctv')setCctvBreakdowns(asset_cctvs);
    if (tableType === 'asset')setCctvBreakdowns(groupedByAsset);
  }, [props?.assets, tableType]);
  return (
    <div style={{ ...cardCss }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="flex-standard">
          <CardTitle title={props.title} />
          <img src={CCTV} style={{ marginLeft: 5 }} />
        </div>
        <TextField
          select
          size="small"
          variant="standard"
          value={tableType}
          style={{ height: '80%', fontFamily: 'CeraProRegular' }}
          onChange={e => setTableType(e.target.value)}
          InputProps={{ disableUnderline: true, style: { fontSize: 12 } }}
        >
          <MenuItem value="cctv" style={{ fontSize: 12, fontFamily: 'CeraProRegular' }}>By CCTV</MenuItem>
          <MenuItem value="asset" style={{ fontSize: 12, fontFamily: 'CeraProRegular' }}>By Site</MenuItem>
        </TextField>
      </div>
      <CardContent {...props} tableType={tableType} tableData={cctvBreakdowns} />
      {props.seeDetails && (
        <Link
          to={props.seeDetails}
          style={{
            display: 'flex', alignItems: 'center', justifyContent: 'flex-end', position: 'absolute', right: 10, bottom: 10,
          }}
        >
          <p style={{ color: '#605f65', fontSize: 10 }}>See Details</p>
          <ArrowRight style={{ color: '#605f65', fontSize: 18 }} />
        </Link>
      )}
    </div>
  );
}
