import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

export default function CenteredLoading({ size = 25, ...props }) {
  return (
    <Grid container justify="center" {...props}>
      <CircularProgress size={size} color="rgb(249, 206, 10)" style={{ margin: 'auto' }} />
    </Grid>
  );
}
