import Table from '@Components/MaterialTable/v6';
import { Grid } from '@material-ui/core';
import React from 'react';

const dummyData = [
  { id: 1, name: 'Report 1', status: 'Open', user: 'Umar', date: '2022-06-01T04:52:33.000Z' },
  { id: 2, name: 'Report 2', status: 'Closed', user: 'Umar', date: '2022-06-05T04:52:33.000Z' },
  { id: 3, name: 'Report 3', status: 'Open', user: 'Umar', date: '2022-06-02T04:52:33.000Z' },
  { id: 4, name: 'Report 4', status: 'Open', user: 'Umar', date: '2022-06-04T04:52:33.000Z' },
  { id: 5, name: 'Report 5', status: 'Closed', user: 'Umar', date: '2022-06-10T04:52:33.000Z' },
  { id: 6, name: 'Report 6', status: 'Open', user: 'Umar', date: '2022-06-10T04:52:33.000Z' },
]

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
};

const cardCss = {
  backgroundColor: 'white',
  borderRadius: 10,
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  padding: 13,
  position: 'relative',
  height: '100%',
};

const CardTitle = ({ title }) => (<p style={{ color: '#605f65', fontSize: 12 }}>{title ?? 'Status'}</p>);
const CardContent = ({ tableData }) => {
  return (
    <Grid container className="mt-1">
      <Grid item xs={12}>
        <Table tableData={tableData} />
      </Grid>
    </Grid>
  );
};
const ReportTable = (props) => {
  return (
    <div style={{ ...cardCss }}>
      <div style={{ ...flexCenter, padding: '5px 0px' }}>
        <CardTitle title={props.title} />
      </div>
      <CardContent {...props} tableData={dummyData} />
    </div>
  );
};

export default ReportTable;
