import MainContentContainer from '@Components/MainContentContainer';
import Navbar from '@Components/Navbar';
import Step from './Step';
import useHook from './hook';
import ReportVerify from './ReportVerify';
import Result from './Result';
import ReviewInfoA from './ReviewInfoA';
import ReviewInfoB from './ReviewInfoB';
import CorrectiveAction from './CorrectiveAction';
import Attachment from './Attachment';
import ReportSummary from './ReportSummary';

export default ({ user }) => {
  const h = useHook({ user });
  return (
    <MainContentContainer>
      <Navbar
        to={`/project/report?id=${h.AssetId}`}
        text="Non-Conformance Report"
        subtext="Part C"
      />
      <Step {...h} />
      {{
        0: <ReviewInfoA {...h} />,
        1: <ReviewInfoB {...h} />,
        2: <CorrectiveAction {...h} />,
        3: <Attachment {...h} />,
        4: <ReportSummary {...h} />,
        5: <ReportVerify {...h} />,
        6: <Result {...h} />,
      }[h.activeStep]}
    </MainContentContainer>
  );
};
