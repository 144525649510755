/* eslint-disable complexity */
import { WeatherIcons } from '@Assets/Icons/WeatherIcons';

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
};

const cardCss = {
  display: 'flex',
  backgroundColor: 'white',
  borderRadius: 10,
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  position: 'relative',
  height: '100%',
  backgroundImage: 'linear-gradient(to right, white , #FF8500)',
};

export default function index({ w, t }) {
  return (
    <div style={{ ...cardCss, paddingRight: 10, paddingTop: 5 }}>
      <div style={{
        ...flexCenter, flex: 3, fontSize: 30, padding: 5,
      }}
      >
        <img src={WeatherIcons[w]} style={{ transform: 'translate(0, 0%)', width: '100%' }} />
      </div>
      <div style={{
        flex: 4,
        fontSize: 12,
        flexDirection: 'column',
        justifyItems: 'center',
        padding: 5,
      }}
      >
        <p style={{ width: '100%', flex: 1, fontSize: 30 }}>{t}°C</p>
        <span className="text-standard" style={{ width: '100%', flex: 1 }}>Raining Heavily</span>
      </div>
      <div style={{ flex: 2, fontSize: 10, padding: 5 }}>
        <p style={{ textAlign: 'end' }}>MON</p>
        <p style={{ textAlign: 'end' }}>13:24 PM</p>
      </div>
    </div>
  );
}
