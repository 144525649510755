import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import {
  VictoryAxis, VictoryBar, VictoryChart, VictoryLegend, VictoryLine, VictoryScatter, VictoryTooltip, VictoryLabel,
} from 'victory';

const animate = {
  duration: 2000,
  onLoad: { duration: 1000 },
};

const colorStack = ['#058dfa', '#f7c61a', '#56da82', '#f74513'];
const Chart = ({ chartData, onClickPlot, hasLabel }) => {
  const dataLength = chartData.map(item => item.data.length)[0];
  const CustomLegendLabel = ({
    x, y, index, text,
  }) => {
    return (
      <Tooltip title={chartData[index].tooltip}>
        <text x={x - 0} y={y + 4} fontSize={9}>
          {text}&nbsp;&#9432;
        </text>
      </Tooltip>
    );
  };
  const CustomTooltip = ({ x, y, datum }) => {
    return (
      <g style={{ pointerEvents: 'none' }} onClick={() => alert('sefsef')}>
        <foreignObject x={x - 40} y={y - 50} width="90" height="50">
          <div style={{ backgroundColor: 'black', padding: 5, borderRadius: 8 }} xmlns="http://www.w3.org/1999/xhtml">
            <div style={{ fontSize: 12 }}>
              <div style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
                <div className="d-flex align-items-center" style={{ flex: 1, paddingTop: 2 }}>
                  <div style={{
                    width: 8, height: 8, backgroundColor: '#f74513', borderRadius: 10,
                  }}
                  />
                </div>
                <div style={{ flex: 5 }}>
                  {Math.ceil(datum._y)}
                </div>
              </div>
              <div style={{ color: '#787878', display: 'flex' }}>
                <div style={{ flex: 1 }} />
                <div style={{ flex: 5 }}>
                  {datum.x}
                </div>
              </div>
            </div>
          </div>
        </foreignObject>
      </g>
    );
  };
  return (
    <div>
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="25%" stopColor="#008ffb" />
            <stop offset="100%" stopColor="#3b71e8" />
          </linearGradient>
        </defs>
      </svg>
      <VictoryChart
        domainPadding={17}
        width={800}
        height={250}
        padding={{
          right: 140, bottom: 30, left: 30, top: 10,
        }}
      >
        <VictoryBar
          animate={animate}
          style={{ data: { fill: 'url(#gradient)' } }}
          data={chartData.find(e => e.type === 'bar').data}
        />
        {chartData.filter(e => e.type === 'line').map((e, idx) => (
          <VictoryLine
            animate={animate}
            style={{
              data: { stroke: colorStack[idx + 1] },
            }}
            data={e.data}
          />
        ))}
        {chartData.filter(e => e.type === 'line').map((e, idx) => (
          <VictoryScatter
            animate={animate}
            data={e.data}
            size={3}
            style={{ data: { fill: colorStack[idx + 1] } }}
            labels={() => ''}
            labelComponent={<VictoryTooltip flyoutComponent={<CustomTooltip />} />}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onClick: () => {
                    return [
                      {
                        target: 'data',
                        mutation: props => onClickPlot(props.datum.x),
                      },
                    ];
                  },
                },
              },
            ]}
          />
        ))}
        <VictoryLegend
          x={680}
          y={100}
          orientation="vertical"
          style={{ border: { stroke: 'transparent' }, labels: { fontSize: 8 } }}
          gutter={20}
          data={chartData.map((e, idx) => ({ ...e, symbol: { fill: colorStack[idx] } }))}
          labelComponent={<CustomLegendLabel />}
        />
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          style={{
            tickLabels: { fontSize: 8, angle: dataLength < 15 ? 0 : -45 },
            axisLabel: {
              padding: 20, textAnchor: 'start', transform: 'translate(100%, 0)', fontSize: '10px',
            },
          }}
          tickFormat={(t) => t}
          label={hasLabel && 'Weeks'}
        />
        <VictoryAxis
          dependentAxis
          style={{
            tickLabels: { fontSize: 8 },
          }}
        />
      </VictoryChart>
    </div>
  );
};

export default Chart;
