/* eslint-disable max-lines */
import { TextField, MenuItem } from '@material-ui/core';
import FilterIcon from '@Assets/Icons/filter-solid.svg';
import React, { useEffect, useState } from 'react';
import {
  VictoryAxis,
  VictoryChart, VictoryLine, VictoryTooltip, VictoryVoronoiContainer, VictoryLabel, VictoryLegend,
} from 'victory';

const cardCss = {
  backgroundColor: 'white',
  borderRadius: 10,
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  padding: 13,
  position: 'relative',
  height: '100%',
};

const dummy = {};
dummy.monthlyData = {
  helmet: [
    { x: 'Jan', y: 10 },
    { x: 'Feb', y: 30 },
    { x: 'Mar', y: 20 },
    { x: 'Apr', y: 50 },
    { x: 'May', y: 90 },
    { x: 'June', y: 80 },
    { x: 'July', y: 70 },
    { x: 'Aug', y: 80 },
    { x: 'Sept', y: 60 },
    { x: 'Oct', y: 50 },
    { x: 'Nov', y: 20 },
    { x: 'Dec', y: 10 },
  ],
  nonHelmet: [
    { x: 'Jan', y: 70 },
    { x: 'Feb', y: 10 },
    { x: 'Mar', y: 30 },
    { x: 'Apr', y: 50 },
    { x: 'May', y: 70 },
    { x: 'June', y: 80 },
    { x: 'July', y: 60 },
    { x: 'Aug', y: 40 },
    { x: 'Sept', y: 10 },
    { x: 'Oct', y: 30 },
    { x: 'Nov', y: 20 },
    { x: 'Dec', y: 10 },
  ],
};

dummy.weeklyData = {
  helmet: [
    { x: 'Week 1', y: 60 },
    { x: 'Week 2', y: 30 },
    { x: 'Week 3', y: 10 },
    { x: 'Week 4', y: 90 },
  ],
  nonHelmet: [
    { x: 'Week 1', y: 30 },
    { x: 'Week 2', y: 40 },
    { x: 'Week 3', y: 10 },
    { x: 'Week 4', y: 20 },
  ],
};

dummy.dailyData = {
  helmet: [
    { x: 'Monday', y: 20 },
    { x: 'Tuesday', y: 40 },
    { x: 'Wednesday', y: 60 },
    { x: 'Thursday', y: 70 },
    { x: 'Friday', y: 10 },
    { x: 'Saturday', y: 20 },
    { x: 'Sunday', y: 10 },
  ],
  nonHelmet: [
    { x: 'Monday', y: 30 },
    { x: 'Tuesday', y: 50 },
    { x: 'Wednesday', y: 80 },
    { x: 'Thursday', y: 70 },
    { x: 'Friday', y: 20 },
    { x: 'Saturday', y: 30 },
    { x: 'Sunday', y: 20 },
  ],
};

export default function AssetCompliance({ data }) {
  const [filter, setFilter] = useState(0);
  const [helmet, setHelmet] = useState([]);
  const [nonHelmet, setNonHelmet] = useState([]);

  useEffect(() => {
    let dataGroup = 'monthlyData';
    switch (filter) {
      case 0:
        dataGroup = 'monthlyData';
        break;
      case 1:
        dataGroup = 'weeklyData';
        break;
      case 2:
        dataGroup = 'dailyData';
        break;

      default:
        dataGroup = 'monthlyData';
        break;
    }
    if (!data[dataGroup]) {
      setHelmet(dummy[dataGroup]?.helmet?.map(({ x, y }) => ({ x, y })));
      setNonHelmet(dummy[dataGroup]?.nonHelmet?.map(({ x, y }) => ({ x, y })));
    } else {
      setHelmet(data[dataGroup]?.helmet?.map(({ x, y }) => ({ x, y })));
      setNonHelmet(data[dataGroup]?.nonHelmet?.map(({ x, y }) => ({ x, y })));
    }
  }, [data, filter]);

  return (
    <div style={{ ...cardCss }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <p style={{ color: '#605f65', fontSize: 12 }}>OSH Compliance Chart</p>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'space-between', transform: 'scale(0.8)',
        }}
        >
          <TextField
            select
            variant="standard"
            value={filter}
            onChange={e => setFilter(e.target.value)}
            InputProps={{ disableUnderline: true }}
            fullWidth
          >
            <MenuItem value={0} style={{ fontSize: 12 }}><img src={FilterIcon} style={{ padding: '0px 5px 3px 5px', cursor: 'pointer', width: 12 }} /> Monthly</MenuItem>
            <MenuItem value={1} style={{ fontSize: 12 }}><img src={FilterIcon} style={{ padding: '5px 5px 5px 5px', cursor: 'pointer', width: 12 }} /> Weekly</MenuItem>
            <MenuItem value={2} style={{ fontSize: 12 }}><img src={FilterIcon} style={{ padding: '5px 5px 5px 5px', cursor: 'pointer', width: 12 }} /> Daily</MenuItem>
          </TextField>
        </div>
      </div>
      <VictoryChart
        padding={{
          top: 30, bottom: 50, left: 30, right: 30,
        }}
        domainPadding={{ x: 0, y: 10 }}
        height={210}
        containerComponent={<VictoryVoronoiContainer />}
      >
        <VictoryLegend
          x={150}
          y={10}
          orientation="horizontal"
          gutter={20}
          style={{ border: { stroke: 'transparent' }, labels: { fontSize: 6 } }}
          data={[
            { name: 'Helmet', symbol: { fill: '#3462fb' } },
            { name: 'Safety Helmet', symbol: { fill: '#8fcfaa' } },
          ]}
        />
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          style={{
            tickLabels: { fontSize: 6, opacity: 0.65 },
            ticks: { size: 0 },
            axis: { stroke: 'transparent' },
            grid: { stroke: '#F4F5F7', strokeWidth: 0.9 },
          }}
          tickLabelComponent={<VictoryLabel angle={-45} />}
          tickCount={12}
          axisLabelComponent={(
            <VictoryLabel
              angle={0}
              verticalAnchor="start"
              dy={60}
              dx={0}
              style={{ fontSize: 32 }}
            />
          )}
        />
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          dependentAxis
          style={{
            tickLabels: { fontSize: 8, opacity: 0.65 },
            ticks: { size: 0 },
            axis: { stroke: 'transparent' },
            grid: { stroke: 'transparent' },
          }}
          tickLabelComponent={<VictoryLabel />}
          tickFormat={t => (t < 0.1 ? '' : t)}
          tickCount={5}
          axisLabelComponent={(
            <VictoryLabel
              angle={0}
              verticalAnchor="start"
              dy={60}
              dx={60}
              style={{ fontSize: 32 }}
            />
          )}
        />
        <VictoryLine
          animate={{
            duration: 2000,
            onLoad: { duration: 1000 },
          }}
          interpolation="linear"
          data={helmet}
          style={{ data: { stroke: '#3462fb' } }}
          labels={({ datum }) => `Helmet: ${Math.ceil(datum.y)}`}
          labelComponent={(
            <VictoryTooltip
              style={{ fontSize: 8, fill: 'white' }}
              cornerRadius={5}
              pointerLength={3}
              flyoutStyle={{
                stroke: 'none',
                fill: 'black',
                opacity: 0.9,
              }}
            />
          )}
        />
        <VictoryLine
          animate={{
            duration: 2000,
            onLoad: { duration: 1000 },
          }}
          interpolation="linear"
          data={nonHelmet}
          style={{ data: { stroke: '#8fcfaa' } }}
          labels={({ datum }) => `Safety Helmet: ${datum.y}`}
          labelComponent={(
            <VictoryTooltip
              style={{ fontSize: 8, fill: 'white' }}
              cornerRadius={5}
              pointerLength={3}
              flyoutStyle={{
                stroke: 'none',
                fill: 'black',
                opacity: 0.9,
              }}
            />
          )}
        />
      </VictoryChart>
    </div>
  );
}
