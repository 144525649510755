import Button from '@Components/Button';
import { TextField, InputLabel } from '@material-ui/core';

export default (h) => {
  const isFieldEmpty = !(!!h.description);
  return (
    <div className="w-25 mt-5 mx-auto" style={{ minHeight: '60vh' }}>
      <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Select Date</InputLabel>
      <TextField
        value={h.reportDate}
        onChange={e => h.setReportDate(e.target.value)}
        className="w-100 mb-3"
        variant="standard"
        type="date"
        InputLabelProps={{ shrink: true }}
      />
      <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Description</InputLabel>
      <TextField
        value={h.description}
        onChange={e => h.setDescription(e.target.value)}
        className="w-100 mb-3 mt-1"
        variant="outlined"
        multiline
        minRows={6}
        maxRows={6}
      />
      <div className="d-flex justify-content-end mt-4">
        <Button variant="text" onClick={h.handleBackStep}>
          PREVIOUS
        </Button>
        <Button onClick={h.handleNextStep} disabled={isFieldEmpty}>
          NEXT
        </Button>
      </div>
    </div>
  );
};
