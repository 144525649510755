import {
  Switch, Route, useParams, Link,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Grid, Paper, Chip, Button,
  List, ListItem, Collapse, ListItemIcon,
  CircularProgress,
} from '@material-ui/core';
// import Analytic from './Site';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drafts, Send, MoveToInbox, ExpandLess, ExpandMore, StarBorder,
} from '@material-ui/icons';
import Prima_dummy from '@Assets/Images/Prima_dummy.png';
import Overview from './Overview';
import ActiveSite from './Details/ActiveSite';
import CompletedSite from './Details/CompletedSite';
import NonConfirmityReport from './Details/NonConfirmityReport';
import CCTVBreakdown from './Details/CCTVBreakdown';
import EachAsset from './EachAsset';
import useHook from './hook';
import ProjectProgressDetails from './Details/ProjectProgressDetails';
import OrthophotoUpdate from './Details/OrthophotoUpdate';

const useStyles = makeStyles({
  paper: {
    width: '100%',
    height: '-webkit-fill-available',
  },
  container: {
    height: '-webkit-fill-available',
    padding: 10,
  },
  bar: {
    borderRight: 'solid grey',
  },
  sideNavButton: {
    width: '100%',
    justifyContent: 'normal',
  },
});
export default function Dashboard(props) {
  const classes = useStyles();
  const [sideNavButton, setSideNavButton] = useState('overview');
  const [open, setOpen] = useState(false);
  const { AssetId } = useParams();
  const h = useHook(AssetId);
  const handleClick = () => {
    setOpen(!open);
    setSideNavButton('allsite');
  };
  const handleClickOverview = () => {
    setOpen(false);
    setSideNavButton('overview');
  };
  useEffect(() => {
    if (!AssetId) return setSideNavButton('overview');
    setOpen(true);
    setSideNavButton(`asset-${AssetId}`);
    const selectedAsset = h.assets.find(asset => asset.id === Number(AssetId));
    if (!selectedAsset) return;
  }, [AssetId]);
  if (h.isLoading) {
    return (
      <CircularProgress
        size={75}
        className="position-absolute"
        style={{
          top: '50%', left: '50%', marginTop: -80, marginLeft: -40, color: 'var(--primary-color)',
        }}
      />
    );
  }

  return (

    <Paper className={classes.paper}>
      <Grid container className={classes.container} style={{ display: 'flex', justifyContent: 'center' }}>
        {/* {[313].includes(props.user?.id) ? <img src={Prima_dummy} style={{ height: '90vh' }} /> : ( */}
        <>
          <Grid item xs={2} md={2}>
            <List>
              <ListItem onClick={handleClickOverview} style={{ backgroundColor: sideNavButton === 'overview' ? '#f3f5f9' : 'transparent' }}>
                <Link to="/dashboard" className={classes.sideNavButton}>
                  <p style={{ cursor: 'pointer' }}>Overview</p>
                </Link>
              </ListItem>
              <ListItem
                onClick={handleClick}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: sideNavButton !== 'overview' ? '#f3f5f9' : 'transparent',
                  borderRadius: 10,
                }}
              >
                <p className="pointer">All Site</p>
                <div style={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}>
                  <Chip label={h.assets.length} style={{ transform: 'scale(0.8)' }} />
                  {open ? <ExpandLess className="pointer" /> : <ExpandMore className="pointer" />}
                </div>
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding style={{ borderRadius: 10, marginTop: 0, backgroundColor: '#e7e9ec' }}>
                  {h.assets.map((asset) => (
                    <ListItem className={classes.sideNavButton} style={{ backgroundColor: asset.id == AssetId ? '#f3f5f9' : 'transparent' }}>
                      <Link to={`/dashboard/asset/${asset.id}`} style={{ color: 'black', fontSize: 12 }}>
                        {asset.name}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </List>
          </Grid>
          <Grid item xs={10} md={10}>
            <Switch>
              {[
                {
                  path: '/dashboard',
                  children: <Overview user={props.user} {...h} />,
                  exact: true,
                },
                {
                  path: '/dashboard/activesite',
                  children: <ActiveSite {...h} />,
                  exact: true,
                },
                {
                  path: '/dashboard/completedsite',
                  children: <CompletedSite {...h} />,
                  exact: true,
                },
                {
                  path: '/dashboard/nonconfirmityreport',
                  children: <NonConfirmityReport {...h} />,
                  exact: true,
                },
                {
                  path: '/dashboard/cctvbreakdown',
                  children: <CCTVBreakdown {...h} />,
                  exact: true,
                },
                {
                  path: '/dashboard/asset/:AssetId',
                  children: <EachAsset {...h} AssetId={AssetId} />,
                },
                {
                  path: '/dashboard/project-deadline',
                  children: <ProjectProgressDetails {...h} />,
                  exact: true,
                },
                {
                  path: '/dashboard/orthophoto-update',
                  children: <OrthophotoUpdate {...h} />,
                  exact: true,
                },
              ].map(e => (
                <Route {...e} />
              ))}
            </Switch>
          </Grid>
        </>
        {/* )} */}
      </Grid>
    </Paper>
  );
}
