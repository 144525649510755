import { Grid, CircularProgress } from '@material-ui/core';
import AssetCard from '../Component/AssetCard';

export default (h) => {
  return (
    <div className="mx-auto" style={{ width: '90%', height: '680px' }}>
      <Grid container spacing={2} className="h-100">
        {h.isLoadingAssets ? (
          <CircularProgress
            size={75}
            className="position-absolute"
            style={{
              top: '50%', left: '50%', marginTop: -80, marginLeft: -40, color: 'var(--primary-color)',
            }}
          />
        ) : (
          <>
            {h.assetTypeList.map(asset => (
              <Grid item xs={12} md={3}>
                <AssetCard {...h} data={asset} isAssetsType />
              </Grid>
            ))}
            <Grid container justify="center" alignItems="center" className="my-2">
              <p className="my-auto" style={{ fontSize: 12 }}>
                Asset type not listed ?&nbsp;
                <a href="https://ofo.my" target="_blank" rel="noopener noreferrer" className="color-text-primary">
                  Contact Us
                </a>
              </p>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
