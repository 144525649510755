import Button from '@Components/Button';
import { TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

export default (h) => {
  const filter = createFilterOptions();
  const isFieldEmpty = !(!!h.refferenceNumber);
  return (
    <div className="w-25 mt-5 mx-auto" style={{ minHeight: '60vh' }}>
      <TextField
        label="Reference Number"
        value={h.refferenceNumber}
        onChange={e => h.setRefferenceNumber(e.target.value)}
        className="w-100 my-0"
        variant="standard"
      />
      <Autocomplete
        className="w-100 my-3"
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={UnitList}
        renderInput={(params) => (
          <TextField {...params} label="House / Unit (Optional)" variant="standard" />
        )}
        value={h.unit}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            h.setUnit({
              title: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            h.setUnit({
              title: newValue.inputValue,
            });
          } else {
            h.setUnit(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}
        renderOption={(option) => option.title}
      />
      <Autocomplete
        className="w-100 my-3"
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={LevelList}
        renderInput={(params) => (
          <TextField {...params} label="Level (Optional)" variant="standard" />
        )}
        value={h.level}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            h.setLevel({
              title: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            h.setLevel({
              title: newValue.inputValue,
            });
          } else {
            h.setLevel(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}
        renderOption={(option) => option.title}
      />
      <Autocomplete
        className="w-100 my-3"
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={BlockList}
        renderInput={(params) => (
          <TextField {...params} label="Block (Optional)" variant="standard" />
        )}
        value={h.block}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            h.setBlock({
              title: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            h.setBlock({
              title: newValue.inputValue,
            });
          } else {
            h.setBlock(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}
        renderOption={(option) => option.title}
      />
      <Button className="mt-4 float-right" onClick={h.handleNextStep} disabled={isFieldEmpty}>
        NEXT
      </Button>
    </div>
  );
};

const UnitList = [
  { title: 'Unit 1' },
  { title: 'Unit 2' },
  { title: 'Unit 3' },
  { title: 'Unit 4' },
];

const LevelList = [
  { title: 'Level 1' },
  { title: 'Level 2' },
  { title: 'Level 3' },
  { title: 'Level 4' },
];

const BlockList = [
  { title: 'Block 1' },
  { title: 'Block 2' },
  { title: 'Block 3' },
  { title: 'Block 4' },
];
