import { Button, Grid, TextField } from '@material-ui/core';
import { User } from '@Assets/Icons';
import Mail from '@material-ui/icons/MailOutline';
import passwordLogo from '@Assets/Icons/Password.svg';
import { ArrowBack, ArrowForward, KeyboardReturn } from '@material-ui/icons';

export default function LoginForm(h) {
  return (
    <div>
      <Grid item container xs={12} justify="space-around" alignItems="center">
        <Grid item xs={2} className="d-flex justify-content-center">
          {h.isForgotPassword ? <Mail width="20" />
            : <User width="20" />}
        </Grid>
        <Grid item container xs={10}>
          <TextField
            onKeyDown={h.onKeyDown}
            id="standard-basic"
            label={h.isForgotPassword ? 'Email' : 'Username'}
            style={{ width: '100%' }}
            value={h.email}
            onChange={(e) => h.setEmail(e.target.value)}
          />
        </Grid>
      </Grid>
      {!h.isForgotPassword && (
        <Grid item container xs={12} justify="space-around" alignItems="center">
          <Grid item xs={2} className="d-flex justify-content-center">
            <img src={passwordLogo} style={{ width: 20 }} />
          </Grid>
          <Grid item container xs={10} style={{ flexFlow: 'row' }}>
            <TextField
              onKeyDown={h.onKeyDown}
              id="standard-password-input"
              label="Password"
              type="password"
              value={h.password}
              onChange={(e) => h.setPassword(e.target.value)}
              autoComplete="current-password"
              style={{ width: '100%' }}
            />
          </Grid>
        </Grid>
      )}
      <Grid container item xs={12} justifyContent="flex-end" className="pb-2">
        <Grid item xs={10}>
          <h6
            className="d-flex align-items-center py-2 pointer"
            onClick={h.toggleForgotPassword}
            style={{ color: '#6082c2', fontSize: 12 }}
          >
            {h.isForgotPassword ? (
              <>
                <ArrowBack style={{ fontSize: 14 }} />
                &nbsp;Back to Login
              </>
            ) : (
              <>
                <ArrowForward style={{ fontSize: 14 }} />
                &nbsp;Forgot your Password
              </>
            )}
          </h6>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="flex-end">
        <Grid item xs={10}>
          <Button
            onClick={() => h.attemptLoginOrPasswordReset()}
            className="mx-auto w-100"
            style={{
              backgroundColor: 'var(--primary-color)', borderRadius: '10px', borderColor: 'none',
            }}
          >
            <p className="text-white">{h.isForgotPassword ? 'RESET PASSWORD' : 'SIGN IN'}</p>
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="flex-end">
        <Grid item xs={10}>
          <a href="https://www.i-supervision.com/">
            <div className="d-flex align-items-center mt-2">
              <KeyboardReturn style={{ color: '#6082c2', width: 16 }} />
              <h6
                className="text-center py-2"
                style={{
                  fontWeight: 600, color: '#6082c2', cursor: 'pointer', fontSize: 12, marginLeft: 5,
                }}
              >
                Back to landing page&nbsp;
              </h6>
            </div>
          </a>
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent="flex-end">
        <Grid item xs={10}>
          <div className="pt-2 d-flex justify-content-center" style={{ color: 'red', fontSize: 12, fontWeight: 700 }}>
            {h.loginError === 'fail to authenticate user' ? 'Invalid username or password' : h.loginError}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
