import { useEffect, useState } from 'react';
import moment from 'moment';
import Api, { endpoints } from '@Helpers/api';
// import queryString from 'query-string';
import { useParams } from 'react-router-dom';
import { today } from '@Helpers';

export default ({ user }) => {
  const { AssetId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [refferenceNumber, setRefferenceNumber] = useState(null);
  const [unit, setUnit] = useState(null);
  const [level, setLevel] = useState(null);
  const [block, setBlock] = useState(null);
  const [reportDate, setReportDate] = useState(today);
  const [description, setDescription] = useState(null);
  const [signature, setSignature] = useState(null);
  const [resultSuccess, setResultSuccess] = useState(false);

  const handleNextStep = () => { setActiveStep((prev) => prev + 1); };
  const handleBackStep = () => { setActiveStep((prev) => prev - 1); };

  useEffect(() => {
    if (!AssetId) return;
    setIsLoading(true);
    Api({
      endpoint: endpoints.showAssets(AssetId),
      onSuccess: () => setIsLoading(false),
      onFail: () => toast('error', 'Error getting assets data. Please try again later.'),
    });
  }, [AssetId]);

  const CreateReport = () => {
    const data = {
      refferenceNumber,
      AssetId,
      signature,
      details: {
        unit: unit.title ?? null,
        level: level.title ?? null,
        block: block.title ?? null,
        description,
        reportDate,
      },
      created_by: user.name,
    };
    setIsLoading(true);
    Api({
      endpoint: endpoints.createNcrReport(),
      data,
      onSuccess: () => {
        toast('success', 'NCR Report created');
        setIsLoading(false);
        setResultSuccess(true);
        handleNextStep();
      },
      onFail: () => {
        toast('error', 'Opss, something went wrong, please try again.');
        setIsLoading(false);
        setResultSuccess(false);
        handleNextStep();
      },
    });
  }

  return {
    activeStep,
    setActiveStep,
    handleNextStep,
    handleBackStep,
    refferenceNumber,
    setRefferenceNumber,
    unit,
    setUnit,
    level,
    setLevel,
    block,
    setBlock,
    reportDate,
    setReportDate,
    description,
    setDescription,
    signature,
    setSignature,
    CreateReport,
    AssetId,
    resultSuccess,
    isLoading,
  };
};
