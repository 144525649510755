import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0, sm: 640, md: 920, lg: 1280, xl: 1920,
    },
  },
});

export default (props) => {
  const arr = [];
  let variance
  if (props.project.scurve && props.project.scurve.physical && props.project.scurve.physical.length > 0) {
    variance = props.project.scurve.physical[0].v
  }
  arr.push({ title: 'PROJECT PROGRESS', to: `/project/project-progress?id=${props?.project?.id}`, disabled: false, isHide: false });
  arr.push({ title: 'OSH', to: `/project/osh?id=${props?.project?.id}`, disabled: false, isHide: [313].includes(props.user?.id) });
  const array = arr.filter(f => !f.isHide);
  const backgroundColor = getBackgroundColor(variance);
  return (
    <MuiThemeProvider theme={theme}>
      <Grid xs={6} item>
        <SidebarCard title="MANPOWER ANALYTIC" to={`/project/manpower-analytic?id=${props?.project?.id}`} disabled={false} />
      </Grid>
      <Grid xs={6} item>
        <SidebarCard style={{ background: backgroundColor }} title="S-CURVE" to={`/project/s-curve?id=${props?.project?.id}`} disabled={false} />
      </Grid>
      {array.map(e => (
        <Grid xs={12} lg={12 / array.length} item>
          <SidebarCard {...e} />
        </Grid>
      ))}
    </MuiThemeProvider>
  );
};

function SidebarCard(props) {
  const { title, to, disabled, style } = props;

  const cardStyling = disabled
    ? {
      background: '#D9DADC',
      opacity: '0.4',
      cursor: 'default',
    }
    : {
      background: '#F5FAFF',
    };

  // Merge additional styles if provided
  const combinedStyles = {
    height: '35%',
    margin: 5,
    borderRadius: 10,
    ...cardStyling,
    ...style, // Add this line to merge additional styles
  };

  return (
    <Link to={to}>
      <div
        className="flex-standard py-3 px-2 text-center"
        style={combinedStyles}
      >
        <h6 style={{ color: 'var(--dark-color)', fontSize: 14, fontWeight: 600 }}>
          {title}
        </h6>
      </div>
    </Link>
  );
}

function getBackgroundColor(variance) {
  if (variance > -10) {
    return '#3DD570'; // Green for high variance
  } else if (variance < -10 || variance > -20) {
    return '#FFB27D'; // Orange for medium variance
  } else if (variance > 20) {
    return '#F74513'; // Red for low variance
  } else {
    return '#F5FAFF'
  }
}

