/* eslint-disable max-lines */
import React from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import { diffInDays, truncateString } from '@Helpers';
import ProgressBar from '@Components/ProgressBar';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles({
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#979797',
      backgroundColor: 'transparent',
      fontSize: '0.65rem',
      padding: 0,
    },
  },
  leftHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      color: '#979797',
      backgroundColor: 'transparent',
      fontSize: '0.65rem',
      padding: 0,
    },
  },
});

export default ({ tableData }) => {
  const classes = useStyles();

  const columns = [
    {
      name: 'Site Name',
      selector: 'name',
      options: {
        setCellHeaderProps: () => ({ className: classes.leftHeader }),
        setCellProps: () => ({ className: classes.leftHeader }),
        sortCompare: (order) => ({ data: data1 }, { data: data2 }) => {
          if (order === 'desc') return calculateProgress(data2, data1);
          return calculateProgress(data1, data2);
        },
      },
    },
    {
      name: 'Completion Date',
      selector: 'date',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
        sortCompare: (order) => ({ data: data1 }, { data: data2 }) => {
          if (order === 'desc') return moment(data2.props.children).diff(moment(data1.props.children));
          return moment(data1.props.children).diff(moment(data2.props.children));
        },
      },
    },
  ];

  const calculateProgress = (object1, object2) => {
    return progress(object1.props.asset.commissionAt, object1.props.asset.expecteddecommissionAt) - progress(object2.props.asset.commissionAt, object2.props.asset.expecteddecommissionAt);
  };

  const options = {
    filter: 'false',
    print: 'false',
    download: 'false',
    sort: 'false',
    viewColumns: 'false',
    search: 'false',
    rowsPerPage: 5,
    rowsPerPageOptions: [5],
    sortOrder: {
      name: 'date',
      direction: 'desc',
    },
  };

  const progress = (startDate, endDate) => {
    const project_duration = Math.ceil(diffInDays(new Date(startDate), new Date(endDate)));
    const project_progress = Math.ceil(diffInDays(new Date(startDate), new Date()));
    return project_progress / project_duration * 100;
  };

  const AssetNameComponent = ({ asset, idx }) => {
    return (
      <div style={{ ...assetProgressContainer, width: '100%' }}>
        <img
          style={{ ...avatar, marginRight: 10 }}
          src={`${process.env.REACT_APP_S3}/${!!asset?.image ? asset?.image : 'static/media/defaultAssetImg-01.png'}`}
        />
        <div style={{ width: '100%' }}>
          <Tooltip title={asset?.name} placement="top-start">
            <p style={{ fontSize: 10, fontWeight: 600 }}>{truncateString(asset.name ?? '-', 30)}</p>
          </Tooltip>
          <div style={{ ...flexCenter, width: '100%' }}>
            <ProgressBar
              progress={Number(progress(asset.commissionAt, asset.expecteddecommissionAt).toFixed(0))}
              color={colorBar[idx % colorBar.length]}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        style={{ overflowX: 'hidden' }}
        options={options}
        columns={columns.map((col) => ({ ...col, label: col.name, name: col.selector }))}
        data={tableData.map((x, idx) => {
          return ({
            ...x,
            name: <AssetNameComponent asset={x} idx={idx} />,
            date: (
              <p style={{ backgroundColor: '#e2e2e9', fontSize: 10 }}>
                {moment(x.expecteddecommissionAt).format('DD MMM YYYY') ?? '-'}
              </p>
            ),
          });
        })}
      />
    </MuiThemeProvider>
  );
};

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
  overrides: {
    MUIDataTableToolbar: {
      root: {
        display: 'none',
      },
    },
    MUIDataTableSelectCell: {
      root: {
        display: 'none',
      },
    },
    MUIDataTableBodyCell: {
      root: {
        paddingLeft: 0,
        paddingRight: '0px !important',
      },
    },
    MUIDataTableHeadRow: {
      root: {
        borderBottom: '2px solid #e5e5e5',
      },
    },
    MUIDataTableBodyRow: {
      root: {
        borderBottom: '1px solid #e5e5e5',
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: '0px 0px 0px 0px',
        width: '100%',
      },
    },
    MuiTableHead: {
      root: {
        '& .MuiTableHead-root': {
          color: '#000000DE',
          fontSize: 14,
          fontWeight: 500,
          verticalAlign: 'middle',
        },
        '& .MuiTableCell-root': {
          color: '#979797',
          fontSize: 14,
          fontWeight: 500,
          verticalAlign: 'middle',
          paddingLeft: '0px !important',
        },
      },
    },
    MuiTableCell: {
      root: {
        '&:last-child': {
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
          width: '25%',
        },
      },
    },
    MUIDataTableHeadCell: {
      sortActive: { color: '#979797' },
      toolButton: {
        margin: '0px !important',
      },
    },
    MuiSvgIcon: {
      root: {
        color: '#979797 !important',
      },
    },
    '. &MuiTableSortLabel': {
      active: {
        color: '#979797 !important',
      },
      root: {
        color: '#979797 !important',
      },
      icon: {
        color: '#979797 !important',
      },
      iconDirectionDesc: {
        color: '#979797 !important',
      },
    },
  },
});

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
};

const avatar = {
  width: '2.0em',
  height: '2.0em',
  borderRadius: '50%',
  fontSize: '14px',
  backgroundColor: '#512DA8',
  color: 'white',
  objectFit: 'cover',
  boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)',
};

const assetProgressContainer = {
  marginTop: 10,
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
};

const colorBar = ['#007EFE', '#00BB00', '#46019B', '#FF974A', '#F7C412'];
