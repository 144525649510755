import Button from '@Components/Button';
import SignatureDrawer from '@Components/SignaturePad/SignatureDrawer';
import {
  Checkbox, CircularProgress, InputLabel, TextField, withStyles,
} from '@material-ui/core';
import { useState } from 'react';

export default (h) => {
  const [checked, setChecked] = useState(false);
  const isFieldEmpty = !(!!h.comment && !!h.signature && !!checked);
  return (
    <div className="mt-5 mx-auto" style={{ minHeight: '60vh', width: '30%' }}>
      {h.isLoading ? (
        <CircularProgress
          size={75}
          style={{
            color: 'var(--primary-color)', position: 'absolute', top: '50%', left: '50%', marginTop: -30, marginLeft: -35,
          }}
        />
      ) : (
        <>
          <div className="mb-3">
            <h3>Follow-Up Action and NCR Close</h3>
          </div>
          <div className="mb-1">
            <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Leave Your Comment Here</InputLabel>
            <TextField
              value={h.comment}
              onChange={e => h.setComment(e.target.value)}
              className="w-100 mb-3 mt-1"
              variant="outlined"
              multiline
              minRows={2}
              maxRows={2}
            />
          </div>
          <div className="mb-3">
            <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Select Inspection Date</InputLabel>
            <TextField
              value={h.reportDate}
              onChange={e => h.setReportDate(e.target.value)}
              className="w-100 mb-3"
              variant="standard"
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="mb-3">
            <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>
              Add your signature to blank box below to verify the report.
            </InputLabel>
            <SignatureDrawer setSignature={h.setSignature} />
          </div>
          <div className="mb-3 d-flex">
            <BlackCheckbox
              disableRipple
              value={checked}
              onClick={() => setChecked(prev => !prev)}
            />
            <p>We hereby to confirm the corrective action proposed have been executed satisfactorily and solve the NCR.</p>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Button onClick={h.UpdateReport} disabled={isFieldEmpty}>
              Submit Report
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    paddingLeft: 0,
  },
})((props) => <Checkbox color="default" {...props} />);
