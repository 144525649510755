/* eslint-disable max-lines */
import React from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import { Typography, Avatar } from '@material-ui/core';
import { diffInDays } from '@Helpers';
import ProgressBar from '@Components/ProgressBar';

const useStyles = makeStyles({
  leftHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      color: '#979797',
      backgroundColor: 'transparent',
      fontSize: '0.70rem',
      padding: 0,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
  },
  leftCell: {
    '& span': {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      backgroundColor: 'transparent',
      fontSize: '0.70rem',
      color: '#000000',
    },
  },
  title: {
    fontWeight: 'bold', fontSize: 16, marginLeft: 10, marginTop: 5,
  },
  content: { color: '#707070', fontSize: 12 },
});

export default (props) => {
  const classes = useStyles();

  const options = {
    filter: 'false',
    print: 'false',
    download: 'false',
    sort: 'false',
    viewColumns: 'false',
    search: 'false',
    responsive: 'vertical',
    rowsPerPage: 5,
    rowsPerPageOptions: [5],
  };

  const progress = (startDate, endDate) => {
    const project_duration = Math.ceil(diffInDays(new Date(startDate), new Date(endDate)));
    const project_progress = Math.ceil(diffInDays(new Date(startDate), new Date()));
    return project_progress / project_duration * 100;
  };

  const AssetLocationContent = (x) => {
    return (
      <Typography component="p" className={classes.content}>
        {`${x?.location}, ${x?.state}`}
      </Typography>
    );
  };

  const AssetName = (x) => {
    return (
      <Typography component="p" className={classes.content}>
        {x?.name}
      </Typography>
    );
  };

  const AssetMode = (x) => {
    return (
      <Typography component="p" className={classes.content}>
        {props.phases.find(d => d.id == x.currentPhase)?.name}
      </Typography>
    );
  };

  const AssetType = (x) => {
    return (
      <Typography component="p" className={classes.content}>
        {props.assetTypeList.find(d => d.id === x.AssetTypeId)?.name}
      </Typography>
    );
  };

  const SiteManager = (x) => {
    return (
      <div className="d-flex align-items-center w-100">
        <Avatar
          style={{ transform: 'scale(0.8)' }}
          alt={x?.project_manager}
          src={`${process.env.REACT_APP_S3}/${x?.image}`}
        />
        <Typography gutterBottom className={classes.content}>{x?.project_manager}</Typography>
      </div>
    );
  };

  const AssetProgress = ({ x, idx }) => {
    return (
      <div className="d-flex align-items-center w-100">
        <ProgressBar
          progress={Number(progress(x.commissionAt, x.expecteddecommissionAt).toFixed(0))}
          color={colorBar[idx % colorBar.length]}
        />
      </div>
    );
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        style={{ overflowX: 'hidden' }}
        options={options}
        columns={props.tableColumns?.map((col) => ({
          options: {
            setCellHeaderProps: () => ({ className: classes.leftHeader }),
            setCellProps: () => ({ className: classes.leftCell }),
          },
          label: col.name,
          name: col.selector,
        }))}
        data={props.tableData.map((x, idx) => {
          return ({
            number: idx + 1,
            siteName: <AssetName {...x} />,
            location: <AssetLocationContent {...x} />,
            siteManager: <SiteManager {...x} />,
            startDate: <Typography component="p" className={classes.content}>
              {moment(x?.createdAt).format('DD/MM/YYYY')}
                       </Typography>,
            progress: <AssetProgress x={x} idx={idx} />,
            endDate: <Typography component="p" className={classes.content}>
              {moment(x?.expecteddecommissionAt).format('DD/MM/YYYY')}
                     </Typography>,
            mode: <AssetMode {...x} />,
            siteType: <AssetType {...x} />,
            contactNo: '+60123456789',
          });
        })}
      />
    </MuiThemeProvider>
  );
};

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
  overrides: {
    MUIDataTableToolbar: {
      root: {
        display: 'none',
      },
    },
    MUIDataTableSelectCell: {
      root: {
        display: 'none',
      },
    },
    MUIDataTableBodyCell: {
      root: {
        paddingLeft: 10,
        paddingRight: '0px !important',
      },
    },
    MUIDataTableHeadRow: {
      root: {
        borderBottom: '1px solid #e5e5e5',
        borderTop: '1px solid #e5e5e5',
      },
    },
    MUIDataTableHeadCell: {
      root: {
        borderRight: '1px solid #e5e5e5',
      },
      toolButton: {
        margin: '0px !important',
      },
    },
    MUIDataTableBodyRow: {
      root: {
        borderBottom: '1px solid #e5e5e5',
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: '0px 0px 0px 0px',
        width: '100%',
      },
    },
    MuiTableHead: {
      root: {
        '& .MuiTableHead-root': {
          color: '#000000DE',
          fontSize: 14,
          fontWeight: 500,
          verticalAlign: 'middle',
        },
        '& .MuiTableCell-root': {
          color: '#979797',
          fontSize: 14,
          fontWeight: 500,
          verticalAlign: 'middle',
          paddingLeft: '0px !important',
        },
      },
    },
    MuiTableCell: {
      root: {
        '&:last-child': {
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
        },
      },
    },
    MuiTablePagination: {
      toolbar: {
        transform: 'scale(0.8)',
        marginTop: -12,
      },
    },
  },
});

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
};

const avatar = {
  width: '2.0em',
  height: '2.0em',
  borderRadius: '50%',
  fontSize: '14px',
  backgroundColor: '#512DA8',
  color: 'white',
  objectFit: 'cover',
  boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.1)',
};

const assetProgressContainer = {
  marginTop: 10,
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
};

const colorBar = ['#007EFE', '#00BB00', '#46019B', '#FF974A', '#F7C412'];
