/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import Button from '@Components/Button';
import Dropzone from '@Components/DropzoneBox';
import {
  Checkbox, Input, InputLabel, ListItemText, MenuItem, Select,
} from '@material-ui/core';

const InputText = ({ text, ...props }) => (
  <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }} {...props}>
    {text}
  </InputLabel>
);

export default (h) => {
  const isFieldEmpty = !(!!h.filesImage.length && (!!h.filesDrawing.length || h.selectedSiteDiary.length));
  return (
    <div className="w-75 mt-5 mx-auto" style={{ minHeight: '60vh' }}>
      <InputText className="mb-1" text="Upload Image" />
      <Dropzone type="image" files={h.filesImage} setFiles={h.setFilesImage} height="30vh" hasButton={false} />
      <InputText className="mt-4 mb-1" text="Select or Upload Drawing" />
      <Select
        multiple
        value={h.selectedSiteDiary}
        onChange={(e) => h.setSelectedSiteDrawing(e.target.value)}
        input={<Input />}
        displayEmpty
        className="mb-2"
        renderValue={(selected) => {
          if (!h.selectedSiteDiary.length) return 'Select Site Drawing';
          const nameArray = selected.map(e => h.siteDrawingList.find(f => f.id === e).name);
          return nameArray.join(', ');
        }}
      >
        {h.siteDrawingList.filter(f => !!f.path).map((name) => {
          return (
            <MenuItem key={name.id} value={name.id}>
              <Checkbox checked={h.selectedSiteDiary.indexOf(name.id) > -1} />
              <ListItemText primary={name.name} />
              {!name?.path ? (
                <img src={`${process.env.REACT_APP_S3}/static/icons/file.png`} style={{ width: 100, height: 70 }} />
              ) : (['pdf'].includes(name?.path?.split('.').pop()) ? (
                <iframe
                  src={`${process.env.REACT_APP_S3}/${name.path}#toolbar=0&navpanes=0`}
                  width="500"
                  height="300"
                  title="pdf"
                />
              ) : <img src={`${process.env.REACT_APP_S3}/${name.path}`} style={{ width: 500, height: 300 }} />)}
            </MenuItem>
          );
        })}
      </Select>
      <Dropzone type="docImg" files={h.filesDrawing} setFiles={h.setFilesDrawing} height="30vh" hasButton={false} />
      <InputText className="mt-4 mb-1" text="Upload File (Optional)" />
      <Dropzone type="pdfreport" files={h.files} setFiles={h.setFiles} height="30vh" hasButton={false} />
      <div className="d-flex justify-content-end mt-4">
        <Button variant="text" onClick={h.handleBackStep}>
          PREVIOUS
        </Button>
        <Button onClick={h.handleNextStep} disabled={isFieldEmpty}>
          NEXT
        </Button>
      </div>
    </div>
  );
};
