import { useState, useEffect } from 'react'
import Api, { endpoints } from '@Helpers/api'
import { useParams } from 'react-router-dom'

export default function hook() {
  const { AssetId } = useParams();
  const [masterlist, setMasterlist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [perpage, setPerpage] = useState(30);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [totaData, setTotalData] = useState(0);

  const getMasterlist = () => {
    setIsLoading(true);
    Api({
      endpoint: AssetId ? endpoints.getAssetFileById(AssetId) : endpoints.getAssetFile(),
      data: {
        perpage, page, sortby: 'createdAt,DESC', keyword,
      },
      onSuccess: (res) => {
        setIsLoading(false);
        setMasterlist(res.data.filter(d => d.type === 'masterlist'));
        setTotalData(res.total);
      },
      onFail: () => {
        toast('error', 'Failed get asset files');
      },
    });
  }

  useEffect(() => {
    getMasterlist();
  }, [])
  return {
    masterlist,
    setMasterlist,
    isLoading,
    page,
    keyword,
    totaData,
    perpage,
    setPage,
    setKeyword,
    setPerpage,
    getMasterlist,
  }
}
