import { useEffect, useState } from 'react';
import moment from 'moment';
import Api, { endpoints } from '@Helpers/api';
import queryString from 'query-string';
import { useParams, useHistory } from 'react-router-dom';
import { today } from '@Helpers';

export default () => {
  const history = useHistory();
  const { AssetId } = useParams();
  const [asset, setAsset] = useState();
  const [phases, setPhases] = useState([]);
  const [filteredPhases, setFilteredPhases] = useState([]);
  const [category, setCategory] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [dateProgress, setDateProgress] = useState(moment().format('Y-M-DD'));
  const [files, setFiles] = useState([]);
  const [image_type_tab, set_image_type_tab] = useState('0');
  const [outputType, setOutputType] = useState();
  const [uploadPercentages, setUploadPercentages] = useState([0]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [drawingTitle, setDrawingTitle] = useState('');
  const [drawingDate, setDrawingDate] = useState(today);
  const [drawingVersion, setDrawingVersion] = useState('');
  const [drawingRemarks, setDrawingRemarks] = useState(''); 

  const handleNextStep = () => { setActiveStep((prev) => prev + 1); };
  const handleBackStep = () => { setActiveStep((prev) => prev - 1); };
  const uploadDocument = (cpid) => {
    if (!files.length) return;
    setUploadPercentages([...Array(files.length)].map(() => 0));
    files.forEach((file, idx) => {
      const xhr = Api({
        endpoint: endpoints.uploadSiteDrawing(),
        data: {
          AssetId,
          name: drawingTitle,
          type: 'site_drawing',
          date: drawingDate,
          remark: drawingRemarks,
          version: drawingVersion,
        },
        files: [file],
        uploadPercent: (p) => updatePercentage(idx, p),
        onSuccess: () => {
          toast('success', `${file.name} uploaded`);
          updatePercentage(idx, 'done');
          // redirect immediately when no payment happend
          history.push(`/project/document?id=${AssetId}`);
          // window.location.reload(true);
        },
        onFail: () => {
          toast('error', 'Something went wrong, please try again.');
        },
      });
      setUploadFiles(prev => [...prev, xhr]);
    });
  };

  const updatePercentage = (i, p) => {
    if (p === 100) p = 99;
    if (p === 'done') p = 100;
    setUploadPercentages(arr => { arr[i] = p; return [...arr]; });
  };

  useEffect(() => {
    setUploadPercentages([...Array(files.length)].map(() => 0));
  }, [files]);
  const getAssetPhase = (id) => {
    Api({
      endpoint: endpoints.showAssets(id),
      onSuccess: ({ data }) => setAsset(data),
      onFail: () => toast('error', 'Error getting assets data. Please try again later.'),
    });
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => {
        setPhases(data.projectphase);
      },
      onFail: (err) => toast('error', err),
    });
  };
  useEffect(() => {
    getAssetPhase(AssetId);
  }, [AssetId]);
  useEffect(() => {
    if (asset && phases) {
      setFilteredPhases(phases.filter(p => asset.selectedPhase.split(',').map(x => Number(x)).includes(p.id)));
    }
  }, [asset, phases]);

  return {
    asset,
    AssetId,
    filteredPhases,
    category,
    setCategory,
    activeStep,
    setActiveStep,
    handleNextStep,
    handleBackStep,
    dateProgress,
    setDateProgress,
    files,
    setFiles,
    image_type_tab,
    set_image_type_tab,
    outputType,
    setOutputType,
    uploadPercentages,
    uploadFiles,
    drawingTitle,
    setDrawingTitle,
    drawingDate,
    setDrawingDate,
    drawingVersion,
    setDrawingVersion,
    drawingRemarks,
    setDrawingRemarks,
    uploadDocument,
  };
};
