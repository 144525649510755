import React from 'react';
import { Pannellum } from 'pannellum-react';

export default ({ imageUrl }) => {
  return (
    <div style={{ fontFamily: 'sans-serif', textAlign: 'center' }}>
      <Pannellum
        width="65vw"
        height="75vh"
        image={imageUrl}
        pitch={10}
        yaw={180}
        hfov={110}
        autoLoad
        showZoomCtrl={false}
        onLoad={() => {
          console.log('panorama loaded');
        }}
      >
        <Pannellum.Hotspot
          type="custom"
          pitch={31}
          yaw={150}
          handleClick={(evt, name) => console.log(name)}
          name="hs1"
        />
      </Pannellum>
    </div>
  );
};
