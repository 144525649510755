/* eslint-disable max-len */
/* eslint-disable linebreak-style */
import { useEffect, useState } from 'react';
import moment from 'moment';
import Api, { endpoints } from '@Helpers/api';
// import queryString from 'query-string';
import { useParams } from 'react-router-dom';
import { timeNow, timeToISO, today } from '@Helpers';

export default ({ user }) => {
  const { LogId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [logDetail, setLogDetail] = useState(null);
  const [reportDate, setReportDate] = useState(today);
  const [hasIotWeather, setHasIotWeather] = useState(null);
  const [start_time, setStartTime] = useState(timeNow);
  const [end_time, setEndTime] = useState(timeNow);
  const [location, setLocation] = useState('');
  const [wbsCodeActivity, setWbsCodeActivity] = useState('');
  const [trade, setTrade] = useState('');
  const [category, setCategory] = useState('');
  const [sub_category, set_sub_category] = useState('');
  const [activity, setActivity] = useState('');
  const [status, setStatus] = useState('');
  const [remark, setRemark] = useState('');
  const [manpowerDataList, setManpowerDataList] = useState([{ id: 1, name: '', value: 0, non_bumi: 0, others: 0, isSub: false }]);
  const [machineryDataList, setMachineryDataList] = useState([{ id: 1, code: '', name: '', quantity: 0, working: 0, idle: 0, down: 0, isSub: false }]);
  const [materialDataList, setMaterialDataList] = useState([{ id: 1, code: '', name: '', quantity: 0, unit: '', receipt_no: '', approval: false, isSub: false }]);
  const [delayActivitiesDataList, setDelayActivitiesDataList] = useState([{ id: 1, code: '', activity: '', location: '', start_time: timeNow, end_time: timeNow, reason_delay: '' }]);
  const [resultSuccess, setResultSuccess] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesDeleted, setFilesDeleted] = useState([]);
  const [savedImage, setSavedImage] = useState([]);

  const handleNextStep = () => { setActiveStep((prev) => prev + 1); };
  const handleBackStep = () => { setActiveStep((prev) => prev - 1); };

  useEffect(() => {
    if (!LogId) return;
    setIsLoading(true);
    Api({
      endpoint: endpoints.showSiteDiaryLog(LogId),
      onSuccess: ({ data }) => {
        setLogDetail(data);
        setIsLoading(false);
      },
      onFail: () => toast('error', 'Error getting assets data. Please try again later.'),
    });
  }, [LogId]);

  useEffect(() => {
    if (!logDetail) return;
    setReportDate(moment(logDetail.reportedAt).format('YYYY-MM-DD'));
    setManpowerDataList(logDetail.ConstructionLogManpowers);
    setMachineryDataList(logDetail.ConstructionLogMachineries);
    setMaterialDataList(logDetail.ConstructionLogMaterials);
    setSavedImage(logDetail.ConstructionLogAttachments);
    setStartTime(moment(logDetail.ConstructionLogActivities[0].start_time).format('HH:mm'));
    setEndTime(moment(logDetail.ConstructionLogActivities[0].end_time).format('HH:mm'));
    setLocation(logDetail.ConstructionLogActivities[0].location);
    setWbsCodeActivity(logDetail.ConstructionLogActivities[0].code);
    setTrade(logDetail.ConstructionLogActivities[0].trade);
    setCategory(logDetail.ConstructionLogActivities[0].category);
    set_sub_category(logDetail.ConstructionLogActivities[0].sub_category);
    setActivity(logDetail.ConstructionLogActivities[0].activity);
    setStatus(logDetail.ConstructionLogActivities[0].status);
    setRemark(logDetail.ConstructionLogActivities[0].remark);
    setDelayActivitiesDataList(logDetail.ConstructionLogDelayActivities.map(e => ({ ...e, start_time: moment(e.start_time).format('HH:mm'), end_time: moment(e.end_time).format('HH:mm') })));
  }, [logDetail]);

  const EditSiteDiary = () => {
    const data = {
      date: reportDate,
      AssetId: logDetail.AssetId,
      manpowers: manpowerDataList.filter(f => ((f.value ?? 0) + (f.non_bumi ?? 0) + (f.others ?? 0))),
      machineries: machineryDataList.filter(f => !!f.quantity),
      materials: materialDataList.filter(f => !!f.quantity),
      delay_activities: delayActivitiesDataList.filter(f => !!f.name).map(e => ({ ...e, start_time: timeToISO(e.start_time), end_time: timeToISO(e.end_time) })),
      activities: [
        {
          start_time: timeToISO(start_time),
          end_time: timeToISO(end_time),
          location,
          trade,
          category,
          sub_category,
          code: wbsCodeActivity,
          activity,
          status,
          remark,
        },
      ],
      filesDeleted,
    };
    setIsLoading(true);
    Api({
      endpoint: endpoints.updateSiteDiaryLog(LogId),
      data,
      files,
      onSuccess: () => {
        toast('success', 'Construction Log updated');
        setIsLoading(false);
        setResultSuccess(true);
        handleNextStep();
      },
      onFail: () => {
        toast('error', 'Opss, something went wrong, please try again.');
        setIsLoading(false);
        setResultSuccess(false);
        handleNextStep();
      },
    });
  };

  return {
    activeStep,
    setActiveStep,
    handleNextStep,
    handleBackStep,
    resultSuccess,
    isLoading,
    reportDate,
    setReportDate,
    manpowerDataList,
    setManpowerDataList,
    trade,
    setTrade,
    category,
    setCategory,
    sub_category,
    set_sub_category,
    activity,
    setActivity,
    status,
    setStatus,
    remark,
    setRemark,
    machineryDataList,
    setMachineryDataList,
    materialDataList,
    setMaterialDataList,
    files,
    setFiles,
    EditSiteDiary,
    savedImage,
    filesDeleted,
    setFilesDeleted,
    logDetail,
    hasIotWeather,
    setHasIotWeather,
    location,
    setLocation,
    wbsCodeActivity,
    setWbsCodeActivity,
    start_time,
    setStartTime,
    end_time,
    setEndTime,
    delayActivitiesDataList,
    setDelayActivitiesDataList,
  };
};
