import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import Pagination from '@Components/Pagination';
import DialogCarousel from '@Components/DialogCarousel';
import SearchBox from '@Components/SearchBox';
import UserInfoCard from './components/UserInfoCard';
import AddNewUserDialog from './components/AddNewUserDialog';
import useHook from './hook';
import RoleInformation from './components/RoleInformation';

const TableHeader = (props) => <p className="text-dark" style={{ fontSize: 16 }}>{props.children}</p>;

export default ({ user }) => {
  const h = useHook();
  const team = (teamId) => h.teams.filter(f => f.id === teamId);
  return (
    <div className="p2" style={{ marginInline: '7.5rem' }}>
      <h1
        className="pt-2"
        style={{
          fontWeight: 600,
          fontSize: 22,
          color: 'var(--primary-color)',
        }}
      >
        User Management
      </h1>
      <div className="w-100 mt-3 d-flex justify-content-between">
        <div>
          <SearchBox onChange={(e) => h.setKeyword(e.target.value)} onKeyDown={h.onKeyDown} onClick={h.getUsers} hasOnClick scale={0.1} />
          {process.env.REACT_APP_BRANCH === 'galaxy' && <RoleInformation {...h} />}
        </div>
        <div className="d-flex align-items-center">
          <DialogCarousel title="How to set access" name="page_access" style={{ fontSize: 20 }} />
          <Pagination size="small" {...h} />
          <AddNewUserDialog OrganizationId={user?.OrganizationId} user={user} {...h} />
        </div>
      </div>
      <Grid container className="mt-2 mb-1 px-3">
        <Grid item xs={3} />
        {[
          { xs: 1, label: '' },
          { xs: 3, label: 'EMAIL' },
          { xs: 1, label: 'TEAM', textStyle: { paddingLeft: '0.5vw' } },
          { xs: 1, label: 'ROLE' },
          // { xs: 1, label: `${process.env.REACT_APP_BRANCH === 'galaxy' ? 'geoRÄISE' : 'i-Supervision'} ROLE` },
          { xs: 2, label: 'ACTION', className: 'flex-standard' },
        ].map(m => (
          <Grid className="d-flex align-items-center" {...m}>
            <div style={{ fontSize: 12, fontFamily: 'CeraProRegular', ...m.textStyle }}>{m.label}</div>
          </Grid>
        ))}
      </Grid>
      {h.isLoading && (
        <CircularProgress
          size={75}
          className="position-absolute"
          style={{
            top: '50%', left: '50%', marginTop: -20, marginLeft: -40, color: 'var(--primary-color)',
          }}
        />
      )}
      {!!h.users?.length && h.users.map((u) => (
        <UserInfoCard user={u} team={team(u.TeamId)} {...h} />
      ))}
    </div>
  );
};
