import Button from '@Components/Button';
import SignatureDrawer from '@Components/SignaturePad/SignatureDrawer';
import {
  CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MuiThemeProvider, TextField, createTheme,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useState } from 'react';

export default (h) => {
  const isFieldEmpty = !(!!h.signature);
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="mt-5 mx-auto" style={{ minHeight: '60vh', width: '30%' }}>
        {h.isLoading ? (
          <CircularProgress
            size={75}
            style={{
              color: 'var(--primary-color)', position: 'absolute', top: '50%', left: '50%', marginTop: -30, marginLeft: -35,
            }}
          />
        ) : (
          <>
            <div className="d-flex justify-content-center mb-2">
              <h2>Reviewer Signature Verification</h2>
            </div>
            <p className="text-secondary" style={{ fontSize: '0.75rem' }}>
              Add your signature to blank box below to verify the report.
            </p>
            <SignatureDrawer setSignature={h.setSignature} />
            <div className="d-flex justify-content-end mt-4">
              <div />
              <Button
                onClick={() => setOpen(true)}
                disabled={isFieldEmpty}
                style={{
                  backgroundColor: isFieldEmpty ? '' : '#F74513',
                  color: isFieldEmpty ? '' : 'white',
                }}
              >
                Reject Report
              </Button>
              <Button className="ml-2" onClick={h.submitReport} disabled={isFieldEmpty}>
                Submit Report
              </Button>
            </div>
          </>
        )}
      </div>
      <MuiThemeProvider theme={getMuiTheme()}>
        <Dialog width="md" fullWidth open={open} onClose={() => setOpen(false)}>
          <DialogTitle className="px-4 pt-3 pb-1">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Reason for Rejection</h2>
              <IconButton onClick={() => setOpen(false)}>
                <Close />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <TextField
              value={h.remark}
              onChange={e => h.setRemark(e.target.value)}
              className="w-100"
              variant="outlined"
              placeholder="Please state your reason here..."
              multiline
              minRows={6}
              maxRows={6}
            />
          </DialogContent>
          <DialogActions className="pt-3">
            <Button variant="text" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: '#F74513',
                color: 'white',
              }}
              className="w-25"
              onClick={h.rejectReport}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    </>
  );
};

const getMuiTheme = () => createTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
});
