/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
  Grid, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TruckIcon from '@Assets/Icons/Truck.svg';
import PersonHelmetIcon from '@Assets/Icons/HelmetPerson.svg';
import PersonNoHelmetIcon from '@Assets/Icons/Nonhelmet.svg';
import BulldozerIcon from '@Assets/Icons/Bulldozer.svg';
import CraneIcon from '@Assets/Icons/Crane.svg';
import PersonIcon from '@Assets/Icons/People.svg';
import MachineryIcon from '@Assets/Icons/Machinery.svg';
import DrillIcon from '@Assets/Icons/Drill.svg';
import ExcavatorIcon from '@Assets/Icons/Excavator.svg';

const Icon = {
  truck: TruckIcon,
  machinery: MachineryIcon,
  person: PersonHelmetIcon,
  person_no_helmet: PersonNoHelmetIcon,
  manpower: PersonIcon,
  bulldozer: BulldozerIcon,
  crane: CraneIcon,
  excavator: ExcavatorIcon,
  drill: DrillIcon,
};

export default (props) => {
  const classes = useStyles();
  const CountType = [
    {
      type: 'machinery',
      totalCount: props.machinery_count,
      count: {
        crane: props.crane_count ?? 0,
        truck: props.truck_count ?? 0,
        excavator: props.excavator_count ?? 0,
        bulldozer: props.bulldozer_count ?? 0,
        drill: props.drill_machine_count ?? 0,
      },
    },
    {
      type: 'manpower',
      totalCount: props.person_count + props.person_no_helmet_count,
      count: {
        person: props.person_count,
        person_no_helmet: props.person_no_helmet_count,
      },
    },
  ];

  const date = new Date(props.captureAt);
  const dateMinus8Hours = date.getTime() - 8 * 60 * 60 * 1000;
  const newDateMinus8Hours = new Date(dateMinus8Hours);
  return (
    <div className={classes.card}>
      <Grid xs={12} className={classes.container}>
        <div className={classes.label}>Date</div>
        <div className={classes.text}>{moment(newDateMinus8Hours).format('DD MMM YYYY')}</div>
      </Grid>
      <Grid xs={12} className={classes.container}>
        <div className={classes.label}>Time</div>
        <div className={classes.text}>{moment(newDateMinus8Hours).format('h:mm A')}</div>
      </Grid>
      <div className={classes.div} />
      {CountType.map(m => (<ListOnHover {...m} />))}
    </div>
  );
};

const ListOnHover = (props) => {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [onHover, setOnHover] = useState(false);
  const [openCustomMenu, setOpenCustomMenu] = useState(false);
  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenCustomMenu(false);
    }
  };

  useEffect(() => {
    if (onHover) setOpenCustomMenu(true);
    if (!onHover) setOpenCustomMenu(false);
  }, [onHover]);

  return (
    <>
      <Grid
        xs={12}
        container
        className={classes.container}
        ref={anchorRef}
        onMouseOver={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        onFocus
        style={{ background: onHover && 'rgba(44, 36, 0, 0.5)' }}
      >
        <Grid xs={2}><img src={Icon[props.type]} style={{ transform: 'translate(0, -15%)' }} width={14} /></Grid>
        <Grid xs={7} className={classes.text} style={{ fontSize: 12, paddingTop: 3 }}>{props.type}</Grid>
        <Grid xs={1} className={classes.text} style={{ fontSize: 12, paddingTop: 3, textAlign: 'right' }}>{props.totalCount}</Grid>
        <Grid xs={1} className={classes.text} style={{ textAlign: 'right' }}><ChevronRightIcon /></Grid>
      </Grid>
      <Popper
        open={openCustomMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1 }}
        placement="right-start"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={`${classes.paperContainer} hide-scroll`}>
              <ClickAwayListener onClickAway={() => setOnHover(false)}>
                <MenuList
                  autoFocusItem={openCustomMenu}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem>
                    <Grid xs={12} container>
                      {Object.keys(props.count).map(m => (
                        <Grid
                          container
                          flex
                          justify="center"
                          xs={Object.keys(props.count).length <= 2 ? 12 : 6}
                          style={{ marginTop: 10 }}
                        >
                          <img src={Icon[m]} style={{ transform: 'translate(0, -10%)', paddingRight: 10 }} width={14} />
                          <p className={classes.text}>{props.count[m] ?? 0}</p>
                        </Grid>
                      ))}
                    </Grid>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const useStyles = makeStyles(() => ({
  card: {
    minWidth: '15%',
    paddingTop: 10,
    paddingBottom: 10,
    background: 'rgba(44, 36, 0, 0.5)',
    borderRadius: 5,
    position: 'absolute',
    top: '8px',
    left: '15px',
    justifyContent: 'space-between',
  },
  tileContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    padding: '5px 15px',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'center',
  },
  label: {
    textAlign: 'left',
    cursor: 'default',
    color: '#d9d9d9',
    fontSize: 11,
  },
  text: {
    textAlign: 'left',
    cursor: 'default',
    color: 'white',
    fontSize: 14,
    textTransform: 'capitalize',
  },
  icon: {
    paddingTop: 3,
    paddingRight: 4,
    textAlign: 'left',
    cursor: 'default',
    color: '#d9d9d9',
    fontSize: 11,
  },
  paperContainer: {
    overflow: 'auto',
    backgroundColor: 'rgba(44, 36, 0, 0.75)',
  },
  div: {
    borderBottom: '1px solid white',
    marginInline: '15px',
    marginTop: '10px',
    marginBottom: '10px',
  },
}));
