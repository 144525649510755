import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

import PaperCard from './Components/PaperCard';

export default function SeverityDisplay(h) {
  const hasAccess = [2].includes(h.user?.RoleId); // only org owner
  return (
    <div style={{ minHeight: '80vh' }}>
      <h1 className="mx-3" style={{ fontSize: 14, color: '#000' }}>
        We provide you the flexibility to define different type of severity level&nbsp;
        for different type of assets to match your need.
      </h1>
      <div className="d-flex justify-content-center w-100">
        <Grid className="mt-2" container spacing={2} nowrap style={{ width: '99%' }}>
          {h.assetTypeList.map((m, idx) => (
            <PaperCard
              assetType={m}
              index={idx}
              hasAccess={hasAccess}
              isDisabled={h.accesibleAssetTypes.includes(m.id)}
              originalSeverity={h.severityGroup[m.id]}
              {...h}
            />
          ))}
          {h.isLoading && (
            <CircularProgress
              size={75}
              className="position-absolute"
              style={{
                top: '50%', left: '50%', marginTop: -80, marginLeft: -40, color: 'var(--primary-color)',
              }}
            />
          )}
        </Grid>
      </div>
    </div>
  );
}
