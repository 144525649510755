/* eslint-disable max-lines */
/* eslint-disable linebreak-style */
import Button from '@Components/Button';
import {
  Grid, InputLabel, MenuItem, TextField,
} from '@material-ui/core';
import { useEffect } from 'react';

export default (h) => {
  useEffect(() => {
    const have_sub_category = ActivityListDummy.find(e => e.name === h.category)?.sub_category.find(f => f === h.sub_category);
    h.set_sub_category(!!have_sub_category ? have_sub_category : ActivityListDummy.find(e => e.name === h.category)?.sub_category[0]);
  }, [h.category]);

  const checkButtonDisable = !(!!h.trade && !!h.category && !!h.sub_category && !!h.activity && !!h.status && !!h.remark);
  return (
    <div className="w-50 mt-5 mx-auto" style={{ minHeight: '60vh' }}>
      <Grid container xs={12}>
        <CustomTextField
          title="Date"
          textFieldComponent={(
            <TextField
              type="date"
              value={h.reportDate}
              onChange={e => h.setReportDate(e.target.value)}
              className="w-100 justify-content-center"
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />

        <Grid container item xs={12}>
          <Grid item xs={6} className="pr-2">
            <CustomTextField
              title="Start Time"
              textFieldComponent={(
                <TextField
                  type="time"
                  value={h.start_time}
                  onChange={e => h.setStartTime(e.target.value)}
                  className="w-100 justify-content-center"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} className="pl-2">
            <CustomTextField
              title="End Time"
              textFieldComponent={(
                <TextField
                  type="time"
                  value={h.end_time}
                  onChange={e => h.setEndTime(e.target.value)}
                  className="w-100 justify-content-center"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Location</InputLabel>
        </Grid>
        <Grid item xs={12} className="mb-3">
          <TextField
            select
            value={h.location}
            onChange={e => h.setLocation(e.target.value)}
            className="w-100 justify-content-center"
            variant="standard"
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem value="BLOCK A">BLOCK A</MenuItem>
            <MenuItem value="BLOCK B">BLOCK B</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Trade</InputLabel>
        </Grid>
        <Grid item xs={12} className="mb-3">
          <TextField
            select
            value={h.trade}
            onChange={e => h.setTrade(e.target.value)}
            className="w-100 justify-content-center"
            variant="standard"
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem value="CIVIL">CIVIL</MenuItem>
            <MenuItem value="STRUCTURAL">STRUCTURAL</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Category</InputLabel>
        </Grid>
        <Grid item xs={12} className="mb-3">
          <TextField
            select
            value={h.category}
            onChange={e => h.setCategory(e.target.value)}
            className="w-100 justify-content-center"
            variant="standard"
            InputLabelProps={{ shrink: true }}
          >
            {ActivityListDummy.map(f => (
              <MenuItem value={f.name}>{f.name}</MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Sub-Category</InputLabel>
        </Grid>
        <Grid item xs={12} className="mb-3">
          <TextField
            select
            value={h.sub_category}
            onChange={e => h.set_sub_category(e.target.value)}
            className="w-100 justify-content-center"
            variant="standard"
            InputLabelProps={{ shrink: true }}
          >
            {ActivityListDummy.find(e => e.name === h.category)?.sub_category.map(f => (
              <MenuItem value={f}>{f}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <CustomTextField
          title="Activity"
          textFieldComponent={(
            <>
              <Grid item xs={4} className="pr-2">
                <TextField
                  placeholder="WBS Code"
                  value={h.wbsCodeActivity}
                  onChange={e => h.setWbsCodeActivity(e.target.value)}
                  className="w-100"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={8} className="pl-2">
                <TextField
                  select
                  value={h.activity}
                  onChange={e => h.setActivity(e.target.value)}
                  className="w-100 justify-content-center"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value="SURVEY WORK">SURVEY WORK</MenuItem>
                  <MenuItem value="CONSTRUCTION ">CONSTRUCTION </MenuItem>
                  <MenuItem value="EXCAVATION WORKS">EXCAVATION WORKS</MenuItem>
                  <MenuItem value="REMOVE AND REPLACE MATERIAL">REMOVE AND REPLACE MATERIAL</MenuItem>
                </TextField>
              </Grid>
            </>
          )}
        />

        <Grid item xs={12}>
          <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Status</InputLabel>
        </Grid>
        <Grid item xs={12} className="mb-3">
          <TextField
            select
            value={h.status}
            onChange={e => h.setStatus(e.target.value)}
            className="w-100 justify-content-center"
            variant="standard"
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem value="STARTED">STARTED</MenuItem>
            <MenuItem value="IN PROGRESS">IN PROGRESS</MenuItem>
            <MenuItem value="COMPLETED">COMPLETED</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Remarks</InputLabel>
        </Grid>
        <Grid item xs={12} className="mb-3">
          <TextField
            value={h.remark}
            onChange={e => h.setRemark(e.target.value)}
            className="w-100 mb-3 mt-1"
            variant="outlined"
            multiline
            minRows={6}
            maxRows={6}
          />
        </Grid>
      </Grid>
      <div className="d-flex justify-content-end mt-4">
        <Button variant="text" onClick={h.handleBackStep}>
          PREVIOUS
        </Button>
        <Button onClick={h.handleNextStep} disabled={checkButtonDisable}>
          NEXT
        </Button>
      </div>
    </div>
  );
};

const CustomTextField = ({ title, textFieldComponent }) => {
  return (
    <>
      <Grid item xs={12}>
        <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>{title}</InputLabel>
      </Grid>
      <Grid container item xs={12} className="mb-3">
        {textFieldComponent}
      </Grid>
    </>
  );
};

const ActivityListDummy = [
  {
    name: 'EARTHWORKS',
    sub_category: [
      'EXCAVATION',
      'SITE GRADING',
      'TRENCHING',
    ],
  },
  {
    name: 'FOUNDATIONS',
    sub_category: [
      'FOOTINGS',
      'PILE DRIVING',
      'FOUNDATION WALLS',
    ],
  },
  {
    name: 'CONCRETE STRUCTURES',
    sub_category: [
      'FORMWORK',
      'REINFORCEMENT',
      'CONCRETE POURING AND CURING',
    ],
  },
  {
    name: 'DRAINAGE',
    sub_category: [
      'SURFACE DRAINAGE',
      'CONCRETE DRAIN',
      'DRAINAGE EASEMENTS',
    ],
  },
  {
    name: 'SITE PREPARATION',
    sub_category: [
      'CLEARING AND GRUBBING',
      'EROSION CONTROL MEASURES',
      'SITE FENCING AND ACCESS',
    ],
  },
  {
    name: 'DEMOLITION',
    sub_category: [
      'BUILDING DEMOLITION',
      'CONCRETE REMOVAL',
      'SITE CLEARANCE',
    ],
  },
];
