import { Grid } from '@material-ui/core';
import MainContentContainer from '@Components/MainContentContainer';
import Navbar from '@Components/Navbar';
import TableAnalytics from '@Components/TableAnalytics';
import CenteredLoading from '@Components/CenteredLoading';
import ScatterGraph from '@Components/ScatterChart';
import DurationButton from '@Components/DurationButtonGroup';
import SelectDetection from '@Components/SelectDetection';
import NoData from '@Assets/Images/DataNotFound.svg';
import Hook from './hook';

const Collist = [
  { label: 'Truck Count', key: 'truck_count' },
  { label: 'Crane Count', key: 'crane_count' },
  { label: 'Bulldozer Count', key: 'bulldozer_count' },
  { label: 'Excavator Count', key: 'excavator_count' },
  { label: 'Drilling Machine Count', key: 'drilling_machine_count' },
  { label: 'Person Count', key: 'person_count' },
  { label: 'Person With No Helmet Count', key: 'person_no_helmet_count' },
];
export default function CctvAnalytics(props) {
  const h = Hook(props);
  const pagelabel = props.analytic_type === 'osh' ? 'OSH' : 'Project Progress';
  const filteranalytics = props.analytic_type === 'osh' ? ['person_no_helmet_count'] : [
    'truck_count', 'truck_count', 'bulldozer_count', 'excavator_count', 'drilling_machine_count', 'person_count',
  ];
  return (
    <MainContentContainer>
      {props.navbar !== false
        ? <Navbar to="/project/" text={pagelabel} subtext={props.filtered_projects[props.selected_project]?.name} />
        : <h1 style={{ paddingLeft: 20 }}>{pagelabel} Dashboard</h1>}
      <div className="d-flex justify-content-between mb-3">
        <SelectDetection {...h} />
        <DurationButton {...h} />
      </div>
      <Grid container>
        {h.loadingTable ? (
          <Grid item xs={12}>
            <CenteredLoading style={{ height: '750px', backgroundColor: '#000000b3', zIndex: 2 }} />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid item xs={12} style={{ height: !!h.footage_detection.length ? '300px' : '60%' }}>
              {!!h.footage_detection.length
                ? <ScatterGraph {...h} graphData={h.footage_detection} segregationMethod="CctvId" duration={h.duration} />
                : <div className="d-flex justify-content-center"> <img src={NoData} style={{ height: '55vh' }} /> </div>}
            </Grid>
            <TableAnalytics
              {...h}
              pagination={h.pagination}
              tableList={h.footage_detection_filtered ?? h.footage_detection}
              collist={Collist.filter(c => filteranalytics.includes(c.key))}
              isFiltered={!!h.footage_detection_filtered}
            />
          </Grid>
        )}
      </Grid>
    </MainContentContainer>
  );
}
