import React from 'react';

export default (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <div style={{
        backgroundColor: '#dfe7f2', borderRadius: 50, width: '100%', height: 10,
      }}
      >
        <div
          style={{
            backgroundColor: props.color,
            borderRadius: 50,
            width: `${props.progress === Number('Infinity') ? 100 : props.progress}%`,
            height: 10,
          }}
        />
      </div>
      <p style={{ marginInline: 10, fontSize: 10, fontWeight: 600 }}>{props.progress === Number('Infinity') ? 'Exceeded' : `${props.progress}%`}</p>
    </div>
  );
};
