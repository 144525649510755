import Button from '@Components/Button';
import Dropzone from '@Components/DropzoneBox';
import { InputLabel } from '@material-ui/core';

const InputText = ({ text, ...props }) => (
  <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }} {...props}>
    {text}
  </InputLabel>
);

export default (h) => {
  const isFieldEmpty = !(!!h.filesImage.length);
  return (
    <div className="w-75 mt-5 mx-auto" style={{ minHeight: '60vh' }}>
      <InputText className="mb-1" text="Upload Image" />
      <Dropzone type="image" files={h.filesImage} setFiles={h.setFilesImage} height="30vh" hasButton={false} />
      <InputText className="mt-4 mb-1" text="Upload File (Optional)" />
      <Dropzone type="pdfreport" files={h.files} setFiles={h.setFiles} height="30vh" hasButton={false} />
      <div className="d-flex justify-content-end mt-4">
        <Button variant="text" onClick={h.handleBackStep}>
          PREVIOUS
        </Button>
        <Button onClick={h.handleNextStep} disabled={isFieldEmpty}>
          NEXT
        </Button>
      </div>
    </div>
  );
};
