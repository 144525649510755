import MainContentContainer from '@Components/MainContentContainer';
import Navbar from '@Components/Navbar';
import useHook from './hook';
import Result from './Result';
import Signature from './Signature';

export default ({ user, isReview = false }) => {
  const h = useHook({ user, isReview });
  return (
    <MainContentContainer>
      <Navbar
        text={`Pending ${isReview ? 'Review' : 'Approval'}`}
        subtext={h.ReportUuid}
      />
      {{
        0: <Signature {...h} />,
        1: <Result {...h} />,
      }[h.activeStep]}
    </MainContentContainer>
  );
};
