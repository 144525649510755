/* eslint-disable max-lines */
/* eslint-disable linebreak-style */
import Button from '@Components/Button';
import {
  Grid, IconButton, InputLabel, MenuItem, TextField,
} from '@material-ui/core';
import { AddOutlined, Delete } from '@material-ui/icons';
import { useState } from 'react';

export default (h) => {
  const [onHover, setOnHover] = useState(false);
  const [onHoverSubCon, setOnHoverSubCon] = useState(false);
  const handleDirectMaterial = (setData, value, idx, attribute) => {
    setData(prev => prev.map(
      (data) => (idx === data.id ? ({ ...data, [attribute]: value }) : data),
    ));
  };
  const addNewRow = (setData, isSub) => {
    return setData(prev => [...prev, {
      id: prev.length + 1, code: '', name: '', quantity: 0, unit: '', receipt_no: '', approval: 'false', isSub: !!isSub,
    }].map((f, idx) => ({ ...f, id: idx + 1 })));
  };
  const checkZeroMaterial = h.materialDataList.filter(e => !e.isSub).reduce((total, item) => {
    return total + (!!item.quantity ? item.quantity : 0);
  }, 0);

  return (
    <div className="w-75 mt-5 mx-auto" style={{ minHeight: '60vh' }}>
      <MaterialSection
        data={h.materialDataList.filter(e => !e.isSub)}
        setData={h.setMaterialDataList}
        hover={onHover}
        setHover={setOnHover}
        handleDirectMaterial={handleDirectMaterial}
        addNewRow={addNewRow}
        label="Contractor"
      />
      <MaterialSection
        className="mt-4"
        data={h.materialDataList.filter(e => !!e.isSub)}
        setData={h.setMaterialDataList}
        hover={onHoverSubCon}
        setHover={setOnHoverSubCon}
        handleDirectMaterial={handleDirectMaterial}
        addNewRow={addNewRow}
        label="Sub-Contractor"
        isSub
      />
      <div className="d-flex justify-content-end mt-4">
        <Button variant="text" onClick={h.handleBackStep}>
          PREVIOUS
        </Button>
        <Button onClick={h.handleNextStep} disabled={!checkZeroMaterial}>
          NEXT
        </Button>
      </div>
    </div>
  );
};

const MaterialSection = ({
  className = '', data, setData, hover, setHover, handleDirectMaterial, addNewRow, label, isSub,
}) => (
  <Grid container xs={12} className={className}>
    {!data.length ? (
      <Grid item xs={12} flex-standard>
        No Data
      </Grid>
    ) : (
      <Grid
        container
        item
        xs={12}
        className="p-4 mb-4"
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          border: '1px solid black',
          borderRadius: 10,
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          transform: hover ? 'scale(1.02)' : 'scale(1)',
          transition: 'all .5s',
        }}
      >
        <Header label={label} />
        {data.map((f) => {
          return <MaterialRow f={f} idx={f.id} handleDirectMaterial={handleDirectMaterial} data={data} setData={setData} isSub={isSub} />;
        })}
      </Grid>
    )}
    <Button
      className="mt-4 float-right w-100"
      style={{ backgroundColor: '#F7F7F7' }}
      onClick={() => addNewRow(setData, isSub)}
      disabled={data.length === materialDummyList.length}
    >
      <AddOutlined style={{ color: data.length === materialDummyList.length ? '#e9d8d8a1' : '#058DFA' }} />
      <p style={{ color: data.length === materialDummyList.length ? '#e9d8d8a1' : '#058DFA' }}>Add {label} Materials</p>
    </Button>
  </Grid>
);

const MaterialRow = ({
  f, idx, handleDirectMaterial, data, setData, isSub,
}) => {
  return (
    <Grid container item xs={12} className="pt-2">
      <Grid item xs={1}>
        <TextField
          value={f.code}
          onChange={e => handleDirectMaterial(setData, e.target.value, idx, 'code')}
          className="mb-0"
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={4} className="pl-2">
        <TextField
          select
          value={f.name}
          onChange={e => {
            handleDirectMaterial(setData, e.target.value, idx, 'name');
            handleDirectMaterial(setData, materialDummyList.find(c => c.name === e.target.value)?.unit, idx, 'unit');
          }}
          className="w-100 justify-content-center pt-1"
          variant="standard"
          InputLabelProps={{ shrink: true }}
        >
          {materialDummyList.map(z => (
            <MenuItem value={z.name} disabled={data.map(e => e.name).includes(z.name)}>{z.name}</MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={1} className="pl-2">
        <TextField
          value={f.quantity.toString()}
          onChange={e => handleDirectMaterial(setData, Number(e.target.value), idx, 'quantity')}
          className="mb-0"
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
          type="number"
          disabled={!f.name}
          InputProps={{
            inputProps: { min: 0 },
          }}
        />
      </Grid>
      <Grid item xs={2} className="pl-2">
        <TextField
          value={!!f.unit ? f.unit : 'NO UNIT'}
          className="mb-0"
          variant="outlined"
          size="small"
          disabled
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={2} className="pl-2">
        <TextField
          value={f.receipt_no}
          onChange={e => handleDirectMaterial(setData, e.target.value, idx, 'receipt_no')}
          className="mb-0"
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
          disabled={!f.name}
        />
      </Grid>
      <Grid item xs={1} className="pl-2">
        <TextField
          select
          value={f.approval ?? false}
          onChange={e => handleDirectMaterial(setData, e.target.value, idx, 'approval')}
          className="w-100 justify-content-center pt-1"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          disabled={!f.name}
        >
          <MenuItem value="true">Yes</MenuItem>
          <MenuItem value="false">No</MenuItem>
        </TextField>
      </Grid>
      {(isSub || (data.length > 1 && !isSub)) && (
        <Grid item xs={1} className="d-flex align-items-center justify-content-center pt-2">
          <IconButton
            className="p-0"
            onClick={() => setData(prev => {
              const updatedData = prev.filter(e => idx !== e.id);
              if (isSub && !updatedData.some(e => !!e.isSub)) {
                return [...updatedData, {
                  id: 0, code: '', name: '', quantity: 0, unit: '', receipt_no: '', approval: 'false', isSub: !!isSub,
                }].map((e, idx2) => ({ ...e, id: idx2 + 1 }));
              }
              return updatedData.map((e, idx2) => ({ ...e, id: idx2 + 1 }));
            })}
          >
            <Delete style={{ color: 'red' }} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

const Header = ({ label = 'Contractor' }) => (
  <Grid container item xs={12}>
    <Grid item xs={1}>
      <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)' }}>WBS Code</InputLabel>
    </Grid>
    <Grid item xs={4} className="pl-2">
      <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)' }}>{label} Materials</InputLabel>
    </Grid>
    <Grid item xs={1} className="pl-2">
      <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)' }}>Quantity:</InputLabel>
    </Grid>
    <Grid item xs={2} className="pl-2">
      <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)' }}>Unit</InputLabel>
    </Grid>
    <Grid item xs={2} className="pl-2">
      <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)' }}>Receipt No</InputLabel>
    </Grid>
    <Grid item xs={1} className="pl-2">
      <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)' }}>Approval</InputLabel>
    </Grid>
  </Grid>
);

const materialDummyList = [
  { name: 'CEMENT', unit: 'KG' },
  { name: 'BRICKS', unit: 'PER UNIT' },
  { name: 'STEEL', unit: 'TON' },
  { name: 'WOOD', unit: '' },
  { name: 'CONCRETE', unit: '' },
  { name: 'REBAR', unit: '' },
  { name: 'PLUMBING MATERIALS', unit: '' },
  { name: 'ELECTRICAL MATERIALS', unit: '' },
];
