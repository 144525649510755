import {
  Dialog, DialogTitle, DialogContent, IconButton,
} from '@material-ui/core';
import Button from '@Components/Button';
import CloseIcon from '@material-ui/icons/Clear';
import CenteredLoading from '@Components/CenteredLoading';

export default ({ currentImage, ...h }) => (
  <>
    <Button
      style={{ borderRadius: 10 }}
      onClick={() => {
        h.getLatestCCTVFootage();
        h.set_isImageLoading(true);
        h.set_openModal(true);
      }}
    >
      Current Image
    </Button>
    {h.openModal && (
      <Dialog
        open={h.openModal}
        onClose={() => h.handleCloseModal(currentImage?.id)}
        maxWidth="xl"
      >
        <DialogTitle>
          <h2 className="font-weight-bold" style={{ minWidth: '35vh' }}>Current Image</h2>
          <IconButton
            onClick={() => h.handleCloseModal(currentImage?.id)}
            style={{ right: -0, top: -0, position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {h.isImageLoading
            ? (<CenteredLoading style={{ marginBottom: 30, marginTop: 10 }} size={70} />)
            : (
              <>
                {!!currentImage?.is_offline || !currentImage?.path
                  ? (<div className="mb-4">Could not load the current image, please try again later</div>)
                  : (
                    <>
                      <img
                        src={`${process.env.REACT_APP_S3}/${currentImage?.path}`}
                        alt="View of Current Img"
                        style={{ objectFit: 'contain', width: '100%', aspectRatio: '4/2' }}
                      />
                      <div className="d-flex justify-content-end">
                        {!currentImage?.is_offline && (
                          <Button
                            style={{ borderRadius: 10, height: '3rem', marginTop: 10 }}
                            onClick={() => h.handleImageDownload(currentImage?.path)}
                          >
                            Download Image
                          </Button>
                        )}
                      </div>
                    </>
                  )}
              </>
            )}

        </DialogContent>
      </Dialog>
    )}
  </>
);
