function Icon(props) {
  return (

    <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="21.2781" cy="20.971" r="20.971" fill={props.selected ? '#86929D' : '#D6DADD'} />
      <circle cx="11.8071" cy="20.5" r="2.5" fill="white" />
      <circle cx="20.8071" cy="20.5" r="2.5" fill="white" />
      <circle cx="29.8071" cy="20.5" r="2.5" fill="white" />
    </svg>

  );
}
export default Icon;
