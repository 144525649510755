/* eslint-disable max-len */
/* eslint-disable complexity */
import { Grid } from '@material-ui/core';
import { useState } from 'react';
import {
  Map, TileLayer, Marker, Tooltip, LayersControl,
} from 'react-leaflet';
import * as L from 'leaflet';
import moment from 'moment';

import WeatherThunderIcon from '@Assets/Icons/WeatherThunder.svg';
import { InfoOutlined } from '@material-ui/icons';

const { BaseLayer, Overlay } = LayersControl;
// const google_map_key = 'AIzaSyAbPx_nphakqjTpgdPX_e_yN4Hdfdss2-w';
// const mapbox_style_id = 'ckty0a3040k2b18s2djzhc9rs';
const mapbox_style_id = 'clcxknczv000h15l1gwwxv6sa';
const mapbox_access_token = 'pk.eyJ1IjoiZGV2b2ZvdGVjaCIsImEiOiJja3R4emdxcXQyeXJlMnBvM3FhMjlrdG9vIn0.3V_xPbc72a4v92QNLxu3NA';
const LeafIcon = L.Icon.extend({ options: {} });
const IconMarker = (iconUrl, extProps) => (new LeafIcon({
  iconUrl,
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [30, 30],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
  ...extProps,
}));
const cardCss = {
  backgroundColor: 'white',
  borderRadius: 10,
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  padding: 13,
  position: 'relative',
  height: '100%',
};

const CardTitle = ({ title }) => (<p style={{ color: '#605f65', fontSize: 12 }}>{title ?? 'Status'}</p>);
const Day = ({ title }) => (<p style={{ color: 'blue', fontSize: 24, fontWeight: 600 }}>{moment().format('dddd')} <span style={{ color: 'grey', fontSize: 8 }}>({moment().format('Do MMMM, YYYY')})</span></p>);

const calculateChanceOfRain = (humidity, pressure) => {
  if (humidity > 70 && pressure < 1000) {
    return 'High';
  } if (humidity > 50 && pressure < 1013) {
    return 'Moderate';
  }
  return 'Low';
};
export default function index(props) {
  const [center, set_center] = useState([4.808695, 108.146558]);
  const [zoom, set_zoom] = useState(5);
  const rankBy = 'temp'; // can change rank by here
  const highestWeatherId = Object.keys(props.assets_weather).sort((a, b) => props.assets_weather[b]?.[rankBy] - props.assets_weather[a]?.[rankBy])[0];
  const isLoading = !props.assets_weather[highestWeatherId];
  return (
    <Grid container xs={12} md={12} style={{ ...cardCss }}>
      <Grid container item xs={3} md={3} style={{ borderRight: 'solid grey 0.5px', paddingRight: 5 }}>
        <CardTitle title={props.title} />
        <div style={{
          display: 'flex', alignItems: 'center', backgroundColor: 'red', borderRadius: 5, paddingInline: 10, height: 30, width: '80%',
        }}
        >
          <InfoOutlined style={{ fill: 'white', width: 12 }} />
          <p style={{ color: 'white', fontSize: 12 }}>&nbsp;{props.assets.find(({ id }) => id == highestWeatherId)?.name} </p>
        </div>
        <Day title={props.title} />
        <Grid container xs={12} md={12} style={{ height: 'max-content' }}>
          {!!isLoading ? (
            <Grid xs={4} md={4}><img src={WeatherThunderIcon} style={{ transform: 'translate(0, -15%)', width: '-webkit-fill-available' }} /></Grid>
          ) : (
            <Grid xs={4} md={4}><img src={`https://openweathermap.org/img/wn/${props.assets_weather[highestWeatherId].weather[0].icon}@2x.png`} style={{ transform: 'translate(0, -15%)', width: '-webkit-fill-available' }} /></Grid>
          )}
          <Grid xs={8} md={8}>
            {!!isLoading ? (
              <p style={{ color: 'black', fontWeight: 600, fontSize: 14 }}>Loading Data</p>
            ) : (
              <p style={{ color: 'black', fontWeight: 600, fontSize: 24 }}>{props.assets_weather[highestWeatherId].temp}° C</p>
            )}
            <div style={{ display: 'flex' }}>
              <p style={{ fontSize: 12, color: 'black' }}>{moment().format('HH:mm')} hours</p>
              {/* <p style={{ fontSize: 12, color: 'grey' }}>&nbsp;&nbsp;(7:19/18:30)</p> */}
            </div>
          </Grid>
        </Grid>
        <Grid container xs={12} md={12} style={{ height: 'max-content', fontSize: '8px' }}>
          <Grid xs={8} md={8}>
            TEMPERATURE<br />HUMIDITY<br />CLOUDS<br />WIND SPEED<br />PRESSURE<br />CHANCE OF RAIN<br />UX INDEX
          </Grid>
          {!!isLoading ? (
            <Grid xs={4} md={4}>
              ...<br />...<br />...<br />...<br />...
            </Grid>
          ) : (
            <Grid xs={4} md={4}>
              {props.assets_weather[highestWeatherId]?.temp}° C<br />
              {props.assets_weather[highestWeatherId]?.humidity}%<br />
              {props.assets_weather[highestWeatherId]?.clouds}%<br />
              {props.assets_weather[highestWeatherId]?.wind_speed}km/h<br />
              {props.assets_weather[highestWeatherId]?.pressure}hPa<br />
              {calculateChanceOfRain(props.assets_weather[highestWeatherId]?.humidity, props.assets_weather[highestWeatherId]?.pressure)}<br />
              {props.assets_weather[highestWeatherId]?.uvi}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={9} md={9} style={{ paddingLeft: 10 }}>
        <Map
        // ref={mapRef}
          center={center}
          zoom={zoom}
          maxZoom={23}
          scrollWheelZoom
          tap={false}
          style={{
            width: '100%',
            height: '100%',
          }}
          // bounds={bounds}
          zoomControl={false}
        >
          <TileLayer
            attribution='Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors, <a href=&quot;https://creativecommons.org/licenses/by-sa/2.0/&quot;>CC-BY-SA</a>, Imagery &copy; <a href=&quot;https://www.mapbox.com/&quot;>Mapbox</a>'
            url={`https://api.mapbox.com/styles/v1/devofotech/${mapbox_style_id}/tiles/256/{z}/{x}/{y}@2x?access_token=${mapbox_access_token}`}
          />
          {props.assets && props.assets.map(asset => (
            <Marker position={[asset.lat, asset.lng]} icon={props.assets_weather[asset.id] ? IconMarker(`https://openweathermap.org/img/wn/${props.assets_weather[asset.id]?.weather[0].icon}@2x.png`, { shadowSize: null }) : IconMarker(WeatherThunderIcon)}>
              <Tooltip>{asset.name}</Tooltip>
            </Marker>
          ))}
        </Map>
      </Grid>
    </Grid>
  );
}
