/* eslint-disable complexity */
import { Grid } from '@material-ui/core';
import { WeatherIcons } from '@Assets/Icons/WeatherIcons';

export default function index({ w, t, title, value }) {
  return (
    <Grid container item xs={12} md={6} className="mb-3">
      <Grid item xs={3} md={3}>
        <div
          className="flex-standard"
          style={{
            fontSize: 10, padding: 7, borderRadius: 50, backgroundColor: '#e6e8f3', width: '1.3rem', height: '1.3rem',
          }}
        >
          <img src={WeatherIcons[w]} style={{ width: '0.9rem', height: '0.9rem' }} />
        </div>
      </Grid>
      <Grid item xs={9} md={9}>
        <div style={{
          display: 'flex', flexDirection: 'column', fontSize: 12, paddingLeft: 10, marginRight: 10
        }}
        >
          <p className="text-standard" style={{ color: '#c1c7d8', fontSize: 10 }}>{title}</p>
          <p>{value}</p>
        </div>
      </Grid>
    </Grid>
  );
}
