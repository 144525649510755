import WeatherThunderIcon from '@Assets/Icons/WeatherThunder.svg';
import clear_skyIcon from '@Assets/Icons/Weather/clear_sky.svg';
import cloud_with_sunIcon from '@Assets/Icons/Weather/cloud_with_sun.svg';
import cloudyIcon from '@Assets/Icons/Weather/cloudy.svg';
import heavy_rainIcon from '@Assets/Icons/Weather/heavy_rain.svg';
import rainIcon from '@Assets/Icons/Weather/rain.svg';
import thunderIcon from '@Assets/Icons/Weather/thunder.svg';
import thunderstom_rainIcon from '@Assets/Icons/Weather/thunderstom_rain.svg';
import windyIcon from '@Assets/Icons/Weather/windy.svg';
import Grey_CelciusIcon from '@Assets/Icons/Weather/Grey_Celcius.svg';
import Grey_rainIcon from '@Assets/Icons/Weather/Grey_rain.svg';
import Grey_SunIcon from '@Assets/Icons/Weather/Grey_Sun.svg';
import Grey_UmbrellaIcon from '@Assets/Icons/Weather/Grey_Umbrella.svg';
import Grey_Water_DropIcon from '@Assets/Icons/Weather/Grey_Water_Drop.svg';
import Grey_WindyIcon from '@Assets/Icons/Weather/Grey_Windy.svg';

export const WeatherIcons = {
  1: WeatherThunderIcon, // dumy
  21: clear_skyIcon,
  22: cloud_with_sunIcon,
  23: cloudyIcon,
  24: heavy_rainIcon,
  25: rainIcon,
  26: thunderIcon,
  27: thunderstom_rainIcon,
  11: windyIcon,
  31: Grey_CelciusIcon,
  32: Grey_rainIcon,
  33: Grey_SunIcon,
  34: Grey_UmbrellaIcon,
  35: Grey_Water_DropIcon,
  36: Grey_WindyIcon,
};
