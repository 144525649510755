import React, { useState, useEffect } from 'react';
import {
  Box, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Tooltip,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import FileDropZone from '@Components/DropzoneBox/v3';
import UploadProgress from '@Components/UploadProgress';

const useStyles = makeStyles(() => ({
  blueBtn: { borderRadius: '6px', width: '150px' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  outlinedBtnText: { fontWeight: 600, fontSize: 16 },
  closeBtn: { cursor: 'pointer', float: 'right' },
}));

export default function UploadInspectionPhoto(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);

  const clickUpload = () => {
    if (!files.length) return;
    if (!props.selectedDiscipline) return;
    props.onSave({ files, DisciplineId: props.selectedDiscipline });
    setOpen(false);
    props.setSelectedDiscipline(0);
  };

  useEffect(() => {
    if (!files.length) return;
    const isUploadCompleted = !props.uploadPercentages.some(e => e !== 100);
    if (isUploadCompleted) {
      setFiles([]);
      props.setUploadPercentages([0]);
    }
  }, [props.uploadPercentages]);

  return (
    <div>
      <div>
        <Tooltip title="Upload Image">
          <AddBoxIcon fontSize="medium" style={{ padding: '15px 10px 10px 0px', cursor: 'pointer', color: 'var(--primary-color)' }} onClick={() => setOpen(true)} />
        </Tooltip>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ style: { borderRadius: 10, maxHeight: '40rem', width: 'auto' } }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <p style={{ fontSize: 18 }}>Upload Inspection Media</p>
            <Close style={{ width: 20 }} className={classes.closeBtn} onClick={() => { setOpen(false); setFiles([]); }} />
          </div>
        </DialogTitle>
        <DialogContent>
          <FileDropZone files={files} setFiles={setFiles} height={400} type="imageVideo" />
          <div className='d-flex align-items-center mt-2'>
            <p>Discipline : </p>
            <div style={{ flex: 1, paddingLeft: 15 }}>
              <TextField
                select
                fullWidth
                variant="outlined"
                value={props.selectedDiscipline}
                onChange={(e) => props.setSelectedDiscipline(e.target.value)}
                size="small"
              >
                <MenuItem value={0} children={<p className="text-dark">Select one</p>} />
                {props.disciplineList.map(e => (<MenuItem value={e.id} children={<p className="text-dark">{e.name}</p>} />))}
              </TextField>
            </div>
          </div>
          <div className='d-flex align-items-center mt-2'>
            <p>Date : </p>
            <div style={{ flex: 1, display: 'flex', paddingLeft: 15, justifyContent: 'end' }}>
              <TextField type='datetime-local' value={props.date} onChange={(e) => props.setDate(e.target.value)} />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{
            display: 'flex', justifyContent: 'space-between', padding: '0 20px', width: '100%',
          }}
          >
            <Box
              component="button"
              className="blue-button-outlined"
              onClick={() => { setOpen(false); setFiles([]); }}
              style={{ borderRadius: '6px', width: '120px', padding: 6 }}
            >
              <h1 style={{ fontSize: 12 }} className={`${classes.outlinedBtnText} flex-standard`}>
                Cancel
              </h1>
            </Box>
            <Box
              component="button"
              className={(!!props.selectedDiscipline && !!files.length) ? `${classes.blueBtn} blue-button` : "blue-button-outlined"}
              onClick={() => clickUpload()}
              disabled={!props.selectedDiscipline && !files.length}
              style={{ borderRadius: '6px', width: '160px', marginLeft: '5rem' }}
            >
              <h1 className={`${(!!props.selectedDiscipline && !!files.length) ? classes.blueBtnText : classes.outlinedBtnText} flex-standard`} style={{ fontSize: 12 }}>
                <CloudUploadIcon style={{ marginRight: 10, width: 20 }} />
                Upload Media
              </h1>
            </Box>
          </div>
        </DialogActions>
      </Dialog>
      {!!files.length && (
        <UploadProgress
          title="Upload Progress"
          files={files}
          setFiles={setFiles}
          percentages={props.uploadPercentages}
          uploadFiles={props.uploadFiles}
        />
      )}
    </div>
  );
}
