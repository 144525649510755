import React, { useEffect, useState } from 'react';
import {
  Grid, TextField, Button, MenuItem,
} from '@material-ui/core';
import Api, { endpoints } from '@Helpers/api';
import moment from 'moment';

const style = {
  button: {
    marginBottom: '1rem',
    backgroundColor: 'var(--primary-color)',
    borderRadius: 5,
    color: 'var(--light-color)',
    fontWeight: 600,
    fontSize: 12,
  },
  cancel: {
    marginBottom: '1rem',
    backgroundColor: 'var(--primary-color)',
    marginRight: 4,
    borderRadius: 5,
    color: 'var(--light-color)',
    fontWeight: 600,
    fontSize: 12,
  },
  input: {
    marginTop: 5,
    fontSize: '12px !important',
    backgroundColor: 'transparent !important',
    width: '100%',
  },
};

export default function EditProject({
  project, handleClickEdit, isEdit, setJustUpdated, getAssets,
}) {
  const startedDate = project?.commissionAt;
  const expectedDate = project?.expecteddecommissionAt;
  const [state, setState] = useState(project?.state ?? null);
  const [commissionAt, setcommissionAt] = useState(startedDate);
  const [expecteddecommissionAt, setexpecteddecommissionAt] = useState(expectedDate);
  const [project_manager, setproject_manager] = useState(project?.project_manager);
  const [contract_no, setcontract_no] = useState(project?.contract_no);
  const [ContractorId, setContractorId] = useState(project?.ContractorId ?? null);
  const [contractors, setContractors] = useState([]);
  const handleSave = () => {
    if (!project.id) return;
    const input = {
      state, commissionAt, expecteddecommissionAt, project_manager, ContractorId, contract_no,
    };
    Api({
      endpoint: endpoints.updateAssets(project.id),
      data: { input },
      onSuccess: () => {
        handleClickEdit();
        toast('success', 'Successfully update Project Details');
        getAssets();
        setJustUpdated((v) => v + 1);
      },
      onFail: () => {
        toast('error', 'Unsuccessfully update Project Details');
      },
    });
  };
  useEffect(() => {
    setState(project?.state ?? null);
    setcommissionAt(startedDate);
    setexpecteddecommissionAt(expectedDate);
    setproject_manager(project?.project_manager);
    setcontract_no(project?.contract_no);
    setContractorId(project?.ContractorId ?? null);
  }, [project]);

  useEffect(() => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => setContractors(!!data.Contractors ? data.Contractors : []),
    });
  }, []);
  return (
    <>
      <Grid
        container
        spacing={1}
        style={{ width: '90%', padding: '10px 0', justifyContent: 'space-evenly' }}
      >
        {[
          {
            title: 'State',
            data: state,
            component: <EditField value={state} onChange={(e) => setState(e.target.value)} />,
          },
          {
            title: 'Commencement Date',
            data: moment(startedDate).format('DD MMMM YYYY'),
            component: (
              <EditField
                type="date"
                value={moment(commissionAt).format('YYYY-MM-DD')}
                onChange={(e) => setcommissionAt(`${e.target.value}T00:00:00.000Z`)}
              />
            ),
          },
          {
            title: 'No Contract',
            data: contract_no,
            component: <EditField value={contract_no} onChange={(e) => setcontract_no(e.target.value)} />,
          },
          {
            title: 'Expected Completion',
            data: moment(expectedDate).format('DD MMMM YYYY'),
            component: (
              <EditField
                type="date"
                value={moment(expecteddecommissionAt).format('YYYY-MM-DD')}
                onChange={(e) => setexpecteddecommissionAt(`${e.target.value}T00:00:00.000Z`)}
              />
            ),
          },
          {
            title: 'Project Manager',
            data: project_manager,
            component: <EditField value={project_manager} onChange={(e) => setproject_manager(e.target.value)} />,
          },
          {
            title: 'Main Contractor',
            data: contractors.find(f => f.id == ContractorId)?.name,
            component: (
              <TextField
                select
                value={ContractorId}
                variant="outlined"
                onChange={(e) => setContractorId(e.target.value)}
                size="small"
                style={style.input}
              >
                {!!contractors.length && contractors.map((e) => (
                  <MenuItem value={e.id}>{e.name}</MenuItem>
                ))}
              </TextField>
            ),
          },
        ].map(e => (
          <>
            <Grid item xs={6} className="d-flex align-items-center my-auto py-0">
              <p className="navbar-text text-dark" style={{ fontWeight: 600 }}>{e.title}</p>
            </Grid>
            <Grid item xs={6} className="py-0">
              {isEdit ? e.component : <p className="navbar-text text-dark">{e.data ?? ' -'}</p>}
            </Grid>
          </>
        ))}
      </Grid>
      {isEdit && (
        <>
          <Grid style={{ marginTop: 4 }}>
            <Button style={style.cancel} onClick={handleClickEdit}>Cancel</Button>
            <Button style={style.button} onClick={handleSave}>Save Changes</Button>
          </Grid>
        </>
      )}
    </>
  );
}

const EditField = (props) => <TextField hiddenLabel variant="outlined" style={style.input} size="small" {...props} />;
