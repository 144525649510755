import React, { useState, useEffect } from 'react';
import { getDirectDataFirebase } from '@Helpers';
import online_video from '@Assets/Videos/ai_online.mp4';
import offline_video from '@Assets/Videos/ai_offline.mp4';

const cardCss = {
  backgroundColor: 'white',
  borderRadius: 10,
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  position: 'relative',
  height: '100%',
};

export default function index({ assetId }) {
  const [status, setStatus] = useState(false);
  useEffect(async () => {
    const url_ai_server = `https://supervision-current-cctv-default-rtdb.asia-southeast1.firebasedatabase.app/server/${assetId}/isOnline.json`;
    setStatus((await getDirectDataFirebase(url_ai_server)).data);
  }, [assetId]);

  const isOnline = (stat) => {
    if (typeof stat == 'string' && stat === 'true') return true;
    if (typeof stat == 'boolean' && !!stat) return true;
    return false;
  };

  return (
    <div style={{ ...cardCss }} className="flex-standard">
      <video
        preload
        autoPlay
        muted
        loop
        className="w-100 h-100"
        style={{ borderRadius: 10 }}
        src={isOnline(status) ? online_video : offline_video}
        type="video/mp4"
      />
    </div>
  );
}
