/* eslint-disable complexity */
/* eslint-disable max-len */
import { Box, Grid, IconButton } from '@material-ui/core';
import Inspection from '@Assets/Icons/Inspection.svg';
import Cube3D from '@Assets/Icons/3D.svg';
import Report from '@Assets/Icons/Report.svg';
import CCTV from '@Assets/Icons/CCTV2.svg';
import BIM from '@Assets/Icons/BIM.svg';
import Documents from '@Assets/Icons/Documents.svg';
import Dropdown from '@Components/Dropdown';
import RoundedButton from '@Components/RoundedButton';
import AssetDetail from '@Components/AssetDetail';
import PieChart from '@Components/PieChart';
import {
  ChevronLeft, ChevronRight, Edit, InfoOutlined,
} from '@material-ui/icons';
import SidebarCards from '@Pages/ProjectSite/SidebarCards';
import EditProject from '@Pages/ProjectSite/components/EditProject';
import './styles.css';

const style = {
  editIcon: {
    position: 'absolute', paddingTop: 1.5, paddingLeft: 10, fontSize: 16, color: 'rgb(30, 52, 101)', cursor: 'pointer',
  },
  buttonIcon: { width: 55, height: 55 },
};

export default function Property(props) {
  const hasSeverity = ![92, 93].includes(props.project.id);
  const hasProjectPhasePackage = ![92, 93].includes(props.project.id);
  const hasPhasePageAccess = !!props.selectedPhaseWithViewPageAccess.length;
  const sidebarClass = props.openSidebar ? 'sidenav' : 'sidenav open';
  return (
    <Grid item xs={12} md={3} id="top" className={sidebarClass} style={{ zIndex: 1 }}>
      <img
        className="sidebar-img"
        style={{ objectFit: 'cover', width: '100%', aspectRatio: '3/2' }}
        src={`${process.env.REACT_APP_S3}/${!!props.project.image ? props.project.image : 'static/media/defaultAssetImg-01.png'}`}
        alt="asset"
      />
      <div className="d-flex justify-content-center flex-wrap" style={{ flex: 1 }}>

        <div className="d-flex align-items-center my-2">
          <h6 className="text-light text-center w-100 mr-1" style={{ fontSize: 12 }}>ASSET INFO</h6>
          <IconButton disableRipple style={{ backgroundColor: 'transparent', padding: 0 }}>
            <InfoOutlined style={{ fontSize: 14 }} onClick={() => props.setOpenInfoDialog(true)} />
          </IconButton>
        </div>

        <Box style={{ width: '90%' }}>
          <Dropdown
            selectedItem={props.selected_project}
            setSelectedItem={props.set_selected_project}
            itemList={props.filtered_projects.map(e => e.name)}
            size="medium"
            Hx="h6"
          />
        </Box>

        <AssetDetail details={props.project} assetTypeList={props.assetTypeList} />

        <div
          className="flex"
          style={{ width: '90%', margin: '16px 20px' }}
        >
          <h6 className="text-light text-center" style={{ flex: 11 }}>
            PROJECT INFO {[2].includes(props.user?.RoleId) && <Edit style={style.editIcon} onClick={props.handleClickEdit} />}
          </h6>
        </div>

        <Grid container spacing={2} justifyContent="center" style={{ width: '98%', padding: 0, justifyContent: 'space-evenly' }}>
          <EditProject {...props} />
        </Grid>

        <Grid container style={{ width: '92%', margin: '10px 0' }}><SidebarCards {...props} /></Grid>

        {hasProjectPhasePackage && hasPhasePageAccess && (
          <Box style={{ width: '90%', marginTop: '10px', marginBottom: '10px' }}>
            <Dropdown
              selectedItem={props.category}
              setSelectedItem={props.set_category}
              itemList={props.selectedPhaseWithViewPageAccess.map(m => m?.name)}
              size="small"
              Hx="h6"
            />
          </Box>
        )}
        {![313].includes(props.user?.id) && (
          <div className="position-relative">
            {{
              0: hasSeverity && <PieChart isRoundOff details={props.project} type="status" />,
              1: hasSeverity && <PieChart isRoundOff details={props.project} type="severity" />,
            }[props.piechartType]}
            {[0].includes(props.piechartType) && <ChevronRight className="position-absolute pointer" style={{ right: 20, top: '50%', color: 'var(--primary-color)' }} onClick={() => props.setPiechartType(1)} />}
            {[1].includes(props.piechartType) && <ChevronLeft className="position-absolute pointer" style={{ left: 20, top: '50%', color: 'var(--primary-color)' }} onClick={() => props.setPiechartType(0)} />}
          </div>
        )}

        <div
          className="d-flex justify-content-around flex-wrap"
          style={{
            flex: 1, marginBottom: 30, marginRight: 10, marginLeft: 10, marginTop: ![313].includes(props.user?.id) ? 10 : 30,
          }}
        >
          {[
            {
              text: 'Site Event',
              to: `/project/inspection?id=${props.project?.id}`,
              children: <img src={Inspection} style={style.buttonIcon} />,
            },
            {
              text: 'CCTV',
              to: `/project/cctv?id=${props.project?.id}`,
              children: <img src={CCTV} style={style.buttonIcon} />,
              disabled: !props.project.has_cctv,
            },
            {
              text: 'Report',
              to: `/project/report?id=${props.project?.id}`,
              children: <img src={Report} style={style.buttonIcon} />,
            },
            {
              text: 'Document',
              to: `/project/document?id=${props.project?.id}`,
              children: <img src={Documents} style={style.buttonIcon} />,
            },
            {
              text: 'BIM Viewer',
              to: `/project/bim?id=${props.project?.id}`,
              children: <img src={BIM} style={style.buttonIcon} />,
              disabled: true,
              isHide: [313].includes(props.user?.id),
            },
            {
              text: '3D',
              to: `/project/asset/3d?id=${props.project?.id}`,
              children: <img src={Cube3D} style={style.buttonIcon} />,
              disabled: !props.project.has_drone,
            },
          ].filter(f => !f.isHide).map(e => (
            <div>
              <RoundedButton scrollTo="top" scrollTop {...e} onClick={() => props.parentProps.setIsMap(false)} />
            </div>
          ))}
        </div>
      </div>
      {props.prefixLocation.length == 2 && (
        <div className="sidenav-toggle" onClick={props.handleViewSidebar}>
          <ChevronLeft
            style={{
              transform: props.openSidebar ? 'rotateZ(-180deg)' : 'rotateZ(0deg)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 18,
              padding: 2,
            }}
          />
        </div>
      )}
    </Grid>
  );
}
