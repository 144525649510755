import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import {
  Border, VictoryLabel, VictoryLegend, VictoryPie, VictoryAxis,
  VictoryChart,
  VictoryVoronoiContainer,
} from 'victory';
import { sumBy } from '@Helpers';

export default function PieChart({ data, legendList }) {
  const pieData = data ?? [
    { x: 1, y: 120 }, { x: 2, y: 75 },
  ];
  const type = legendList ?? ['Open', 'Closed'];
  const color = ['#d63f1d', '#7fe39d', '#f0ce33', '#FF5722', '#E91E63'];
  const legend = pieData.map((p, idx) => ({ name: `${p.y} ${type[idx]}`, symbol: { fill: color[idx], type: 'circle' } }));

  return (
    <VictoryChart
      padding={{
        left: 10, right: 220, top: 10, bottom: 10,
      }}
      containerComponent={<VictoryVoronoiContainer />}
    >
      <VictoryPie
        colorScale={color}
        animate={{ duration: 2000 }}
        standalone={false}
        width={550}
        height={350}
        data={!!sumBy(pieData, 'y') ? pieData : [{ y: 1 }]}
        innerRadius={80}
        labels={() => null}
        reponsive
      />
      <VictoryLabel
        textAnchor="middle"
        style={{ fontFamily: 'CeraProRegular', fontSize: 50, fontWeight: 600 }}
        x={120}
        y={130}
        text={(sumBy(pieData, 'y')).toLocaleString()}
      />
      <VictoryLabel
        textAnchor="middle"
        style={{ fontFamily: 'CeraProRegular', fontSize: 30, color: '#605f65' }}
        x={120}
        y={170}
        text="total"
      />
      <VictoryLegend
        x={250}
        y={50}
        orientation="horizontal"
        standalone={false}
        itemsPerRow={1}
        gutter={10}
        data={legend}
        style={{ labels: { fontFamily: 'CeraProRegular', fontSize: 25 }, border: { stroke: '#dddce0', rx: '10', ry: '10' } }}
        borderComponent={<Border width="11vw" />}
        borderPadding={{ left: 5, right: 5 }}
      />
      <VictoryAxis style={{
        axis: { stroke: 'transparent' },
        ticks: { stroke: 'transparent' },
        tickLabels: { fill: 'transparent' },
      }}
      />
    </VictoryChart>
  );
}
