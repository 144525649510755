/* eslint-disable linebreak-style */
/* eslint-disable complexity */
import React from 'react';
import Button from '@Components/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import {
  CircularProgress, DialogTitle, Divider, IconButton,
} from '@material-ui/core';
import moment from 'moment';

export default ({
  open, setOpen, selected, setSelected, siteDiaryHistoryDetail, isLoading,
}) => {
  if (!selected) return (<></>);
  const handleClose = () => {
    setSelected();
    setOpen(false);
  };
  const CheckHistoryStatus = (attribute) => {
    return siteDiaryHistoryDetail.filter(e => [attribute].includes(e.status)).sort((a, b) => b.createdAt.localeCompare(a.createdAt))[0];
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose}>
        <DialogTitle className="py-1">
          <div className="d-flex justify-content-between align-items-center">
            Report Status History
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="mx-0">
          {isLoading ? (
            <div className="flex-standard" style={{ height: '30vh' }}>
              <CircularProgress
                size={75}
                style={{
                  color: 'var(--primary-color)',
                }}
              />
            </div>
          ) : (
            <>
              <div className="mb-3">
                <p className="font-weight-bold mt-2" style={{ color: 'black' }}>Date</p>
                <p className="text-muted">{moment(selected.createdAt).format('DD/MM/YYYY')}</p>
              </div>
              {!!siteDiaryHistoryDetail.length ? (
                <>
                  {!!CheckHistoryStatus('Submited') && (
                    <>
                      <Divider className="mb-3" style={{ backgroundColor: 'D9D9D9' }} />
                      <div className="mb-3">
                        <p className="font-weight-bold mt-2" style={{ color: 'black' }}>Prepared By</p>
                        <img
                          src={CheckHistoryStatus('Submited')?.signature}
                          className="py-2"
                          style={{ width: '150px', height: '100px', objectFit: 'scale-down' }}
                        />
                        <p className="font-weight-bolder">{CheckHistoryStatus('Submited')?.UserId}</p>
                        <p className="text-muted">{moment(CheckHistoryStatus('Submited')?.createdAt).format('HH:mm A  DD MMMM YYYY')}</p>
                      </div>
                    </>
                  )}
                  {!!CheckHistoryStatus('Approved') && (
                    <>
                      <Divider className="mb-3" style={{ backgroundColor: 'D9D9D9' }} />
                      <div className="mb-3">
                        <p className="font-weight-bold mt-2" style={{ color: 'black' }}>Reviewed By</p>
                        <img
                          src={CheckHistoryStatus('Approved')?.signature}
                          className="py-2"
                          style={{ width: '150px', height: '100px', objectFit: 'scale-down' }}
                        />
                        <p className="font-weight-bolder">{CheckHistoryStatus('Approved')?.UserId}</p>
                        <p className="text-muted">{moment(CheckHistoryStatus('Approved')?.createdAt).format('HH:mm A  DD MMMM YYYY')}</p>
                      </div>
                    </>
                  )}
                  {!!CheckHistoryStatus('Completed') && (
                    <>
                      <Divider className="mb-3" style={{ backgroundColor: 'D9D9D9' }} />
                      <div className="mb-3">
                        <p className="font-weight-bold mt-2" style={{ color: 'black' }}>Approved By</p>
                        <img
                          src={CheckHistoryStatus('Completed')?.signature}
                          className="py-2"
                          style={{ width: '150px', height: '100px', objectFit: 'scale-down' }}
                        />
                        <p className="font-weight-bolder">{CheckHistoryStatus('Completed')?.UserId}</p>
                        <p className="text-muted">{moment(CheckHistoryStatus('Completed')?.createdAt).format('HH:mm A  DD MMMM YYYY')}</p>
                      </div>
                    </>
                  )}
                  {!!CheckHistoryStatus('Rejected') && (
                    <>
                      <Divider className="mb-3" style={{ backgroundColor: 'D9D9D9' }} />
                      <div className="mb-3">
                        <p className="font-weight-bold mt-2" style={{ color: 'red' }}>Rejected By</p>
                        <img
                          src={CheckHistoryStatus('Rejected')?.signature}
                          className="py-2"
                          style={{ width: '150px', height: '100px', objectFit: 'scale-down' }}
                        />
                        <p className="font-weight-bolder">{CheckHistoryStatus('Rejected')?.UserId}</p>
                        <p className="text-muted">{moment(CheckHistoryStatus('Rejected')?.createdAt).format('HH:mm A  DD MMMM YYYY')}</p>
                      </div>
                      <div className="mb-3">
                        <p className="font-weight-bold mt-2" style={{ color: 'red' }}>Reason for rejection</p>
                        <p className="text-muted">{CheckHistoryStatus('Rejected')?.remark ?? '-'}</p>
                      </div>
                    </>
                  )}
                  <Divider className="mb-3" style={{ backgroundColor: 'D9D9D9' }} />
                </>
              ) : (
                <p className="font-weight-bold mt-2" style={{ color: 'red' }}>No Data Found</p>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions className="mb-1">
          <Button onClick={handleClose}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

const getMuiTheme = () => createTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
});
