/* eslint-disable complexity */
import { removeAsset } from '@Helpers';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
};

const cardCss = {
  backgroundColor: 'white',
  borderRadius: 10,
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  padding: 13,
  position: 'relative',
  height: '100%',
};

const outlinedBluePill = {
  display: 'flex',
  alignItems: 'center',
  borderRadius: 50,
  outline: '#5672cb 2px solid',
  minWidth: '4vw',
  padding: 2,
  justifyContent: 'center',
};

const CardTitle = ({ title }) => (<p style={{ color: '#605f65', fontSize: 12 }}>{title ?? 'Status'}</p>);
const InfoLabel = ({ title, value }) => (
  <p style={{ color: '#605f65', fontSize: 10, marginRight: 10 }}>
    {title ?? 'Status'}<br />
    <span style={{ color: 'black', fontSize: 12 }}>{value}</span>
  </p>
);

export default function index({ asset, assetTypeList }) {
  const assetType = assetTypeList.find(type => type.id === Number(asset.AssetTypeId));
  return (
    <div style={{ ...cardCss }}>
      <div className="text-standard-lg" style={{ ...flexCenter, marginTop: -5 }}>
        Status: &nbsp;&nbsp;&nbsp;
        <div style={outlinedBluePill}>
          <h3 style={{ color: 'grey', fontSize: 12 }}>FULL</h3>
        </div>
      </div>
      <hr />
      <CardTitle title="Site Details" />
      <br />
      <Grid container xs={12} md={12}>
        <Grid container xs={12} md={6}>
          <InfoLabel title="Site Manager" value={asset.project_manager ?? '-'} />
        </Grid>
        <Grid container xs={12} md={6}>
          <InfoLabel title="Contact Number" value={asset.project_manager_phone ?? '-'} />
        </Grid>
        <Grid container xs={12} md={6}>
          <InfoLabel title="Start On" value={moment(asset.commissionAt).format('DD MMMM YYYY') ?? '-'} />
        </Grid>
        <Grid container xs={12} md={6}>
          <InfoLabel title="Completion Date" value={moment(asset.expecteddecommissionAt).format('DD MMMM YYYY') ?? '-'} />
        </Grid>
        <Grid container xs={12} md={6}>
          <InfoLabel title="Site Type" value={removeAsset(assetType?.name)} />
        </Grid>
        <Grid container xs={12} md={6}>
          <InfoLabel title="Province" value={asset.state ?? '-'} />
        </Grid>
        <Grid container xs={12} md={6}>
          <InfoLabel title="Location" value={asset.location ?? '-'} />
        </Grid>
      </Grid>
    </div>
  );
}
