import Button from '@Components/Button';
import { Link } from 'react-router-dom';
import ReportCreatedImg from '@Assets/Images/Report.svg';
import { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

export default (h) => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('@Assets/Icons/failed-lottie.json'),
    });
  }, [h.isLoading]);

  return (
    <div className="w-50 mt-5 mx-auto d-flex flex-column align-items-center" style={{ minHeight: '60vh' }}>
      {h.resultSuccess ? (
        <img src={ReportCreatedImg} style={{ height: 400, width: 600, objectFit: 'cover' }} />
      ) : (
        <div className="text-center mt-0">
          <h1 className="text-dark">Create Failed !</h1>
          <div ref={container} style={{ height: 450, marginTop: -50, marginBottom: -50 }} />
          <h2 className="text-dark">Failed to create NCR report, please retry in a moment !!!</h2>
        </div>
      )}
      <div className="d-flex justify-content-between" style={{ width: '60%', marginTop: !h.resultSuccess && 20 }}>
        <Button onClick={() => window.location.reload(false)}>
          Create New Report
        </Button>
        <Link to={`/project/report?id=${h.AssetId}`}>
          <Button style={{ border: '2px solid black', backgroundColor: 'white', color: 'black' }}>
            Return To Report Page
          </Button>
        </Link>
      </div>
    </div>
  );
};
