import Button from '@Components/Button';
import SignatureDrawer from '@Components/SignaturePad/SignatureDrawer';
import { CircularProgress } from '@material-ui/core';

export default (h) => {
  const isFieldEmpty = !(!!h.signature);
  return (
    <div className="mt-5 mx-auto" style={{ minHeight: '60vh', width: '30%' }}>
      {h.isLoading ? (
        <CircularProgress
          size={75}
          style={{
            color: 'var(--primary-color)', position: 'absolute', top: '50%', left: '50%', marginTop: -30, marginLeft: -35,
          }}
        />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <h2>Report Verification</h2>
          </div>
          <p className="text-secondary" style={{ fontSize: '0.75rem' }}>
            Add your signature to blank box below to verify the report.
          </p>
          <SignatureDrawer setSignature={h.setSignature} />
          <div className="d-flex justify-content-end mt-4">
            <Button variant="text" onClick={h.handleBackStep}>
              PREVIOUS
            </Button>
            <Button onClick={h.CreateReport} disabled={isFieldEmpty}>
              Submit Report
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
