import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import {
  Border, VictoryLabel, VictoryLegend, VictoryPie, VictoryAxis,
  VictoryChart,
  VictoryVoronoiContainer,
} from 'victory';
import { sumBy } from '@Helpers';

export default function PieChart({ data }) {
  const pieData = data ?? [
    { x: 1, y: 120 }, { x: 2, y: 25 },
  ];
  const type = ['Online', 'Error'];
  const color = ['#46cc96', '#fc5a5a'];
  const legend = pieData.map((p, idx) => ({ name: `${type[idx]}`, symbol: { fill: color[idx], type: 'square' } }));

  return (
    <VictoryChart
      padding={{
        left: 30, right: 200, top: 10, bottom: 100,
      }}
      containerComponent={<VictoryVoronoiContainer />}
    >
      <VictoryPie
        colorScale={color}
        standalone={false}
        data={!!sumBy(pieData, 'y') ? pieData : [{ y: 1 }]}
        labels={({ datum }) => datum.y}
        labelComponent={<VictoryLabel />}
        reponsive
        style={{ labels: { fontSize: 18, fill: 'white' } }}
        labelRadius={50}
      />
      <VictoryLabel
        textAnchor="middle"
        style={{ fontSize: 14 }}
        x={140}
        y={250}
        text={`Total CCTV: ${sumBy(pieData, 'y')}`}
      />
      <VictoryLegend
        x={280}
        y={70}
        orientation="horizontal"
        standalone={false}
        itemsPerRow={1}
        gutter={10}
        data={legend}
        style={{ labels: { fontFamily: 'CeraProRegular', fontSize: 14 } }}
        borderPadding={{ left: 5, right: 5 }}
      />
      <VictoryAxis style={{
        axis: { stroke: 'transparent' },
        ticks: { stroke: 'transparent' },
        tickLabels: { fill: 'transparent' },
      }}
      />
    </VictoryChart>
  );
}
