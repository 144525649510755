import {
  Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, InputLabel, TextField,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Button from '@Components/Button';
import Api, { endpoints } from '@Helpers/api';
import { Close, Visibility, VisibilityOff } from '@material-ui/icons';

const ResetPassword = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmHelperText, setConfirmHelperText] = useState('');
  const [currentHelperText, setCurrentHelperText] = useState('');

  const handleMouseDownPassword = (event) => event.preventDefault();

  useEffect(() => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setConfirmHelperText('');
    setCurrentHelperText('');
    setShowCurrentPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
  }, [openDialog]);

  const updatePassword = () => {
    if (!currentPassword.length) return;
    if (!newPassword.length) return;
    if (!confirmPassword.length) return;
    if (currentPassword === newPassword) return setCurrentHelperText('Cannot Use Same Password');
    if (newPassword !== confirmPassword) return setConfirmHelperText('Password Does Not Match');
    setCurrentHelperText('');
    setConfirmHelperText('');
    Api({
      endpoint: endpoints.updateUser(props.user.id),
      data: {
        old_password: currentPassword,
        new_password: newPassword,
      },
      onSuccess: () => {
        setOpenDialog(false);
        toast('success', 'Password successful updated');
      },
      onFail: (error) => {
        setCurrentHelperText(error);
        toast('error', 'Failed update user');
      },
    });
  };

  return (
    <>
      <Button
        size="small"
        onClick={() => setOpenDialog(true)}
        fullWidth
        style={{
          backgroundColor: 'white', color: 'blue', border: '1px solid blue', height: 30,
        }}
      >
        <p style={{ fontSize: 12, color: 'blue', cursor: 'pointer' }}>Change Password</p>
      </Button>
      <Dialog open={openDialog} fullWidth onClose={() => setOpenDialog(false)} maxWidth="sm">
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="font-weight-bold">Change Password</h2>
            <Close className="pointer" onClick={() => setOpenDialog(false)} />
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            <InputLabel>Current Password</InputLabel>
            <TextField
              error={!!currentHelperText}
              helperText={currentHelperText}
              variant="outlined"
              type={showCurrentPassword ? 'text' : 'password'}
              fullWidth
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="mt-4">
            <InputLabel>New Password</InputLabel>
            <TextField
              variant="outlined"
              type={showNewPassword ? 'text' : 'password'}
              fullWidth
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="mt-4">
            <InputLabel>Confirm Password</InputLabel>
            <TextField
              variant="outlined"
              error={!!confirmHelperText}
              helperText={confirmHelperText}
              type={showConfirmPassword ? 'text' : 'password'}
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px', justifyContent: 'space-between', margin: '10px 0px' }}>
          <div style={{ flex: 1 }}>
            <Button
              className="w-75"
              style={{ backgroundColor: 'white', color: 'blue', border: '1px solid blue' }}
              onClick={() => setOpenDialog(false)}
            >
              Cancel
            </Button>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Button className="w-75" onClick={updatePassword}>
              Save Changes
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResetPassword;
