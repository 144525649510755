/* eslint-disable complexity */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import DisciplineOne from '@Assets/Icons/Discipline/One';
import DisciplineTwo from '@Assets/Icons/Discipline/Two';
import DisciplineThree from '@Assets/Icons/Discipline/Three';
import DisciplineFour from '@Assets/Icons/Discipline/Four';
import DisciplineFive from '@Assets/Icons/Discipline/Five';
import DisciplineMore from '@Assets/Icons/Discipline/More';
import RoundedButton from '@Components/RoundedButtonV2';
import moment from 'moment';

export default function Discipline({ value, set_value, ...props }) {
  const xValue = (props.isDrawAnnotation || props.isInspection) ? '0%' : '30%';
  const yValue = (props.isDrawAnnotation || props.isInspection) ? '-25px' : '-35px';
  return (
    <Box
      item
      xs={6}
      md={4}
      className="sidebar mx-auto mb-3"
      style={{
        position: 'relative', top: 100, maxWidth: '39rem', transform: `translate(${xValue}, ${yValue}) scale(0.95)`, visibility: props.open ? 'visible' : 'hidden',
      }}
    >
      <div
        className="p-8"
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          backgroundColor: 'white',
          outline: '1px solid var(--primary-color)',
          borderRadius: 3,
        }}
      >
        <Grid
          container
          spacing={1}
          className="d-flex flex-column py-3"
          style={{ paddingInline: '2rem', minWidth: '100%' }}
        >

          <Typography
            variant="h3"
            className="text-center pb-2"
            style={{
              fontSize: 13, color: 'var(--primary-color)', fontFamily: 'CeraProRegular', fontWeight: 'bold',
            }}
          >
            {moment().format('MMM YYYY')}
          </Typography>

        </Grid>
        <Grid className="w-100" style={{ paddingInline: '2em' }}>
          <div
            className="d-flex justify-content-around flex-wrap"
            style={{
              flex: 1, marginBottom: 30, marginRight: 10, marginLeft: 10, marginTop: ![313].includes(props.user?.id) ? 10 : 30,
            }}
          >
            {[
              {
                id: 1,
                title: 'Civil and Structural',
                children: value.includes(1) ? <DisciplineOne selected /> : <DisciplineOne />,
              },
              {
                id: 2,
                title: 'Architectural',
                children: value.includes(2) ? <DisciplineTwo selected /> : <DisciplineTwo />,
              },
              {
                id: 3,
                title: 'Mechanical',
                children: value.includes(3) ? <DisciplineThree selected /> : <DisciplineThree />,
              },
              {
                id: 4,
                title: 'Electrical',
                children: value.includes(4) ? <DisciplineFour selected /> : <DisciplineFour />,
              },
              {
                id: 5,
                title: 'ICT',
                children: value.includes(5) ? <DisciplineFive selected /> : <DisciplineFive />,
              },
              {
                id: 6,
                title: 'Others',
                children: value.includes(6) ? <DisciplineMore selected /> : <DisciplineMore />,
              },
            ].filter(f => !f.isHide).map(e => (
              <Tooltip title={e.title}>
                <div>
                  <RoundedButton scrollTo="top" scrollTop {...e} onClick={() => set_value(pvalues => (pvalues.includes(e.id) ? pvalues.filter(pv => pv != e.id) : [...pvalues, e.id]))} />
                </div>
              </Tooltip>
            ))}
          </div>
        </Grid>
      </div>
    </Box>
  );
}
