/* eslint-disable linebreak-style */
import React, { useEffect, useState, useRef } from 'react';
import lottie from 'lottie-web';
import Button from '@Components/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

export default ({ open, setOpen, confirmFunction }) => {
  const click = useRef(null);
  const container = useRef(null);
  const [isShow, setIsShow] = useState(true);
  const [doneCycle, setDoneCycle] = useState(false);
  const handleClose = () => { setOpen(false); setDoneCycle(false); setIsShow(true); };
  const handleConfirm = () => {
    confirmFunction();
    handleClose();
  };
  const refresh = () => click.current.click();
  const onShow = () => { if (!isShow) { setIsShow(!isShow); setDoneCycle(true); } };

  useEffect(() => {
    if (!open) return;
    if (doneCycle) return;
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('@Assets/Icons/question-lottie.json'),
    });
    setIsShow(false);
  }, [open, isShow]);

  useEffect(() => {
    if (isShow) return;
    setTimeout(refresh, 200);
  }, [isShow]);

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent className="mx-3">
          <div className="d-flex justify-content-end pointer" onClick={handleClose}><Close /></div>
          <div className="d-flex justify-content-center mx-auto" ref={container} style={{ marginTop: -20, height: '70%', width: '70%' }} />
          <h2 className="text-center mb-2" ref={click} onClick={onShow} style={{ fontSize: 20 }}>Confirm Submission of Form?</h2>
          <DialogContentText align="center">
            Are you sure you want to submit this form?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center mb-1">
          <Button onClick={handleClose} style={{ backgroundColor: 'white', color: 'var(--primary-color)' }}>
            CANCEL
          </Button>
          <Button onClick={handleConfirm}>
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

const getMuiTheme = () => createTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
});
