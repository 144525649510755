/* eslint-disable complexity */
import PieChart from '@Components/PieChart/v2';
import { Hidden, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  ArrowDownward, ArrowRight, ArrowUpward, InfoOutlined,
} from '@material-ui/icons';
import React from 'react';
import { truncateString } from '@Helpers';

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
};

const cardCss = {
  backgroundColor: 'white',
  borderRadius: 10,
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  padding: 13,
  position: 'relative',
  minHeight: 'max-content',
  height: 130,
};

const transparentPill = {
  display: 'flex',
  alignItems: 'center',
  minWidth: '4vw',
  padding: 2,
};

const outlinedBluePill = {
  display: 'flex',
  alignItems: 'center',
  borderRadius: 50,
  outline: '#5672cb 2px solid',
  minWidth: '4vw',
  padding: 2,
  justifyContent: 'center',
};

const rateIssueStyle = {
  display: 'flex',
  alignItems: 'center',
};

const CardTitle = ({ title }) => (<p style={{ color: '#605f65', fontSize: 12 }}>{title ?? 'Status'}</p>);
const CardContent = ({
  withChart, chartData,
  withFraction, fractionData,
  withStatistics, statisticsData,
  withErrorLabel, errorLabel,
  withProjectName, projectName,
  withSegments, segmentsData,
  legendList,
}) => {
  let Content = () => (<></>);
  if (withChart) {
    if (!chartData) console.warn('Dashboard Card Err: withChart attribute require chartData');
    Content = () => <PieChart data={chartData} legendList={legendList} />;
  }
  if (withSegments) {
    if (!segmentsData) console.warn('Dashboard Card Err: withSegments attribute require segemntsData');
    Content = () => (
      <>
        <Hidden only={['xs', 'sm', 'md', 'lg']}>
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center">
              <p style={{ color: 'black', fontWeight: 600, fontSize: 38 }}>{segmentsData[0].y}</p>
              <p style={{ color: '#f0ce33', fontSize: 11, marginLeft: 10 }}>Open</p>
            </div>
            <div className="d-flex align-items-center" style={{ borderRight: '1px solid black', borderLeft: '1px solid black', paddingInline: 10 }}>
              <p style={{ color: 'black', fontWeight: 600, fontSize: 38 }}>{segmentsData[1].y}</p>
              <p style={{ color: '#7fe39d', fontSize: 11, marginLeft: 10 }}>Closed</p>
            </div>
            <div className="d-flex align-items-center">
              <p style={{ color: 'black', fontWeight: 600, fontSize: 38 }}>{segmentsData[2].y}</p>
              <p style={{ color: '#5672cb', fontSize: 11, marginLeft: 10 }}>Pending</p>
            </div>
          </div>
        </Hidden>
        <Hidden only={['xl']}>
          <div className="d-flex justify-content-around w-100">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <p style={{ color: 'black', fontWeight: 600, fontSize: 32 }}>{segmentsData[0].y}</p>
              <p style={{ color: '#f0ce33', fontSize: 11 }}>Open</p>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <p style={{ color: 'black', fontWeight: 600, fontSize: 32 }}>{segmentsData[1].y}</p>
              <p style={{ color: '#7fe39d', fontSize: 11 }}>Closed</p>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <p style={{ color: 'black', fontWeight: 600, fontSize: 32 }}>{segmentsData[2].y}</p>
              <p style={{ color: '#5672cb', fontSize: 11 }}>Pending</p>
            </div>
          </div>
        </Hidden>
      </>
    );
  }
  if (withFraction) {
    if (!fractionData) console.warn('Dashboard Card Err: withFraction attribute require fractionData');
    Content = () => (fractionData ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ color: 'black', fontWeight: 600, fontSize: 40 }}>{fractionData.x}</p>
        <p style={{ color: '#605f65', fontSize: 12, marginLeft: 10 }}>of {fractionData.y} total {fractionData.unit}</p>
      </div>
    ) : (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ color: 'black', fontWeight: 600, fontSize: 40 }}>N/A</p>
      </div>
    ));
  }
  if (withErrorLabel) {
    if (!errorLabel) console.warn('Dashboard Card Err: withErrorLabel attribute require errorLabel');
    Content = () => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ color: 'black', fontWeight: 600, fontSize: 40 }}>{errorLabel.value}</p>
        <p style={{ color: '#cf4956', fontSize: 12, marginLeft: 10 }}>{errorLabel.description}</p>
      </div>
    );
  }
  return (
    <div style={{ height: 100 }}>
      <div style={{ ...flexCenter, justifyContent: 'space-between', height: '100%' }}>
        {withSegments
          ? (<Content />)
          : (
            <>
              <Content />
              {withStatistics ? (
                <div style={statisticsData.rate ? { ...rateIssueStyle } : { ...outlinedBluePill }}>
                  <h3 style={{ color: statisticsData.percent > 0 ? '#5672cb' : '#cf4956', fontSize: 12 }}>{statisticsData.percent}%</h3>
                  {statisticsData.rate && (
                    statisticsData.percent > 0
                      ? <ArrowUpward style={{ fontSize: 12, color: '#5672cb' }} />
                      : <ArrowDownward style={{ fontSize: 12, color: '#cf4956' }} />)}
                </div>
              ) : <div style={{ ...transparentPill }} />}
            </>
          )}
      </div>
    </div>
  );
};
export default function index(props) {
  return (
    <div style={{ ...cardCss }}>
      <div style={{ ...flexCenter }}>
        <CardTitle title={props.title} />
        <Tooltip title="Title text goes here">
          <InfoOutlined style={{ width: 10, color: '#605f65', marginLeft: 5 }} />
        </Tooltip>
      </div>
      <CardContent {...props} />
      {props.withProjectName && (
        <Tooltip title={props.projectName}>
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'flex-start', position: 'absolute', left: 10, bottom: 10,
          }}
          >
            <p style={{ fontSize: 12, fontWeight: 600 }}>{truncateString(props.projectName, 25)}</p>
          </div>
        </Tooltip>
      )}
      {props.seeDetails && (
        <Link
          to={props.seeDetails}
          target={props.isReload ? '_top' : false}
          style={{
            display: 'flex', alignItems: 'center', justifyContent: 'flex-end', position: 'absolute', right: 10, bottom: 10,
          }}
        >
          <p style={{ color: '#605f65', fontSize: 10, cursor: 'pointer' }}>See Details</p>
          <ArrowRight style={{ color: '#605f65', fontSize: 18 }} />
        </Link>
      )}
    </div>
  );
}
