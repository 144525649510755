import React from 'react';
import MUIDataTable from 'mui-datatables';
import { createTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import { truncateString } from '@Helpers';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles({
  centeredHeader: {
    '& span': {
      justifyContent: 'center',
      alignItems: 'center',
      color: '#979797',
      fontSize: 8,
    },
  },
  leftHeader: {
    '& span': {
      justifyContent: 'left',
      alignItems: 'center',
      color: '#979797',
      fontSize: 8,
    },
  },
});

export default ({ tableData, tableType }) => {
  const classes = useStyles();

  const columns = [
    {
      name: 'Site Name',
      selector: 'AssetName',
      options: {
        setCellHeaderProps: () => ({ className: classes.leftHeader }),
        setCellProps: () => ({ className: classes.leftHeader }),
      },
    },
    {
      name: 'Status',
      selector: 'status',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
        sortCompare: (order) => ({ data: data1 }, { data: data2 }) => {
          if (order === 'desc') return data2.props.children.localeCompare(data1.props.children);
          return (data1.props.children.localeCompare(data2.props.children));
        },
      },
    },
    {
      name: 'CCTV ID',
      selector: 'label',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
        display: tableType === 'cctv',
      },
    },
    {
      name: 'Total CCTV',
      selector: 'total_cctv',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
        display: tableType === 'asset',
      },
    },
    {
      name: 'Last Updated',
      selector: 'updatedAt',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
        sortCompare: (order) => ({ data: data1 }, { data: data2 }) => {
          if (order === 'desc') return moment(data2, 'DD/MM/YYYY').diff(moment(data1, 'DD/MM/YYYY'));
          return moment(data1, 'DD/MM/YYYY').diff(moment(data2, 'DD/MM/YYYY'));
        },
      },
    },
  ];

  const options = {
    filter: 'false',
    print: 'false',
    download: 'false',
    sort: 'false',
    viewColumns: 'false',
    search: 'false',
    responsive: 'vertical',
    rowsPerPage: 5,
    rowsPerPageOptions: [5],
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        options={options}
        columns={columns.map((col) => ({ ...col, label: col.name, name: col.selector }))}
        data={tableData.map((x) => {
          return ({
            ...x,
            AssetName: (
              <Tooltip title={x.AssetName} placement="top-start">
                <p style={{ color: 'rgba(30, 52, 101)' }}>{truncateString(x.AssetName, 30)}</p>
              </Tooltip>),
            status: <p style={{ color: 'red' }}>{!!(typeof x.status == 'number') ? 'Error' : x.status}</p>,
            cctv_id: x.cctv_id,
            total_cctv: x.total_cctv,
            updatedAt: moment(x.updatedAt).format('DD/MM/YYYY'),
          });
        })}
      />
    </MuiThemeProvider>
  );
};

const getMuiTheme = () => createTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
  overrides: {
    MUIDataTableToolbar: {
      root: {
        display: 'none',
      },
    },
    MUIDataTableSelectCell: {
      root: {
        display: 'none',
      },
    },
    MUIDataTableBodyCell: {
      root: {
        paddingLeft: 10,
        fontSize: '10px',
        fontWeight: 500,
      },
    },
    MUIDataTableHeadRow: {
      root: {
        borderBottom: '2px solid #e5e5e5',
      },
    },
    MUIDataTableBodyRow: {
      root: {
        borderBottom: '1px solid #e5e5e5',
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: '0px 0px 0px 0px',
        width: '100%',
      },
    },
    MuiTableHead: {
      root: {
        '& .MuiTableHead-root': {
          color: '#000000DE',
          fontSize: 14,
          fontWeight: 500,
          verticalAlign: 'middle',
        },
        '& .MuiTableCell-root': {
          color: '#979797',
          fontSize: 14,
          fontWeight: 500,
          verticalAlign: 'middle',
          paddingLeft: '10px !important',
        },
      },
    },
    MUIDataTableHeadCell: {
      data: {
        fontSize: 10,
      },
      toolButton: {
        margin: '0px !important',
      },
      sortLabelRoot: {
        display: 'none',
      },
    },
    MuiTablePagination: {
      toolbar: {
        transform: 'scale(0.8)',
        marginTop: -12,
      },
    },
  },
});
