import Button from '@Components/Button';
import Dropzone from '@Components/DropzoneBox';
import { IconButton, InputLabel, Tooltip } from '@material-ui/core';
import { Delete, Restore } from '@material-ui/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';

const InputText = ({ text, ...props }) => (
  <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }} {...props}>
    {text}
  </InputLabel>
);

export default (h) => {
  return (
    <div className="w-75 mt-5 mx-auto" style={{ minHeight: '60vh' }}>
      <InputText className="mt-4 mb-1" text="Saved Image" />
      <div
        className={`p-2 ${!h.savedImage.length && 'flex-standard'}`}
        style={{
          border: '1px solid black',
          borderRadius: 10,
          maxHeight: '30vh',
          minHeight: '10vh',
          overflowY: 'auto',
        }}
      >
        {!!h.savedImage.length ? (
          h.savedImage.map(e => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 1 }} key={e.path}>
                <Link to={{ pathname: `${process.env.REACT_APP_S3}/${e.path}` }} target="_blank">
                  <img
                    src={`${process.env.REACT_APP_S3}/${e.path}`}
                    style={{
                      width: '100%',
                      height: '100px',
                      objectFit: 'contain',
                    }}
                    alt="uploaded"
                  />
                </Link>
              </div>
              <div style={{
                flex: 2, display: 'flex', flexDirection: 'row', marginLeft: 5,
              }}
              >
                <p style={{ fontSize: '0.9em', textDecoration: h.filesDeleted.includes(e.id) && 'line-through' }} className="flex-standard">
                  Saved Date: {moment(e.createdAt).format('DD MMM YYYY hh:mm A')}
                </p>
              </div>
              <div style={{ flex: 1 }} className="flex-standard">
                {h.filesDeleted.includes(e.id) ? 'Will Be Deleted' : 'Saved'}
              </div>
              <div style={{ flex: 1 }} className="flex-standard">
                {h.filesDeleted.includes(e.id) ? (
                  <IconButton
                    onClick={() => h.setFilesDeleted(prev => prev.filter(f => f !== e.id))}
                    style={{ flex: 1, backgroundColor: 'transparent' }}
                  >
                    <Tooltip title="Restore">
                      <Restore style={{ color: 'green' }} />
                    </Tooltip>
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => h.setFilesDeleted(prev => [...prev, e.id])}
                    style={{ flex: 1, backgroundColor: 'transparent' }}
                  >
                    <Tooltip title="Delete">
                      <Delete style={{ color: 'red' }} />
                    </Tooltip>
                  </IconButton>
                )}
              </div>
              <hr />
            </div>
          ))
        ) : 'No Image Saved'}
      </div>
      <InputText className="mt-4 mb-1" text="Upload Image" />
      <Dropzone type="image" files={h.files} setFiles={h.setFiles} height="30vh" hasButton={false} />
      <div className="d-flex justify-content-end mt-4">
        <Button variant="text" onClick={h.handleBackStep}>
          PREVIOUS
        </Button>
        <Button onClick={h.handleNextStep} >
          NEXT
        </Button>
      </div>
    </div>
  );
};
