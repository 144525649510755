import Button from '@Components/Button';
import { Grid, InputLabel, TextField } from '@material-ui/core';
import moment from 'moment';
import { Link } from 'react-router-dom';

export default (h) => {
  return (
    <div className="mt-5 mx-auto" style={{ minHeight: '60vh', width: '30%' }}>
      <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Date</InputLabel>
      <p>{moment(h.reportDate).format('DD/MM/YYYY')}</p>
      <InputLabel className="mt-3" style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Cause of Non-Conformance</InputLabel>
      <TextField
        className="w-100 mt-1"
        multiline
        minRows={4}
        maxRows={4}
        value={h.causeNonConformance}
        variant="outlined"
        InputProps={{
          readOnly: true,
        }}
      />
      <InputLabel className="mt-3" style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Corrective Action</InputLabel>
      <TextField
        className="w-100 mt-1"
        multiline
        minRows={4}
        maxRows={4}
        value={h.correctiveAction}
        variant="outlined"
        InputProps={{
          readOnly: true,
        }}
      />
      <InputLabel className="mt-3" style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Image</InputLabel>
      <p>{h.filesImage.length} images uploaded</p>
      <Grid container spacing={2} className="my-1">
        {!h.filesImage.length ? (
          <Grid xs={12} item>
            No filesImage
          </Grid>
        ) : (
          h.filesImage.map(e => (
            <Grid xs={2} item>
              <Link to={{ pathname: e.preview }} target="_blank">
                <img src={e.preview} style={{ width: '100%', height: '100%' }} />
              </Link>
            </Grid>
          ))
        )}
      </Grid>
      <InputLabel className="mt-3" style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>File</InputLabel>
      <p>{h.files.length} items selected</p>
      <Grid container spacing={2} className="my-1">
        {!!h.files.length && (
          h.files.map(e => (
            <>
              <Grid xs={4} item>
                <Link to={{ pathname: e.previewObject }} target="_blank">
                  <img src={e.preview} style={{ width: 100, height: 100 }} />
                </Link>
              </Grid>
              <Grid xs={8} item className="d-flex" alignItems="center">
                {e.name}
              </Grid>
            </>
          ))
        )}
      </Grid>
      <div className="d-flex justify-content-end mt-4">
        <Button variant="text" onClick={h.handleBackStep}>
          PREVIOUS
        </Button>
        <Button onClick={h.handleNextStep}>
          NEXT
        </Button>
      </div>
    </div>
  );
};
