import {
  Dialog, DialogTitle, DialogContent, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Clear';
import Panorama from '@Components/Panorama';

import PanoramicIcon from '@Assets/Images/Panorama-icon.png';

export default ({ selectedImage, ...h }) => (
  <>
    {!!selectedImage && (
      <div
        onClick={() => h.setOpenModal(true)}
        style={{
          padding: 10,
          borderRadius: 10,
          backgroundColor: 'rgba(44, 36, 0, 0.5)',
          position: 'absolute',
          right: 30,
          bottom: 90,
          cursor: 'pointer',
        }}
      >
        <img src={PanoramicIcon} height={25} style={{ filter: 'invert(100%)' }} />
      </div>
    ) }
    {h.openModal && (
      <Dialog
        open={h.openModal}
        onClose={() => h.setOpenModal(false)}
        maxWidth="xl"
      >
        <DialogTitle>
          <h2 className="font-weight-bold" style={{ minWidth: '35vh' }}>Panoramic Viewer</h2>
          <IconButton
            onClick={() => h.setOpenModal(false)}
            style={{ right: -0, top: -0, position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="w-100 mb-3">
            <Panorama is360View={false} imageUrl={`https://raise-supervision.s3.ap-southeast-1.amazonaws.com/${selectedImage}`} />
          </div>
        </DialogContent>
      </Dialog>
    )}
  </>
);
