import { initialsFromUser, isValidHttpUrl } from '@Helpers';
import { Tooltip } from '@material-ui/core';
import React from 'react';

const avstyle = {
  width: '2em',
  height: '2em',
  borderRadius: '50%',
  fontSize: '12px',
  backgroundColor: '#506288',
  color: 'white',
  objectFit: 'cover',
  paddingBottom: '1px',
  marginLeft: 'auto',
  marginRight: 'auto',
  boxShadow: '0.5px 0.5px 4px 1px rgba(0,0,0,0.1)',
};

const UserIcon = (props) => {
  if (!props.User?.image) {
    return (
      <Tooltip title={props.pilot_name}>
        <div className="flex-standard" style={avstyle}>{initialsFromUser({ name: props.pilot_name })}</div>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={props.pilot_name}>
      <img
        src={isValidHttpUrl(props.User.image)
          ? props.User.image
          : `${process.env.REACT_APP_S3}/${props.User.image}`}
        style={avstyle}
      />
    </Tooltip>
  );
};

export default UserIcon;
