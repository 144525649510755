import React from 'react';
import { Grid } from '@material-ui/core';
import AssetStorage from './AssetStorage';
import Hook from './hook';
import Sidebar from './Sidebar';

export default (props) => {
  const h = Hook(props);
  return (
    <Grid container>
      <Sidebar {...h} />
      <AssetStorage {...h} user={props.user} />
    </Grid>
  );
};
