/* eslint-disable complexity */
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import moment from 'moment';
import _ from 'lodash';

const ChartLegend = [
  {
    key: 'raining',
    name: 'Raining',
    tooltip: 'i-Supervision uses on-site IOT sensors, or weather public server services, to determine the current raining condition.',
    type: 'bar',
    formulae: (d) => d.filter(fd => !!fd.rain_intensity).length,
  },
  {
    key: 'person_max',
    name: 'Manpower (Max)',
    tooltip: 'i-Supervision captures the highest number of workers present during the day using CCTV.',
    type: 'line',
    formulae: (d) => (_.maxBy(d, 'person_count')).person_count,
  },
  {
    key: 'person_avg',
    name: 'Manpower (Avg)',
    tooltip: 'Excluding break hours, i-Supervision calculates the average number of workers present during the working day.',
    type: 'line',
    formulae: (d) => _.meanBy(d, 'person_count'),
  },
  {
    key: 'person_no_helmet',
    name: 'Non Compliance',
    tooltip: 'i-Supervision detects non-compliance of workers by not wearing safety helmets.',
    type: 'line',
    formulae: (d) => (_.maxBy(d, 'person_no_helmet_count')).person_no_helmet_count,
  },
];
const groupByDay = fd => moment(fd.captureAt).format('DD/MM/YY');
const groupByMonth = fd => moment(fd.captureAt).format('MMM YYYY');
const groupByWeek = fd => moment(fd.captureAt).format('WW');

export default function Hook({ project, user }) {
  const projectDurationInWeeks = moment(project.expecteddecommissionAt).format('MM') - moment(project.commissionAt).format('MM');
  const [loadingTable, setLoadingTable] = useState(false);
  const [footage_detection, set_footage_detection] = useState([]);
  const [footage_detection_filtered, set_footage_detection_filtered] = useState(null);
  const [chartData, set_chartData] = useState([]);
  const [page, setPage] = useState(1);
  const [duration, setDuration] = useState(6);
  const [detectionLevel, setDetectionLevel] = useState(1);
  const [filterType, setFilterType] = useState('days');
  const [hasLabel, setHasLabel] = useState(false);
  const perpage = 10;

  const filterTime = [
    { value: 6, label: 'Weekly' },
    { value: 12, label: 'Monthly' },
  ];
  if ([313, 225].includes(user?.id)) {
    filterTime.push(...[
      { value: 323, label: '01/03/2023 - 01/04/2023' },
      { value: 423, label: '01/04/2023 - 01/05/2023' },
      { value: 523, label: '01/05/2023 - 01/06/2023' },
      { value: 3523, label: '01/03/2023 - 01/06/2023' },
      { value: projectDurationInWeeks, label: 'Project Start - Project End' },
    ]);
  }

  const getCCTVFootage = (CctvIdList) => {
    Api({
      endpoint: endpoints.getCCTVFootage(),
      data: {
        detection: detectionLevel, disablePagination: 1, sortby: 'id,DESC', CctvId: CctvIdList.join(','),
      },
      onSuccess: (res) => {
        set_footage_detection(res.data);
        onChangeDuration(res.data);
        setLoadingTable(false);
      },
      onFail: (err) => { toast('error', err); setLoadingTable(false); },
    });
  };
  const onClickPlot = (e) => {
    let dateFormat = 'DD/MM/YY';
    if (duration == 12) dateFormat = 'MMM YYYY';
    const fds = footage_detection.filter(fd => moment(fd.captureAt).format(dateFormat) == e);
    set_footage_detection_filtered(fds);
    // set_footage_detection_filtered([e.data])
  };
  const onClickClear = () => set_footage_detection_filtered(null);
  useEffect(() => {
    if (!project?.id) return;
    setLoadingTable(true);
    Api({
      endpoint: endpoints.getCCTV(),
      data: { AssetId: project.id },
      onSuccess: ({ data }) => {
        const result = data.map(({ id }) => String(id));
        getCCTVFootage(result, project.id);
      },
      onFail: (err) => { toast('error', err); setLoadingTable(false); },
    });
  }, [project, detectionLevel, duration]);
  const onChangeDuration = (data) => {
    let startDate;
    let endDate;
    let dataGroup = groupByDay;
    if (duration == 12) { // monthly
      setFilterType('months');
      dataGroup = groupByMonth;
      setHasLabel(false);
    }
    if (duration == 323) {
      startDate = '2023-03-01';
      endDate = '2023-04-01';
      dataGroup = groupByDay;
      setFilterType('days');
      setHasLabel(false);
    }
    if (duration == 423) {
      startDate = '2023-04-01';
      endDate = '2023-05-01';
      dataGroup = groupByDay;
      setFilterType('days');
      setHasLabel(false);
    }
    if (duration == 523) {
      startDate = '2023-05-01';
      endDate = '2023-06-01';
      dataGroup = groupByDay;
      setFilterType('days');
      setHasLabel(false);
    }
    if (duration == 3523) {
      startDate = '2023-03-01';
      endDate = '2023-06-01';
      dataGroup = groupByMonth;
      setFilterType('months');
      setHasLabel(false);
    }
    if (duration === projectDurationInWeeks) {
      startDate = moment(project.commissionAt).format('YYYY-MM-DD');
      endDate = moment(project.expecteddecommissionAt).format('YYYY-MM-DD');
      dataGroup = groupByWeek;
      setFilterType('weeks');
      setHasLabel(true);
    }
    let filteredData = data.filter(fd => moment(fd.captureAt) > moment().subtract(duration, filterType));
    if (startDate && endDate) {
      filteredData = data.filter(fd => moment(fd.captureAt).isBetween(startDate, endDate, null, '[]'));
    }
    const groupedData = _.groupBy(filteredData, dataGroup);
    const gdKey = [duration].includes(filterTime[6].value) ? Object.keys(groupedData) : Object.keys(groupedData).reverse();

    const cData = ChartLegend.map(cl => {
      const cldata = gdKey.map(x => {
        const y = cl.formulae(groupedData[x]);
        return { x, y };
      });
      return { ...cl, data: cldata };
    });
    set_chartData(cData);
  };

  return {
    footage_detection,
    footage_detection_filtered,
    pagination: {
      perpage,
      page,
      setPage,
    },
    loadingTable,
    duration,
    setDuration,
    onClickPlot,
    onClickClear,
    chartData,
    projectDurationInWeeks,
    filterType,
    hasLabel,
    filterTime,
  };
}
