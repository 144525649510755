/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import {
  Grid, Paper, Typography, makeStyles, Button, TextField,
} from '@material-ui/core';
import moment from 'moment';
import HighlightTabs from '@Components/HighlightTabs';
import FormAnnotation from './FormAnnotation';

const useStyles = makeStyles(() => ({
  cardHeader: { fontSize: '14px', color: 'var(--dark-color)' },
  cardDetail: { fontSize: '10px', color: 'var(--dark-color)' },
  saveButton: { color: '#FFFFFF', backgroundColor: 'var(--secondary-color)', fontFamily: 'CeraProRegular' },
  editButton: { color: '#FFFFFF', backgroundColor: 'green', fontFamily: 'CeraProRegular' },
  cancelButton: { color: '#FFFFFF', backgroundColor: 'red', fontFamily: 'CeraProRegular' },
}));

export default function ActionBar({
  inspection_module, mainImage, mainImageAnnotations, setMainImageAnnotations, mainAnnotationId, saveImage, isDeveloper, severity, user, disciplineList, updateInspectionFileActivity,
}) {
  const [tab, setTab] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [activity, setActivity] = useState(mainImage['activity'] ?? '');
  const [address, setAddress] = useState(mainImage['address'] ?? '');
  const classes = useStyles();
  let userCanEditAnnotation = false;
  if (!mainImage['Inspection.InspectionCategoryId']) userCanEditAnnotation = !!user?.can_annotate;
  if (mainImage['Inspection.ProjectPhaseId'] === 1) userCanEditAnnotation = !!user?.can_edit_planning;
  if (mainImage['Inspection.ProjectPhaseId'] === 2) userCanEditAnnotation = !!user?.can_edit_development;
  if (mainImage['Inspection.ProjectPhaseId'] === 3) userCanEditAnnotation = !!user?.can_edit_construction;
  if (mainImage['Inspection.ProjectPhaseId'] === 4) userCanEditAnnotation = !!user?.can_edit_om;
  if (mainImage['Inspection.ProjectPhaseId'] === 5) userCanEditAnnotation = !!user?.can_edit_decommission;

  const submitInspectionFile = () => {
    if (!mainImage.id) return;
    updateInspectionFileActivity({ id: mainImage.id, data: { activity, address } });
    setIsEdit(false);
  }

  useEffect(() => {
    setActivity(mainImage['activity'] ?? '');
    setAddress(mainImage['address'] ?? '');
    setIsEdit(false);
  }, [mainImage.id]);

  const MediaHeader = () => (
    <Typography gutterBottom className={classes.cardHeader} style={{ display: 'flex', marginTop: 10 }}>
      <div style={{ flex: 5 }}>
        General Info {isDeveloper && `(${mainImage.id})`} &nbsp;
      </div>
    </Typography>
  );
  return (
    <div className="paper shadow overflow-auto hide-scroll h-100 px-3" style={{ backgroundColor: 'var(--container-color)' }}>
      <MediaHeader />
      <Paper variant="outlined" style={{ padding: 10 }}>
        <Typography gutterBottom className={classes.cardDetail}>
          {/* <strong>Filename:</strong> {mainImage.name} <br /> */}
          <strong>Date:</strong> {moment(mainImage.createdAt).format('D MMMM YYYY')} <br />
          <strong>Report ID:</strong> {mainImage['Inspection.id']} <br />
          <strong>Report Name:</strong> {mainImage['Inspection.name']} <br />
          <strong>Discipline Name:</strong> {disciplineList.find(e => e.id === mainImage['DisciplineId'])?.name ?? '-'} <br />
          <div className='d-flex align-items-center'>
            <strong>Activity:&nbsp;</strong>
            {isEdit ? (
              <TextField
                value={activity}
                onChange={(e) => setActivity(e.target.value)}
                placeholder="Activity here"
                variant="outlined"
                size='small'
                style={{ paddingLeft: 10, width: '100%', backgroundColor: 'white', borderRadius: '5px' }}
                inputProps={{ style: { fontSize: '8px' } }}
                InputLabelProps={{ style: { fontSize: '10px' } }}
              />
            ) : activity}
          </div>
          <div className='d-flex align-items-center'>
            <strong>Location:&nbsp;</strong>
            {isEdit ? (
              <TextField
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Location here"
                variant="outlined"
                size='small'
                style={{ paddingLeft: 5, width: '100%', backgroundColor: 'white', borderRadius: '5px' }}
                inputProps={{ style: { fontSize: '8px' } }}
                InputLabelProps={{ style: { fontSize: '10px' } }}
              />
            ) : address}
          </div>
          <div className="d-flex justify-content-end mt-2">
            {isEdit ? (
              <>
                <Button variant="contained" style={{ height: 30 }} className={`my-2 + ${classes.cancelButton}`} onClick={() => setIsEdit(false)}>
                  Cancel
                </Button>
                <Button variant="contained" style={{ height: 30 }} className={`my-2 ml-2 + ${classes.saveButton}`} onClick={() => submitInspectionFile()}>
                  Save Changes
                </Button>
              </>
            ) : (
              <Button variant="contained" style={{ height: 30 }} className={`my-2 + ${classes.editButton}`} onClick={() => setIsEdit(true)}>
                Edit
              </Button>
            )}
          </div>
          {/* <strong>Inspections Category:</strong> &nbsp;
                  {inspectionCategoryOptions.find(({ id }) => id === mainImage['Inspection.InspectionCategoryId'])?.name ?? 'OSH'}
                  <br /> */}
        </Typography>
      </Paper>
      {!!mainImageAnnotations.length && (
        <>
          <HighlightTabs
            items={[{ label: 'Active', value: 0 }, { label: `All (${mainImageAnnotations.length})`, value: 1 }]}
            tab={tab}
            setTab={setTab}
            customStyle={{ fontSize: '10px', minWidth: '50%', minHeight: '20px' }}
          />
          <br />
          <Grid container spacing={1} style={{ height: '50vh', overflow: 'auto' }}>
            {mainImageAnnotations.map((annotation) => {
              const isselected = !!(mainAnnotationId === annotation.id);
              if (!!!tab && !isselected) return;
              return (
                <FormAnnotation
                  mainImage={mainImage}
                  annotation={annotation}
                  isselected={isselected}
                  setMainImageAnnotations={setMainImageAnnotations}
                  mainImageAnnotations={mainImageAnnotations}
                  isDeveloper={isDeveloper}
                  severity={severity}
                  inspection_module={inspection_module}
                />
              );
            })}
          </Grid>
        </>
      )}
      {userCanEditAnnotation && (
        <div className="d-flex justify-content-end mt-2">
          <Button variant="contained" style={{ height: 30 }} className={`my-2 + ${classes.saveButton}`} onClick={() => saveImage()}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
}
