/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid, CircularProgress, Box,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import Table from '@Components/MaterialTable/v4';
import Navbar from '@Components/Navbar';
import DeleteDialog from '@Components/DeleteDialog';
import moment from 'moment';
import DataNotFoundImg from '@Assets/Images/Data-not-found3.svg';
import HighlightTabs from '@Components/HighlightTabs';
import MainContentContainer from '@Components/MainContentContainer';
import Hook from './hook';
import ActionSection from './component/ActionSection';

const columns = [
  {
    name: 'ID',
    selector: 'id',
    align: 'center',
  },
  {
    name: 'DRAWING TITLE',
    selector: 'title',
    align: 'left',
    minWidth: 240,
  },
  {
    name: 'DRAWING DATE',
    selector: 'date',
    align: 'left',
    minWidth: 150,
  },
  {
    name: 'VERSION',
    selector: 'version',
    align: 'center',
  },
  {
    name: 'REMARKS',
    selector: 'remark',
    align: 'center',
  },
  {
    name: 'ACTION',
    selector: 'actions',
    align: 'center',
  },
];

export default function Document(props) {
  const h = Hook(props);
  const [data, setData] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(false);
  const [open, setOpen] = useState(false);

  const tabslist = [
    { label: 'SITE DRAWING', value: 'drawing' },
  ];


  useEffect(() => {
    setData(h.documents.map(docs => ({
      ...docs,
      id: <p style={{ fontSize: 12, paddingInline: 10 }}>{docs.id}</p>,
      title: <p style={{ fontSize: 12, paddingInline: 10 }}>{docs.name}</p>,
      date: <div style={{ fontSize: 12 }}>{moment(docs.createdAt).format('DD/MM/YYYY')}</div>,
      version: <p style={{ fontSize: 12, paddingInline: 10 }}>{docs.version}</p>,
      remark: <p style={{ fontSize: 12, paddingInline: 10 }}>{docs.remark}</p>,
      actions: <ActionSection {...docs} />,
    })));
  }, [h.documents, h.tab]);
  return (
    <MainContentContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 7 }}>
        <Navbar
          to="/project/"
          text="Document"
          subtext={props.filtered_projects[props.selected_project]?.name}
        />
        <Link to={`/document/${props.project.id}`}>
          <Box
            component="button"
            className="blue-button"
            style={{ borderRadius: '6px', width: '210px' }}
          >
            <h1 className="flex-standard" style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 16 }}>
              <Add />
              Add New Drawing
            </h1>
          </Box>
        </Link>

      </div>
      <HighlightTabs items={tabslist} tab={h.tab} setTab={h.setTab} />
      <DeleteDialog
        open={open}
        setOpen={setOpen}
        selected={selectedDocument}
        setSelected={setSelectedDocument}
        deleteFunction={() => h.onDelete(selectedDocument.id)}
      />
      {h.isLoading ? (
        <CircularProgress
          size={75}
          className="position-absolute"
          style={{
            top: '40%', left: '60%', marginTop: -20, marginLeft: 0, color: 'var(--primary-color)',
          }}
        />
      ) : (
        <Grid
          item
          xs={12}
          style={{
            paddingLeft: 0, paddingRight: 20, paddingTop: 20, paddingBottom: 20,
          }}
        >
          {!!data?.length ? (
            <Table
              tableHead
              columns={columns}
              tableData={props.data ?? data}
              tableMinWidth={300}
              tableMaxHeight={900}
            />
          ) : (
            <div className="d-flex justify-content-center">
              <img src={DataNotFoundImg} style={{ width: '30vw' }} />
            </div>
          )}
        </Grid>
      )}
    </MainContentContainer>
  );
}
