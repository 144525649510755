import * as React from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
} from '@material-ui/core';
import { Check, Close, Remove } from '@material-ui/icons';

const columns = [
  { id: 'siteName', label: '       ', minWidth: 220 },
  { id: 'Jan', label: 'Jan', minWidth: 50 },
  { id: 'Feb', label: 'Feb', minWidth: 50 },
  { id: 'Mar', label: 'Mar', minWidth: 50 },
  { id: 'Apr', label: 'Apr', minWidth: 50 },
  { id: 'May', label: 'May', minWidth: 50 },
  { id: 'Jun', label: 'Jun', minWidth: 50 },
  { id: 'Jul', label: 'Jul', minWidth: 50 },
  { id: 'Aug', label: 'Aug', minWidth: 50 },
  { id: 'Sep', label: 'Sep', minWidth: 50 },
  { id: 'Oct', label: 'Oct', minWidth: 50 },
  { id: 'Nov', label: 'Nov', minWidth: 50 },
  { id: 'Dec', label: 'Dec', minWidth: 50 },
];
export default function v11(props) {
  const rows = props.tableData;

  // const isEven = (num) => (num % 2 === 0);

  const conditioningCellValue = (value) => {
    if (typeof value === 'undefined') return <Remove />;
    if (typeof value !== 'number') return value;
    // if (value === 0) return <Remove />;
    if (value === 0) return <Close style={{ color: 'red' }} />;
    // return isEven(value)
    //   ? <Check style={{ color: 'green' }} />
    //   : <Close style={{ color: 'red' }} />;
    return <Check style={{ color: 'green' }} />;
  };
  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2}>
              <p style={{ fontWeight: 600 }}>Site Name</p>
            </TableCell>
            <TableCell align="center" colSpan={12}>
              <p style={{ fontWeight: 600 }}>Month</p>
            </TableCell>
          </TableRow>
          <TableRow>
            {columns?.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ bottom: 50, minWidth: '100%', border: '1px solid black', fontFamily: 'CeraProRegular' }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align} style={{ fontFamily: 'CeraProRegular' }}>
                        {conditioningCellValue(value)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
