import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { db } from '@Configs/firebase';
import { ref, update, onChildChanged } from 'firebase/database';
import moment from 'moment';

export default function Hook(props) {
  // if project change / tab change
  const [cctvs, set_cctvs] = useState([]);
  const [selected_cctv, set_selected_cctv] = useState(0);

  // after change / toggle cctv dropdown / initial dropdown
  const [footage, set_footage] = useState([]);
  const [footage_detection, set_footage_detection] = useState([]);
  const [projectTimelineTaskAsset, setProjectTimelineTaskAsset] = useState(null);
  const [current_slider_key, set_current_slider_key] = useState();

  // image download dialog
  const [openModal, set_openModal] = useState(false);
  const [openPanoramicModal, set_openPanoramicModal] = useState(false);
  const [isImageLoading, set_isImageLoading] = useState(false);
  const [currentImage, set_currentImage] = useState({});

  const getNextPageFootage = (CctvId, page) => {
    Api({
      endpoint: endpoints.getCCTVFootage(),
      data: {
        CctvId, detection: 1, page: page ?? 1, sortby: 'id,DESC',
      },
      onSuccess: (resp) => set_footage_detection(resp),
      onFail: (err) => toast('error', err),
    });
  };
  const getCCTVFootage = (CctvId) => {
    Api({
      endpoint: endpoints.getCCTVFootage(),
      data: { CctvId, disablePagination: 1 },
      onSuccess: ({ data }) => {
        data.sort((a, b) => new Date(a.captureAt) - new Date(b.captureAt));
        const filteredData = data.filter(f => !f.detection && !!f.path);
        set_footage(filteredData.length ? filteredData : []);
      },
      onFail: (err) => toast('error', err),
    });
    getNextPageFootage(CctvId, 1);
  };

  const getLatestCCTVFootage = () => {
    update(ref(db, 'cctv/'), { [cctvs[selected_cctv].id]: { status: 1 } })
      .then(() => { console.log('Trigger current footage'); })
      .catch((e) => { console.log('error', e); });
  };

  const handleCloseModal = async (footageId) => {
    if (footageId) {
      Api({
        endpoint: endpoints.deleteCCTVFootage(footageId),
        onSuccess: () => console.log('Successful delete image'),
        onFail: (err) => console.log(`Error: ${err}`),
      });
    }
    await update(ref(db, 'cctv/'), { [cctvs[selected_cctv].id]: { status: 0 } })
      .then(() => { console.log('Set status 0'); })
      .catch((e) => { console.log('error', e); });
    set_openModal(false);
    getCCTVFootage(cctvs[selected_cctv].id);
  };

  const handleImageDownload = (imageLink) => {
    const imageUrl = `${process.env.REACT_APP_S3}/${imageLink}`;
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(xhr.response);
      a.download = `${imageLink}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    xhr.open('GET', imageUrl);
    xhr.send();
  };

  useEffect(() => {
    onChildChanged(ref(db, 'cctv'), (data) => {
      if (!cctvs[selected_cctv]?.id) return;
      if (data.key == cctvs[selected_cctv].id) {
        set_isImageLoading(false);
        set_currentImage(data.val());
        return;
      }
    });
  }, [cctvs[selected_cctv]]);

  useEffect(() => {
    if (!props.projects.length) return;
    if (!props.project.id) return;
    set_selected_cctv(0);
    const param = {
      AssetId: props.project.id,
    };
    Api({
      endpoint: endpoints.getCCTV(),
      data: param,
      onSuccess: ({ data }) => {
        if (data.length) {
          set_cctvs(data);
          getCCTVFootage(data[0].id);
        } else {
          set_cctvs([]);
          set_footage([]);
          set_footage_detection([]);
        }
      },
      onFail: (err) => toast('error', err),
    });
  }, [props.project]);

  const getProjectTimeline = () => {
    if (!props.project.id) return;
    Api({
      endpoint: endpoints.getProjectTimelineFile(),
      data: { AssetId: props.project.id },
      onSuccess: ({ data }) => {
        setProjectTimelineTaskAsset(data);
      },
      onFail: (err) => toast('error', err),
    });
  };

  useEffect(() => {
    if (!!cctvs.length) getCCTVFootage(cctvs[selected_cctv].id);
  }, [selected_cctv]);

  useEffect(() => {
    getProjectTimeline();
  }, [props.project.id]);

  // When on demand cctv footage is display but tab on close/refresh
  useEffect(() => {
    return () => {
      if (!currentImage.id && !isImageLoading) return;
      window.addEventListener('beforeunload', () => {
        if (!isImageLoading) {
          handleCloseModal(currentImage.id);
          return;
        }
      });
    };
  });

  return {
    cctvs,
    selected_cctv,
    set_selected_cctv,
    footage,
    footage_detection,
    getNextPageFootage,
    projectTimelineTaskAsset,
    setProjectTimelineTaskAsset,
    current_slider_key,
    set_current_slider_key,
    getLatestCCTVFootage,
    getCCTVFootage,
    handleCloseModal,
    handleImageDownload,
    isImageLoading,
    set_isImageLoading,
    openModal,
    set_openModal,
    openPanoramicModal,
    set_openPanoramicModal,
    currentImage,
  };
}
