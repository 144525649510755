import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Button from '@Components/Button';
import { Link } from 'react-router-dom';
import { formatBytes } from '@Helpers';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: { maxWidth: '100%', margin: '0 2px', minHeight: '100%' },
  media: { height: '30vh' },
  title: { fontWeight: 'bold', fontSize: 18, fontFamily: 'CeraProRegular' },
  content: { color: '#707070', fontSize: 12, fontFamily: 'CeraProRegular' },
  deleteButtonContent: { right: 15, top: '30%' },
  avatarContent: { right: 10, top: '20%' },
  deleteButton: { color: '#FF1E1E' },
  editButtonContent: { position: 'absolute', right: 11, top: 11 },
  editButton: {
    color: '#045C5C', backgroundColor: '#FFFFFF', borderRadius: '50%', padding: 2,
  },
});

export default ({ data, assetType }) => {
  const project_img = `${process.env.REACT_APP_S3}/${data?.image}`;
  const classes = useStyles();
  return (
    <Grid container xs={12} className="paper-shadow mb-3" style={{ backgroundColor: '#fff', minHeight: '12rem' }}>
      <Grid item xs={5}><img src={project_img} alt={project_img} width={250} height="100%" /></Grid>
      <Grid container item xs={7} className="p-3 w-100 bg-white">
        <div className="bg-white w-75">
          <Typography className={classes.title} gutterBottom>{data.name}</Typography>
          <br />
          <Typography component="p" className={classes.content}>{data.location}, {data.state}</Typography>
          <Typography component="p" className={classes.content}>{assetType}</Typography>
        </div>
        <div className="w-25 d-flex justify-content-between align-items-end" style={{ flexDirection: 'column' }}>
          <p className="font-weight-bold">{formatBytes(data.asset_size, true, 2)}</p>
          <Link to={`/asset/${data?.id}`}>
            <Button size="small" style={{ height: 30 }}><p className="text-white" style={{ fontSize: 12 }}>View</p></Button>
          </Link>
        </div>
      </Grid>
    </Grid>
  );
};

