import MainContentContainer from '@Components/MainContentContainer';
import Navbar from '@Components/Navbar';
import Step from './Step';
import useHook from './hook';
import ReportVerify from './ReportVerify';
import Result from './Result';
import ReviewInfoA from './ReviewInfoA';
import ReviewInfoB1 from './ReviewInfoB1';
import Attachment from './Attachment';

export default ({ user }) => {
  const h = useHook({ user });
  return (
    <MainContentContainer>
      <Navbar
        to={`/project/report?id=${h.AssetId}`}
        text="Non-Conformance Report"
        subtext="Part B2"
      />
      <Step {...h} />
      {{
        0: <ReviewInfoA {...h} />,
        1: <ReviewInfoB1 {...h} />,
        2: <Attachment {...h} />,
        3: <ReportVerify {...h} />,
        4: <Result {...h} />,
      }[h.activeStep]}
    </MainContentContainer>
  );
};
