import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

export default function index({ duration, setDuration }) {
  const handleToggle = (event, newType) => setDuration(newType);
  return (
    <ToggleButtonGroup exclusive value={duration} onChange={handleToggle} size="small">
      <ToggleButton value={1}>1 DAY</ToggleButton>
      <ToggleButton value={7}>7 DAYS</ToggleButton>
      <ToggleButton value={30}>1 MONTH</ToggleButton>
    </ToggleButtonGroup>
  );
}
