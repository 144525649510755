import MainContentContainer from '@Components/MainContentContainer';
import Navbar from '@Components/Navbar';
import Step from './Step';
import useHook from './hook';
import ReportInfo from './ReportInfo';
import ReportVerify from './ReportVerify';
import Result from './Result';
import ReviewInfo from './ReviewInfo';
import Attachment from './Attachment';
import ReportSummary from './ReportSummary';

export default ({ user }) => {
  const h = useHook({ user });
  return (
    <MainContentContainer>
      <Navbar
        to={`/project/report?id=${h.AssetId}`}
        text="Non-Conformance Report"
        subtext="Part B1"
      />
      <Step {...h} />
      {{
        0: <ReviewInfo {...h} />,
        1: <ReportInfo {...h} />,
        2: <Attachment {...h} />,
        3: <ReportSummary {...h} />,
        4: <ReportVerify {...h} />,
        5: <Result {...h} />,
      }[h.activeStep]}
    </MainContentContainer>
  );
};
