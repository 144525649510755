import React from 'react';
import { Link } from 'react-router-dom';
import { primaryColor } from '@Assets/css/index';

export default function RoundedButton({ scrollTop = false, scrollTo, onClick = () => null, ...props }) {
  const toTop = () => scrollTop && document.getElementById(scrollTo).scrollIntoView({ behavior: 'smooth' });
  return (
    <Link
      to={props.disabled || props.to}
      onClick={() => { toTop(); onClick(); }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 5,
          cursor: props.disabled ? 'default' : 'pointer',
          width: 20,
          height: 20,
        }}
        onClick={() => (props.onClick ? props.onClick() : null)}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: props.disabled ? 0.5 : 1,
          }}
        >
          {props.children}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              fontSize: 10,
              textAlign: 'center',
              color: 'rgba(30,52,101,0.65)',
              whiteSpace: 'nowrap'
            }}
          >
            {props.text}
          </p>
        </div>
      </div>
    </Link>
  );
}
