import React, { useState, useEffect } from 'react';
import {
  TextField, Dialog, DialogTitle, DialogContent, IconButton, DialogActions, Button,
} from '@material-ui/core';
import CircularProgressBar from '@Components/CircularProgressBar/v2';
import { InfoOutlined, Close as CloseIcon } from '@material-ui/icons';
import moment from 'moment';
import { getDirectDataFirebase } from '@Helpers';
import TimeRangeChart from '@Components/TimeRangeChart';
import _ from 'lodash';

const cardCss = {
  backgroundColor: 'white',
  borderRadius: 10,
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  padding: 13,
  position: 'relative',
  height: '100%',
};

const dateStyle = {
  color: 'grey',
  marginBottom: 20,
  fontSize: 10,
};

const CCTVListContainer = {
  width: '100%',
  padding: 40,
  display: 'flex',
  justifyContent: 'space-between',
};

export default function CCTV({
  cctvs, open, setOpen, selected, setSelected,
}) {
  const usagePercentage = (num1, num2) => (num1 / num2) * 100;
  const [date, setDate] = useState(moment().format('D MMM YYYY'));
  const [eachCctvs, setEachCctvs] = useState([]);
  useEffect(async () => {
    const url_domain = 'https://supervision-current-cctv-default-rtdb.asia-southeast1.firebasedatabase.app';
    const cctvsAll = await Promise.all(cctvs.map(async cctv => {
      const cctv_b_url = `${url_domain}/cctv/${cctv.id}/breakdown.json`;
      let breakdowns = (await getDirectDataFirebase(`${cctv_b_url}`)).data;
      if (breakdowns) {
        // filter
        breakdowns = Object.values(breakdowns).length;
      } else {
        breakdowns = 0;
      }
      return { ...cctv, breakdowns };
    }));
    setEachCctvs(_.orderBy(cctvsAll, 'name'));
  }, [cctvs]);
  return (
    <>
      <div style={{ ...cardCss }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p style={{ color: '#605f65', fontSize: 12 }}>CCTV Status</p>
          <div style={{ display: 'flex', alignItems: 'center', transform: 'scale(0.82)', width: '25%', marginRight: '-1.0vw' }}>
            <TextField
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type="date"
              size="small"
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
        <p className="text-standard0lg" style={{ ...dateStyle }}>3rd Jan 2023 - 9th Jan 2023</p>
        <div style={{ ...CCTVListContainer }}>
          {eachCctvs.map((cctv) => (
            <div
              style={{ display: 'flex', alignItems: 'center', width: '25%' }}
              onClick={() => { setSelected(cctv); setOpen(true); }}
            >
              <CircularProgressBar scale={2.5} x={5} value={!cctv.breakdowns ? cctv.breakdowns : usagePercentage(cctv.breakdowns, 10)} />
              <div style={{ marginLeft: 50 }}>
                <p className="text-title" style={{ fontWeight: 600, color: 'black' }}>{cctv.breakdowns}</p>
                <p className="text-standard-lg" style={{ opacity: 0.6 }}>{cctv.name}</p>
              </div>
            </div>
          ))}
        </div>
        <hr />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InfoOutlined style={{ color: 'grey', width: 16 }} />
          <p style={{ color: 'grey', fontSize: 10, marginLeft: 5 }}>
            The number stated indicates on how many times the CCTV was breakdown without any notice
          </p>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(!open)}
        maxWidth="md"
        fullWidth
        style={{ padding: 40 }}
      >
        <DialogTitle>
          <h2 className="font-weight-bold">CCTV HISTORY</h2>
          <IconButton onClick={() => setOpen(false)} style={{ right: 15, top: 3, position: 'absolute' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent fullWidth>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
            <p style={{ color: 'grey', fontWeight: 600, fontSize: 12 }}>CCTV ID:</p>
            &nbsp;&nbsp;
            <p style={{
              backgroundColor: '#d9d9d9', fontWeight: 600, fontSize: 12, paddingInline: 10,
            }}
            >{selected?.name}
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TimeRangeChart />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: '#2960f6', color: 'white', textTransform: 'none', height: '-webkit-fill-available', width: '20%', marginRight: 20,
            }}
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const CardTitle = ({ title }) => (<p style={{ color: '#605f65', fontSize: 12 }}>{title ?? 'Status'}</p>);

