import React from 'react';
import {
  IconButton, Grid, CircularProgress, Card, CardContent,
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import Pagination from '@Components/Pagination';
import SearchBox from '@Components/SearchBox';
import useHook from './hook';
import AssetFileListCard from './components/MasterlistFileList';

const TableHeader = (props) => <p className="text-dark" style={{ fontSize: 16 }}>{props.children}</p>;

export default function index() {
  const h = useHook();
  return (
    <div className="mx-5 mb-5">
      <div className="w-100 mt-3 d-flex align-items-cente justify-content-between">
        <h1
          className="pt-2"
          style={{
            fontWeight: 600,
            fontSize: 22,
            color: 'var(--primary-color)',
          }}
        >
          Masterlist
        </h1>
        <div className="d-flex align-items-center">
          <IconButton onClick={() => h.getMasterlist()}>
            <Refresh />
          </IconButton>
          <SearchBox onChange={(e) => h.setKeyword(e.target.value)} onKeyDown={h.onKeyDown} onClick={h.getAssetFile} hasOnClick />
        </div>
      </div>
      <Card className="mb-4 mt-3">
        <CardContent>
          <Grid container style={{ height: 15 }}>
            <Grid item xs={1}><TableHeader className="text-light">ID</TableHeader></Grid>
            <Grid item xs={3}><TableHeader className="text-light">Asset</TableHeader></Grid>
            <Grid item xs={2}><TableHeader>Masterlist Name</TableHeader></Grid>
            <Grid item xs={2}><TableHeader>Type</TableHeader></Grid>
            <Grid item xs={1} style={{ textAlign: 'center' }}><TableHeader>Date</TableHeader></Grid>
            <Grid item xs={1} style={{ textAlign: 'center' }}><TableHeader>Last Update</TableHeader></Grid>
            <Grid item xs={2} style={{ textAlign: 'center' }}><TableHeader>Action</TableHeader></Grid>
          </Grid>
        </CardContent>
      </Card>

      {h.isLoading && (
        <CircularProgress
          size={75}
          className="position-absolute"
          style={{
            top: '50%', left: '50%', marginTop: -20, marginLeft: -40, color: 'var(--primary-color)',
          }}
        />
      )}
      {!!h.masterlist?.length && h.masterlist.map((a) => (
        <AssetFileListCard file={a} {...h} />
      ))}
      <div className="mt-3">
        <Pagination {...h} />
      </div>
    </div>
  );
}
