import * as L from 'leaflet';
import customIcon from '@Assets/Icons/Discipline/Control.svg'; // Import your custom SVG file

class CustomControl extends L.Control {
  constructor(onClick, options) {
    super(options);
    this.onClick = onClick;
  }

  onAdd() {
    const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');

    container.style.backgroundColor = 'white';
    container.style.width = '37px';
    container.style.height = '37px';
    container.style.cursor = 'pointer';
    container.style.backgroundImage = `url(${customIcon})`;
    container.style.backgroundRepeat = 'no-repeat';
    // container.style.backgroundAttachment = 'fixed';
    container.style.backgroundPosition = 'center';
    container.style.backgroundSize = '25px 25px';
    container.style.boxSizing = 'content-box';
    container.onclick = this.onClick;

    return container;
  }
}

export default CustomControl;
