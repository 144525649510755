// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
// import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//   apiKey: 'AIzaSyAbPx_nphakqjTpgdPX_e_yN4Hdfdss2-w',
//   authDomain: 'raise-285115.firebaseapp.com',
//   databaseURL: 'https://raise-285115-default-rtdb.firebaseio.com',
//   projectId: 'raise-285115',
//   storageBucket: 'raise-285115.appspot.com',
//   messagingSenderId: '879166198750',
//   appId: '1:879166198750:web:919b1ca904762b5a61c34f',
//   measurementId: 'G-X9L6KQ7EFF',
// };

// const firebaseConfig = {
//   apiKey: 'AIzaSyAi5sdXqULv_mJ2zXE00Ii1KspqPKR0JhI',
//   authDomain: 'raise-supervision.firebaseapp.com',
//   databaseURL: 'https://raise-supervision-default-rtdb.asia-southeast1.firebasedatabase.app',
//   projectId: 'raise-supervision',
//   storageBucket: 'raise-supervision.appspot.com',
//   messagingSenderId: '376508418665',
//   appId: '1:376508418665:web:c3ba8aa96f6fb8b7ba70f8',
//   measurementId: 'G-KPCN2BZXW5',
// };

const firebaseConfig = {
  apiKey: 'AIzaSyAG_DldARL-ffxc-iC_e7EKZZkQrODivbc',
  authDomain: 'supervision-current-cctv.firebaseapp.com',
  databaseURL: 'https://supervision-current-cctv-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'supervision-current-cctv',
  storageBucket: 'supervision-current-cctv.appspot.com',
  messagingSenderId: '585696682560',
  appId: '1:585696682560:web:d52445b5ea5e072b88b5a5',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getDatabase();
