import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import _ from 'lodash';

const Days = {
  0: 'Sunday', 1: 'Monday', 2: 'Tuesday', 3: 'Wednesday', 4: 'Thursday', 5: 'Friday', 6: 'Saturday',
};

const Months = {
  1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'June', 7: 'July', 8: 'Aug', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dec',
};
const subtractMonths = (date, months) => {
  date.setMonth(date.getMonth() - months);
  return date;
};
const subtractDays = (date, day) => {
  date.setDate(date.getDate() - day);
  return date;
};

const generateMonthsReverse = () => [...Array(12)].map((v, didx) => {
  const newDate = subtractMonths(new Date(), didx);
  return `${newDate.getFullYear()}/${(newDate.getMonth() + 1)}`;
});

const generateDaysReverse = () => [...Array(7)].map((v, didx) => {
  const newDate = subtractDays(new Date(), didx);
  return `${newDate.getFullYear()}/${(newDate.getMonth() + 1)}/${newDate.getDate()}`;
});

export default function Hook(AssetId) {
  const [assets, setAssets] = useState([]);
  const [assets_weather, setAssetsWeather] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [searchProjects, setSearchProjects] = useState([]);
  const [assetTypeList, setAssetTypeList] = useState([]);
  const [contractorList, setContractorList] = useState([]);
  const [complianceData, setComplianceData] = useState({});
  const [phases, setPhases] = useState([]);
  const [openCCTVModal, setOpenCCTVModal] = useState(false);
  const [selectedCCTV, setSelectedCCTV] = useState([]);
  const [ncrReport, setNcrReport] = useState([]);
  const getAssets = () => {
    Api({
      endpoint: endpoints.getAssets(),
      onSuccess: ({ data }) => {
        setAssets(data);
        setIsLoading(false);
      },
      onFail: (err) => { toast('error', err); },
    });
  };
  const getStaticData = () => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => {
        setAssetTypeList(data.AssetType);
        setContractorList(data.Contractors);
        setPhases(data.projectphase);
      },
      onFail: () => { toast('error', 'Failed get static data'); },
    });
  };
  const getNcrReport = () => {
    Api({
      endpoint: endpoints.getNcrReport(),
      data: { disablePagination: 1 },
      onSuccess: ({ data }) => {
        setNcrReport(data);
      },
      onFail: () => { toast('error', 'Failed get static data'); },
    });
  };
  useEffect(() => {
    console.log('vvv', AssetId);
    getAssets();
    getStaticData();
    getNcrReport();
  }, []);

  const handleSearchActiveSite = (e) => {
    const search = e.target.value.toLowerCase();
    const result = assets?.filter((data) => {
      return data.name?.toLowerCase().search(search) != -1;
    });
    setSearchProjects(result);
  };

  // temp, later need to push to backend
  useEffect(async () => {
    if (!assets.length) return;
    const p = {};
    for (let assetIdx = 0; assetIdx < assets.length; assetIdx++) {
      const asset = assets[assetIdx];
      const url = `https://api.openweathermap.org/data/3.0/onecall?lat=${asset.lat}&lon=${asset.lng}&appid=${process.env.REACT_APP_OWM}&units=metric`;
      const res = await fetch(url);
      const { current } = await res.json();
      p[asset.id] = current;
    }
    setAssetsWeather(p);
  }, [assets]);
  useEffect(() => {
    if (!assets.length) return;
    console.log('vvv AssetId change', AssetId);
    let dailyData = {};
    let monthlyData = {};
    let all_cctvs = [];
    if (!AssetId) {
      all_cctvs = assets.map(asset => asset.cctvs).flat();
    } else {
      all_cctvs = assets.find(asset => asset.id === Number(AssetId)).cctvs;
    }

    const allFootages = all_cctvs
      .map(cctv => cctv.CctvFootages)
      .flat().map(footage => {
        const d = new Date(footage?.captureAt);
        const keyMonth = `${d.getFullYear()}/${(d.getMonth() + 1)}`;
        const keyDate = `${keyMonth}/${d.getDate()}`;
        return {
          ...footage,
          keyMonth,
          keyDate,
        };
      });
    dailyData = getComplinaceDataDaily(allFootages);
    monthlyData = getComplinaceDataMonthly(allFootages);
    // weekly
    // daily
    setComplianceData({ dailyData, monthlyData });
  }, [assets, AssetId]);
  const getComplinaceDataDaily = (allFootages) => {
    const daysReverse = generateDaysReverse();
    const allFootagesFilteredDate = _.groupBy(allFootages.filter(footage => daysReverse.includes(footage.keyDate)), 'keyDate');
    const dailyData = { helmet: [], nonHelmet: [] };
    for (let mIdx = 0; mIdx < daysReverse.length; mIdx++) {
      const mId = daysReverse[mIdx];
      const ymId = mId.split('/');
      const x = Days[(new Date(mId).getDay())];
      const y = 0;
      const thisM = allFootagesFilteredDate[mId];
      const thisMObj = {
        mId, yearIdx: Number(ymId[0]), monthIdx: Number(ymId[1]), dateIdx: Number(ymId[2]), x, y,
      };
      if (thisM) {
        dailyData.helmet.push({ ...thisMObj, y: _.sumBy(thisM, 'person_count') });
        dailyData.nonHelmet.push({ ...thisMObj, y: _.sumBy(thisM, 'person_no_helmet_count') });
      } else {
        dailyData.helmet.push(thisMObj);
        dailyData.nonHelmet.push(thisMObj);
      }
    }

    dailyData.helmet = _.orderBy(dailyData.helmet, ['yearIdx', 'monthIdx', 'dateIdx'], ['asc', 'asc', 'asc']);
    dailyData.nonHelmet = _.orderBy(dailyData.nonHelmet, ['yearIdx', 'monthIdx', 'dateIdx'], ['asc', 'asc', 'asc']);
    console.log('dailyData', dailyData);
    return dailyData;
  };
  const getComplinaceDataMonthly = (allFootages) => {
    const monthsReverse = generateMonthsReverse();
    const allFootagesFilteredMonth = _.groupBy(allFootages.filter(footage => monthsReverse.includes(footage.keyMonth)), 'keyMonth');
    const monthlyData = { helmet: [], nonHelmet: [] };
    for (let mIdx = 0; mIdx < monthsReverse.length; mIdx++) {
      const mId = monthsReverse[mIdx];
      const ymId = mId.split('/');
      const x = `${Months[ymId[1]]} ${ymId[0]}`;
      const y = 0;
      const thisM = allFootagesFilteredMonth[mId];
      const thisMObj = {
        mId, yearIdx: Number(ymId[0]), monthIdx: Number(ymId[1]), x, y,
      };
      if (thisM) {
        monthlyData.helmet.push({ ...thisMObj, y: _.sumBy(thisM, 'person_count') });
        monthlyData.nonHelmet.push({ ...thisMObj, y: _.sumBy(thisM, 'person_no_helmet_count') });
      } else {
        monthlyData.helmet.push(thisMObj);
        monthlyData.nonHelmet.push(thisMObj);
      }
    }

    monthlyData.helmet = _.orderBy(monthlyData.helmet, ['yearIdx', 'monthIdx'], ['asc', 'asc']);
    monthlyData.nonHelmet = _.orderBy(monthlyData.nonHelmet, ['yearIdx', 'monthIdx'], ['asc', 'asc']);
    return monthlyData;
  };

  const searchKeyword = (searchKey, array) => {
    if (!searchKey) return array;
    const result = array?.filter((data) => {
      return data.name?.toLowerCase().search(searchKey) != -1;
    });
    return result;
  };

  return {
    assets: searchProjects.length ? searchProjects : assets,
    assets_weather,
    isLoading,
    assetTypeList,
    getComplinaceDataMonthly,
    complianceData,
    contractorList,
    phases,
    searchProjects,
    setSearchProjects,
    handleSearchActiveSite,
    searchKeyword,
    openCCTVModal,
    setOpenCCTVModal,
    selectedCCTV,
    setSelectedCCTV,
    ncrReport,
  };
}
