import React from 'react';
import {
  VictoryBar, VictoryChart, VictoryAxis, VictoryLabel, VictoryTheme,
} from 'victory';
import { stringWrapper } from '@Helpers';

export default function SingleBarGraph({ data, yAxis, height }) {
  return (
    <div className="w-100">
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="25%" stopColor="#346D9C" />
            <stop offset="100%" stopColor="#519CC3" />
          </linearGradient>
        </defs>
      </svg>
      <VictoryChart domainPadding={{ x: 150, y: 20 }} width={1500} height={height ?? 650} theme={VictoryTheme.material}>
        <VictoryBar
          data={data}
          x="x"
          y="y"
          barWidth={60}
          animate={{
            duration: 2000,
            onLoad: { duration: 2000 },
          }}
          cornerRadius={3}
          style={{
            data: { fontFamily: 'Poppins', fill: 'url(#gradient)' },
          }}
        />
        <VictoryAxis
          tickFormat={(x) => stringWrapper(x.toString(), 20)}
          offsetY={100}
          style={{
            tickLabels: { fontSize: 19.5, fontWeight: 600, angle: -25 },
            ticks: { size: 0 },
            axis: { stroke: 'rgba(30, 52, 101, 0.15)', strokeWidth: 1 },
            grid: { stroke: 'transparent' },
          }}
        />
        <VictoryAxis
          dependentAxis
          axisLabelComponent={<VictoryLabel angle={0} verticalAnchor="start" dy={-270} />}
          tickValues={[0, 2, 4, 6, 8, 10]}
          tickFormat={(t) => (yAxis === 'percentage' ? `${t * 10}%` : t)}
          offsetX={60}
          style={{
            axis: { stroke: '#FFFFFF' },
            tickLabels: { fontSize: 23, padding: 5, fontWeight: 600 },
            ticks: { stroke: '#FFFFFF', size: 0 },
            grid: { stroke: 'rgba(30, 52, 101, 0.15)', strokeDasharray: false },
          }}
        />
      </VictoryChart>
    </div>
  );
}
