import React from 'react';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import _ from 'lodash';
import moment from 'moment';

const styles = {
  tooltip: {
    container: {
      background: 'rgba(51, 51, 51, 0.9)',
      color: '#fff',
      fontSize: '12px',
      borderRadius: '0',
      boxShadow: 'none',
      padding: '10px 14px',
    },
  },
};

const colors = [
  { cctv: 'CCTV 1', color: '#380459' }, { cctv: 'CCTV 2', color: '#747ab0' }, { cctv: 'CCTV 3', color: '#28CFFD' },
  // { cctv: 'CCTV 4', color: '#380459' }, { cctv: 'CCTV 5', color: '#747ab0' }, { cctv: 'CCTV 6', color: '#28CFFD' },
];

export default ({
  graphData, onClickPlot, segregationMethod, duration,
}) => {
  //   pass custom duration
  const filteredData = graphData.filter(x => moment().diff(moment(x.captureAt), 'days') < duration);
  // const data = _.chain(filteredData).groupBy(x => `CCTV ${x[segregationMethod]}`).map((v, k) => ({
  const data = _.chain(filteredData).groupBy(x => x.Cctv.name).map((v, k) => ({
    id: k,
    color: colors.find(x => x.cctv === k)?.color,
    data: v.map(eachFootage => ({
      ...eachFootage,
      x: moment(eachFootage.captureAt).format('YYYY-MM-DD HH:mm:ss'),
      y: (eachFootage.car_count + eachFootage.machinery_count + eachFootage.truck_count) ?? 0,
    })),
  }))
    .value();
  const labelTextColor = '#000';
  const hasLegend = {
    legends: [
      {
        dataFrom: 'keys',
        anchor: 'top-right',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [{ on: 'hover', style: { itemOpacity: 1 } }],
      },
    ],
  };
  return (
    <ResponsiveScatterPlot
      data={data}
      xScale={{
        type: 'time',
        format: '%Y-%m-%d %H:%M:%S',
        precision: 'day',
        // min: moment().subtract(1, 'days').toDate(),
        // min: moment().subtract(7, 'days').toDate(),
        min: moment().subtract(duration, 'days').toDate(),
        max: moment().toDate(),
      }}
      //   xFormat="time:%Y-%m-%d %H"
      //   xFormat="time:%Y-%m-%d"
      margin={{
        top: 5,
        bottom: 65,
        right: 5,
        left: 60,
      }}
      enableGridY={false}
      padding={0.6}
      innerPadding={5}
      borderRadius={4}
      colors={data.map(({ color }) => color)}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: -45,
        format: duration < 10 ? '%Y-%m-%d %Hh' : '%Y-%m-%d',
        tickValues: duration < 10 ? 24 : 30,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0,
        legendPosition: 'middle',
        legendOffset: -40,
        tickValues: 5,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={labelTextColor}
      animate
      motionStiffness={90}
      motionDamping={15}
      gridYValues={7}
      theme={{
        background: 'transparent',
        axis: {
          fontSize: '15px',
          tickColor: '#eee',
          ticks: {
            line: {
              stroke: '#555555',
            },
            text: {
              fill: '#8898AA',
            },
          },
          legend: {
            text: {
              fill: '#aaaaaa',
            },
          },
        },
        grid: {
          line: {
            stroke: '#8898AA',
            strokeWidth: 1,
            strokeDasharray: '3',
          },
        },
        legends: { text: { fill: '#000000' } },
      }}
      {...hasLegend}
      onClick={onClickPlot || (() => console.log('point click'))}
      tooltip={({ node }) => (
        <div style={styles.tooltip.container}>
          CCTV: {node.data.CctvId}<br />
          Date: {node.data.captureAt}<br />
          Manpower: {node.data.person_count}(All) {node.data.person_no_helmet_count}(No Helmet)<br />
          Machinery: {node.data.machinery_count}(Machine) {node.data.car_count}(Car) {node.data.truck_count}(Truck)<br />
        </div>
      )}
    />
  );
};
