/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { Gantt } from 'gantt-task-react';
import Api, { endpoints } from '@Helpers/api';
import Papa from 'papaparse';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import DialogAddInspectionId from './DialogAddInspectionId';

import 'gantt-task-react/dist/index.css';

export default function Content({
  projectTimelineTaskAsset, project, footage, current_slider_key,
}) {
  const history = useHistory();
  const [tasks, setTask] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [showAllTask, setShowAllTask] = useState(false);
  const [openDialog, setOpenDialog] = useState();
  const [inspectionSessions, setInspectionSessions] = useState([]);
  useEffect(() => {
    getInspectionSessions();
    Papa.parse(
      `${process.env.REACT_APP_S3}/${projectTimelineTaskAsset.path}`,
      {
        ...{ delimiter: ',' },
        header: true,
        download: true,
        complete: (result) => {
          const filteredResult = result.data.filter(d => !!d.ID);
          const lowest_outline = Math.min(...filteredResult.map(d => Number(d.Outline_Level)));
          const formattedData = result.data.filter(d => !!d.ID).map((d, didx) => {
            let type = 'task';
            const extraProps = {};
            if (d.Outline_Level != lowest_outline) {
              let previdx = didx;
              while (result.data[previdx].Outline_Level == d.Outline_Level) {
                previdx--;
              }
              extraProps.project = result.data[previdx].ID;
            }
            const dependencies = d.Predecessors.split(',');
            if (!(dependencies.length === 1 && dependencies[0] === '')) {
              extraProps.dependencies = dependencies;
            }

            if (d.Outline_Level == lowest_outline) {
              extraProps.hideChildren = false;
              type = 'project';
            }

            return {
              id: d.ID,
              name: d.Name,
              start: new Date(d.Start || d.Start_Date),
              end: new Date(d.Finish || d.Finish_Date),
              ...extraProps,
              progress: 0,
              isDisabled: true,
              type,
              displayOrder: didx + 1,
              InspectionId: d.InspectionId ?? null,
            };
          });
          setTask(formattedData);
        },
      },
    );
  }, [projectTimelineTaskAsset]);
  useEffect(() => {
    if (!tasks.length) return;
    const footageDate = (new Date(footage[current_slider_key]?.captureAt));
    setFilteredTasks(showAllTask ? tasks : tasks?.filter(t => t.start <= footageDate && footageDate <= t.end));
  }, [tasks, showAllTask, footage, current_slider_key]);

  const getInspectionSessions = () => {
    if (!project?.id) return;
    const data = {
      AssetId: project.id,
    };
    Api({
      endpoint: endpoints.getInspectionSession(),
      data,
      onSuccess: (response) => {
        setInspectionSessions(response.data);
      },
      onFail: () => setInspectionSessions([]),
    });
  };

  const handleClick = (task) => {
    if (task.InspectionId) return history.push(`/inspection/${task.InspectionId}`);
    const taskInspection = inspectionSessions.find(ins => ins.type && ins.type.split(',').includes(task.id));
    if (taskInspection) return history.push(`/inspection/${taskInspection.id}`);
    return setOpenDialog(task.id);
  };

  const fontDescription = {
    fontSize: 12,
  }

  const fontSubHeader = {
    fontSize: 14,
  }

  const updateInspectionTask = (data, inspectionId) => {
    Api({
      endpoint: endpoints.updateInspectionTask(inspectionId),
      data,
      onSuccess: () => {
        toast('success', 'Successfully add inspection to task');
      },
      onFail: () => toast('error', 'Failed to add inspection to task'),
    });
  };

  const addInspectionId = (InspectionId) => {
    if (!openDialog) return;
    setTask(data => {
      const indexOfOpenTask = data.findIndex(t => t.id == openDialog);
      data[indexOfOpenTask].InspectionId = InspectionId;
      return data;
    });
    updateInspectionTask({ taskId: openDialog }, InspectionId);
  };
  return (
    <Grid item xs={12} md={12} className="sidebar" style={{ zIndex: 1, height: 'max-content', padding: 20 }}>
      <div className="d-flex justify-content-center flex-wrap" style={{ flex: 1 }}>
        <Box className="d-flex align-items-center mb-3 w-100">
          <p style={fontSubHeader}>Project Timeline</p>
          <p style={fontDescription}><small className="text-secondary mx-2">{projectTimelineTaskAsset.version}</small></p>
          <Button onClick={() => setShowAllTask(pv => !pv)} variant="outlined" style={{ width: 100, height: 30 }}>
            {showAllTask ? 'All' : moment(footage[current_slider_key]?.captureAt).format('DD/MM/YY')}
          </Button>
        </Box>
        <Box className="w-100" style={{ maxHeight: 300, overflowY: 'auto' }}>
          {!!filteredTasks.length ? (
            <Gantt
              tasks={filteredTasks}
              // tasks={tasks}
              viewMode="Day"
              onClick={(task) => handleClick(task)}
              rowHeight={30}
              headerHeight={30}
              fontSize="12px"
              onExpanderClick={(task) => setTask(pv => {
                const prevTask = pv.find(tpv => tpv.id === task.id);
                const remainTask = pv.filter(tpv => tpv.id != task.id);
                return [...remainTask, { ...prevTask, hideChildren: !prevTask.hideChildren }];
              })}
              // listCellWidth={isChecked ? '155px' : ''}
              // columnWidth={columnWidth}
              viewDate={new Date()}
            />
          ) : 'No Task Today Onwards'}
        </Box>
        <DialogAddInspectionId
          open={openDialog}
          setOpen={setOpenDialog}
          addInspectionId={addInspectionId}
          inspectionSessions={inspectionSessions}
        />
      </div>
    </Grid>
  );
}
