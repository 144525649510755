import React from 'react';

export default function RoundedButton({
  scrollTo, ...props
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 5,
        cursor: props.disabled ? 'default' : 'pointer',
        width: 20,
        height: 20,
      }}
      onClick={() => (props.onClick ? props.onClick() : null)}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: props.disabled ? 0.5 : 1,
        }}
      >
        {props.children}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            fontSize: 10,
            textAlign: 'center',
            color: 'rgba(30,52,101,0.65)',
            whiteSpace: 'nowrap',
          }}
        >
          {props.text}
        </p>
      </div>
    </div>
  );
}
