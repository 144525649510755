import Button from '@Components/Button';
import {
  TextField, InputLabel, Select, MenuItem, FormControl, CircularProgress,
} from '@material-ui/core';
import NoData from '@Assets/Images/Data-not-found3.png';
import { makeStyles } from '@material-ui/core/styles';

const Loader = () => (
  <CircularProgress
    size={75}
    className="position-absolute"
    style={{
      top: '50%', left: '50%', marginTop: -80, marginLeft: -40, color: 'var(--primary-color)',
    }}
  />
);

const useStyles = makeStyles({
  resize: {
    fontSize: 12,
  },
});

const NoDataUi = () => (
  <div className="d-flex justify-content-center w-100 m-4 p-5">
    <img src={NoData} style={{ height: '55vh' }} />
  </div>
);
export default (h) => {
  const classes = useStyles();
  const isFieldEmpty = !(!!h.dateProgress && !!h.assetId);
  if (h.isLoading) return <Loader />;

  return !h.assets.length ? <NoDataUi /> : (
    <div className="w-25 mt-5 mx-auto" style={{ minHeight: '60vh' }}>
      <TextField
        label="Assets Name"
        value={h.assetId}
        onChange={(e) => h.setAssetId(e.target.value)}
        className="w-100 my-1"
        size="small"
        variant="standard"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          classes: {
            input: classes.resize,
          },
        }}
        select
      >
        {h.assets.map(a => (
          <MenuItem value={a.id} className={classes.resize}>{a.name}</MenuItem>
        ))}
      </TextField>
      <TextField
        label="Select Phase"
        value={h.category}
        onChange={(e) => h.setCategory(Number(e.target.value))}
        className="w-100 my-1"
        size="small"
        variant="standard"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          classes: {
            input: classes.resize,
          },
        }}
        select
      >
        {h.filteredPhases.map(p => (
          <MenuItem value={p.id} className={classes.resize}>{p.name}</MenuItem>
        ))}
      </TextField>
      <TextField
        label="Date Progress"
        value={h.dateProgress}
        onChange={e => h.setDateProgress(e.target.value)}
        className="w-100 my-3"
        variant="standard"
        type="date"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          classes: {
            input: classes.resize,
          },
        }}
      />
      <Button style={h.buttonStyle} className="mt-4 float-right" onClick={h.handleNextStep} disabled={isFieldEmpty}>
        NEXT
      </Button>
    </div>
  );
};
