import { Grid, Box } from '@material-ui/core';
import MainContentContainer from '@Components/MainContentContainer';
import Dropdown from '@Components/Dropdown';
import Navbar from '@Components/Navbar';
import no_data from '@Assets/Images/no_data.png';
import Projectbar from '@Components/Projectbar';
import Slider from './components/Slider';
import CurrentImage from './CurrentImage';
import Detection from './Detection';

import Hook from './hook';

export default function Report(props) {
  const h = Hook(props);

  return (
    <div className="main-content" style={{ width: '-webkit-fill-available' }}>
      <div className="d-flex justify-content-between">
        <Navbar
          to="/project/"
          text="CCTV"
          subtext={props.project?.name}
        />
      </div>
      {!!h.projectTimelineTaskAsset && <Projectbar {...h} project={props.project} />}
      {!!h.cctvs.length ? (
        <Grid item xs={12} style={{ padding: 20 }}>
          <Box className="d-flex justify-content-between" style={{ transform: 'scaleY(0.95)' }}>
            <Dropdown
              selectedItem={h.selected_cctv}
              setSelectedItem={h.set_selected_cctv}
              itemList={h.cctvs.map(e => e.name)}
              Hx="h6"
            />
            {![274, 291].includes(props.project.id) && <CurrentImage {...h} />}
          </Box>
          {!!h.footage.length ? (
            <>
              <Slider
                footage={h.footage}
                current_slider_key={h.current_slider_key}
                set_current_slider_key={h.set_current_slider_key}
                openPanoramicModal={h.openPanoramicModal}
                set_openPanoramicModal={h.set_openPanoramicModal}
              />
              {!!h.footage_detection.data?.length ? (
                <Box className="mt-2 relative">
                  <Detection {...h} />
                </Box>
              ) : (<NoData desc="detection" />)}
            </>
          ) : (<NoData desc="footage" />)}
        </Grid>
      ) : (<NoData desc="CCTV" />)}
    </div>
  );
}

const NoData = ({ desc }) => (
  <div className="mx-auto d-flex flex-column text-center">
    <img src={no_data} width="40%" className="mx-auto mt-5 mb-3" style={{ filter: 'opacity(0.5) drop-shadow(0 0 0 yellow)' }} />
    <p className="font-weight-bold" style={{ color: '#b5b585' }}>No {desc} available</p>
  </div>
);
