import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { ViewIcon } from '@Assets/Icons';
import { GetApp, Delete } from '@material-ui/icons';


export default function ActionSection({ path }) {
  return (
    <div className="flex-standard" style={{ gap: 2 }}>
      <Link to={{ pathname: `${path}` }} target="_blank">
        <IconButton style={{ width: 24, height: 24 }}>
          <ViewIcon height="18px" width="18px" color="var(--primary-color)" />
        </IconButton>
      </Link>
      <IconButton style={{ width: 24, height: 24 }}>
        <Delete height="15px" width="15px" style={{ color: 'red' }} />
      </IconButton>
      <IconButton style={{ width: 24, height: 24 }}>
        <GetApp height="15px" width="15px" />
      </IconButton>
    </div>
  )
}
