import { useDropzone } from 'react-dropzone';

const getDimension = async (f) => {
  const image = new Image();
  const extra_data = {};
  image.src = URL.createObjectURL(f);
  await image.decode();
  extra_data.width = image.width;
  extra_data.height = image.height;
  return extra_data;
};
export default function Hook({ setFiles, type = 'image' }) {
  const acceptType = {
    excel: '.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    image: 'image/jpeg, image/png',
    imageVideo: 'image/jpeg, image/png, .mp4, .mkv, .avi',
    doc: '.pdf, .docx, .doc, .excel, .csv',
    project: '.xlsx, .csv',
    compress: '.zip, .rar',
    potree: '.zip',
    vector: '.kml, .geojson, .shape',
    vectornraster: '.kml, .geojson, .shape, .zip',
    raster: '.zip',
    pdfreport: '.pdf',
    docImg: '.pdf, .docx, .doc, .excel, .csv, image/jpeg, image/png',
    siteDrawing: '.pdf, image/jpeg, image/png',
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: acceptType[type],
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) => {
          const etc = {};
          if (file.type.includes('image')) {
            etc.resolution = () => getDimension(file);
          }
          return Object.assign(file, {
            preview: type === 'image' ? URL.createObjectURL(file) : `${process.env.REACT_APP_S3}/static/icons/file.png`,
            previewObject: URL.createObjectURL(file),
            ...etc,
          });
        }),
      );
    },
  });

  return { getRootProps, getInputProps, open };
}
