import UserIcon from '@Components/UserIcon';
import { Button, IconButton } from '@material-ui/core';
import {
  AccessTime, CheckCircleOutline, Delete, GetApp, PlayArrow,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';

export const StageNCR = (props) => {
  if (!props.NonConformityReportFiles?.length) return 'Part A';
  const sortArr = props.NonConformityReportFiles.sort((a, b) => b.stage - a.stage);
  const stage = {
    1: 'Part A', 2: 'Part B1', 3: 'Part B', 4: 'Part C', 5: 'Part D', 6: 'Part E',
  }[sortArr[0].stage];
  return stage;
};

export const VerificationNCR = (props, h) => {
  if (!props.NonConformityReportFiles?.length) return;
  if (!h.user) return;
  if (!!props.verified_by) return <UserIcon pilot_name={props.verified_by} />;
  const sortArr = props.NonConformityReportFiles.sort((a, b) => b.stage - a.stage);
  const stage = {
    1: { stage: 'B1', label: 'Part B', isConsultant: ['consultant'].includes(h.user.raise_role) },
    2: { stage: 'B2', verify: true, isConsultant: !['consultant'].includes(h.user.raise_role) },
    3: { stage: 'C', label: 'Part C', isConsultant: !['consultant'].includes(h.user.raise_role) },
    4: { stage: 'D', verify: true, isConsultant: ['consultant'].includes(h.user.raise_role) },
    5: { stage: 'E', verify: true, isConsultant: ['consultant'].includes(h.user.raise_role) },
  }[sortArr[0]?.stage];
  return (
    !!stage.isConsultant ? (
      <Link to={`/ncr-form/part-${stage.stage}/${props.id}`}>
        <Button
          variant="contained"
          style={{
            color: 'white', backgroundColor: '#2196F3', fontFamily: 'CeraProRegular', height: 30,
          }}
        >
          {stage.verify ? (
            <>
              <CheckCircleOutline style={{ fontSize: 18, color: 'white ' }} />
              <p className="text-white" style={{ fontSize: 10 }}>&nbsp;Verify Now</p>
            </>
          ) : (
            <>
              <PlayArrow style={{ fontSize: 18, color: 'white ' }} />
              <p className="text-white" style={{ fontSize: 10 }}>Proceed To {stage.label}</p>
            </>
          )}
        </Button>
      </Link>
    ) : (
      <Button
        variant="contained"
        style={{
          color: 'white', backgroundColor: '#a5b8cf', fontFamily: 'CeraProRegular', height: 30,
        }}
        disabled
      >
        <AccessTime style={{ fontSize: 18, color: 'white ' }} />
        <p className="text-white" style={{ fontSize: 10 }}>&nbsp;Awaiting Verification</p>
      </Button>
    )
  );
};

export const CategoryNCR = (props) => {
  if (!props.NonConformityReportFiles?.length) return '-';
  if (!!props.NonConformityReportFiles.find(e => e.stage === 2)) return props.NonConformityReportFiles.find(e => e.stage === 2).details.category;
  return 'N/A';
};

export const ActionSectionNCR = (report, extHook) => {
  return (
    <div className="flex-standard" style={{ gap: 2 }}>
      <IconButton style={{ width: 24, height: 24 }}>
        <GetApp />
      </IconButton>
      <IconButton style={{ width: 24, height: 24 }} onClick={() => { extHook.setSelectedReport(report); extHook.setOpenDialog(true); }}>
        <Delete height="15px" width="15px" style={{ color: 'red' }} />
      </IconButton>
    </div>
  );
};

export const CheckStatus = (x, { setSelectedReport, setOpenSiteDiaryHistory }, h) => {
  if (!x?.uuid) return '-';
  const StatusButton = {
    Merged: (
      !!h.user?.can_submit_cl ? (
        <Link to={`/site-diary/send-review/${x?.uuid}`}>
          <Button
            variant="contained"
            style={{
              color: 'white', backgroundColor: '#2196F3', fontFamily: 'CeraProRegular', height: 30,
            }}
          >
            <PlayArrow style={{ fontSize: 18, color: 'white ' }} />
            <p className="text-white" style={{ fontSize: 10 }}>Send To Review</p>
          </Button>
        </Link>
      ) : 'Pending Submission'
    ),
    Submited: (
      !!h.user?.can_review_cl ? (
        <Link to={`/site-diary/pending-review/${x?.uuid}`}>
          <Button
            variant="contained"
            style={{
              color: 'white', backgroundColor: '#2196F3', fontFamily: 'CeraProRegular', height: 30,
            }}
          >
            <PlayArrow style={{ fontSize: 18, color: 'white ' }} />
            <p className="text-white" style={{ fontSize: 10 }}>Review Now</p>
          </Button>
        </Link>
      ) : 'Pending Review'
    ),
    Approved: (
      !!h.user?.can_approve_cl ? (
        <Link to={`/site-diary/pending-approval/${x?.uuid}`}>
          <Button
            variant="contained"
            style={{
              color: 'white', backgroundColor: '#2196F3', fontFamily: 'CeraProRegular', height: 30,
            }}
          >
            <PlayArrow style={{ fontSize: 18, color: 'white ' }} />
            <p className="text-white" style={{ fontSize: 10 }}>Approve Now</p>
          </Button>
        </Link>
      ) : 'Pending Approval'
    ),
    Rejected: (
      <Button
        variant="contained"
        style={{
          color: 'white', backgroundColor: 'red', fontFamily: 'CeraProRegular', height: 30,
        }}
        onClick={() => { setSelectedReport(x); setOpenSiteDiaryHistory(true); }}
      >
        <p className="text-white" style={{ fontSize: 10 }}>Rejected</p>
      </Button>
    ),
    Completed: 'Complete',
  }[x?.status];
  return StatusButton;
};
