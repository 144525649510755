/* eslint-disable object-curly-newline */
import React from 'react';
import { Grid } from '@material-ui/core';
import TeamAssign from './TeamAssign';
import TeamWorkflow from './TeamWorkflow';

export default (props) => {
  const roleType = [
    { id: 1, name: 'OPEN', value: 'open', seqId: 0, color: '#FEB019' },
    { id: 2, name: 'IN-PROGRESS', value: 'inprogress', seqId: 1, color: '#1F3566' },
    { id: 3, name: 'ON HOLD', value: 'onhold', seqId: 2, color: '#C70EBA' },
    { id: 4, name: 'RESOLVED', value: 'resolved', seqId: 3, color: '#A7A700' },
  ];
  return (
    <Grid xs={12} item className="mt-3" style={{ height: '100%' }}>
      <TeamWorkflow roleType={roleType} {...props} />
      <TeamAssign roleType={roleType} {...props} />
    </Grid>
  );
};
