import React from 'react';
import { Link } from 'react-router-dom';
import {
  Chip, Card, CardContent, IconButton, Grid,
  makeStyles, Tooltip,
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import moment from 'moment';
import AssetFileUpdate from './AssetFileUpdate';
import DeleteAssetFile from './DeleteAssetFileDialog';

export default ({ file, getAssetFile }) => {
  const classes = useStyles();

  return (
    <Card className="bg-white" variant="outlined">
      <CardContent className="py-0">
        <Grid container>
          {[
            { xs: 1, children: <p style={{ fontSize: 12 }}>{file?.id}</p> },
            { xs: 3, children: <Tooltip title={file?.Asset.name?.toString()}><p style={{ fontSize: 12 }}>{file?.Asset.name}</p></Tooltip> },
            { xs: 2, children: <Tooltip title={file?.label?.toString()}><p style={{ fontSize: 12 }}>{file?.label}</p></Tooltip>},
            { xs: 2, children: file?.type ? <Chip size="small" className={classes.root} label={file?.type} style={{ fontSize: 12 }} /> : null },
            { xs: 1, children: <p style={{ fontSize: 12, textAlign: 'center', width: 'inherit' }}>{moment(file?.createdAt).format('DD MMMM YYYY')}</p> },
            { xs: 1, children: <p style={{ fontSize: 12, textAlign: 'center', width: 'inherit' }}>{moment(file?.createdAt).format('DD MMMM YYYY')}</p> },
            { xs: 2, children: <ButtonList file={file} refresh={getAssetFile} /> },
          ].map(e => <Grid container {...e} className={!!e.className ? e.className : 'd-flex align-items-center'} />)}
        </Grid>

      </CardContent>
    </Card>
  );
};

const DownloadFile = (assetfile) => {
  return (
    <>
      { !!assetfile.raw_path
        ? (
          <Link to={{ pathname: `${assetfile.raw_path}` }} target="_blank">
            <IconButton>
              <GetApp style={{ color: 'var(--primary-color)', width: 20 }} />
            </IconButton>
          </Link>
        )
        : (
          <IconButton disabled>
            <GetApp style={{ width: 20 }} />
          </IconButton>
        )}
    </>
  );
};

const ButtonList = ({ file, refresh }) => {
  return (
    <div style={{ fontSize: 14, textAlign: 'center', width: 'inherit' }}>
      <AssetFileUpdate file={file} refresh={refresh} />
      <DeleteAssetFile file={file} refresh={refresh} />
      <DownloadFile {...file} />
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    backgroundColor: 'var(--secondary-color)',
    textWeight: 600,
    color: 'white',
    padding: 1,
  },
  textLabel: {
    fontWeight: 600, fontSize: '0.8em', color: 'var(--tertiary-color)', cursor: 'pointer',
  },
  textData: { fontWeight: 800, fontSize: 14, color: 'var(--primary-color)' },
});
