import Button from '@Components/Button';
import { TextField, InputLabel, MenuItem } from '@material-ui/core';
import { useState } from 'react';

export default (h) => {
  const [helperText, setHelperText] = useState(null);
  const [hasError, setHasError] = useState(false);

  const checkNextStep = () => {
    if (![3].includes(h.correctiveAction)) return h.handleNextStep();
    if (!!h.specifyReason) return h.handleNextStep();
    setHasError(true);
    setHelperText('Required');
  }

  return (
    <div className="w-25 mt-5 mx-auto" style={{ minHeight: '60vh' }}>
      <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Select Category</InputLabel>
      <TextField
        value={h.category}
        onChange={(e) => h.setCategory(e.target.value)}
        className="w-100 mb-3"
        size="small"
        variant="standard"
        InputLabelProps={{ shrink: true }}
        select
      >
        <MenuItem value="Minor">Minor</MenuItem>
        <MenuItem value="Major">Major</MenuItem>
      </TextField>
      <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Select corrective action to be taken</InputLabel>
      <TextField
        value={h.correctiveAction}
        onChange={(e) => h.setCorrectiveAction(e.target.value)}
        className="w-100 mb-3"
        size="small"
        variant="standard"
        InputLabelProps={{ shrink: true }}
        select
      >
        <MenuItem value={1}>To be disposed of site</MenuItem>
        <MenuItem value={2}>To be rectified (submit proposed corrective action)</MenuItem>
        <MenuItem value={3}>To be used for other purpose / application</MenuItem>
      </TextField>
      {[3].includes(h.correctiveAction) && (
        <>
          <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Please specify your reason</InputLabel>
          <TextField
            value={h.specifyReason}
            onChange={e => h.setSpecifyReason(e.target.value)}
            className="w-100 mb-3"
            variant="standard"
            size="small"
            InputLabelProps={{ shrink: true }}
            error={hasError}
            helperText={helperText}
          />
        </>
      )}
      <div className="d-flex justify-content-end mt-4">
        <Button variant="text" onClick={h.handleBackStep}>
          PREVIOUS
        </Button>
        <Button onClick={checkNextStep}>
          NEXT
        </Button>
      </div>
    </div>
  );
};
