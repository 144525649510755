import { useState, useEffect } from 'react';
import {
  Typography, Box, Slider, IconButton, Tooltip,
} from '@material-ui/core';
import NavigateNext from '@material-ui/icons/NavigateNext';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import { styled } from '@material-ui/core/styles';
import moment from 'moment';
import NoImage from '@Assets/Images/No_Image.jpg';
import PanoramicDialog from './PanoramicDialog';

export default ({
  footage, current_slider_key, set_current_slider_key, openPanoramicModal, set_openPanoramicModal,
}) => {
  // const [current_slider_key, set_current_slider_key] = useState(footage.length - 1);
  const [displayed_image, set_displayed_image] = useState(footage[footage.length - 1]?.thumbnail ?? footage[footage.length - 1]?.path);
  const [displayed_capturedAt, set_displayed_capturedAt] = useState(footage[footage.length - 1]?.captureAt);

  useEffect(() => {
    if (!footage.length) return;
    set_displayed_image(footage[footage.length - 1]?.thumbnail ?? footage[footage.length - 1]?.path);
    set_current_slider_key(footage.length - 1);
    set_displayed_capturedAt(footage[footage.length - 1]?.captureAt);
  }, [footage]);

  const onChangeTracker = (e, csk) => {
    set_current_slider_key(csk);
    const selectedImage = footage[csk].thumbnail ?? footage[csk].path;
    const selectedCapturedAt = footage[csk].captureAt;
    set_displayed_image(selectedImage);
    set_displayed_capturedAt(selectedCapturedAt);
  };

  const handleNext = () => {
    if (current_slider_key >= footage.length - 1) return;
    const selectedImage = footage[current_slider_key + 1].thumbnail ?? footage[current_slider_key + 1].path;
    const selectedCapturedAt = footage[current_slider_key + 1].captureAt;
    set_displayed_image(selectedImage);
    set_current_slider_key(current_slider_key + 1);
    set_displayed_capturedAt(selectedCapturedAt);
  };

  const handlePrev = () => {
    if (current_slider_key <= 0) return;
    const selectedImage = footage[current_slider_key - 1].thumbnail ?? footage[current_slider_key - 1].path;
    const selectedCapturedAt = footage[current_slider_key - 1].captureAt;
    set_displayed_image(selectedImage);
    set_current_slider_key(current_slider_key - 1);
    set_displayed_capturedAt(selectedCapturedAt);
  };

  return (
    <Box className="mt-2" style={{ background: 'rgba(249, 206, 10, 0.15)', position: 'relative' }}>
      <img
        src={!!displayed_image ? `${process.env.REACT_APP_S3}/${displayed_image}` : NoImage}
        alt="View of "
        style={{ objectFit: 'cover', width: '100%', aspectRatio: '4/2' }}
      />
      {!!footage[footage.length - 1]?.panorama && (
        <PanoramicDialog
          selectedImage={displayed_image}
          openModal={openPanoramicModal}
          setOpenModal={set_openPanoramicModal}
        />
      )}

      <Box className="px-4 my-auto" style={{ background: 'rgb(249, 206, 10)' }}>
        <div className="pt-2" style={{ textAlign: 'center', fontSize: 12, fontWeight: 700 }}>
          {moment(displayed_capturedAt).format('DD MMM yyyy, hh:mmA')}
        </div>
        <div className="d-flex">
          <Typography className="mt-2">
            {moment(footage[0].captureAt).format('YYYY')}
          </Typography>
          <Box className="w-100 mt-1 px-4">
            <TimeSlider
              defaultValue={current_slider_key}
              step={1}
              marks={footage}
              min={0}
              max={footage.length - 1}
              onChangeCommitted={onChangeTracker}
              key={current_slider_key}
              ValueLabelComponent={valueLabelComponent}
              valueLabelFormat={moment(displayed_capturedAt).format('DD MMM yyyy, hh:mmA')}
            />
          </Box>
          <Typography className="mt-2">
            {moment(footage[footage.length - 1].captureAt).format('YYYY')}
          </Typography>
          <Box className="d-flex" style={{ marginTop: -3 }}>
            <IconButton onClick={handlePrev}>
              <NavigateBefore />
            </IconButton>
            <IconButton onClick={handleNext}>
              <NavigateNext />
            </IconButton>
          </Box>
        </div>
      </Box>
      {/* <InfoCard {...h} /> */}
    </Box>
  );
};

function valueLabelComponent(props) {
  const { children, open, value } = props;
  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const TimeSlider = styled(Slider)(() => ({
  color: 'transparent',
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    marginTop: -6,
    height: 15,
    width: 15,
    backgroundColor: 'rgb(249, 206, 10)',
    border: '1px solid black',
  },
  '& .MuiSlider-valueLabel': {
    display: 'none',
  },
  '& .MuiSlider-rail': {
    backgroundColor: 'black',
  },
  '& .MuiSlider-mark': {
    backgroundColor: 'black',
    marginTop: -3,
    marginBottom: 0,
    borderRadius: 40,
    height: 8,
    width: 8,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'black',
    },
  },
  '& .MuiSlider-markLabel': {
    display: 'none',
  },
}));
