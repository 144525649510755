/* eslint-disable max-lines */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import HighlightTabs from '@Components/HighlightTabs';
import {
  Grid, Avatar,
  IconButton, Tooltip, Switch, CircularProgress,
} from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import {
  FilterList,
} from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
import FilterIcon from '@Assets/Icons/filter-solid.svg';
import _ from 'lodash';
import { capitalize } from '@Helpers';
import Button from '@Components/Button';
import AvatarIcon from '@Components/AvatarIcon';
import SearchBox from '@Components/SearchBox';

const tabslist = [
  { label: 'Page Access', value: '0' },
  { label: 'Asset Access', value: '1' },
];

export default function UserAccess(h) {
  const history = useHistory();
  const [onIconHover, setOnIconHover] = useState({});
  const toggleSwitchAsset = (event) => {
    let data = [];
    if (event.target.checked) {
      data = _.uniq([...h.selectedAsset, Number(event.target.value)]);
      h.setSelectedAsset(data);
      h.updateAssetAccess(data.join(','));
      return;
    }
    data = h.selectedAsset.filter(u => u !== Number(event.target.value));
    h.setSelectedAsset(data);
    h.updateAssetAccess(data.join(','));
  };

  const toggleSwitchPage = (event) => {
    let data = [];
    if (event.target.checked) {
      data = _.uniq([...h.selectedPage, Number(event.target.value)]);
      h.setSelectedPage(data);
      h.updateUser({ page_access: data.join(',') });
      return;
    }
    data = h.selectedPage.filter(u => u !== Number(event.target.value));
    h.setSelectedPage(data);
    h.updateUser({ page_access: data.join(',') });
  };

  const pageAccessGroup = _.groupBy(h.pageAccessList, x => x.group);
  const HasTeam = () => {
    return (
      <>
        <h6 className="mt-5 text-center mb-3" style={{ fontSize: 16 }}>
          This user {{ 0: 'page', 1: 'asset' }[h.accessTypeTab]} access is according to their assigned team:
        </h6>
        <div className="flex-standard">
          <div className="p-3 w-50 d-flex" style={{ border: '1px solid grey', borderRadius: 5, gap: 10 }}>
            <AvatarIcon
              user={h.team}
              size="2rem"
              fontSize="12px"
              colorType="inherit"
              backgroundColor={!!h.team.colour && `#${h.team.colour}`}
              style={{ color: !h.team.colour ? 'black' : 'white' }}
            />
            <div style={{ width: '80%' }}>
              <p style={{ fontSize: 15 }}>{h.team?.name}</p>
              <div className="d-flex justify-content-between">
                <p style={{ fontSize: 12, color: 'grey' }}>Team members:</p>
                <AvatarGroupIcon max={3}>
                  {h.team?.Users?.map((u, i) => (
                    <Tooltip title={u?.name}>
                      <Avatar
                        style={{
                          backgroundColor: `#${ Math.floor(Math.random() * 16777215).toString(16)}`,
                          height: '15px',
                          width: '15px',
                          fontSize: 8,
                          zIndex: onIconHover[i] ? 999 : !i ? 10 : 1,
                        }}
                        alt={u.name}
                        src={`${process.env.REACT_APP_S3}/${u?.image}`}
                        onMouseOver={() => setOnIconHover({ [i]: !onIconHover[i] })}
                        onMouseLeave={() => setOnIconHover({ [i]: !!onIconHover[i] })}
                      />
                    </Tooltip>
                  ))}
                </AvatarGroupIcon>
              </div>
            </div>
          </div>
        </div>
        <p className="text-center mt-4" style={{ fontSize: 12 }}>
          You may manage the group {{ 0: 'page', 1: 'asset' }[h.accessTypeTab]} access at the&nbsp;
          {{ 0: 'Page', 1: 'Asset' }[h.accessTypeTab]} List page.<br />
          {/* You have the option to overwrite this user {{ 0: 'page', 1: 'asset' }[h.accessTypeTab]} access but it will<br />
          remove this user from the assigned team. */}
        </p>
        <div className="d-flex justify-content-center mt-2">
          <Button size="small" style={{ width: '6.5rem' }} onClick={() => history.push('/analysis-management?view=team')}>VIEW TEAM</Button>
        </div>
      </>
    );
  };

  const UserAccessDisplay = () => (
    <div>
      {h.accessTypeTab === '0'
        ? (
          <>
            {h.isLoadingPageAccess && (
              <div className="position-relative" style={{ height: '20rem' }}>
                <CircularProgress
                  className="position-absolute"
                  style={{
                    top: '50%', left: '50%', marginTop: -20, marginLeft: -18, color: 'var(--primary-color)',
                  }}
                />
              </div>
            )}
            {
              Object.keys(pageAccessGroup).map(title => (
                [
                  <div className="d-flex justify-content-between mb-2 mt-3"><p>{capitalize(title)}</p><p><IOSSwitch /></p></div>,
                  pageAccessGroup[title].map(eachPage => (
                    <div className="d-flex justify-content-between">
                      <p className="ml-4 mt-1">{eachPage?.label}</p>
                      <p><IOSSwitch value={eachPage.id} checked={!!h.selectedPage.includes(eachPage.id)} onChange={toggleSwitchPage} /></p>
                    </div>
                  )),
                ]
              ))
            }
          </>
        )
        : (
          <>
            {h.isLoadingAssetAccess && (<CircularProgress style={{ color: 'var(--primary-color)' }} />)}
            {h.assetAccessList.map(eachAsset => (
              <div className="d-flex justify-content-between mt-2">
                <p className="mt-1">{eachAsset.name}</p>
                <p><IOSSwitch value={eachAsset.id} checked={!!h.selectedAsset.includes(eachAsset.id)} onChange={toggleSwitchAsset} /></p>
              </div>
            ))}
          </>
        )}
    </div>
  );

  return (
    <div className="bg-white shadow rounded-xl px-4 h-100 mb-4 w-100">
      <Grid item xs={12} direction="row">
        <HighlightTabs
          items={tabslist}
          tab={h.accessTypeTab}
          setTab={h.setAccessTypeTab}
          customStyle={{
            fontSize: '15px', minWidth: '50%', minHeight: '20px',
          }}
        />
        <div className="d-flex align-items-center justify-content-between mb-2">
          <SearchBox onChange={(e) => h.setSearchKey(e.target.value)} />
          <div className="d-flex align-items-end align-items-center">
            <Tooltip title="Filter (Coming Soon)">
              <img src={FilterIcon} style={{ padding: '15px 10px 10px 0px', cursor: 'pointer', width: '15px' }} />
            </Tooltip>
            <IconButton>
              <FilterList />
            </IconButton>
          </div>

        </div>
        <div className="d-flex justify-content-between">
          <p className="text-secondary" style={{ fontSize: 14 }}>
            {{ 0: 'PAGE', 1: 'ASSET' }[h.accessTypeTab]}
          </p>
          <p className="text-secondary" style={{ fontSize: 14 }}>ACCESS</p>
        </div>
        {!!h.user?.TeamId ? <HasTeam /> : <UserAccessDisplay />}
      </Grid>
    </div>
  );
}

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#04847C',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? 'grey' : 'lightgrey',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const AvatarGroupIcon = styled(AvatarGroup)({
  '& .MuiAvatar-root': {
    height: '15px',
    width: '15px',
    fontSize: 8,
    backgroundColor: `#${ Math.floor(Math.random() * 16777215).toString(16)}`,
    '&:hover': {
      zIndex: '999 !important',
    },
  },
});
