import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { useHistory, useParams } from 'react-router-dom';

export default () => {
  const history = useHistory();
  const { ReportId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [reportDetail, setReportDetail] = useState(null);
  const [category, setCategory] = useState('Minor');
  const [correctiveAction, setCorrectiveAction] = useState(1);
  const [specifyReason, setSpecifyReason] = useState(null);
  const [files, setFiles] = useState([]);
  const [filesImage, setFilesImage] = useState([]);
  const [filesDrawing, setFilesDrawing] = useState([]);
  const [signature, setSignature] = useState(null);
  const [resultSuccess, setResultSuccess] = useState(true);
  const [reportPartA, setReportPartA] = useState(null);
  const [siteDrawingList, setSiteDrawingList] = useState([]);
  const [selectedSiteDiary, setSelectedSiteDrawing] = useState([]);

  const handleNextStep = () => { setActiveStep((prev) => prev + 1); };
  const handleBackStep = () => { setActiveStep((prev) => prev - 1); };

  useEffect(() => {
    setSpecifyReason(null);
  }, [correctiveAction]);

  useEffect(() => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.showNcrReport(ReportId),
      onSuccess: ({ data }) => {
        setReportDetail(data);
        setIsLoading(false);
        if (!!data.NonConformityReportFiles.find(e => e.stage === 2)) {
          toast('error', 'Access Denied - Part B1 Completed');
          history.push(`/project/report?id=${data.AssetId}`);
        }
      },
      onFail: () => {
        setIsLoading(false);
        toast('error', 'Error');
        history.push('/denied');
      },
    });
  }, [ReportId]);

  useEffect(() => {
    if (!reportDetail) return;
    setReportPartA(reportDetail?.NonConformityReportFiles.find(e => e.stage === 1));
    Api({
      endpoint: endpoints.getSiteDrawings(reportDetail.AssetId, 'site_drawing'),
      onSuccess: ({ data }) => {
        setSiteDrawingList(data);
      },
      onFail: setSiteDrawingList([]),
    });
  }, [reportDetail]);

  const UpdateReport = () => {
    const data = {
      stage: 2,
      details: {
        category,
        correctiveAction: {
          1: 'To be disposed of site',
          2: 'To be rectified (submit proposed corrective action)',
          3: 'To be used for other purpose / application',
        }[correctiveAction],
        specifyReason,
        verified_by: reportDetail.created_by,
        filesDrawing: selectedSiteDiary.map(e => {
          return siteDrawingList.find(f => f.id === e).path;
        }),
      },
      signature,
    };
    setIsLoading(true);
    Api({
      endpoint: endpoints.updateNcrReport(ReportId),
      data,
      files,
      filesImage,
      filesDrawing,
      onSuccess: () => {
        toast('success', 'NCR Report updated');
        setIsLoading(false);
        setResultSuccess(true);
        handleNextStep();
      },
      onFail: () => {
        toast('error', 'Opss, something went wrong, please try again.');
        setIsLoading(false);
        setResultSuccess(false);
        handleNextStep();
      },
    });
  };

  return {
    activeStep,
    setActiveStep,
    handleNextStep,
    handleBackStep,
    signature,
    setSignature,
    UpdateReport,
    ReportId,
    resultSuccess,
    isLoading,
    reportDetail,
    category,
    setCategory,
    correctiveAction,
    setCorrectiveAction,
    specifyReason,
    setSpecifyReason,
    files,
    setFiles,
    filesImage,
    setFilesImage,
    filesDrawing,
    setFilesDrawing,
    reportPartA,
    siteDrawingList,
    selectedSiteDiary,
    setSelectedSiteDrawing,
  };
};
