/* eslint-disable max-lines */
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import CircularProgressBar from '@Components/CircularProgressBar';
import MainContentNavbar from '@Components/MainContentNavbar';
import {
  Button, CircularProgress, Grid, IconButton,
} from '@material-ui/core';
import {
  AddOutlined, CheckBoxOutlined, CameraAlt, ListAlt, Visibility, Dvr
} from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
import MultiColorProgressBar from '@Components/MultiColorProgressBar';
import { Link, useHistory } from 'react-router-dom';
import { formatBytes, multiKeysFilter } from '@Helpers';
import {
  AssetFile, Icon360, Cube3D, ReportIcon, Gantt,
} from '@Assets/Icons';
import templateIcon from '@Assets/Icons/masterist_csv_icon.svg';

const SeverityButton = styled(Button)(() => ({
  backgroundColor: 'transparent',
  color: 'var(--primary-color)',
  border: '1px solid var(--primary-color)',
  paddingBottom: '3px !important',
  width: '2rem',
}));

const filesLength = (arr, keys) => arr.filter(multiKeysFilter(keys)).length;

export default (h) => {
  const [orthoLength, setOrthoLength] = useState(0);
  const [threeDimensionLength, setThreeDimensionLength] = useState(0);
  const [inspectionPhotoLength, setInspectionPhotoLength] = useState(0);
  const [inspectionVideoLength, setInspectionVideoLength] = useState(0);
  const [reportLength, setReportLength] = useState(0);
  const [threesixtyLength, setThreesixtyLength] = useState(0);
  const usagePercentage = (h.totalSize / h.selectedStorage) * 100;
  const history = useHistory()

  useEffect(() => {
    if (h.asset?.InspectionFiles?.length) {
      const arrMedia = h.asset?.InspectionFiles;
      setInspectionPhotoLength(filesLength(arrMedia, { isVideo: 0 }));
      setInspectionVideoLength(filesLength(arrMedia, { isVideo: 1 }));
    }
    if (h.asset?.AssetFiles?.length) {
      const arr = h.asset?.AssetFiles;

      setOrthoLength(filesLength(arr, { media_type: 'orthophotos', is_external: false }));
      setThreeDimensionLength(
        filesLength(arr, { media_type: '3d', is_external: false })
        + filesLength(arr, { media_type: 'point-clouds', is_external: false })
        + filesLength(arr, { media_type: '360-models', is_external: false }),
      );
      setReportLength(filesLength(arr, { media_type: 'site-reports', is_external: false }));
      setThreesixtyLength(filesLength(arr, { media_type: '360-models', is_external: false }));
    }
  }, [h.asset?.AssetFiles]);

  return (
    <Grid item xs={12} md={9}>
      <div style={{ padding: '10px 20px 0px 20px', height: '95%' }}>
        <MainContentNavbar to="/asset" text="Asset Storage Analysis" />
        <Grid container xs={12} className="mt-2 p-4" style={{ borderRadius: '10px', backgroundColor: 'var(--light-color)', gap: 25 }}>
          <div className="mr-4">
            <CircularProgressBar scale={1.25} x={15} value={usagePercentage} />
          </div>
          <div className="d-flex flex-column ml-1">
            <Grid item>
              <p className="text-light" style={{ fontSize: 14 }}>Total Asset Storage</p>
            </Grid>
            <Grid item>
              <h1 className="font-weight-bold" style={{ fontSize: 22 }}>{formatBytes(h.asset?.asset_size, true, 2)}</h1>
            </Grid>
          </div>
        </Grid>
        <div className="my-4" style={{ borderRadius: '10px', backgroundColor: 'var(--light-color)', padding: '10px 3%' }}>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h1 className="color-primary" style={{ fontSize: 22 }}>Asset Storage Usage</h1>
            {!!h.user?.can_view_asset_files && (
              <Link to={`/asset-file-list/${h.asset?.id}`}>
                <SeverityButton className="d-flex my-auto py-1 mr-2">
                  <AssetFile color="var(--primary-color)" height={20} width={20} />
                </SeverityButton>
              </Link>
            )}
          </div>
          <MultiColorProgressBar {...h} />
          <Grid container spacing={3} className="d-flex align-items-stretch my-3">
            {[
              {
                icon: <CameraAlt style={{ color: 'var(--light-color)', width: 20 }} />,
                bg_color: '#5397FE',
                title: 'Inspection',
                image: inspectionPhotoLength,
                video: inspectionVideoLength,
                size: h.asset?.inspections_size,
                view_button: false,
                view_access: true,
              },
              {
                icon: <CheckBoxOutlined style={{ color: 'var(--light-color)', width: 25 }} />,
                bg_color: '#F5533D',
                title: '2D',
                file: orthoLength,
                size: h.asset?.orthophotos_size,
                upload_button: true,
                view_button: false,
                view_access: true,
              },
              {
                icon: <Cube3D color="var(--light-color)" />,
                bg_color: '#35CC57',
                title: '3D',
                width: 20,
                file: threeDimensionLength,
                size: h.asset?.['3d_size'],
                upload_button: true,
                view_button: false,
                view_access: true,
              },
              {
                icon: <ReportIcon color="var(--light-color)" />,
                bg_color: '#FFBA0A',
                title: 'Report',
                file: reportLength,
                size: h.asset?.site_reports_size,
                upload_button: true,
                view_button: false,
                view_access: true,
              },
              {
                icon: <Icon360 style={{ color: 'var(--light-color)', padding: 0 }} />,
                bg_color: '#CC35A9',
                title: '360',
                file: threesixtyLength,
                size: h.asset?.['360_size'] ?? 0,
                upload_button: false,
                view_button: false,
                view_access: true,
              },
              {
                // todo: change icon
                icon: <Gantt style={{ color: 'var(--light-color)' }} />,
                bg_color: 'darkblue',
                title: 'Project Timeline',
                file: threesixtyLength,
                size: h.asset?.timeline_size ?? 0, // todo
                upload_button: true,
                view_button: false,
                view_access: true,
              },
              {
                // todo: change icon
                icon: <Dvr style={{ color: 'var(--light-color)' }} />,
                bg_color: '#025C64',
                title: 'Masterlist',
                file: threesixtyLength,
                size: h.asset?.masterlist_size ?? 0, // todo
                upload_button: true,
                view_button: true,
                view_access: true,
              },
            ].map(data => !!data.view_access && (
              <Grid item xs={12} md={6}>
                <div className="bg-color-container p-4" style={{ height: '160px' }}>
                  <div style={{ maxHeight: '70px' }}>
                    <IconButton
                      style={{
                        backgroundColor: data.bg_color, borderRadius: 30, padding: 10, marginBottom: 5, width: 20, height: 20,
                      }}
                    >
                      {data.icon}
                    </IconButton>
                    <br />
                    <div className="d-flex justify-content-between">
                      <h3 className="color-primary font-weight-bold" style={{ fontSize: 18 }}>{data.title} Data</h3>
                      <h3 className="color-primary font-weight-bold" style={{ fontSize: 18 }}>{formatBytes(data.size, true, 2)} used</h3>
                    </div>
                    <br />
                    {(!!data.image || data.image == 0) && <FileUsage value={data.image} label="image(s)" />}
                    {(!!data.video || data.video == 0) && <FileUsage value={data.video} label="video(s)" />}
                    {(!!data.file || data.file == 0) && <FileUsage value={data.file} label="file(s)" />}
                  </div>
                  <br />
                  <div className="d-flex align-items-center justify-content-between">
                    {!!data.view_button && (
                      <div className="d-flex align-items-center mt-5">
                        {/* <IconButton children={<img src={templateIcon} />}/> */}
                        <img src={templateIcon} style={{ marginRight: 20 }} />
                        <Link to={`/masterlist-list/${h.asset?.id}`}>
                          <Button
                            variant="contained"
                            size="small"
                            style={{ color: '#black', backgroundColor: h.isLoading ? '#' : 'var(--container-color)', height: 30, outline: '1px solid black' }}
                            disabled={h.isLoading}
                          >
                            <Visibility style={{ width: 14 }} />
                            <p style={{ fontSize: 12 }}>&nbsp;&nbsp;View</p>
                          </Button>
                        </Link>
                      </div>
                    )}
                    {!!data.upload_button && (
                      <div className="d-flex">
                        <div className="position-relative">
                          <Link to={`/asset/${h.asset?.id}/${data.title.replace(' ', '')}`}>
                            <Button
                              variant="contained"
                              className="mt-5"
                              size="small"
                              style={{ color: '#FFFFFF', backgroundColor: h.isLoading ? '#808080a8' : 'var(--primary-color)', height: 30 }}
                              disabled={h.isLoading}
                            >
                              <AddOutlined style={{ width: 14 }} />
                              <p className="text-white" style={{ fontSize: 12 }}>Upload {data.title} Data</p>
                            </Button>
                          </Link>
                          {h.isLoading
                          && (
                            <CircularProgress
                              size={24}
                              className="color-primary position-absolute"
                              style={{
                                top: '50%', left: '50%', marginTop: -12, marginLeft: -12,
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </Grid>
  );
};

const FileUsage = ({ value, label }) => <p className="color-primary" style={{ fontSize: 14, marginBottom: 5, color: '#1F3566' }}>{value} {label}</p>;