import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

const riskValueStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  fontSize: 5,
  whiteSpace: 'nowrap',
  fontWeight: 600,
  cursor: 'pointer',
};

export default ({
  scale = 1, x = 0, y = 0, ...props
}) => {
  const RiskValue = () => {
    if (!props.value && props.value !== 0) return 'Loading...';
    if (props.value <= 25) return 'Very Good';
    if (props.value <= 50) return 'Normal';
    if (props.value > 50) return 'Risk';
  };

  const progressColour = props.value <= 25 ? '#6ed29d' : props.value <= 50 ? '#2960f6' : '#e4542d';
  return (
    <Box position="relative" display="inline-flex" style={{ transform: `scale(${scale}) translate(${x}px, ${y}px)`, marginTop: '0.75rem' }}>
      <CircularProgress
        variant="determinate"
        thickness={3}
        value={100}
        style={{ color: progressColour, opacity: 0.2 }}
        className="position-absolute"
      />
      <CircularProgress
        variant="determinate"
        thickness={3}
        style={{ color: progressColour }}
        value={props?.value}
      />
      <p style={{ ...riskValueStyle }}><RiskValue /></p>
    </Box>
  );
};
