/* eslint-disable linebreak-style */
import { useEffect, useState } from 'react';
import moment from 'moment';
import Api, { endpoints } from '@Helpers/api';
// import queryString from 'query-string';
import { useHistory, useParams } from 'react-router-dom';

export default ({ user, isReview }) => {
  const history = useHistory();
  const { ReportUuid } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [signature, setSignature] = useState(null);
  const [resultSuccess, setResultSuccess] = useState(false);
  const [remark, setRemark] = useState(null);
  const [isRejected, setIsRejected] = useState(false);

  const handleNextStep = () => { setActiveStep((prev) => prev + 1); };
  const handleBackStep = () => { setActiveStep((prev) => prev - 1); };

  useEffect(() => {
    if (!ReportUuid) return;
    setIsLoading(true);
    const NotAllowStatus = isReview ? ['Approved', 'Rejected', 'Completed'] : ['Rejected', 'Completed'];
    const AllowStatus = isReview ? ['Submited'] : ['Approved'];
    Api({
      endpoint: endpoints.showSiteDiary(ReportUuid),
      onSuccess: ({ data }) => {
        setIsLoading(false);
        if (!data.filter(e => AllowStatus.includes(e.status)).length) {
          toast('error', 'Access Denied');
          history.push('/project');
        } else if (!!data.filter(e => NotAllowStatus.includes(e.status)).length) {
          toast('error', 'Access Denied');
          history.push('/project');
        }
      },
      onFail: () => {
        setIsLoading(false);
        toast('error', 'Error getting assets data. Please try again later.');
      },
    });
  }, [ReportUuid]);

  const submitReport = () => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.updateSiteDiary(ReportUuid),
      data: { signature, status: isReview ? 'Approved' : 'Completed' },
      onSuccess: () => {
        toast('success', `Site Diary ${isReview ? 'Reviewed' : 'Approved'}`);
        setIsLoading(false);
        setResultSuccess(true);
        handleNextStep();
      },
      onFail: () => {
        toast('error', 'Opss, something went wrong, please try again.');
        setIsLoading(false);
        setResultSuccess(false);
        handleNextStep();
      },
    });
  };

  const rejectReport = () => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.updateSiteDiary(ReportUuid),
      data: { signature, status: 'Rejected', remark },
      onSuccess: () => {
        toast('success', 'Site Diary Rejected');
        setIsLoading(false);
        setIsRejected(true);
        setResultSuccess(true);
        handleNextStep();
      },
      onFail: () => {
        toast('error', 'Opss, something went wrong, please try again.');
        setIsLoading(false);
        setResultSuccess(false);
        handleNextStep();
      },
    });
  };

  return {
    activeStep,
    setActiveStep,
    handleNextStep,
    signature,
    setSignature,
    resultSuccess,
    isLoading,
    submitReport,
    remark,
    setRemark,
    rejectReport,
    ReportUuid,
    isRejected,
  };
};
