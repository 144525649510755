import React, { useRef, useEffect, useState } from 'react';
import Button from '@Components/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { AddOutlined, Close } from '@material-ui/icons';
import {
  DialogTitle, IconButton,
} from '@material-ui/core';
import lottie from 'lottie-web';
import SignatureDrawer from '@Components/SignaturePad/SignatureDrawer';

export default ({
  open, setOpen, mergeFunction, signature, setSignature,
}) => {
  const container = useRef(null);
  const [countRefresh, setCountRefresh] = useState(0);
  const handleClose = () => {
    setOpen(false);
  };
  const isFieldEmpty = !(!!signature);

  useEffect(() => {
    if (!open) return;
    if (!container.current) return;
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('@Assets/Icons/question-lottie.json'),
    });
  }, [open, container.current, countRefresh]);

  useEffect(() => {
    if (!open) return;
    if (!!container.current) return;
    setTimeout(() => {
      setCountRefresh(prev => prev + 1);
    }, 100);
  }, [open, countRefresh]);

  return (
    <>
      <div className="w-100 d-flex justify-content-end">
        <Button
          variant="contained"
          className="mr-4"
          onClick={() => setOpen(true)}
          style={{
            color: '#FFFFFF', backgroundColor: 'var(--primary-color)', fontFamily: 'CeraProRegular', height: 30,
          }}
        >
          <AddOutlined style={{ fontSize: 14 }} />
          <p className="text-white" style={{ fontSize: 12 }}>Merge Log</p>
        </Button>
      </div>
      <MuiThemeProvider theme={getMuiTheme()}>
        <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose}>
          <DialogTitle className="py-1">
            <div className="d-flex justify-content-end align-items-center">
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent className="mx-0">
            <div
              className="d-flex justify-content-center mx-auto"
              ref={container}
              style={{
                height: '70%', width: '70%', marginTop: '-70px', marginBottom: '-30px',
              }}
            />
            <p className="text-center" style={{ fontWeight: 'bolder', fontSize: 20 }}>Confirm to Merge Log ?</p>
            <p className="text-center">Are you sure you want to merge the logs ?</p>
            <SignatureDrawer setSignature={setSignature} />
          </DialogContent>
          <DialogActions className="mb-1 d-flex justify-content-around">
            <Button
              onClick={handleClose}
              style={{ color: 'var(--primary-color)', backgroundColor: 'white', border: '1px solid var(--primary-color)' }}
            >
              CANCEL
            </Button>
            <Button
              disabled={isFieldEmpty}
              onClick={() => {
                setOpen(false);
                mergeFunction();
              }}
            >
              CONFIRM
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    </>
  );
};

const getMuiTheme = () => createTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
});
