/* eslint-disable max-len */
import MainContentContainer from '@Components/MainContentContainer';
import Navbar from '@Components/Navbar';
import { MenuItem, TextField } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import React from 'react';
import TableAnalytics from '@Components/TableAnalytics';
import NoData from '@Assets/Images/DataNotFound.svg';
import Chart from './Chart';
import Hook from './hook';

const Collist = [
  { label: 'Manpower', key: 'person_count' },
  { label: 'Manpower (Non-Compliance)', key: 'person_no_helmet_count' },
  { label: 'Rain Intensity', key: 'rain_intensity' },
];

const ManpowerAnalytic = (props) => {
  const h = Hook(props);
  return (
    <MainContentContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Navbar
          to="/project/"
          text="Manpower Analytic"
          subtext={props.filtered_projects[props.selected_project]?.name}
        />
        <div style={{ transform: 'scale(0.8)' }}>
          <TextField
            select
            variant="outlined"
            value={h.duration}
            onChange={e => h.setDuration(e.target.value)}
            size="small"
            fullWidth
          >
            {h.filterTime.map(m => (
              <MenuItem value={m.value} style={{ fontSize: 12 }}><div style={{ display: 'flex', alignItems: 'center' }}><CalendarToday style={{ width: 20 }} />&nbsp;&nbsp;{m.label}</div></MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      {!!h.footage_detection.length && !!h.chartData.length
        ? <Chart chartData={h.chartData} {...h} />
        : <div className="d-flex justify-content-center"> <img src={NoData} style={{ height: '55vh' }} /> </div>}
      <TableAnalytics
        {...h}
        pagination={h.pagination}
        tableList={h.footage_detection_filtered ?? h.footage_detection}
        collist={Collist}
        isFiltered={false}
      />
    </MainContentContainer>
  );
};

export default ManpowerAnalytic;
