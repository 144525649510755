import { useState, useEffect } from 'react';
import {
  Grid, Button, TextField, MenuItem,
} from '@material-ui/core';
import {
  ChevronLeft, DescriptionOutlined,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import SearchBox from '@Components/SearchBox';
import { Link } from 'react-router-dom';
import Table from '@Components/MaterialTable/v9';

const useStyles = makeStyles({
  paper: {
    width: '100%',
    height: '-webkit-fill-available',
    padding: 20,
  },
  container: {
    height: '-webkit-fill-available',
    padding: 10,
  },
  sideNavButton: {
    width: '100%',
    justifyContent: 'normal',
  },
});

const columns = [
  {
    name: 'No',
    selector: 'number',
  },
  {
    name: 'Site Name',
    selector: 'siteName',
  },
  {
    name: 'Location',
    selector: 'location',
  },
  {
    name: 'Site Manager',
    selector: 'siteManager',
  },
  {
    name: 'Start Date',
    selector: 'startDate',
  },
  {
    name: 'Progress',
    selector: 'progress',
  },
  {
    name: 'End Date',
    selector: 'endDate',
  },
];
export default function ProjectProgressDetails(props) {
  const classes = useStyles();
  const [projectList, setProjectList] = useState([]);
  const [filter, setFilter] = useState(1);
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    if (!props.assets.length) return;
    setProjectList(props.assets);
  }, [props.assets]);

  useEffect(() => {
    if (!props.assets.length) return;
    const searchedArray = props.searchKeyword(searchKey, props.assets);
    const sortingArray = {
      1: searchedArray.slice().sort((a, b) => a.name.localeCompare(b.name)),
      2: searchedArray.slice().sort((b, a) => a.name.localeCompare(b.name)),
      3: searchedArray.slice().sort((a, b) => a.expecteddecommissionAt.localeCompare(b.expecteddecommissionAt)),
      4: searchedArray.slice().sort((b, a) => a.expecteddecommissionAt.localeCompare(b.expecteddecommissionAt)),
    }[filter];
    setProjectList(sortingArray);
  }, [filter, props.assets, searchKey]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={12}>
        <p className="d-flex align-items-center">
          <Link to="/dashboard" style={{ marginBlock: 20, color: 'grey' }} className="d-flex align-items-center">
            <ChevronLeft />Overview/
          </Link>
          <span style={{ color: 'blue' }}>Project Deadline</span>
        </p>
        <h1 className="pl-3" style={{ fontWeight: 600, fontSize: 20 }}>Project Deadline </h1>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p className="pl-3" style={{ marginTop: 10, marginBottom: 20, color: 'grey', fontSize: 14 }}>Manage and track your progress of site development.</p>
          {/* <Button
            style={{
              backgroundColor: '#2960f6', color: 'white', textTransform: 'none', height: '-webkit-fill-available',
            }}
          >
            <DescriptionOutlined style={{ width: 20, marginRight: 5 }} />
            Generate Report
          </Button> */}
        </div>
      </Grid>
      <hr width="100%" />
      <Grid container item xs={12} md={12} style={{ outline: '1px #DCDCDC solid', borderRadius: 10, padding: 10 }}>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 20,
        }}
        >
          <SearchBox onChange={(e) => setSearchKey(e.target.value)} />
          <div className="d-flex align-items-center" style={{ transform: 'scale(0.8)' }}>
            <p style={{ color: '#a4a4a4' }}>Sort By: </p>
            <TextField
              select
              variant="standard"
              value={filter}
              onChange={e => setFilter(e.target.value)}
              InputProps={{ disableUnderline: true, style: { fontFamily: 'CeraProRegular' } }}
              style={{ marginLeft: 10 }}
            >
              <MenuItem value={1} style={{ fontFamily: 'CeraProRegular', fontSize: 12 }}>A --&#8594; Z</MenuItem>
              <MenuItem value={2} style={{ fontFamily: 'CeraProRegular', fontSize: 12 }}>Z --&#8594; A</MenuItem>
              <MenuItem value={3} style={{ fontFamily: 'CeraProRegular', fontSize: 12 }}>Oldest --&#8594; Latest</MenuItem>
              <MenuItem value={4} style={{ fontFamily: 'CeraProRegular', fontSize: 12 }}>Latest --&#8594; Oldest</MenuItem>
            </TextField>
          </div>
        </div>
        <Table
          tableHead
          tableData={projectList}
          tableColumns={columns}
          tableMinWidth={900}
          tableMaxHeight={900}
          {...props}
        />
      </Grid>
    </Grid>
  );
}
