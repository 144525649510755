import Button from '@Components/Button';
import { InputLabel, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default (h) => {
  const replaceText = text => text.replace('ncr/', '');
  return (
    <div className="mt-5 mx-auto" style={{ minHeight: '60vh', width: '30%' }}>
      <div className="mb-3">
        <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Image</InputLabel>
        <p>{h.reportPartB1.details.filesImage.length} images uploaded</p>
        <Grid container spacing={2} className="my-1">
          {!!h.reportPartB1.details.filesImage.length && (
            h.reportPartB1.details.filesImage.map(image => (
              <Grid xs={2} item>
                <Link to={{ pathname: `${process.env.REACT_APP_S3}/${image}` }} target="_blank">
                  <img src={`${process.env.REACT_APP_S3}/${image}`} style={{ width: '100%', height: '100%' }} />
                </Link>
              </Grid>
            ))
          )}
        </Grid>
      </div>
      <div className="my-3">
        <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>Drawing</InputLabel>
        <p>{h.reportPartB1.details.filesDrawing.length} items uploaded</p>
        <Grid container spacing={2} className="my-1">
          {!!h.reportPartB1.details.filesDrawing.length && (
            h.reportPartB1.details.filesDrawing.map(image => (
              <>
                <Grid xs={4} item>
                  <Link to={{ pathname: `${process.env.REACT_APP_S3}/${image}` }} target="_blank">
                    <img src={`${process.env.REACT_APP_S3}/static/icons/file.png`} style={{ width: 100, height: 100 }} />
                  </Link>
                </Grid>
                <Grid xs={8} item className="d-flex" alignItems="center">
                  {replaceText(image)}
                </Grid>
              </>
            ))
          )}
        </Grid>
      </div>
      <div className="my-3">
        <InputLabel style={{ fontSize: 16, transform: 'scale(0.75)', margin: 0 }}>File</InputLabel>
        <p>{h.reportPartB1.details.files.length} items uploaded</p>
        <Grid container spacing={2} className="my-1">
          {!!h.reportPartB1.details.files.length && (
            h.reportPartB1.details.files.map(image => (
              <>
                <Grid xs={4} item>
                  <Link to={{ pathname: `${process.env.REACT_APP_S3}/${image}` }} target="_blank">
                    <img src={`${process.env.REACT_APP_S3}/static/icons/file.png`} style={{ width: 100, height: 100 }} />
                  </Link>
                </Grid>
                <Grid xs={8} item className="d-flex" alignItems="center">
                  {replaceText(image)}
                </Grid>
              </>
            ))
          )}
        </Grid>
      </div>
      <div className="d-flex justify-content-end mt-4">
        <Button variant="text" onClick={h.handleBackStep}>
          PREVIOUS
        </Button>
        <Button onClick={h.handleNextStep}>
          NEXT
        </Button>
      </div>
    </div>
  );
};
