/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import {
  Card, CardActionArea, CardContent, CardMedia, Typography,
} from '@material-ui/core';
import mapping_2d from '@Assets/Images/mapping_2d.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  media: { height: 200 },
  title: { height: 60, fontSize: 18 },
  description: { height: '21rem', overflow: 'auto', padding: '0 5px' },
});

export default ({ isAssetsType = false, ...h }) => {
  const classes = useStyles();
  const [onHover, setOnHover] = useState(false);
  const [animation, setAnimation] = useState(false);
  const nextStep = () => {
    if (!h.view) {
      h.handleNextStepAssetType();
      h.setAssetType(h.data.id);
    }
  };
  const assetImage = !!h.data?.image ? `${process.env.REACT_APP_S3}/${h.data?.image}` : mapping_2d;

  useEffect(() => {
    if (!isAssetsType) return;
    if (onHover) { setAnimation(true); return; }
    setAnimation(false);
    return;
  }, [onHover]);

  const fontDescriptions = {
    fontSize: 12,
  }

  return (
    <Card onClick={nextStep} onMouseOver={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)} style={{ backgroundColor: animation && 'var(--primary-color)', transition: 'all .25s' }}>
      <CardActionArea>
        <CardMedia className={classes.media} image={assetImage} title={h.data.name} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" className={classes.title} style={{ color: animation && 'white', transition: 'all .25s' }}>
            {h.data.name}
          </Typography>
          <Typography className={classes.description} variant="body2" color="textSecondary" component="p" style={{ position: 'relative' }}>
            {h.data.description.split('|').map(e => (
              <>
                <p className={`text-justify ${animation ? 'text-white' : 'text-secondary'}`} style={{ color: animation && 'white', transition: 'all .25s', ...fontDescriptions }}>{e}</p>
                <br />
              </>
            )) ?? 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'}
            <p className="mt-2" style={{ position: 'absolute', bottom: 5, ...fontDescriptions }}>
              <a href="https://ofo.my" target="_blank" rel="noopener noreferrer" className={`text-justify ${animation ? 'text-white' : 'color-text-primary"'}`}>
                Learn More
              </a>
            </p>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
