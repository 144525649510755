/* eslint-disable complexity */
/* eslint-disable max-lines */
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import {
  ExpandMore, Notifications, AllInclusive, NavigateBeforeSharp,
} from '@material-ui/icons';
import { useRouteMatch, Link } from 'react-router-dom';
import {
  Grid, Menu, MenuItem, Drawer, Box, Badge, Popover,
} from '@material-ui/core';
import { decimalNumberFormat, tokenFormatter } from '@Helpers';
import {
  DashboardIcon, MapIcon, BuildingIcon, HRIcon, MarketingIcon, BellIcon, ProjectSiteIcon,
} from '@Assets/Icons/TopbarIcons';
import tokenIcon from '@Assets/Images/RaiseToken-shadow.png';
import logo_raise from '@Assets/Images/supervision2_horizontal_nobg_v2.png';
// import logo_raise from '@Assets/Images/supervision2_horizontal_nobg.svg';
import { db } from '@Configs/firebase';
import { ref, onChildAdded } from 'firebase/database';
import Avatar from './Avatar';
import ActivityLog from './ActivityLog';

export default function TopBar(props) {
  const { path } = useRouteMatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorNotification, setAnchorNotification] = useState(null);
  const [showActivityLog, setShowActivityLog] = useState(false);
  const [activityLog, setActivityLog] = useState({});
  const [hasRead, setHasRead] = useState(false);
  const [isLogUpdated, setIsLogUpdated] = useState(0);
  const isOrgUnlimited = !!props?.['Organization.StoreStorage.is_token_unlimited'];
  const handleClickMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);
  const handleClickNotification = (e) => {
    setShowActivityLog(true);
    setAnchorNotification(e.currentTarget);
  };
  const handleCloseNotification = (e) => {
    setHasRead(true);
    setShowActivityLog(false);
    setAnchorNotification(null);
  };

  useEffect(() => {
    const notificationsRef = ref(db, 'notifications');
    onChildAdded(notificationsRef, () => setIsLogUpdated(prev => prev + 1));
  }, []);

  let projectSiteStyle = {
    zIndex: 99,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    backdropFilter: 'blur(10px)',
  };
  if (!props.isProjectSite) projectSiteStyle = {};
  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className="topbar navbar-text"
      style={{ ...projectSiteStyle }}
    >
      <Grid xs={6} justifyContent="flex-start" className="d-flex" style={{ zIndex: 100 }}>
        <Link to="/">
          <img src={logo_raise} height={18} />
        </Link>
      </Grid>

      <Grid container item xs={12} justifyContent="center" alignItems="center" className="position-absolute">
        {[
          {
            roles: ['developer', 'organization_admin', 'asset_manager', 'user'],
            link: '/project',
            icon: <ProjectSiteIcon />,
            title: 'Map View',
            page_access: true,
          },
          {
            roles: ['developer', 'organization_admin', 'asset_manager', 'user'],
            link: '/dashboard',
            selected: '/dashboard',
            icon: <DashboardIcon />,
            title: 'Dashboard',
            page_access: props.can_view_dashboard,
          },
          {
            roles: ['developer', 'organization_admin', 'asset_manager', 'user'],
            link: '/asset',
            icon: <BuildingIcon />,
            title: 'Project List',
            page_access: props.can_view_asset,
          },
          {
            roles: ['developer', 'organization_admin', 'asset_manager', 'user'],
            link: '/mapping-list',
            icon: <BuildingIcon />,
            title: 'Geo Processing',
            page_access: props.can_view_mapping_list && ![313].includes(props?.id),
          },
          {
            roles: ['developer'],
            link: '/mapping-processing',
            icon: <BuildingIcon />,
            title: 'GIS Processing',
            page_access: ['processing'].includes(props.raise_role),
          },
        ].map(nav => !!nav.page_access && (
          <Link to={nav.link}>
            <Grid
              item
              container
              alignItems="center"
              className="mx-4"
            >
              <h6
                style={{ fontSize: '14px', zIndex: 101 }}
                className={path.includes(nav.selected ?? nav.link)
                  ? `color-secondary ${props.isProjectSite ? 'shadow-selected' : ''}`
                  : `color-tertiary ${props.isProjectSite ? 'shadow-unselected' : ''}`}
              >
                {/* <span className="mx-1" style={{ opacity: path.includes(nav.selected ?? nav.link) ? 1 : 0.4 }}>
                  {nav.icon}
                </span> */}
                {nav.title}
              </h6>
            </Grid>
          </Link>
        ))}
      </Grid>

      <Grid
        justifyContent="flex-end"
        alignItems="center"
        xs={6}
        className="d-flex flex-row"
        style={{ cursor: 'pointer' }}
      >
        {!isOrgUnlimited
            && (
              <>
                <img src={tokenIcon} height={25} width={25} />&nbsp;
                <h3 className="text-center navbar-text" style={{ color: '#FEB019', fontSize: 14 }}>
                  {isOrgUnlimited
                    ? (<AllInclusive style={{ color: '#FEB019', width: 25 }} />)
                    : tokenFormatter(props.wallet)}
                </h3>
              </>
            )}
          &nbsp;&nbsp;&nbsp;
        {!!props.can_see_activity_log && (
          <div className="mr-4">
            <Badge badgeContent={hasRead ? 0 : activityLog.data?.length} color="secondary" onClick={(e) => handleClickNotification(e)}>
              <Notifications onClick={(e) => handleClickNotification(e)} style={{ backgroundColor: 'primary' }} />
            </Badge>
          </div>
        )}
        <Avatar {...props} />
        <div style={{ cursor: 'pointer', zIndex: 99999 }} onClick={handleClickMenu}>
          <p style={{ fontSize: 14, fontWeight: 600 }} className="mt-1 navbar-text">{props.name?.split(' ').slice(0, 2).join(' ')}</p>
          <ExpandMore onBlur={handleCloseMenu} onClick={handleClickMenu} />
        </div>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <MenuItem disabled className="d-flex justify-content-between">
            <div className="mr-2 navbar-text">{props.name?.split(' ').slice(0, 2).join(' ')}</div>
          </MenuItem>
          {/* {!!props.can_view_admin_panel && (
              <Link to="/admin-panel">
                <MenuItem className="py-0">
                  <span className="color-primary navbar-text">Admin Panel</span>
                </MenuItem>
              </Link>
            )} */}
          {/* {!!props.can_view_asset_files && (
              <Link to="/asset-file-list">
                <MenuItem className="py-0">
                  <span className="color-primary navbar-text">Asset Files</span>
                </MenuItem>
              </Link>
            )} */}
          {[1].includes(props.RoleId) && (
            <Link to="/data-mining">
              <MenuItem className="py-0">
                <span className="color-primary navbar-text">Data Mining</span>
              </MenuItem>
            </Link>
          )}
          {!!props.can_view_storage_analysis && (
            <Link to="/storage-analysis">
              <MenuItem className="py-0">
                <span className="color-primary navbar-text">Storage Analysis</span>
              </MenuItem>
            </Link>
          )}
          {(!isOrgUnlimited && !!props.can_view_transaction_history) && (
            <Link to="/transaction-overview">
              <MenuItem className="py-0">
                <span className="color-primary navbar-text">Transaction Overview</span>
              </MenuItem>
            </Link>
          )}
          {[1, 2].includes(props.RoleId) && (
            <Link to="/user">
              <MenuItem className="py-0">
                <span className="color-primary navbar-text">User Management</span>
              </MenuItem>
            </Link>
          )}
          {!!props.can_view_storage_monitoring && (
            <Link to="/storage-monitoring">
              <MenuItem className="py-0">
                <span className="color-primary navbar-text">Storage Monitoring</span>
              </MenuItem>
            </Link>
          )}
          {[2].includes(props.RoleId) && (
            <Link to="/analysis-management">
              <MenuItem className="py-0">
                <span className="color-primary navbar-text">Workflow Management</span>
              </MenuItem>
            </Link>
          )}
          {[2].includes(props.RoleId) && (
            <Link to="/module-management">
              <MenuItem className="py-0">
                <span className="color-primary navbar-text">Module Management</span>
              </MenuItem>
            </Link>
          )}
          {props.raise_role === 'monitoring' && (
            <Link to="/organization-summary">
              <MenuItem className="py-0">
                <span className="color-primary navbar-text">Organization Summary</span>
              </MenuItem>
            </Link>
          )}
          <Link to="/profile-page">
            <MenuItem className="py-0">
              <span className="color-primary navbar-text">Profile Page</span>
            </MenuItem>
          </Link>
          <Link to="/logout">
            <MenuItem className="py-0">
              <span className="color-primary navbar-text">Logout</span>
            </MenuItem>
          </Link>
        </Menu>
      </Grid>
      {showActivityLog && (
        <Popover
          open={Boolean(anchorNotification)}
          anchorEl={anchorNotification}
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <ActivityLog />
        </Popover>
      )}
    </Grid>
  );
}
