/* eslint-disable complexity */
import React from 'react';
import { diffInDays } from '@Helpers';
import { LinearProgress } from '@material-ui/core';

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
};

const cardCss = {
  display: 'flex',
  backgroundColor: 'white',
  borderRadius: 10,
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  padding: 13,
  position: 'relative',
  height: '100%',
  backgroundImage: 'linear-gradient(to right, white , #FF8500)',
};
const days_in_month = 30;

export default function index({ startDate, endDate }) {
  const project_duration = Math.ceil(diffInDays(startDate, endDate));
  const project_progress = Math.ceil(diffInDays(startDate, new Date()));
  const days_left = project_duration - project_progress;
  const months = Math.floor(days_left / days_in_month);
  const days = days_left % days_in_month;
  console.log('timeline', project_duration, project_progress, days_left, days_in_month, days);
  let label = `${days} ${ days > 1 ? 'days' : 'day'} left`;
  if (months) label = `${months} ${ months > 1 ? 'months' : 'month'} ${label}`;
  return (
    <div style={{ ...cardCss }}>
      <div style={{
        ...flexCenter, flex: 1, fontSize: 30, padding: 5,
      }}
      >
        <p>{(project_progress / project_duration * 100).toFixed(0)}%</p>
      </div>
      <div style={{
        ...flexCenter,
        flex: 2,
        fontSize: 10,
        flexDirection: 'column',
        padding: 17,
      }}
      >
        <LinearProgress
          variant="determinate"
          value={project_progress / project_duration * 100}
          style={{ width: '100%', flex: 1, borderRadius: '15px' }}
        />
        <span style={{ width: '100%', flex: 1 }}>({label})</span>
      </div>
    </div>
  );
}
