import MainContentContainer from '@Components/MainContentContainer';
import Navbar from '@Components/Navbar';
import useHook from './hook';
import ReportVerify from './ReportVerify';
import Result from './Result';

export default ({ user }) => {
  const h = useHook({ user });
  return (
    <MainContentContainer>
      <Navbar
        to={`/project/report?id=${h.AssetId}`}
        text="Non-Conformance Report"
        subtext="Part D"
      />
      {{
        0: <ReportVerify {...h} />,
        1: <Result {...h} />,
      }[h.activeStep]}
    </MainContentContainer>
  );
};
